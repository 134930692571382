import './SingleColumnLayout.scss';

const SingleColumnLayout = ({chart = <>chart 1</>, isFromOrg = false }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className={`single-column ${isFromOrg && 'device-uptime'}`}>
        <div>{chart}</div>
      </div>
    </div>
  );
};

export default SingleColumnLayout;