import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardContent } from '@mui/material';

import Header from './Header';
import { constants, Utils } from '../../helpers';

import './LinkExpired.scss';

const NoAccess = ({ orgName }) => {
  return (
    <div className="App no-access">
      <Header showNavigation={false} />
      <Container>
        <Row>
          <Col>
            <div className="no-access-card-container">
              <Card className="no-access-card">
                <CardContent>
                  <div className="no-access-card-title">
                    {constants.CLIP_LANDING_PAGE_NO_ACCESS_TITLE}
                  </div>
                  <div className="no-access-card-message">
                    {Utils.replaceStringValues(
                      constants.CLIP_LANDING_PAGE_NO_ACCESS_REASON,
                      '$orgName',
                      orgName ||
                        constants.CLIP_LANDING_PAGE_NO_ORGNAME_SUBSTITUTE
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoAccess;
