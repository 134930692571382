import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';

import { HiOutlineExclamation, HiOutlineCalendar } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';

import axios from 'axios';
import moment from 'moment';
import {
  Avatar,
  BreadcrumbList,
  Header,
  SiteSpinner,
  SiteToast,
} from '../../components/common';

import { ListTable } from './';
import { constants, Utils, roles } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

import './History.scss';

const gridFields = [
  {
    field: 'orderNumber',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_ORDER_NUMBER_FIELD,
  },
  {
    field: 'purchaseDate',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_PURCHASE_DATE_FIELD,
  },
  {
    field: 'purchasedBy',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_PURCHASE_BY_FIELD,
  },
  {
    field: 'invoiceNumber',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_INVOICE_NUMBER_FIELD,
  },
  {
    field: 'orderStatus',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_ORDER_STATUS_FIELD,
  },
  {
    field: 'paymentStatus',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_PAYMENT_STATUS_FIELD,
  },
  {
    field: 'fulfillmentStatus',
    displayName:
      constants.ORDER_HISTORY_PAGE_ORDER_HISTORY_TABLE_FULFILLMENT_STATUS_FIELD,
  },
];

const getColDefs = () => {
  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    displayName: gridField.displayName,
  }));
};

const DATE_FORMAT = 'll';

const History = () => {
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [hasData, setHasData] = useState(false);
  const [orgId, setOrgId] = useState(searchParams?.get('orgId'));
  const [orgName, setOrgName] = useState(
    searchParams?.get('orgName') || 'Duclo'
  );
  const [orderHistory, setOrderHistory] = useState([]);
  const [columnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState([]);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();

  const navigate = useNavigate();

  const breadCrumbs = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title: `${orgName}`,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId}&orgName=${orgName}`,
      title: constants.LICENSES_PAGE_TITLE,
    },
    {
      url: ``,
      title: constants.ORDER_HISTORY_PAGE_TITLE,
    },
  ];

  const orderStatusMap = {
    CANCELED: constants.ORDER_HISTORY_PAGE_ORDER_STATUS_CANCELED,
    CLOSED: constants.ORDER_HISTORY_PAGE_ORDER_STATUS_CLOSED,
    OPEN: constants.ORDER_HISTORY_PAGE_ORDER_STATUS_OPEN,
  };

  const paymentStatusMap = {
    PAID: constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_PAID,
    REFUNDED: constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_REFUNDED,
    REJECTED: constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_REJECTED,
    UNPAID: constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_UNPAID,
  };

  const fulfillmentStatusMap = {
    FULFILLED: constants.ORDER_HISTORY_PAGE_FULFILLMENT_STATUS_FULFILLED,
    PENDING_FULFILLMENT:
      constants.ORDER_HISTORY_PAGE_FULFILLMENT_STATUS_PENDING_FULFILLMENT,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRowData = (orderHistory) => {
    let orderProps = {};
    const rowData = [];
    const orderStatusMap = {
      CANCELED: (
        <div className="order-status-field">
          <FaCircle className="order-status-indicator order-status-canceled" />
          {constants.ORDER_HISTORY_PAGE_ORDER_STATUS_CANCELED}
        </div>
      ),
      CANCELLED: (
        <div className="order-status-field">
          <FaCircle className="order-status-indicator order-status-canceled" />
          {constants.ORDER_HISTORY_PAGE_ORDER_STATUS_CANCELED}
        </div>
      ),
      CLOSED: (
        <div className="order-status-field">
          <FaCircle className="order-status-indicator order-status-closed" />
          {constants.ORDER_HISTORY_PAGE_ORDER_STATUS_CLOSED}
        </div>
      ),
      DRAFTED: (
        <div className="order-status-field">
          <FaCircle className="order-status-indicator order-status-drafted" />
          {constants.ORDER_HISTORY_PAGE_ORDER_STATUS_DRAFTED}
        </div>
      ),
      OPEN: (
        <div className="order-status-field">
          <FaCircle className="order-status-indicator order-status-open" />
          {constants.ORDER_HISTORY_PAGE_ORDER_STATUS_OPEN}
        </div>
      ),
    };
    const paymentStatusMap = {
      UNPAID: (
        <div className="payment-status-field">
          <FaCircle className="payment-status-indicator payment-status-unpaid" />
          {constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_UNPAID}
        </div>
      ),
      PAID: (
        <div className="payment-status-field">
          <FaCircle className="payment-status-indicator payment-status-paid" />
          {constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_PAID}
        </div>
      ),
      REFUNDED: (
        <div className="payment-status-field">
          <FaCircle className="payment-status-indicator payment-status-refunded" />
          {constants.ORDER_HISTORY_PAGE_PAYMENT_STATUS_REFUNDED}
        </div>
      ),
    };
    const fulfillmenttStatusMap = {
      PENDING_FULFILLMENT: (
        <div className="fulfillment-status-field">
          <FaCircle className="fulfillment-status-indicator fulfillment-status-pending" />
          {constants.ORDER_HISTORY_PAGE_FULFILLMENT_STATUS_PENDING_FULFILLMENT}
        </div>
      ),
      FULFILLED: (
        <div className="fulfillment-status-field">
          <FaCircle className="fulfillment-status-indicator fulfillment-status-fulfilled" />
          {constants.ORDER_HISTORY_PAGE_FULFILLMENT_STATUS_FULFILLED}
        </div>
      ),
    };

    if (!Array.isArray(orderHistory)) return;

    orderHistory.forEach((order) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'PURCHASEDATE':
            orderProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in order
                  ? moment(order?.purchaseDate).format(DATE_FORMAT)
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            break;

          case 'PURCHASEDBY':
            orderProps[def?.field] = (
              <div className="buyer-field">
                {order?.imageUrl ? (
                  <Avatar
                    key={order?.orderNumber}
                    className="buyer-avatar-image"
                    valueType="icon"
                    value={order?.imageUrl}
                    size="medium"
                    avatarStyle="roundedCircle"
                  />
                ) : (
                  <div key={order?.orderNumber} className="buyer-avatar">{`${
                    order?.firstName?.charAt(0) || 'N'
                  }${order?.lastName?.charAt(0) || '/A'}`}</div>
                )}
                <span>{order[def?.field]}</span>
              </div>
            );
            break;

          case 'INVOICENUMBER':
            orderProps[def?.field] = order[def?.field]
              ? order[def?.field]
              : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT;
            break;

          case 'ORDERSTATUS':
            if (order[def?.field]) {
              orderProps[def?.field] =
                orderStatusMap[order[def?.field]?.toUpperCase()];
            } else {
              orderProps[def?.field] = (
                <div className="order-status-field">
                  <span></span>
                  {constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          case 'PAYMENTSTATUS':
            if (order[def?.field]) {
              orderProps[def?.field] =
                paymentStatusMap[order[def?.field]?.toUpperCase()];
            } else {
              orderProps[def?.field] = (
                <div className="payment-status-field">
                  <span></span>
                  {constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          case 'FULFILLMENTSTATUS':
            if (order[def?.field]) {
              orderProps[def?.field] =
                fulfillmenttStatusMap[order[def?.field]?.toUpperCase()];
            } else {
              orderProps[def?.field] = (
                <div className="fulfillment-status-field">
                  <span></span>
                  {constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          default:
            if (def?.field in order) {
              orderProps[def?.field] = order[def?.field];
            }
        }
      });

      // Add other props not needed for table display,
      orderProps = {
        ...order,
        ...orderProps,
      };
      rowData.push(orderProps);
      orderProps = {};
    });

    return rowData;
  };

  const fetchData = useCallback(async () => {
    try {
      setShowLoader(true);
      const orderHistoryData = await fetchOrderHistory();
      // Fetch avatar URL for each purchaser
      const orderHistoryPromises = orderHistoryData.map(
        async (orderHistory) => {
          const orderAccount = await Utils.geAccountDetailsByAccountId(
            orgId,
            orderHistory?.accountId
          );

          return {
            ...orderHistory,
            firstName: orderAccount?.firstName,
            lastName: orderAccount?.lastName,
            imageUrl: orderAccount?.image?.url,
          };
        }
      );

      const orderHistory = await Promise.all(orderHistoryPromises);

      const tableRowData = Array.isArray(orderHistory)
        ? getRowData(orderHistory)
        : [];

      setOrderHistory(orderHistory);
      setRowData(tableRowData);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      setShowLoader(false);
    }
  }, []);

  const fetchOrderHistory = async () => {
    let orderHistory = [];
    try {
      const res = await axios.get(
        `/user/orgs/${orgId}/orders`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (Array.isArray(responseData?.data)) {
          // Filter out orders that have not initiated the payment process
          orderHistory = responseData.data?.filter(
            (data) => data?.orderStatus?.toUpperCase() !== 'DRAFTED'
          );

          // Sort the order history by order number
          orderHistory.sort((orderA, orderB) => {
            if (orderA.orderNumber < orderB.orderNumber) {
              return -1;
            }

            if (orderA.orderNumber > orderB.orderNumber) {
              return 1;
            }

            return 0;
          });
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return orderHistory;
    }
  };

  return (
    <div className="App order-history">
      <Header />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className='mw-100'>
              {showLoader ? (
                <div className="spinner-container">
                  <SiteSpinner height="50px" width="50px" />
                </div>
              ) : (
                <div className="page-header mt-4 mb-5">
                  <Row xs={1} md={2} className="g-4">
                    <Col>
                      <BreadcrumbList list={breadCrumbs} />
                    </Col>
                  </Row>
                  <Row>
                    <div className="page-title-wrapper">
                      <span className="page-title">
                        {constants.ORDER_HISTORY_PAGE_TITLE}
                      </span>
                    </div>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="order-history-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="order-history-table-container">
                        <ListTable
                          colsDef={columnDefs}
                          colSorting={true}
                          rowData={rowData}
                          statusMap={orderStatusMap}
                          // rowClickHandler={(orderId, orderNumber) => {
                          rowClickHandler={(order) => {
                            const stringifiedOrderObject =
                              JSON.stringify(order);

                            navigate(
                              `/orders/details.html?orgId=${orgId}&orgName=${orgName}&oi=${
                                order?.orderId
                              }&on=${order?.orderNumber}&os=${JSON.stringify(
                                order?.orderStatus
                              )}&ai=${order?.accountId}&in=${
                                order?.invoiceNumber
                              }&ps=${order?.paymentStatus}&pd=${JSON.stringify(
                                order?.paymentUpdateDate
                              )}&pb=${order?.purchasedBy}&pd=${
                                order?.purchaseDate
                              }`
                            );

                            // navigate(
                            //   `/orders/details.html?orgId=${orgId}&orgName=${orgName}&order=${encodeURIComponent(
                            //     stringifiedOrderObject
                            //   )}`
                            // );
                          }}
                          cssClasses="order-history-table"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default History;
