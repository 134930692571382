import { memo } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useCart } from '../../store/CartStore';
import { HiOutlineMinus, HiOutlinePlusSm } from 'react-icons/hi';

const QuantityModifier = memo(({ sku, quantity, quantityClassName }) => {
  const addToCart = useCart((state) => state.addToCart);
  const removeFromCart = useCart((state) => state.removeFromCart);

  return (
    <InputGroup
      id={`ig${sku}`}
      className={
        quantityClassName
          ? `quantity-modifier ${quantityClassName}`
          : `quantity-modifier`
      }
      size="md"
    >
      <Button
        variant="primary"
        className="decrement-btn"
        onClick={() => removeFromCart(sku)}
      >
        <HiOutlineMinus />
      </Button>
      <Form.Control
        className="quantity-form-control"
        aria-label="quantity modifier"
        readOnly
        htmlSize="20px"
        value={quantity}
      />
      <Button
        variant="primary"
        className="increment-btn"
        onClick={() => addToCart(sku)}
      >
        <HiOutlinePlusSm />
      </Button>
    </InputGroup>
  );
});

export default QuantityModifier;
