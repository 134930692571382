import store from '../../../../../store/Store';
import {
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setLoadingStream,
  setMultiLiveStreamLoader,
  setRecievedOffers,
  setRemoteStreams,
  setStremBitmapsData,
} from '../../../../../store/reducers/StreamingReducer';
import * as mqtt from '../../../../../utils/connection/mqttConnection';
import Store from '../../../../../store/Store';
import { Utils } from '../../../../../helpers';

let mqttPeerConnections = {};
let offerSendDeviceList = [];

export const getLocalStreamLive = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId,
  deviceName
) => {
  createMQTTPeerConnection(
    publishTopic,
    serverDetails,
    accountId,
    deviceId,
    hubId,
    uuid,
    streamId,
    deviceName
  );
};

const createMQTTPeerConnection = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId,
  deviceName
) => {
  const { stun, turn } = serverDetails;
  const iceConfiguration = {
    iceServers: [
      {
        urls: `${stun.protocol}:${stun.host}:${stun.port}`,
      },
      {
        urls: `${turn.protocol}:${turn.host}:${turn.port}`,
        username: `${turn.userName}`,
        credential: `${turn.password}`,
      },
    ],
    // Todo: Delete later - keep it for now as connection is not finalyzed yet
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  let mqttPeerConnection;
  if (mqttPeerConnections?.[deviceId]) {
    mqttPeerConnection = mqttPeerConnections[deviceId];
    if (mqttPeerConnection.connectionState === 'closed' || mqttPeerConnection.connectionState === 'failed') {
      handleLeaveCall(deviceId);
      setTimeout(() => {
        createMQTTPeerConnection(
          publishTopic,
          serverDetails,
          accountId,
          deviceId,
          hubId,
          uuid,
          streamId
        );
      }, 100);
    }
  } else {
    mqttPeerConnection = new RTCPeerConnection(iceConfiguration);

    mqttPeerConnection.addTransceiver('video', {
      direction: 'recvonly',
    });

    // Will test once update on live streaming from firmware
    mqttPeerConnection.addTransceiver('audio', {
      direction: 'recvonly',
    });

    mqttPeerConnection.ontrack = ({ streams: [stream] }) => {
      Utils.vmsLogger().log('Livestream on track', stream, '-', getTimesinmili());
      if (stream.getVideoTracks().length) {
        const track = stream.getVideoTracks()[0];
        // if (typeof ImageCapture !== 'undefined') {
        //   setTimeout(() => {
        //     new ImageCapture(track)
        //       ?.grabFrame()
        //       ?.then((imageBitmap) => {
        //         const data = {
        //           StreamHeight: imageBitmap?.height,
        //           StreamWidth: imageBitmap?.width,
        //         };
        //         //TODO DELETE LATER
        //         Utils.vmsLogger().log('imageBitmap', data);
        //         store.dispatch(
        //           setStremBitmapsData({ id: deviceId, bitmapObject: data })
        //         );
        //         if (data) {
        //           const updatedObj = {
        //             deviceId: deviceId,
        //             isLoading: false,
        //           };
        //           store.dispatch(setMultiLiveStreamLoader(updatedObj));
        //         }
        //       })
        //       .catch((error) => {
        //         //TODO DELETE LATER
        //         const updatedObj = {
        //           deviceId: deviceId,
        //           isLoading: false,
        //         };
        //         store.dispatch(setMultiLiveStreamLoader(updatedObj));
        //         Utils.vmsLogger().error('grabFrame() error: ', error);
        //       });
        //   }, 2000);
        // } else {
          const video = document.createElement('video');
          video.setAttribute('id', 'video-c-' + deviceId);
          video.srcObject = new MediaStream([track]);
          const handleLoadedMetadata = () => {
            // const aspectRatio = 16 / 9;
            let width = video.videoWidth;
            let height = video.videoHeight;

            //TODO: DELETE later
            // As the parent div is already in 16/9 aspect ratio no need to calculate this.

            // Calculate dimensions maintaining aspect ratio
            // if (width / height > aspectRatio) {
            //   width = height * aspectRatio;
            // } else {
            //   height = width / aspectRatio;
            // }
          
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, width, height);
            // const imageData = ctx.getImageData(0, 0, width, height);            
            const data = {
              StreamHeight: height,
              StreamWidth: width,
              // You may need to handle imageData to send it or process it further
            };
            store.dispatch(
              setStremBitmapsData({ id: deviceId, bitmapObject: data })
            );          
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
          };
          video.addEventListener('loadedmetadata', handleLoadedMetadata);
          video.play().catch((error) => {
            Utils.vmsLogger().error('Error playing video: ', error);
          });
        // }
        if(stream?.active) {
          let videoElement = document.getElementById('video' + deviceId + deviceId);
          if(videoElement) {
            videoElement.srcObject = stream;
            Utils.vmsLogger().log("Livestream on track element", getTimesinmili(), '-', stream);
            videoElement.addEventListener('loadeddata', handleFirstFrame);
          }
        };
        store.dispatch(setRemoteStreams({ id: deviceId, stream: stream }));
      }
    };

    mqttPeerConnection.onicecandidate = (event) => {
      Utils.vmsLogger().log("LiveStream: onicecandidate ~ candidate:",  '-',deviceId, '-', getTimesinmili());
      if (event.candidate) {
        let candidate_data = JSON.parse(JSON.stringify(event.candidate));
        candidate_data.type = 'candidate';
        const time = Math.floor(new Date().getTime() / 1000.0);
        candidate_data.id = `${streamId}`;
        const payload = {
          tid: `${time}`,
          to: hubId,
          from: accountId,
          msg: {
            resource: `ch/${deviceId}/camera/streaming`,
            properties: { ...candidate_data },
          },
          publish: `d/notify/${accountId}/${uuid}`,
        };
        mqtt.sendWebRTCCandidate(publishTopic, payload);
      }
    };

    mqttPeerConnection.onconnectionstatechange = (event) => {
      // TODO: delete later
      if (mqttPeerConnection.connectionState === 'connected') {
        store.dispatch(setRecievedOffers({ id: deviceId, value: true }));
        // TODO: delete later
        Utils.vmsLogger().log('LiveStream: peer connection connected', deviceId, '-', getTimesinmili());
        Store.dispatch(setLoadingStream(false));
      }
      if (mqttPeerConnection.connectionState === 'disconnected') {
        Utils.vmsLogger().log('LiveStream: peer connection disconnected', deviceId, '-', getTimesinmili());
      }
      if (mqttPeerConnection.connectionState === 'failed') {
        Utils.vmsLogger().log('LiveStream: peer connection failed', deviceId, '-', getTimesinmili());
        getLocalStreamLive(
          publishTopic,
          serverDetails,
          accountId,
          deviceId,
          hubId,
          uuid,
          streamId
        );
      }
    };

    // store.dispatch(
    //   setMQTTPeerConnections({ id: deviceId, client: mqttPeerConnection })
    // );
    mqttPeerConnections[deviceId] = mqttPeerConnection;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(isSafari) {
      setTimeout(() => {
        sendOffer(publishTopic, accountId, deviceId, hubId, uuid, streamId, deviceName);
      }, 2500);
    } else {
      setTimeout(() => {
        sendOffer(publishTopic, accountId, deviceId, hubId, uuid, streamId, deviceName);
      }, 100);
    }
  }
};

const getTimesinmili = () => {
  const time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds();
  return time;
}

export const sendOffer = async (
  publishTopic,
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId,
  deviceName
) => {
  //TODO Delete later
  Utils.vmsLogger().log('LiveStream: offer send', deviceId,'-', deviceName, '-', getTimesinmili());
  const mqttPeerConnection = mqttPeerConnections?.[deviceId];
  const offer = await mqttPeerConnection?.createOffer({
    offerToReceiveVideo: true,
    offerToReceiveAudio: true,
  });
  await mqttPeerConnection?.setLocalDescription(offer);
  const time = Math.floor(new Date().getTime() / 1000.0);
  const payload = {
    tid: `${time}`,
    to: hubId,
    from: accountId,
    msg: {
      resource: `ch/${deviceId}/camera/streaming`,
      properties: {
        id: `${updatedStreamId}`,
        type: 'offer',
        sdp: offer?.sdp,
      },
    },
    publish: `d/notify/${accountId}/${uuid}`,
  };
  mqtt.sendWebRTCOffer(publishTopic, payload);
  offerSendDeviceList.push(deviceId);
};

export const getSentOfferDevice = () => offerSendDeviceList;
export const setSentOfferDevice = () => offerSendDeviceList = [];

export const handleAnswer = async (data, deviceId) => {
  Utils.vmsLogger().log('Peer : handle answer receive', deviceId, '-', getTimesinmili());
  const mqttPeerConnection = mqttPeerConnections?.[deviceId];
  await mqttPeerConnection
    ?.setRemoteDescription(data)
    ?.then((data) => {
      Utils.vmsLogger().log('Peer : setRemoteDescription ', deviceId, '-', getTimesinmili());
    })
    .catch((error) =>
      Utils.vmsLogger().log('')
    );
};

export const handleCandidate = async (candidate, deviceId) => {
  console.log("Livestream handleCandidate ~ candidate:",deviceId,'-', candidate, '-', getTimesinmili())
  try {
    if (!candidate) return;
    const mqttPeerConnection = mqttPeerConnections?.[deviceId];

    if (mqttPeerConnection) {
      if (!candidate.candidate) {
        await mqttPeerConnection.addIceCandidate(null);
      } else {
        await mqttPeerConnection.addIceCandidate(candidate);
      }
    }
  } catch (err) {
    // TODO: Delete later
    Utils.vmsLogger().error(
      'error occurred when trying to add received ice candidate',
      err
    );
  }
};

export const handleLeaveCall = (deviceId) => {
  let mqttPeerConnection = mqttPeerConnections?.[deviceId];
  if (mqttPeerConnection && mqttPeerConnection?.close) {
    mqttPeerConnection.close();
    mqttPeerConnection.oniceconnectionstatechange = null;
    mqttPeerConnection.onicecandidate = null;
    mqttPeerConnection.ontrack = null;
    mqttPeerConnection.onconnectionstatechange = null;
    mqttPeerConnection = null;
    const video = document.getElementById('video-c-' + deviceId);
    if (video) {
      video.removeEventListener('loadedmetadata', () => {});
    }
  }
  let videoElement = document.getElementById('video' + deviceId + deviceId);
  if (videoElement) {
    videoElement.removeEventListener('loadeddata', handleFirstFrame);
  }
  store.dispatch(removeRemoteStreams(deviceId));
  store.dispatch(removeRemoteStreamsAudio(deviceId));
  delete mqttPeerConnections[deviceId];  
};

const handleFirstFrame = (event) => {
  const deviceId = event?.target?.dataset?.deviceId;
  Utils.vmsLogger().log('LiveStream: First frame render', event.target.dataset.deviceId, '-', getTimesinmili());
  const updatedObj = {
    deviceId: deviceId,
    isLoading: false,
  };
  store.dispatch(setMultiLiveStreamLoader(updatedObj));
}
