import { Outlet } from 'react-router-dom';
import IncidentDetails from './IncidentDetails';
import ListTable from './ListTable';
import ManageIncidents from './ManageIncidents';

const Incidents = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { IncidentDetails, Incidents, ListTable, ManageIncidents };
