import Store from './Store';
import { getItem, setItem } from './indexDB/db';

export const getSelectedOrganization = async () => {
  return await getItem('selectedOrganization');
};

export const setSelectedOrganization = async (devices) => {
  return await setItem('selectedOrganization', devices);
};

export const getAllDevicesData = async () => {
  const result = await getItem('allDevices');
  return result || [];
};

export const setAllDevicesData = async (devices) => {
  const data = [...devices];
  data?.sort(function (a, b) {
    if (a?.deviceName < b?.deviceName) {
      return -1;
    }
    if (a?.deviceName > b?.deviceName) {
      return 1;
    }
    return 0;
  });
  return await setItem('allDevices', data);
};

export const getAllDevicesDataNotifiction = async () => {
  const result = await getItem('notificationDevices');
  return result || [];
};

export const setAllDevicesDataNotification = async (devices) => {
  const data = [...devices];
  data?.sort(function (a, b) {
    if (a.deviceName < b.deviceName) {
      return -1;
    }
    if (a.deviceName > b.deviceName) {
      return 1;
    }
    return 0;
  });
  return await setItem('notificationDevices', data);
};

// export const getAllMqttDataFromResponse = async () => {
//   const result = await getItem('allMqttDataFromResponse');
//   return result || {};
// };

// export const setAllMqttDataFromResponse = async (data) => {
//   return await setItem('allMqttDataFromResponse', data);
// };

// export const resetAllMqttDataFromResponse = async () => {
//   return await setItem('allMqttDataFromResponse', {});
// };

export const getPassedDeviceInfoFromDeviceList = async () => {
  return await getItem('passedDeviceInfoFromDeviceList');
};

export const setPassedDeviceInfoFromDeviceList = async (devices) => {
  return await setItem('passedDeviceInfoFromDeviceList', devices);
};

export const resetAllDevicesData = async () => {
  return await setItem('allDevices', []);
};
