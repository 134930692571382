import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  RangeInputField,
  SelectField,
  TextField,
  ToggleInput,
} from '../../../../components/forms';
import { Formik } from 'formik';
import { AppDefaults, constants, Utils } from '../../../../helpers';
// TODO : use later once have the aPI
// import axios from 'axios';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './AudioSettingsBlock.scss';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { useSelector } from 'react-redux';
import {
  getAllMqttDataFromResponse
} from '../../../../store/reducers/AccountReducer';

const AudioSettingsBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  deviceInfos,
  accountId,
  devicePublishId,
  uuid,
  updateStore,
  hasManagePermission,
}) => {
  const formRef = useRef();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [deviceSettings, setDeviceSettings] = useState([]);
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(true);
  const [streamWarningPopUpShow, setStreamWarningPopUpShow] = useState(false);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const resourceList = Utils.getDeviceSettingResource(3);
  const capAudioInput = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0]
  );
  const audioInSource = Utils.getDropDownPair(
    capAudioInput?.['audio-in-source']
  );
  const capAudioOutput = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1]
  );

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [audioInWarningModal, setAudioInWarningModal] = useState(false);
  const [audioOutWarningModal, setAudioOutWarningModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState();

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  useEffect(() => {
    if (formRef?.current && allMqttData) {
      if(allMqttData.msg && allMqttData.msg.properties && 'audio-in-enable' in allMqttData.msg.properties){
        if(formRef?.current?.values){
          formRef.current.setFieldValue('audioInhideShow', allMqttData.msg.properties['audio-in-enable']);
          formRef.current.values.audioInhideShow = allMqttData.msg.properties['audio-in-enable'];
        }
      }     
    }
  }, [allMqttData]);

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title">
        <label>{constants.DEVICES_TAB_AUDIO_SETTINGS}</label>
      </div>

      {curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS && (
        <div className="audio-page-offline-message">
          <div className="left-section">
            <HiOutlineExclamationCircle
              size={20}
              stroke={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_24')}
            />
          </div>
          <div className="right-section">
            <div className="title-heading">
              {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING}
            </div>
            <div className="sub-title-heading">
              {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE}
            </div>
          </div>
        </div>
      )}
      {deviceInfos?.properties?.['audio-in-codec']?.toLowerCase() !==
        AppDefaults.AUDIO_CODE_AAC && (
        <Alert variant={variant} className="custom-alert" dismissible>
          <Row className="alert-aac">
            <Col md={1} className="w-md2">
              <HiOutlineExclamationCircle
                size={15}
                stroke={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_24')}
              />
            </Col>
            <Col className="p-0 m-t-2">
              <>
                <p>{constants.AUDIO_WILL_NOT_PLAY}</p>
              </>
            </Col>
          </Row>
        </Alert>
      )}
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          audioInSource: deviceInfos?.properties?.['audio-in-source']
            ? deviceInfos?.properties?.['audio-in-source']
            : '',
          volume: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-in-gain'],
            false
          ),
          sensitivity: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-in-sensitivity'],
            false
          ),
          noiseReduction:
            deviceInfos?.properties?.['audio-in-noise-reduction'] ===
            constants.PROPERTY_STATUS_TRUE,
          audioOutVolume: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-out-gain'],
            false
          ),
          audioInhideShow:
            deviceInfos?.properties?.['audio-in-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          audioOuthideShow:
            deviceInfos?.properties?.['audio-out-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
          // TODO : Delete later
          // Utils.vmsLogger().log(values);
          setSubmitting(true);
          setVariant('danger');
          setUserMsg('');
          setSubmitting(false);
          saveAudioSettingsCallback(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <Row className="settings-block audio-in-title">
              <Col className="audio-text-toggle">
                <label className="settings-block-title">
                  {constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_LABEL}
                </label>
                <ToggleInput
                  name="audioIn"
                  className="toggle-btn"
                  value={values.audioInhideShow}
                  changeHandler={() => {
                    setSelectedValues(values);
                    setAudioInWarningModal(true);
                  }}
                  // }}
                  // disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                  // disabled={!canChangeSettings}
                />
              </Col>
            </Row>
            {values.audioInhideShow && (
              <>
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'audio-in-source',
                  capAudioInput
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <SelectField
                        name="audioInSource"
                        options={audioInSource}
                        label=""
                        defaultOptionLabel={
                          constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_SEARCH_LABEL
                        }
                        onChange={(evebr) => {
                          let selectedVal = evebr?.target?.value;
                          setFieldValue('audioInSource', selectedVal);
                          const property = {
                            'audio-in-source': selectedVal,
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[0],
                            publishPayload
                          );
                        }}
                        placeholderOptionClass="d-none"
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    </Col>
                  </Row>
                )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'audio-in-gain',
                  capAudioInput
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <RangeInputField
                        label={
                          constants.DEVICES_TAB_AUDIO_SETTINGS_VOLUME_LABEL
                        }
                        fieldName="volume"
                        minValueLabel={constants.MIN_VALUE_LABEL}
                        maxValueLabel={constants.MAX_VALUE_LABEL}
                        maxValue={Utils.getPropertyValueIfExist(
                          capAudioInput?.['audio-in-gain']?.max,
                          true
                        )}
                        minValue={Utils.getPropertyValueIfExist(
                          capAudioInput?.['audio-in-gain']?.min,
                          false
                        )}
                        currentValue={values.volume}
                        changeHandler={(value) => {
                          setFieldValue('volume', value);
                          const property = {
                            'audio-in-gain': value.toString(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[0],
                            publishPayload
                          );
                        }}
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    </Col>
                  </Row>
                )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'audio-in-sensitivity',
                  capAudioInput
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <RangeInputField
                        label={
                          constants.DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL
                        }
                        fieldName="sensitivity"
                        minValueLabel={constants.MIN_VALUE_LABEL}
                        maxValueLabel={constants.MAX_VALUE_LABEL}
                        maxValue={Utils.getPropertyValueIfExist(
                          capAudioInput?.['audio-in-sensitivity']?.max,
                          true
                        )}
                        minValue={Utils.getPropertyValueIfExist(
                          capAudioInput?.['audio-in-sensitivity']?.min,
                          false
                        )}
                        currentValue={values.sensitivity}
                        thumbLabelHide={true}
                        changeHandler={(value) => {
                          setFieldValue('sensitivity', value);
                          const property = {
                            'audio-in-sensitivity': value.toString(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[0],
                            publishPayload
                          );
                        }}
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    </Col>
                  </Row>
                )}
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'audio-in-noise-reduction',
                  capAudioInput
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <ToggleInput
                        label={
                          constants.DEVICES_TAB_AUDIO_SETTINGS_NOISE_REDUCTION_LABEL
                        }
                        name="noiseReduction"
                        value={values.noiseReduction}
                        changeHandler={() => {
                          setFieldValue(
                            'noiseReduction',
                            !values.noiseReduction
                          );
                          const status = getCheckPropertyStatus(
                            !values.noiseReduction,
                            'audio-in-noise-reduction'
                          );
                          updateStore(status);
                          publishDeviceSetting(
                            status,
                            resourceList[0],
                            publishPayload
                          );
                        }}
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            <Row className="settings-block audio-in-title ">
              <Col className="audio-text-toggle">
                <label className="settings-block-title">
                  {constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_OUT_LABEL}
                </label>
                <ToggleInput
                  name="audioOut"
                  className="toggle-btn"
                  value={values.audioOuthideShow}
                  changeHandler={() => {
                    setSelectedValues(values);
                    setAudioOutWarningModal(true);
                  }}
                  // disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                  // disabled={!canChangeSettings}
                />
              </Col>
            </Row>
            {values.audioOuthideShow && (
              <>
                {Utils.getPropertyShowStatus(
                  curDeviceStatus,
                  'audio-out-enable',
                  capAudioOutput
                ) && (
                  <Row className="settings-block">
                    <Col>
                      <RangeInputField
                        label={
                          constants.DEVICES_TAB_AUDIO_SETTINGS_VOLUME_LABEL
                        }
                        fieldName="audioOutVolume"
                        minValueLabel={constants.MIN_VALUE_LABEL}
                        maxValueLabel={constants.MAX_VALUE_LABEL}
                        maxValue={Utils.getPropertyValueIfExist(
                          capAudioOutput?.['audio-out-gain']?.max,
                          true
                        )}
                        minValue={Utils.getPropertyValueIfExist(
                          capAudioOutput?.['audio-out-gain']?.min,
                          false
                        )}
                        currentValue={values.audioOutVolume}
                        changeHandler={(value) => {
                          setFieldValue('audioOutVolume', value);
                          const property = {
                            'audio-out-gain': value.toString(),
                          };
                          updateStore(property);
                          publishDeviceSetting(
                            property,
                            resourceList[1],
                            publishPayload
                          );
                        }}
                        disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
                        disabled={!canChangeSettings}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
      <SiteModal
        modalTitle={
          constants.DEVICES_TAB_AUDIO_SETTING_AUDIO_IN_OUT_WARNING_TITLE
        }
        showModal={audioInWarningModal}
        hideModal={() => {
          setAudioInWarningModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--custom_color_19'
          )}
        >
          <div>
            {' '}
            {
              constants.DEVICES_TAB_AUDIO_SETTING_AUDIO_IN_OUT_WARNING__SUB_CONTENT
            }{' '}
          </div>
        </TextBlock>
        <PrimaryButton
          className="btn mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_primary')}
          height="56px"
          fontSize="1rem"
          lineHeight="24px"
          onClick={() => {
            setAudioInWarningModal(false);
            if (selectedValues) {
              formRef.current.setFieldValue(
                'audioInhideShow',
                !selectedValues.audioInhideShow
              );
              selectedValues.audioInhideShow = !selectedValues.audioInhideShow;
              const property = {
                'audio-in-enable': !selectedValues.audioInhideShow
                  ? 'false'
                  : 'true',
              };
              updateStore(property);
              publishDeviceSetting(property, resourceList[0], publishPayload);
            }
          }}
        >
          {constants.DEVICES_CONFIRM_TEXT}
        </PrimaryButton>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setAudioInWarningModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>
      <SiteModal
        modalTitle={
          constants.DEVICES_TAB_AUDIO_SETTING_AUDIO_IN_OUT_WARNING_TITLE
        }
        showModal={audioOutWarningModal}
        hideModal={() => {
          setAudioOutWarningModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--custom_color_19'
          )}
        >
          <div>
            {' '}
            {
              constants.DEVICES_TAB_AUDIO_SETTING_AUDIO_IN_OUT_WARNING__SUB_CONTENT
            }{' '}
          </div>
        </TextBlock>
        <PrimaryButton
          className="btn mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_primary')}
          height="56px"
          fontSize="1rem"
          lineHeight="24px"
          onClick={() => {
            setAudioOutWarningModal(false);
            if (selectedValues) {
              formRef.current.setFieldValue(
                'audioOuthideShow',
                !selectedValues.audioOuthideShow
              );
              selectedValues.audioOuthideShow =
                !selectedValues.audioOuthideShow;
              const property = {
                'audio-out-enable': !selectedValues.audioOuthideShow
                  ? 'false'
                  : 'true',
              };
              updateStore(property);
              publishDeviceSetting(property, resourceList[1], publishPayload);
            }
          }}
        >
          {constants.DEVICES_CONFIRM_TEXT}
        </PrimaryButton>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setAudioOutWarningModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
          </Col>
        </Row>
      </SiteModal>
    </div>
  );
};

export default AudioSettingsBlock;
