import PrivateRoute from '../auth/PrivateRoute';
import PublicRoute from '../auth/PublicRoute';
import React, { Suspense, lazy } from 'react';

// import { accountVerifyRoute } from './AccountVerifyRoute';
import SignIn from '../auth/SignIn';
import BecameApartner from '../pages/marketing/BecameApartner';
import BecameAcustomer from '../pages/marketing/BecameAcustomer';
import SuccessPartner from '../pages/marketing/SuccessPartner';
import SuccessCustomer from '../pages/marketing/SuccessCustomer';
import NewLandingPage from '../pages/marketing/NewLandingPage';
import {
  OrganizationDashboard as CustomerDashboard,
  Organizations as Customers,
} from '../pages/organizations';
import { CamerasPage } from '../pages/cameras';
import { LiveView as Live, MultiViewSelector } from '../pages/live';
import POSlinking from '../pages/organizations/POSlinking';
import CameraLinking from '../pages/cameras/CameraLinking';
import { Licenses } from '../pages/licenses';
import { Clips } from '../pages/clips';
import { AccessClip, LinkExpired, NoAccess } from '../pages/external';
import { Utils } from '../helpers';
import { Incidents } from '../pages/incidents';
import { EditLocation, Locations, NewLocation } from '../pages/locations';
import { NewUser, Users } from '../pages/users';
import { NewRole, Roles } from '../pages/customRoles';
import { ProductListing, Products } from '../pages/products';
import PageNotFound from '../pages/PageNotFound';
import {
  Purchase,
  PurchaseConfirmation,
  PurchaseSummary,
} from '../pages/purchase';
import { OrderDetails, OrderHistory, Orders } from '../pages/orders';
import ComingSoon from '../pages/ComingSoon';
import { Devices } from '../pages/devices';
import PartnerInvitation from '../pages/organizations/PartnerInvitation';
import { Box } from '@mui/material';
import { SiteSpinner } from '../components/common';
import ChannelDetails from '../pages/devices/listDevice/ChannelDetails';
import AnalyticsDashboard from '../pages/analytics/AnalyticsDashboard';
import NotificationSetting from '../pages/notifications/NotificationSetting';
import OrganizationDetails from '../pages/organizations/OrganizationDetails';

const Loading = () => (
  <Box
    width="100%"
    alignItems="center"
    display="flex"
    justifyContent="center"
    height="100vh"
  >
    <SiteSpinner height="50px" width="50px"></SiteSpinner>
  </Box>
);

const shouldHideLogin = process.env.REACT_APP_HIDE_LOGIN === 'true';
const moduleStatus = Utils.getModuleStatus();
const Cameras = lazy(() => import('../pages/cameras/Cameras'));
const CameraList = lazy(() => import('../pages/cameras/CameraList'));
const CameraDashboard = lazy(() => import('../pages/cameras/CameraDashboard'));
const AccountVerify = lazy(() => import('../auth/AccountVerify'));
const ManageCustomers = lazy(() =>
  import('../pages/organizations/ManageOrganizations')
);
const EditOrganization = lazy(() =>
  import('../pages/organizations/EditOrganization')
);
const AccountSettings = lazy(() => import('../pages/settings/AccountSettings'));
const OrganizationSettings = lazy(() =>
  import('../pages/settings/OrganizationSettings')
);
const UpdatePassword = lazy(() => import('../pages/settings/UpdatePassword'));
const ViewDetails = lazy(() => import('../pages/analytics/ViewDetails'));
const PosIntegration = lazy(() =>
  import('../pages/organizations/PosIntegration')
);
const ManageLicenses = lazy(() => import('../pages/licenses/ManageLicenses'));
const ClipDetails = lazy(() => import('../pages/clips/ClipDetails'));
const ClipsList = lazy(() => import('../pages/clips/ClipsList'));
const IncidentDetails = lazy(() =>
  import('../pages/incidents/IncidentDetails')
);
const ManageIncidents = lazy(() =>
  import('../pages/incidents/ManageIncidents')
);
const ManageLocations = lazy(() =>
  import('../pages/locations/ManageLocations')
);
const ManageUsers = lazy(() => import('../pages/users/ManageUsers'));
const ManageRoles = lazy(() => import('../pages/customRoles/ManageRoles'));
const CustomRoleDetails = lazy(() =>
  import('../pages/customRoles/CustomRoleDetails')
);
const Device = lazy(() => import('../pages/devices/listDevice/Device'));
const DeviceDashboard = lazy(() =>
  import('../pages/devices/listDevice/DeviceDashboard')
);
const DiscoveredDevices = lazy(() =>
  import('../pages/devices/listDevice/DiscoveredDevices')
);
const ManagePartnerUsers = lazy(() =>
  import('../pages/partnerUsers/ManagePartnerUsers')
);
const ManageTechnicians = lazy(() =>
  import('../pages/technicians/ManageTechnicians')
);
const Events = lazy(() => import('../pages/events/Events'));
const NotificationDashboard = lazy(() =>
  import('../pages/notifications/NotificationDashboard')
);
const ExportIncidentBundle = lazy(() =>
  import('../pages/incidents/ExportIncidentBundle')
);

const routes = [
  {
    path: '/',
    element: <PublicRoute />,
    children: shouldHideLogin
      ? [{ path: '/', element: <NewLandingPage /> }]
      : [
          { path: '/', element: <SignIn /> },
          { path: 'login', element: <SignIn /> },
          { path: '/null', element: <SignIn /> },
          { path: '/null/*', element: <SignIn /> },
        ],
  },
  {
    path: '/',
    element: <PublicRoute />,
    children: [
      { path: '/createpartnerorg', element: <BecameApartner /> },
      { path: '/becomeacustomer', element: <BecameAcustomer /> },
      { path: '/successpartner', element: <SuccessPartner /> },
      { path: '/successcustomer', element: <SuccessCustomer /> },
      { path: '/pos', element: <POSlinking /> },
      { path: '/cameraDetails', element: <CameraLinking /> },
      { path: '/shared-clip.html', element: <LinkExpired /> },
      { path: '*', element: <PageNotFound /> },
      { path: '/partnerinvite', element: <PartnerInvitation /> },
      { path: '/external/clips/access-clip.html', element: <AccessClip /> },
      { path: '/clip', element: <AccessClip /> },
      { path: '/external/expired-link.html', element: <LinkExpired /> },
      { path: '/external/no-access.html', element: <NoAccess /> },
    ],
  },
  {
    path: '/customers',
    element: (
      <PrivateRoute>
        <Customers />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'manage.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageCustomers />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboard.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <OrganizationDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'editOrganization.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <EditOrganization />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/editOrganization.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <EditOrganization />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/cameras',
    element: (
      <PrivateRoute>
        <CamerasPage />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'devices.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <Cameras />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'listing.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <CameraList />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboard.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <CameraDashboard />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'live.html',
        element: (
          <PrivateRoute>
            <Live />
          </PrivateRoute>
        ),
      },
      {
        path: 'multiview-select.html',
        element: (
          <PrivateRoute>
            <MultiViewSelector />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/accountVerify',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <AccountVerify />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/accountVerify/:invitationCode',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <AccountVerify />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/accountVerify.html/:invitationCode',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <AccountVerify />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/accountsettings.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <AccountSettings />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/updatepassword.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <UpdatePassword />
        </Suspense>
      </PrivateRoute>
    ),
  },
  moduleStatus?.OPERATION_METRICES && {
    path: '/reports.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <AnalyticsDashboard />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/reports.html/details',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ViewDetails />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/organizationsettings.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <OrganizationSettings />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/app/pos',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <PosIntegration />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/licenses',
    element: (
      <PrivateRoute>
        <Licenses />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'listing.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageLicenses />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/clips',
    element: (
      <PrivateRoute>
        <Clips />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'details.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ClipDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'list.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ClipsList />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  moduleStatus?.INCIDENT_MANAGEMENT && {
    path: '/incidents',
    element: (
      <PrivateRoute>
        <Incidents />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'manage.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageIncidents />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'details.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <IncidentDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/locations',
    element: (
      <PrivateRoute>
        <Locations />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'manage.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageLocations />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'new.html',
        element: (
          <PrivateRoute>
            <NewLocation />
          </PrivateRoute>
        ),
      },
      {
        path: 'edit.html:locationId',
        element: (
          <PrivateRoute>
            <EditLocation />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/users',
    element: (
      <PrivateRoute>
        <Users />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'manage.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageUsers />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'new.html',
        element: (
          <PrivateRoute>
            <NewUser />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/roles',
    element: (
      <PrivateRoute>
        <Roles />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'manage.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ManageRoles />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'new.html',
        element: (
          <PrivateRoute>
            <NewRole />
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboard.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <CustomRoleDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  moduleStatus?.PRODUCT_LISTING && {
    path: '/products',
    element: (
      <PrivateRoute>
        <Products />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'listing.html',
        element: (
          <PrivateRoute>
            <ProductListing />
          </PrivateRoute>
        ),
      },
    ],
  },
  moduleStatus?.PRODUCT_LISTING && {
    path: '/purchase',
    element: (
      <PrivateRoute>
        <Purchase />
      </PrivateRoute>
    ),
  },
  moduleStatus?.PRODUCT_LISTING && {
    path: '/purchase/summary.html',
    element: (
      <PrivateRoute>
        <PurchaseSummary />
      </PrivateRoute>
    ),
  },
  moduleStatus?.PRODUCT_LISTING && {
    path: '/purchase/confirmation.html',
    element: (
      <PrivateRoute>
        <PurchaseConfirmation />
      </PrivateRoute>
    ),
  },
  moduleStatus?.PRODUCT_LISTING && {
    path: '/orders',
    element: (
      <PrivateRoute>
        <Orders />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'history.html',
        element: (
          <PrivateRoute>
            <OrderHistory />
          </PrivateRoute>
        ),
      },
      {
        path: 'details.html',
        element: (
          <PrivateRoute>
            <OrderDetails />
          </PrivateRoute>
        ),
      },
      {
        path: 'details.html',
        element: (
          <PrivateRoute>
            <ComingSoon />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/devices',
    element: (
      <PrivateRoute>
        <Devices />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'listing.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <Device />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'dashboard.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <DeviceDashboard />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'channel-details.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <ChannelDetails />
            </Suspense>
          </PrivateRoute>
        ),
      },
      {
        path: 'discovered-devices.html',
        element: (
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <DiscoveredDevices />
            </Suspense>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/managepartnerusers.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ManagePartnerUsers />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/managetechnicians.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ManageTechnicians />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/events.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <Events />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/notificationdashboard.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <NotificationDashboard />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/notificationsetting.html',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <NotificationSetting />
        </Suspense>
      </PrivateRoute>
    ),
  },
  {
    path: '/incidentBundle',
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading />}>
          <ExportIncidentBundle />
        </Suspense>
      </PrivateRoute>
    ),
  },
];

export default routes;
