import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Utils, constants } from '../../../../helpers';
import '../stepsnotification.scss';
import { Form, Card, Col } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
// import CameraWallSnapshot from '../../../devices/listDevice/blocks/CameraWallSnapshot';
import { getCDNInfo } from '../../../../store/reducers/StreamingReducer';
import './StepCustomNotification.scss';
import LineCrossingSnapShot from '../../../devices/listDevice/blocks/LineCrossingSnapShot';

const StepCustomNotification = (props) => {
    const [imageWidth, setImageWidth] = useState(0);
    const [aspectRatio, setAspectRatio] = useState(1.7);
    const [searchQuery, setSearchQuery] = useState('');
    const cdnInfo = useSelector(getCDNInfo);
    const snapShotContainerRef = useRef(null); // Ref to access the snapshot container
    
    useEffect(() => {
        if (props?.customLocationId?.toString()?.trim().length) {
            props?.handleSelectCustomLocation(props?.customLocationId);
            props?.setCustomLocationId(props?.customLocationId);
        }
    }, [props?.customLocationId]);


    const handleSelect = (e, deviceId, uuid) => {
        const { checked } = e.target;
        if (checked) {
            props?.setDeviceIds(prevDeviceIds => {
                const newDeviceIds = new Set(prevDeviceIds);
                newDeviceIds.add(deviceId);
                return Array.from(newDeviceIds);
            });
            props?.setVirtualLineUuid(prevIds => {
                const newIds = new Set(prevIds);
                newIds.add(uuid);
                return Array.from(newIds);
            });
        } else {
            props?.setDeviceIds(prevDeviceIds => {
                const newDeviceIds = new Set(prevDeviceIds);
                newDeviceIds.delete(deviceId);
                return Array.from(newDeviceIds);
            });
            props?.setVirtualLineUuid(prevIds => {
                const newIds = new Set(prevIds);
                newIds.delete(uuid);
                return Array.from(newIds);
            });
        }
    };

    const getNormalCoordinates = (SPx, SPy, EPx, EPy,item) => {
        const capabilityList = item?.capDetails?.capabilities;
        const resourceList = Utils.getDeviceSettingResource(7);
        const capVirtualLine = Utils.getCurrentTabProperty(
            capabilityList,
            resourceList[6]
        );
        const REGION_X_RANGE =
            +capVirtualLine?.['virtual-line-x-range']?.max + 1 || 1;
        const REGION_Y_RANGE =
            +capVirtualLine?.['virtual-line-y-range']?.max + 1 || 1;
        const divResolutionWidth =
            document.getElementById('img-snapshot')?.offsetWidth;
        const divResolutionHeight =
            document.getElementById('img-snapshot')?.offsetHeight;
        const scaleX = divResolutionWidth / parseInt(REGION_X_RANGE - 1);
        const scaleY = divResolutionHeight / parseInt(REGION_Y_RANGE - 1);

        const normalStartPoint = {
            x: SPx * scaleX,
            y: SPy * scaleY,
        };

        const normalEndPoint = {
            x: EPx * scaleX,
            y: EPy * scaleY,
        };

        return { startPoint: normalStartPoint, endPoint: normalEndPoint };
    };

    const filteredVirtualLinesData = () => {
        const allLines = props?.virtualLinesData.map(item => {
            const dimensions = getNormalCoordinates(
                ...item['virtual-line-coordinates']?.split(','),item
            );
            return { ...item, ...dimensions }
        })
        return searchQuery
            ? allLines?.filter(item => {
                const lineName = item['virtual-line-name']?.toLowerCase() || '';
                const areaName = item.areaName?.toLowerCase() || '';
                const cameraName = item.cameraName?.toLowerCase() || '';
                const query = searchQuery.toLowerCase();
                return lineName.includes(query) || areaName.includes(query) || cameraName.includes(query);
            }) : allLines;
    }

    return (
        <>
            <div className="device-selection-row mt-3">
                <div className="step-title-desc-section">
                    {constants.SELECT_LOCATION_TITLE_LINE_CROSSING}
                </div>
            </div>
            <div className="location-main location-main-searchbox mb-2">
                <Form.Select
                    className='form-control shadow-none'
                    onChange={(e) => {
                        props?.handleSelectCustomLocation(e.target.value);
                        props?.setCustomLocationId(e.target.value);
                    }}
                    value={props?.customLocationId || ''}
                >
                    <option>Select a Location</option>
                    {props?.customLocations.length && props?.customLocations?.map(item =>
                        <option value={item?.locationId} key={item.locationId}>{item?.locationName}</option>
                    )}
                </Form.Select>
            </div>
            {props?.virtualLinesData?.length ? <>
                <div className="device-selection-row">
                    <div className="step-title-desc-section">
                        {constants.SELECT_LOCATION_TITLE_LINE}
                    </div>
                </div>
                <div className="search-input-container mt-2">
                    <IoIosSearch className="search-icon" />
                    <Form.Control
                        className='search-input'
                        type="text"
                        placeholder={constants.NOTIFICATION_SEARCH_HEADER}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='card-container-notification'>
                    {filteredVirtualLinesData()?.map((item, key) =>
                        // item.connectionStatus !== 'offline' &&
                        <Col xs={12} sm={6} className="mb-4" key={key}>
                            <Card className='card-notification-custom'>
                                <div className="snap-shot-container" ref={snapShotContainerRef}>
                                    <div className="live-snapshot">
                                        <LineCrossingSnapShot
                                            key={item.deviceId}
                                            customNotification={true}
                                            deviceElemId={`camera${item.deviceId}`}
                                            orgId={item?.orgId}
                                            cdnInfo={cdnInfo}
                                            locationAreaName={item.locationAreaNames}
                                            isHighlighted={false}
                                            showZoomOptions={false}
                                            conStatus={item.connectionStatus || 'offline'}
                                            hubId={item.gatewayId}
                                            displayDeviceStatus={Utils.getDeviceStatus(
                                                item?.deviceStatus,
                                                item?.connectionStatus
                                            )}
                                            setImageWidth={setImageWidth}
                                            aspectRatio={aspectRatio}
                                            {...item}
                                        />
                                        <svg
                                            width={document.getElementById(`camera${item.deviceId}`)?.offsetWidth}
                                            height={document.getElementById(`camera${item.deviceId}`)?.offsetHeight}
                                            style={{ position: 'absolute', top: 0, zIndex: 10 }}
                                        >
                                            <line
                                                key={item.deviceId + key}
                                                x1={item.startPoint.x}
                                                y1={item.startPoint.y}
                                                x2={item.endPoint.x}
                                                y2={item.endPoint.y}
                                                stroke={'#4B93FB'}
                                                strokeWidth="2"
                                            />
                                            <line
                                                x1={(item.startPoint.x + item.endPoint.x) / 2}
                                                y1={(item.startPoint.y + item.endPoint.y) / 2 - 10}
                                                x2={(item.startPoint.x + item.endPoint.x) / 2}
                                                y2={(item.startPoint.y + item.endPoint.y) / 2 + 10}
                                                stroke="#4B93FB"
                                                strokeWidth="2"
                                                strokeDasharray="4,4"
                                            />
                                            <circle
                                                cx={item.endPoint.x}
                                                cy={item.endPoint.y}
                                                r="4"
                                                fill="#4B93FB"
                                            />
                                            <circle
                                                cx={item.startPoint.x}
                                                cy={item.startPoint.y}
                                                r="4"
                                                fill="#4B93FB"
                                            />
                                            {(item['virtual-line-direction'] === 'left' || item['virtual-line-direction'] === 'both') &&
                                            <polygon
                                                points={`
                                                    ${(item.startPoint.x + item.endPoint.x) / 2 - 5},${(item.startPoint.y + item.endPoint.y) / 2 - 10}
                                                    ${(item.startPoint.x + item.endPoint.x) / 2 + 5},${(item.startPoint.y + item.endPoint.y) / 2 - 10}
                                                    ${(item.startPoint.x + item.endPoint.x) / 2},${(item.startPoint.y + item.endPoint.y) / 2 - 15}
                                                `}
                                                fill="#4B93FB"
                                            />}
                                            {(item['virtual-line-direction'] === 'right' || item['virtual-line-direction'] === 'both') &&
                                            <polygon
                                                points={`
                                                    ${(item.startPoint.x + item.endPoint.x) / 2 - 5},${(item.startPoint.y + item.endPoint.y) / 2 + 10}
                                                    ${(item.startPoint.x + item.endPoint.x) / 2 + 5},${(item.startPoint.y + item.endPoint.y) / 2 + 10}
                                                    ${(item.startPoint.x + item.endPoint.x) / 2},${(item.startPoint.y + item.endPoint.y) / 2 + 15}
                                                `}
                                                fill="#4B93FB"
                                            />}
                                        </svg>
                                    </div>
                                </div>
                                <Card.Body className='card-notification-body'>
                                    <Card.Title className='card-notification-custom-heading'>
                                        <span>{item['virtual-line-name']}</span>
                                        <span>
                                            <input
                                                name={item['virtual-line-uuid']}
                                                className="input-notification"
                                                type="checkbox"
                                                checked={props?.virtualLineUuid.includes(item['virtual-line-uuid'])}
                                                onChange={(e) => handleSelect(e, item.deviceId, item['virtual-line-uuid'])}
                                            />
                                        </span>
                                    </Card.Title>
                                    <Card.Text className='card-notification-custom-heading-2'>
                                        {item.areaName} - {item.cameraName}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </div>
            </> : null}
        </>
    );
};

export default StepCustomNotification;
