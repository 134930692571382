import React, { useCallback, useEffect, useRef, useState } from 'react';
import RegionSelect from 'react-region-select';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxPlus } from 'react-icons/rx';
import { useSelector } from 'react-redux';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import { getAllMqttDataFromResponse } from '../../../../store/reducers/AccountReducer';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/crossWithRound.svg';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import { getStreamBitmapData } from '../../../../store/reducers/StreamingReducer';
import nosnapshot from '../../../../assets/images/nosnapshot.svg';
// import { getAllMqttDataFromResponse } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';

const OccupancyQueue = ({
  accountId,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  capQueueManagement,
  queuesData,
  hideModelPopup,
}) => {
  const initialValues = {
    queueName: '',
    maximum: '',
    high: '',
    highDuration: '',
    medium: '',
    mediumDuration: '',
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
  });
  const watchedFields = watch();
  const formRef = useRef();
  const expiredTime = 20;
  const resourceList = Utils.getDeviceSettingResource(7);
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capQueueManagement?.['queue-index']?.max + 1 || 1;
  const REGION_X_RANGE = +capQueueManagement?.['queue-x-range']?.max + 1 || 1;
  const REGION_Y_RANGE = +capQueueManagement?.['queue-y-range']?.max + 1 || 1;
  const colors = Utils.getAllColors();
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [snapshot, setSnapshot] = useState('');
  const [queues, setQueues] = useState(queuesData['queues'] || []);
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const bitmapObject = useSelector(getStreamBitmapData);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.7);

  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  // useEffect(() => {
  //   const handleUpdate = async (data) => {
  //     if (data.key === 'allMqttDataFromResponse') {
  //       await loadAllMqttData();
  //     }
  //   };
  //   observerInstance.addObserver(handleUpdate);
  //   loadAllMqttData();

  //   return () => {
  //     observerInstance.removeObserver(handleUpdate);
  //   };
  // }, [loadAllMqttData]);

  useEffect(() => {
    setQueues(queuesData['queues'] || []);
  }, [queuesData]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes(resourceList[8]) && resource?.includes(deviceId)) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        if (isDeleteTriggered) {
          setIsDeleteTriggered(false);
        } else if (showLoader) {
          hideModelPopup();
        }
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    const updatedRegions = regions.map((reg) => {
      return {
        ...reg,
        data: {
          ...reg.data,
          regionStyle: {
            ...reg.data.regionStyle,
            display:
              selectedRegion?.data?.regionColor === reg.data.regionColor
                ? 'block'
                : 'none',
          },
        },
      };
    });
    setRegions(updatedRegions);
    document.getElementsByClassName('modal-body')[0].scrollTo(0, 0);
  }, [selectedRegion]);

  useEffect(() => {
    const snapshotURL = fetchSnapshotCanvas(`canvas`, `video`);
    setSnapshot(snapshotURL);
  }, []);

  const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    return data;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (REGION_X_RANGE && REGION_Y_RANGE && imageWidth) {
      setAspectRatio(REGION_X_RANGE / REGION_Y_RANGE);
      const regionList = queues.map((r) => {
        const dimensions = getZoneDimensions(
          ...r['queue-coordinates']?.split(',')
        );
        const color = colors[r['queue-index']];
        return {
          x: dimensions.left,
          y: dimensions.top,
          width: dimensions?.width,
          height: dimensions?.height,
          index: r['queue-index'],
          data: {
            queueName: r['queue-name'],
            maximum: r['queue-max-count'],
            high: r['queue-level-high-value'],
            highDuration: r['queue-level-high-duration'],
            medium: Math.round(+r['queue-level-high-value'] / 2),
            mediumDuration: r['queue-level-medium-duration'],
            regionColor: color,
            regionStyle: {
              border: `2px solid ${color}`,
            },
            x: dimensions.left,
            y: dimensions.top,
            width: dimensions?.width,
            height: dimensions?.height,
            ...dimensions,
          },
          isChanging: false,
        };
      });
      if (queues.length === 0) {
        setSelectedRegion(null);
        setRegions([]);
      }
      if (regionList.length) {
        setRegions(regionList);
        const updatedSelectedRegion = regionList.find(
          (region) =>
            region.data.regionColor === selectedRegion?.data?.regionColor
        );
        updateRegionAndForm(
          updatedSelectedRegion
            ? updatedSelectedRegion
            : regionList.length > 0
            ? regionList[0]
            : null
        );
      }
    }
  }, [imageWidth, queues]);

  const handleAddZoneClick = () => {
    // If there is any region without name then it should not allow to add new region
    const withoutName = regions.filter(
      (x) => x.data.queueName === '' || x.data.queueName === 'Queue Name'
    );
    if (withoutName.length > 0) {
      formRef.current.requestSubmit();
      return;
    }
    const occupiedIndices = queues.map((x) => x['queue-index']);
    const index = Utils.getMinAvailableIndex(occupiedIndices);
    const color = colors[index];
    const initialRegion = {
      x: 26,
      y: 25,
      width: 44,
      height: 50,
      new: false,
      index: index,
      data: {
        ...getZoneCoordinates(26, 25, 44, 50),
        queueName: 'Queue Name',
        maximum: '',
        high: '',
        highDuration: '',
        medium: '',
        mediumDuration: '',
        regionColor: color,
        regionStyle: {
          border: `2px solid ${color}`,
        },
        x: 26,
        y: 25,
        width: 44,
        height: 50,
      },
      isChanging: false,
    };
    setRegions([...regions, initialRegion]);
    updateRegionAndForm(initialRegion);
  };

  const onRegionChange = (regs) => {
    let updatedRegions = [];
    if (regs.filter((x) => x.isChanging).length === 0) {
      updatedRegions = regs.map((reg) => {
        return {
          ...reg,
          data: {
            ...reg.data,
            ...getZoneCoordinates(reg.x, reg.y, reg?.width, reg?.height),
            x: reg.x,
            y: reg.y,
            height: reg?.height,
            width: reg?.width,
          },
        };
      });
    }
    setRegions(updatedRegions.length > 0 ? updatedRegions : regs);
  };

  const getZoneCoordinates = (left, top, width, height) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;

    const updatedLeft = proportionWidth * ((divResolutionWidth / 100) * left);
    const updatedTop = proportionHeight * ((divResolutionHeight / 100) * top);
    const updatedHeight =
      proportionHeight * ((divResolutionHeight / 100) * height);
    const updatedWidth = proportionWidth * ((divResolutionWidth / 100) * width);

    const TL = { x: updatedLeft, y: updatedTop };
    const TR = { x: updatedLeft + updatedWidth, y: updatedTop };
    const BL = { x: updatedLeft, y: updatedTop + updatedHeight };
    const BR = { x: updatedLeft + updatedWidth, y: updatedTop + updatedHeight };

    return {
      TLx: Math.round(TL.x),
      TLy: Math.round(TL.y),
      TRx: Math.round(TR.x),
      TRy: Math.round(TR.y),
      BLx: Math.round(BL.x),
      BLy: Math.round(BL.y),
      BRx: Math.round(BR.x),
      BRy: Math.round(BR.y),
    };
  };

  const getZoneDimensions = (BLx, BLy, BRx, BRy, TRx, TRy, TLx, TLy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;
    const left = +TLx / proportionWidth / (divResolutionWidth / 100);
    const top = +TLy / proportionHeight / (divResolutionHeight / 100);
    const width = (+BRx - +BLx) / proportionWidth / (divResolutionWidth / 100);
    const height =
      (+TLy - +BLy) / proportionHeight / (divResolutionHeight / 100);

    return {
      left: left,
      top: top,
      width: Math.abs(width),
      height: Math.abs(height),
      TLx,
      TLy,
      TRx,
      TRy,
      BLx,
      BLy,
      BRx,
      BRy,
    };
  };

  const getMsgProperties = (region) => {
    const index = colors.indexOf(region.data.regionColor) || 0;
    const jsonObject = {};
    const keyPrefix = `queue`;
    jsonObject[`${keyPrefix}-index`] = index;
    jsonObject[`${keyPrefix}-enable`] = 'true';
    jsonObject[`${keyPrefix}-name`] = region.data.queueName;
    jsonObject[`${keyPrefix}-max-count`] = region.data.maximum;
    jsonObject[
      `${keyPrefix}-coordinates`
    ] = `${region.data.BLx},${region.data.BLy},${region.data.BRx},${region.data.BRy},${region.data.TRx},${region.data.TRy},${region.data.TLx},${region.data.TLy}`;
    jsonObject[`${keyPrefix}-level-high-enable`] = 'true';
    jsonObject[`${keyPrefix}-level-medium-enable`] = 'true';
    jsonObject[`${keyPrefix}-level-high-value`] = region.data.high;
    jsonObject[`${keyPrefix}-level-high-duration`] = region.data.highDuration;
    jsonObject[`${keyPrefix}-level-medium-duration`] =
      region.data.mediumDuration;
    return jsonObject;
  };

  const saveZoneHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const regionsWithUpdatedCoordinates = regions.find(
        (region) =>
          region.data.regionColor === selectedRegion?.data?.regionColor
      );
      const updatedSelectedRegion = {
        ...regionsWithUpdatedCoordinates,
        data: {
          ...regionsWithUpdatedCoordinates.data,
          ...values,
        },
      };
      const msgProperties = getMsgProperties(updatedSelectedRegion);
      publishDeviceSetting(msgProperties, resourceList[8], publishPayload);
      setSelectedRegion(updatedSelectedRegion);
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleZoneClick = (region) => {
    updateRegionAndForm(region);
  };

  const updateRegionAndForm = (region) => {
    reset();
    setValue(
      'queueName',
      region.data.queueName === 'Queue Name' ? '' : region.data.queueName
    );
    setValue('maximum', region.data.maximum);
    setValue('high', region.data.high);
    setValue('highDuration', region.data.highDuration);
    setValue('medium', region.data.medium);
    setValue('mediumDuration', region.data.mediumDuration);
    setSelectedRegion(region);
  };

  const handleRemoveZone = (e, region) => {
    e.stopPropagation();
    try {
      const currentIndex = colors.indexOf(region.data.regionColor);
      if (queues.find((q) => q['queue-index'] === currentIndex)) {
        setErrorMsg('');
        setShowLoader(true);
        setRemainingTime(expiredTime);
        const msgProperties = {
          'queue-index': currentIndex,
        };
        deleteDeviceSetting(msgProperties, resourceList[8], publishPayload);
        setIsDeleteTriggered(true);
      } else {
        const updatedRegions = regions.filter(
          (item) => item.data.regionColor !== region.data.regionColor
        );
        setRegions(updatedRegions);
        setSelectedRegion(updatedRegions.length > 0 ? updatedRegions[0] : null);
        updateRegionAndForm(
          updatedRegions.length > 0 ? updatedRegions[0] : null
        );
      }
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  const isFormOk = () => {
    if (watchedFields?.queueName?.length > 15) {
      return true;
    }
    if (
      +watchedFields?.maximum < +capQueueManagement?.['queue-max-count']?.min ||
      +watchedFields?.maximum > +capQueueManagement?.['queue-max-count'].max
    ) {
      return true;
    }
    if (
      +watchedFields?.highDuration <
        +capQueueManagement?.['queue-level-high-duration']?.min ||
      +watchedFields?.highDuration >
        +capQueueManagement?.['queue-level-high-duration'].max
    ) {
      return true;
    }
    if (
      +watchedFields?.mediumDuration <
        +capQueueManagement?.['queue-level-medium-duration']?.min ||
      +watchedFields?.mediumDuration >
        +capQueueManagement?.['queue-level-medium-duration'].max
    ) {
      return true;
    }
    if (
      +watchedFields?.high &&
      (+watchedFields?.high < 2 ||
        +watchedFields?.high > +watchedFields?.maximum - 1)
    ) {
      return true;
    }

    return (
      !selectedRegion ||
      !watchedFields.queueName ||
      !watchedFields?.maximum ||
      !watchedFields?.high ||
      !watchedFields?.highDuration ||
      !watchedFields?.mediumDuration
    );
  };

  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      <div className="activity-zone-info">
        {constants.OCCUPANCY_QUEUE_POPUP_TITLE_INFO.replace(
          '${queuecount}',
          `${MAX_REGION_TO_BE_ADDED_COUNT} ${
            MAX_REGION_TO_BE_ADDED_COUNT < 2 ? 'queue' : 'queues'
          }`
        )}
      </div>
      <div className="hr-devider"></div>
      {errorMsg && (
        <div className="mb-4 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      <div className="modal-sub-heading">
        {constants.ANALYTICS_SETTINGS_POPUP_CREATE_QUEUE_ZONES}
      </div>
      <div className="activity-zone-info">
        {constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_INFO}
      </div>
      <div className="regions-container mt-3 mb-3">
        {regions.map((region, index) => (
          <PrimaryButton
            key={index}
            className={`region-btn ${
              region.data.regionColor === selectedRegion?.data?.regionColor &&
              'selected-region'
            }`}
            type="button"
            fontSize="12px"
            width="fit-content"
            lineHeight="16px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            onClick={() => handleZoneClick(region)}
          >
            <div
              className="circle-dot"
              style={{ backgroundColor: region.data.regionColor }}
            ></div>
            {region.data.regionColor === selectedRegion?.data?.regionColor
              ? watchedFields.queueName || 'Queue Name'
              : region.data.queueName}
            <CrossIcon
              className="cross-icon"
              onClick={(e) => handleRemoveZone(e, region)}
            />
          </PrimaryButton>
        ))}
        {regions.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn"
            type="button"
            width="112px"
            fontSize="12px"
            lineHeight="16px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddZoneClick}
          >
            <RxPlus className="plus-icon-btn" strokeWidth={'1.5px'} size={16} />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_QUEUE}
          </PrimaryButton>
        )}
      </div>
      <div className="snap-shot-container">
        <div className="live-snapshot">
          <img
            id="img-snapshot"
            src={snapshot}
            alt=""
            className={`live-snapshot-image ${
              aspectRatio === 1 ? 'image-aspect' : ''
            }`}
            onLoad={() => {
              setImageWidth(
                document.getElementById('img-snapshot')?.offsetWidth
              );
            }}
            onError={(event) => {
              event.target.src = nosnapshot;
            }}
          />
        </div>
        <RegionSelect
          id="region-select"
          maxRegions={3}
          regions={regions}
          selectedRegion={selectedRegion}
          regionStyle={{
            outline: 'none',
            zIndex: 70,
          }}
          className={`region-container region${selectedRegion?.index}`}
          onChange={onRegionChange}
          style={{
            border: '0px solid black',
            position: 'absolute',
            height: '-webkit-fill-available',
            display: 'inline-block',
            top: '0px',
            left: '0px',
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          constraint={true}
        >
          {regions
            .filter(
              (region) =>
                region.data.regionColor !== selectedRegion?.data?.regionColor
            )
            .map((region) => (
              <div
                style={{
                  border: `2px solid ${region.data.regionColor}`,
                  position: 'absolute',
                  height: `${region.data?.height}%`,
                  width: `${region.data?.width}%`,
                  zIndex: 20,
                  pointerEvents: 'none',
                  left: `${region.data.x}%`,
                  top: `${region.data.y}%`,
                }}
              ></div>
            ))}
          <div></div>
        </RegionSelect>
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveZoneHandler)}>
        {selectedRegion && (
          <div className="add-zone-container">
            <div className="mb-4 mt-4 zone-input-wrapper">
              <div className="placeholder-wrap">
                <input
                  {...register('queueName', { required: true })}
                  defaultValue={selectedRegion?.data?.queueName}
                  className="input-box"
                  autoFocus
                />
                {!watchedFields.queueName && (
                  <div className="custom-placeholder">
                    {constants.OCCUPANCY_QUEUE_CREATE_QUEUE_ZONE_PLACEHOLDER}
                    <span className="required">*</span>
                  </div>
                )}
              </div>
              {errors.queueName && (
                <div className="error">
                  {constants.OCCUPANCY_QUEUE_CREATE_QUEUE_ZONE_ERROR}
                </div>
              )}
            </div>
            <div className="modal-sub-heading">
              {constants.OCCUPANCY_QUEUE_THRESOLD_AND_MINIMUM_LABEL}
            </div>
            <div className="activity-zone-info">
              {constants.OCCUPANCY_QUEUE_THRESOLD_AND_MINIMUM_LABEL_INFO}
            </div>
            {errors.high && (
              <div className="mt-3 error-conatiner">
                <div className="error-message-container">
                  <RiErrorWarningLine className="icon-warning-col" />
                  <div className="">
                    {constants.OCCUPANCY_QUEUE_HIGH_MAX_COUNT_ERROR}
                  </div>
                </div>
              </div>
            )}
            <div className="mb-4 mt-4 zone-input-wrapper">
              <div className="placeholder-wrap">
                <input
                  type="number"
                  {...register('maximum', { required: true, min: 1, max: 50 })}
                  defaultValue={selectedRegion?.data?.maximum}
                  className="input-box"
                />
                {!watchedFields.maximum && (
                  <div className="custom-placeholder">
                    {constants.OCCUPANCY_QUEUE_MAXIMUM_PLACEHOLDER}
                    <span className="required">*</span>
                  </div>
                )}
              </div>
              <div className={`${errors.maximum ? 'error' : ''} info-msg`}>
                {constants.OCCUPANCY_QUEUE_MAXIMUM_ERROR}
              </div>
            </div>
            <div className="input-group-container mb-4 mt-1">
              <div className="high zone-input-wrapper">
                <div className="placeholder-wrap">
                  <input
                    type="number"
                    {...register('high', {
                      required: true,
                      max: watchedFields.maximum,
                    })}
                    defaultValue={selectedRegion?.data?.high}
                    className="input-box"
                  />
                  {!watchedFields.high && (
                    <div className="custom-placeholder">
                      {constants.OCCUPANCY_QUEUE_HIGH_PLACEHOLDER}
                      <span className="required">*</span>
                    </div>
                  )}
                </div>
                <div className={`${errors.high ? 'error' : ''} info-msg`}>
                  {constants.OCCUPANCY_QUEUE_HIGH_ERROR}
                </div>
              </div>
              <div className="duration zone-input-wrapper">
                <div className="placeholder-wrap">
                  <input
                    type="number"
                    {...register('highDuration', {
                      required: true,
                    })}
                    defaultValue={selectedRegion?.data?.highDuration}
                    className="input-box"
                  />
                  {!watchedFields.highDuration && (
                    <div className="custom-placeholder">
                      {constants.OCCUPANCY_QUEUE_DURATION_PLACEHOLDER}
                      <span className="required">*</span>
                    </div>
                  )}
                </div>
                <div
                  className={`${errors.highDuration ? 'error' : ''} info-msg`}
                >
                  {constants.OCCUPANCY_QUEUE_HIGH_DURATION_ERROR.replace(
                    '${min}',
                    +capQueueManagement?.['queue-level-high-duration']?.min
                  ).replace(
                    '${max}',
                    +capQueueManagement?.['queue-level-high-duration']?.max
                  )}
                </div>
              </div>
              <div className="seconds zone-input-wrapper">
                {constants.OCCUPANCY_QUEUE_SECONDS_LABEL}
              </div>
            </div>
            <div className="input-group-container mb-4 mt-1">
              <div className="medium zone-input-wrapper">
                <div className="placeholder-wrap">
                  <input
                    type="number"
                    {...register('medium')}
                    defaultValue={selectedRegion?.data?.medium}
                    value={Math.round(watchedFields.high / 2)}
                    className="input-box"
                    disabled
                  />
                  {!watchedFields.medium && (
                    <div
                      className={`custom-placeholder ${
                        !watchedFields.high ||
                        watchedFields.high < 2 ||
                        watchedFields.high > watchedFields.maximum - 1
                          ? 'medium-no-value'
                          : ''
                      }`}
                    >
                      {(watchedFields.high < 26 && watchedFields.high > 2) ||
                      !watchedFields.high
                        ? constants.OCCUPANCY_QUEUE_MEDIUM_PLACEHOLDER
                        : constants.OCCUPANCY_QUEUE_ERROR}
                    </div>
                  )}
                </div>
              </div>
              <div className="duration zone-input-wrapper">
                <div className="placeholder-wrap">
                  <input
                    type="number"
                    {...register('mediumDuration', {
                      required: true,
                    })}
                    defaultValue={selectedRegion?.data?.mediumDuration}
                    className="input-box"
                  />
                  {!watchedFields.mediumDuration && (
                    <div className="custom-placeholder">
                      {constants.OCCUPANCY_QUEUE_DURATION_PLACEHOLDER}
                      <span className="required">*</span>
                    </div>
                  )}
                </div>
                <div
                  className={`${errors.mediumDuration ? 'error' : ''} info-msg`}
                >
                  {constants.OCCUPANCY_QUEUE_HIGH_DURATION_ERROR.replace(
                    '${min}',
                    +capQueueManagement?.['queue-level-medium-duration']?.min
                  ).replace(
                    '${max}',
                    +capQueueManagement?.['queue-level-medium-duration']?.max
                  )}
                </div>
              </div>
              <div className="seconds zone-input-wrapper">
                {constants.OCCUPANCY_QUEUE_SECONDS_LABEL}
              </div>
            </div>
          </div>
        )}
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          disabled={isFormOk()}
        >
          {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default OccupancyQueue;
