import { Outlet } from 'react-router-dom';
import PurchaseSummary from './PurchaseSummary';
import PurchaseConfirmation from './PurchaseConfirmation';

const Purchase = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Purchase, PurchaseConfirmation, PurchaseSummary };
