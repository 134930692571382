import { HiOutlineOfficeBuilding } from 'react-icons/hi';

const NoDataBlock = ({ styleClasses, contentIcon, contentBlock, ...props }) => {
  return (
    <div className={`site-no-content-block ${styleClasses}`}>
      <div className="no-content-icon">
        {contentIcon ? contentIcon : <HiOutlineOfficeBuilding size={24} />}
      </div>
      <div className="no-content-block">{contentBlock}</div>
    </div>
  );
};

export default NoDataBlock;
