const DefaultPolicies = {
  create_cust_org: false,
  update_partner_org: false,
  update_cust_org: false,
  view_partner_org: false,
  view_cust_org: false,
  create_location: false,
  update_location: false,
  view_location: false,
  delete_location: false,
  create_area: false,
  update_area: false,
  delete_area: false,
  view_area: false,
  enable_installer_account: false,
  create_account: false,
  update_account: false,
  view_account: false,
  update_account_status: false,
  install_device: false,
  delete_device: false,
  claim_device: false,
  manage_device_settings: false,
  view_device_settings: false,
  create_features: false,
  update_features: false,
  view_features: false,
  create_products: false,
  update_products: false,
  view_products: false,
  create_pricebooks: false,
  update_pricebooks: false,
  view_pricebooks: false,
  view_cameras_real_time: false,
  view_camera_history: false,
  view_faces: false,
  video_search: false,
  save_video_search_parameters: false,
  clip_share_videos: false,
  create_events: false,
  subscribe_event: false,
  create_investigations: false,
  contribute_investigations: false,
  purchase_services: false,
  create_custom_roles: false,
  update_custom_roles: false,
  delete_custom_roles: false,
  create_manufacturer_sku: false,
  delete_installer_account: false,
  view_self_account: false,
  delete_user_account: false,
  view_license_key: false,
  custorg_remove_customer_user: false,
  custorg_remove_customer_admin: false,
  custorg_reset_password_customer_user: false,
  custorg_reset_password_customer_admin: false,
  partnerorg_remove_partner_user: false,
  partnerorg_remove_partner_admin: false,
  partnerorg_reset_password_partner_user: false,
  partnerorg_reset_password_partner_admin: false,
  view_org_partnerorg: false,
};

export default DefaultPolicies;
