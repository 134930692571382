import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiteModal, PrimaryButton } from '../../components/common';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { Utils, constants } from '../../helpers';
import { error, success } from '../../assets/images';
import CustomizedTable from './CustomizeLicenseTable';
import { useNavigate } from 'react-router-dom';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const ActiveLicenses = ({ data, callBackModal, from }) => {
  const [activeLicense, setActiveLicense] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedLicensesList, setSelectedLicensesList] = useState([]);
  const [activeResponse, setActiveResponse] = useState(false);
  const [activeResponseStatus, setActiveResponseStatus] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [userMsg, setUserMsg] = useState('');
  const [orgDetails, setOrgDetails] = useState();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const navigate = useNavigate();
  useEffect(() => {
    setIsShow(data.length > 0 ? true : false);
  }, [data.length > 0]);

  const submitLicenseList = async () => {
    try {
      setShowLoader(true);
      selectedLicensesList.licenses.forEach((obj) => {
        delete obj.isSelected;
      });
      const reqBody = selectedLicensesList;

      const res = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/activate/licenses`,
        reqBody,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setActiveResponse(true);
        setActiveResponseStatus(true);
        setIsShow(false);
      } else {
        setIsShow(false);
        setActiveResponse(true);
        if (responseData?.meta?.code === 1806) {
          setActiveResponseStatus(false);
          setUserMsg(responseData?.meta?.userMsg);
        } else {
          setActiveResponseStatus(true);
        }
      }
      setShowLoader(false);
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const handleSelectedLicenseForActivate = (list) => {
    const newArray = list.map((item) => {
      return {
        activeDate: item.activeDate,
        licenseKey: item.licenseKey,
        isSelected: item.isSelected,
      };
    });
    if (newArray.length > 0) {
      const selectedLicenses = newArray.filter((license) => {
        return (
          license.hasOwnProperty('isSelected') && license.isSelected === true
        );
      });

      const result = {
        licenses: selectedLicenses,
      };

      setDisableSubmitButton(selectedLicenses.length > 0 ? false : true);
      setSelectedLicensesList(result);
    } else {
      setDisableSubmitButton(true);
    }
  };

  const handleErrorTryAgain = () => {
    setIsShow(true);
    setActiveResponse(false);
    setActiveResponseStatus(false);
  };

  const backToParentScreen = () => {
    callBackModal();
    if (from === constants.FROM_DASHBOARD) navigate('/clips/list.html');
  };

  return (
    <div>
      <SiteModal
        showModal={isShow}
        modalTitle={'Activate Licenses'}
        hideModal={() => setActiveLicense(false)}
        modalHeader={true}
        classes={'active-lic-modal'}
        size={'xl'}
      >
        <Row>
          <Col>
            <Row>
              <Col>
                <div className="title">
                  {constants.ACTIVATE_LICENSES}
                  <IoClose
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_56')}
                    className="close"
                    onClick={() => {
                      callBackModal(true);
                    }}
                  />
                </div>
                <div className="subtitle">
                  {constants.SELECT_VIDEOCLIP_LICENSES}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {data.length > 0 && (
                  <CustomizedTable
                    data={data}
                    onSelect={handleSelectedLicenseForActivate}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <PrimaryButton
                  className="adddevice-btn"
                  fontSize="14px"
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  height="44px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  type="submit"
                  loader={showLoader}
                  onClick={() => {
                    submitLicenseList();
                  }}
                  disabled={disableSubmitButton}
                >
                  {constants.ACTIVATE_SELECTED_LICENSES}
                </PrimaryButton>
                <PrimaryButton
                  fontSize="14px"
                  className={'device-btn-list m-t-8'}
                  backgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_black')}
                  height="44px"
                  onClick={() => {
                    callBackModal();
                  }}
                >
                  {constants.CANCEL_BUTTON_ACTIVE_LIC}
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </SiteModal>
      <SiteModal
        showModal={activeResponse}
        hideModal={() => setActiveResponse(false)}
        modalHeader={true}
        classes={'active-lic-success-error'}
        sm={'sm'}
      >
        {activeResponseStatus ? (
          <Row className="pop-holder">
            <Col>
              <Row>
                <Col className="title">
                  <div>{constants.ACTIVATE_SUCCESS}</div>
                  <IoClose
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_40')}
                    className="close"
                    onClick={() => {
                      callBackModal();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="img-holder">
                  <div>
                    <img src={success} alt="green circled check mark" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PrimaryButton
                    className="adddevice-btn"
                    fontSize="14px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    height="44px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    type="submit"
                    // loader={showLoader}
                    onClick={() => {
                      backToParentScreen();
                    }}
                  >
                    {constants.BACK_TO_CLIP}
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="pop-holder">
            <Col>
              <Row>
                <Col className="title">
                  <div>{constants.SOMETHING_WENT_WRONG}</div>
                  <IoClose
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_40')}
                    className="close"
                    onClick={() => {
                      callBackModal();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="is-center">
                  <div className="subtitle-error">
                    {constants.ACTIVATE_LICENSE_ERROR}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="img-holder">
                  <div>
                    <img src={error} alt="green circled check mark" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PrimaryButton
                    className="adddevice-btn"
                    fontSize="14px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    height="44px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    type="submit"
                    onClick={() => {
                      handleErrorTryAgain();
                    }}
                  >
                    {constants.TRY_AGAIN_BUTTON_TITLE}
                  </PrimaryButton>
                  <PrimaryButton
                    fontSize="14px"
                    className={'device-btn-list m-t-8'}
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_black')}
                    height="44px"
                    onClick={() => {
                      backToParentScreen();
                    }}
                  >
                    {constants.BACK_TO_CLIP}
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </SiteModal>
    </div>
  );
};

export default ActiveLicenses;
