import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Col, Row } from 'react-bootstrap';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import axios from 'axios';

import {
  Avatar,
  SiteToast,
  SiteSpinner,
  TextBlock,
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';

import { usePoliciesStore } from '../../../store/policiesStore';
import { useCustomerOrgUsers } from '../../../store/CustomerOrgUsersStore';

import { ReactComponent as UserIcon } from '../../../assets/images/icons/userIcon.svg';

import './ContributorsTab.scss';

const ContributorsTab = ({
  orgDetails,
  selectedIncident,
  incidentContributors,
  setIncidentContributors,
  setShowToast,
  setUserMsg,
  statusClosed,
  setShowPendingChangesModal,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();

  const [showLoading, setShowLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const { getLoggedInUserPolicies } = usePoliciesStore();
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );

  const userPolicies = getLoggedInUserPolicies();

  const customerOrgUsers = getCustomerOrgUsersData();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchContributors = async () => {
    let contributors;

    if (!orgDetails || !selectedIncident?.incidentId) {
      return;
    }

    try {
      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/users`,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        contributors = responseData?.data?.users;
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return contributors;
    }
  };

  const fetchData = async () => {
    try {
      setShowLoading(true);
      setOrganizationUsers(customerOrgUsers);

      const contributorsData = await fetchContributors();

      const contributorsWithAvatarAndInitials = contributorsData?.map(
        (contributor) => {
          const accountData = customerOrgUsers.find(
            (orgUser) => orgUser?.accountId === contributor?.accountId
          );

          return {
            ...contributor,
            ...accountData,
          };
        }
      );

      setIncidentContributors &&
        setIncidentContributors([...contributorsWithAvatarAndInitials]);
      setHasData(true);
    } catch (error) {
      Utils.vmsLogger().error(error);
      setHasData(false);
    } finally {
      setShowLoading(false);
    }
  };

  const handleContributorsUpdate = async (contributors) => {
    let updatedContributors = [];

    if (!orgDetails || !selectedIncident?.incidentId) {
      return;
    }

    try {
      if (!Array.isArray(contributors)) return;

      const contributorAccountIds = contributors.map(
        (contributor) => contributor.accountId
      );

      const reqBody = {
        accountIds: [...contributorAccountIds],
      };

      const res = await axios.put(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/users`,
        reqBody,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        updatedContributors = responseData?.data?.users;
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return updatedContributors;
    }
  };

  return (
    <Container>
      {/* Contributors Title */}
      <Row>
        <Col>
          <div className="d-flex justify-content-start mt-4 mb-4">
            <span className="contributors-title">
              {constants.INCIDENTS_PAGE_TAB_CONTRIBUTORS_LABEL}
            </span>
          </div>
        </Col>
      </Row>
      {/* Table of Contributors */}
      <Row className="">
        <Col>
          {showLoading ? (
            <div className="mb-2 contributors-content">
              <SiteSpinner height="50px" width="50px" />
            </div>
          ) : hasData ? (
            <div className="mb-2 contributors-content">
              {Array.isArray(organizationUsers) &&
                organizationUsers.map((orgUser, orgUserIndex) => (
                  <Row key={orgUser?.accountId}>
                    <Col>
                      <div className="mb-3 contributors-content-wrapper">
                        <TextBlock className="contributors-content-item">
                          <div className="contributors-content-item-details">
                            <div>
                              <Avatar
                                valueType={
                                  orgUser?.image?.url &&
                                  Utils.checkURLValidity(orgUser?.image?.url)
                                    ? 'icon'
                                    : 'text'
                                }
                                value={
                                  orgUser?.image?.url &&
                                  Utils.checkURLValidity(orgUser?.image?.url)
                                    ? orgUser?.image?.url
                                    : orgUser?.initials
                                }
                                size="medium"
                                avatarStyle="roundedCircle"
                              />
                            </div>
                            <div className="contributors-content-item-info">
                              <div className="view-log-detail-creator-name">
                                {orgUser?.name}
                              </div>
                            </div>
                          </div>
                          <Checkbox
                            className="contributors-content-item-checkbox"
                            value={JSON.stringify(orgUser)}
                            defaultChecked={orgUser?.owner}
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 },
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--brand_primary'),
                              },
                            }}
                            onChange={async (e) => {
                              let isChecked = e?.target?.checked;
                              let value = JSON.parse(e?.target?.value);
                              let index;
                              const contributors =
                                [...incidentContributors] || [];

                              if (contributors?.length > 0) {
                                index = contributors?.findIndex(
                                  (contributor) =>
                                    contributor.accountId === value.accountId
                                );

                                if (index > -1) {
                                  contributors?.splice(index, 1);
                                } else {
                                  contributors?.push(value);
                                }
                              } else {
                                contributors?.push(value);
                              }

                              const updatedContributors =
                                await handleContributorsUpdate(contributors);

                              setIncidentContributors([...updatedContributors]);
                            }}
                            checked={
                              incidentContributors.findIndex(
                                (contributor) =>
                                  contributor.accountId === orgUser?.accountId
                              ) !== -1
                            }
                            disabled={
                              !userPolicies.update_all_incidents &&
                              !userPolicies.update_created_incident &&
                              !userPolicies.update_shared_incident
                            }
                          />
                        </TextBlock>
                      </div>
                    </Col>
                  </Row>
                ))}
            </div>
          ) : (
            <div className="mb-2 summary-input-wrapper">No Content</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContributorsTab;
