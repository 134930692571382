import { useState, useEffect } from 'react';
import FormSteps from './FormSteps';
import DetailsContextProvider from './DetailsContextProvider';
import DataContextProvider from './DataContextProvider';
import { SiteModal, PrimaryButton } from '../../../components/common/';
import { PlusIcon } from '../../../assets/images';
import { constants } from '../../../helpers';
import { usePoliciesStore } from '../../../store/policiesStore';

const AddLicense = (props) => {
  const reloadData = () => props?.reloadData();
  const setShowClaimLicenseModal = () => props?.setShowClaimLicenseModal();

  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showDiscoveredLicensesModel, setShowDiscoveredLicensesModel] =
    useState(false);

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  const handleShow1 = () => {
    if (getCustomerOrgPolicies()?.add_license_customer_org) {
      setShowAddOrgModal(true);
      handleButtonClick('Flow1');
    } else {
      return;
    }
  };
  const handleShow2 = () => {
    setShowAddOrgModal(true);
    handleButtonClick('Flow2');
  };
  const [buttonClickValue, setButtonClickValue] = useState(null);

  const handleButtonClick = (value) => {
    setButtonClickValue(value);
  };

  localStorage.setItem('modalOpen', 0);

  const handleClose = () => {
    reloadData && reloadData();
    setShowAddOrgModal(false);
  };

  useEffect(() => {
    setShowAddOrgModal(props.showClaimLicenseModal);
  }, [props.showClaimLicenseModal]);

  const LicenseModal = () => {
    return (
      <SiteModal
        modalHeader={true}
        showModal={showAddOrgModal}
        hideModal={() => handleClose}
      >
        <FormSteps
          buttonClickValue={buttonClickValue}
          reloadData={() => reloadData()}
          hideModal={() => handleClose()}
          onDiscoverLicenses={() => setShowDiscoveredLicensesModel(true)}
        />
      </SiteModal>
    );
  };

  return (
    <DetailsContextProvider>
      <DataContextProvider>
        <PrimaryButton
          className="addLicenseBtnStyle d-md-block d-none"
          type="button"
          width="auto"
          height="44px"
          fontSize="14px"
          onClick={handleShow1}
        >
          <img src={PlusIcon} className="plusIconClass" alt="plusicon" />
          {constants.LICENSES_PAGE_ADD_BUTTON_LABEL}
        </PrimaryButton>
        <LicenseModal />
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default AddLicense;
