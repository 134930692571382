import React from 'react';
import { ReactComponent as CameraPlusIcon } from '../../../assets/images/CameraPlus.svg';
import videoProcessing from '../../../assets/images/cameras/videoProcessing.svg';
import { constants } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { setIsEditModeActive } from '../../../store/reducers/ViewsReducer';
import { disconnectWithMQTT } from '../../../utils/connection/mqttConnection';
import { getWSSConnections, removeMQTTPeerConnections, removeRemoteStreams, removeRemoteStreamsAudio } from '../../../store/reducers/StreamingReducer';
import { disconnectWithWebSocket } from '../../multilive/components/playback/wssConnection/wssConnection';
import { useSelector } from 'react-redux';

const EditCamera = () => {
  const dispatch = useDispatch();
  const wssConnections = useSelector(getWSSConnections);
  
  const handlEditViewClick = () => {
    disconnectWithMQTT();
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }
    dispatch(setIsEditModeActive(true));
  };

  return (
    <>
      <div className="not-active-camera-details">
        <CameraPlusIcon className='no-active-icon' />
        <div
          className="not-active-camera-button"
          onClick={() => handlEditViewClick()}
        >
          {constants.CAMERAS_VIDEO_EDIT_VIEW_LABEL}
        </div>
      </div>
      <img
        className="hide-image"
        src={videoProcessing}
        alt="video processing"
      />
    </>
  );
};

export default EditCamera;
