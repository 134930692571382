import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

const useDebouncedCallback = (callback, delay) => {
  const callbackRef = useRef(callback);
  const debouncedCallbackRef = useRef(debounce(callbackRef.current, delay));

  useEffect(() => {
    callbackRef.current = callback;
    debouncedCallbackRef.current = debounce(callbackRef.current, delay);
  }, [callback, delay]);

  return debouncedCallbackRef.current;
};

export default useDebouncedCallback;
