import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AppDefaults, Utils } from '../helpers/';

let cancelToken;

export const useCustomerOrgLocations = create(
  persist(
    (set, get) => ({
      customerOrgLocationsData: [],
      editCustomerOrgLocations: [],
      locationSelections: [],
      userCustomers: [],

      getSelectedLocation: (accountId) => {
        if (!accountId) return null;

        const currentLocationSelections = get().locationSelections;

        if (!currentLocationSelections) return null;

        return currentLocationSelections.find(
          (locationSelection) => locationSelection.accountId === accountId
        );
      },

      setLocationSelection: (accountId, locationId) => {
        let existingLocationSelectionIndex, newLocationSelection;

        if (!accountId || !locationId) return;

        set((state) => {
          const currentLocationSelections = get().locationSelections;

          existingLocationSelectionIndex =
            state.getLocationSelectionIndex(accountId);
          if (existingLocationSelectionIndex !== -1) {
            // Modify an existing location selection
            newLocationSelection = {
              accountId: accountId,
              locationId: locationId,
              locationName: state.getLocationNameByLocationId(locationId),
              filteredLocationData:
                state.filterCustomerOrgLocations(locationId),
            };
            currentLocationSelections[existingLocationSelectionIndex] =
              newLocationSelection;
          } else {
            // Nre location selection
            newLocationSelection = {
              accountId: accountId,
              locationId: locationId,
              locationName: state.getLocationNameByLocationId(locationId),
              filteredLocationData:
                get().customerOrgLocationsData.filter(
                  (locationData) => locationData.locationId === locationId
                )?.length > 0
                  ? get().customerOrgLocationsData.filter(
                      (locationData) => locationData.locationId === locationId
                    )?.length > 0
                  : state.filterCustomerOrgLocations(locationId),
            };
            currentLocationSelections.push(newLocationSelection);
          }

          return {
            ...state,
            locationSelections: [...currentLocationSelections],
          };
        });
      },

      getLocationSelectionIndex: (accountId) => {
        if (!accountId) return;

        return get().locationSelections.findIndex(
          (locationSelection) => locationSelection.accountId === accountId
        );
      },

      getSelectedLocationId: (accountId) => {
        if (!accountId) return null;

        const currentLocationSelections = get().locationSelections;
        const selectedLocation = currentLocationSelections?.find(
          (locationSelection) => locationSelection.accountId === accountId
        );

        if (!selectedLocation || !selectedLocation?.locationId) return null;

        return selectedLocation.locationId;
      },

      getSelectedLocationName: (locationId) => {
        if (!locationId) return null;

        const currentLocationSelections = get().locationSelections;

        const selectedLocation = currentLocationSelections?.find(
          (locationSelection) => locationSelection.locationId === locationId
        );

        if (!selectedLocation || !selectedLocation?.locationName) return null;

        return selectedLocation.locationName;
      },

      getLocationNameByLocationId: (locationId) => {
        if (!locationId) return null;

        let location = get().customerOrgLocationsData.find(
          (orgLocationData) => orgLocationData.locationId === locationId
        );

        if (!location) return;

        return location.locationName;
      },

      filterCustomerOrgLocations: (locationId) => {
        if (!locationId || locationId === AppDefaults.ALL_LOCATIONS_ID) {
          return get().customerOrgLocationsData;
        } else {
          return get().customerOrgLocationsData.filter(
            (locationData) => locationData.locationId === locationId
          );
        }
      },

      getEditCustomerOrgLocations: async (route) => {
        if (!route) return;

        // Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.');
        }

        // Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();

        try {
          const requestId = uuidv4();
          const res = await axios.get(route, {
            params: {
              requestTime: Date.now(),
              requestId: requestId,
            },
            ...Utils.requestHeader(requestId),
            timeout: 60000,
            credentials: 'include',
            withCredentials: true,
            cancelToken: cancelToken.token,
          });
          const response = res?.data;

          let result;

          // Set edit customer org locations if request is successful
          if (response?.meta?.code === 200) {
            let locationsData = response?.data;
            if (locationsData) {
              const allCheckedLocationData = locationsData.map((location) => {
                if (location?.areas) {
                  location.areas = location?.areas.map((area) => {
                    area.checked = true;
                    return area;
                  });
                }
                return location;
              });
              set({
                editCustomerOrgLocations: allCheckedLocationData,
              });
            }

            result = {
              status: 'success',
              msg: 'edit customer organizations user assigned locations retrieved',
            };
          } else {
            if (res?.code) {
              result = {
                status: 'failure',
                msg: `${res.code}: ${res.message}`,
              };
            } else if (response?.data) {
              result = { status: 'failure', msg: response?.data?.userMsg };
            } else {
              result = { status: 'failure', msg: 'Unknown internal API error' };
            }
          }
          return result;
        } catch (err) {
          Utils.vmsLogger().error(err);
        }
      },

      getEditCustomerOrgLocationsData: () => {
        return get().editCustomerOrgLocations;
      },

      getCustomerOrgLocations: async (route) => {
        if (!route) return;

        // Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.');
        }

        // Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();

        try {
          const requestId = uuidv4();
          const res = await axios.get(route, {
            params: {
              requestTime: Date.now(),
              requestId: requestId,
            },
            ...Utils.requestHeader(requestId),
            timeout: 60000,
            credentials: 'include',
            withCredentials: true,
            cancelToken: cancelToken.token,
          });
          const response = res?.data;
          let result;

          // Set customer org locations if request is successful
          if (response?.meta?.code === 200) {
            let locationsData = response?.data;
            if (
              locationsData &&
              JSON.stringify(locationsData) !==
                JSON.stringify(get().customerOrgLocationsData)
            ) {
              set({
                customerOrgLocationsData: locationsData,
              });
            }

            result = {
              status: 'success',
              msg: 'customer locations retrieved`',
            };
          } else {
            if (res?.code) {
              result = {
                status: 'failure',
                msg: `${res.code}: ${res.message}`,
              };
            } else if (response?.data) {
              result = { status: 'failure', msg: response?.data?.userMsg };
            } else {
              result = { status: 'failure', msg: 'Unknown internal API error' };
            }
          }
          return result;
        } catch (err) {
          Utils.vmsLogger().error(err);
        }
      },

      getCustomerOrgLocationsData: () => {
        return get().customerOrgLocationsData;
      },

      getCustomerOrgEditLocationsData: (locationId) => {
        try {
          let resultData = get().customerOrgLocationsData;

          if (resultData?.length >= 1) {
            const editLocationData = resultData?.find(
              (location) => location.locationId === locationId
            );
            return editLocationData;
          }
          return null;
        } catch (error) {
          return error;
        }
      },

      setCustomerOrgLocations: (locationData) => {
        if (!locationData) return;
        set({
          customerOrgLocationsData: locationData,
        });
      },

      setUserCustomer: (userCustomers) => {
        if (!userCustomers) return;
        set({
          userCustomers: userCustomers,
        });
      },

      getUserCustomer: () => {
        return get().userCustomers;
      },

      resetCustomerOrgLocations: () =>
        set((state) => {
          return {
            ...state,
            customerOrgLocationsData: [],
            editCustomerOrgLocations: [],
            userCustomers: [],
          };
        }),

      getAllFilteredLocations: () => {
        try {
          let resultData = get().customerOrgLocationsData;

          if (resultData?.length >= 1) {
            const userLocationData = [];
            resultData.forEach((location) => {
              const areas = location?.areas;
              const userAreas = [];
              if (areas?.length > 0) {
                areas.forEach((area) => {
                  userAreas.push({ areaId: area.areaId });
                });
                userLocationData.push({
                  locationId: location.locationId,
                  areas: userAreas,
                });
              }
            });
            return userLocationData;
          }
          return null;
        } catch (error) {
          return error;
        }
      },

      getAreasCount: () => {
        try {
          let resultData = get().customerOrgLocationsData;
          let areasCount = 0;
          if (resultData?.length >= 1) {
            resultData.forEach((location) => {
              const areas = location?.areas;
              if (areas) {
                areas.forEach((area) => {
                  areasCount += 1;
                });
              }
            });
          }
          return areasCount;
        } catch (error) {
          return error;
        }
      },

      getUserSelectedLocation: () => {
        return get().selectedUserLocation;
      },

      setUserSelectedLocation: (selectedUserLocation) => {
        if (!selectedUserLocation) return;
        set({
          selectedUserLocation: selectedUserLocation,
        });
      },
    }),
    {
      name: 'net.duclo.vms.customerorglocations',
      version: '1.2',
    }
  )
);
