import StepIndicator from '../StepIndicator';
import { Utils, constants } from '../../../../helpers';
import '../stepsnotification.scss';
import { useState } from 'react';
import vehicle from '../../../../assets/images/vehicle-notification.svg';
import person from '../../../../assets/images/person-notification.svg';
import camera from '../../../../assets/images/camera-notification.svg';
import tampering from '../../../../assets/images/Tampering-notification.svg';
import shock from '../../../../assets/images/Shock.svg';
import motion from '../../../../assets/images/motion-notification.svg';
import LineCrossing from '../../../../assets/images/LineCrossing-notification.svg';
import { PrimaryButton } from '../../../../components/common';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { useEffect } from 'react';

const Two = (props) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [triggerList, setTriggerList] = useState([
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_LINE_CROSSING,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_LINE_CROSSING_DESCRIPTION,
      isSelected: false,
      icon: LineCrossing,
      type: 'ai.duclo.virtualLineDetected',
      eventClass: [Utils.getNotificationEventName().virtualLineLeft,Utils.getNotificationEventName().virtualLineRight,
        Utils.getNotificationEventName().virtualLineBoth],
      resource: Utils.getNotificationResources().virtualLine,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_CAMERA,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_CAMERA_DESCRIPTION,
      isSelected: false,
      icon: camera,
      type: 'ai.duclo.CameraStatus',
      eventClass: [Utils.getNotificationEventName().fogStart,Utils.getNotificationEventName().fogEnd],
      internalEvent: ['online', 'offline', 'license-expire'],
      resource: Utils.getNotificationResources().camera,
      resourceFog: Utils.getNotificationResources().fog,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_PERSON,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_PERSON_DESCRIPTION,
      isSelected: false,
      icon: person,
      type: 'ai.duclo.PersonDetected',
      eventClass: [Utils.getNotificationEventName().person],
      resource: Utils.getNotificationResources().person,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_VEHICLE,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_VEHICLE_DESCRIPTION,
      isSelected: false,
      icon: vehicle,
      type: 'ai.duclo.VehicleDetected',
      eventClass: [Utils.getNotificationEventName().vehicle],
      resource: Utils.getNotificationResources().vehicle,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_TAMPERING,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_TAMPERING_DESCRIPTION,
      isSelected: false,
      icon: tampering,
      type: 'ai.duclo.Tampering',
      eventClass: [Utils.getNotificationEventName().tamperStart, Utils.getNotificationEventName().tamperEnd],
      resource: Utils.getNotificationResources().tempering,
    },
    // {
    //   name: constants.NOTIFICATION_CREATE_NOTIFICATION_LOITERING,
    //   desc: constants.NOTIFICATION_CREATE_NOTIFICATION_LOITERING_DESCRIPTION,
    //   isSelected: false,
    //   icon: camera,
    //   type: 'ai.duclo.Loitering',
    //   eventClass: [Utils.getNotificationEventName().loitering],
    //   resource: Utils.getNotificationResources().loitering,
    // },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_DEFOCUS,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_DEFOCUS_DESCRIPTION,
      isSelected: false,
      icon: camera,
      type: 'ai.duclo.DefocusDetection',
      eventClass: [Utils.getNotificationEventName().defocusStart,Utils.getNotificationEventName().defocusEnd],
      resource: Utils.getNotificationResources().defocus,
    },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_SHOCK,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_SHOCK_DESCRIPTION,
      isSelected: false,
      icon: shock,
      type: 'ai.duclo.ShockDetection',
      eventClass: [Utils.getNotificationEventName().shockStart,Utils.getNotificationEventName().shockEnd],
      resource: Utils.getNotificationResources().shock,
    },
    // {
    //   name: constants.NOTIFICATION_CREATE_NOTIFICATION_AUDIO,
    //   desc: constants.NOTIFICATION_CREATE_NOTIFICATION_AUDIO_DESCRIPTION,
    //   isSelected: false,
    //   icon: camera,
    //   type: 'ai.duclo.SoundRecognition',
    //   eventClass: [Utils.getNotificationEventName().scream,Utils.getNotificationEventName().explosion,
    //     Utils.getNotificationEventName().gunShot,Utils.getNotificationEventName().glassBreaking],
    //   resource: Utils.getNotificationResources().audio
    // },
    // {
    //   name: constants.NOTIFICATION_CREATE_NOTIFICATION_GEBS,
    //   desc: constants.NOTIFICATION_CREATE_NOTIFICATION_GEBS_DESCRIPTION,
    //   isSelected: false,
    //   icon: camera,
    //   type: 'ai.duclo.SoundRecognition',
    //   eventClass: ['scream', 'explosion', 'gun-shot', 'glass-breaking'],
    //   resource: Utils.getNotificationResources().sound,
    // },
    {
      name: constants.NOTIFICATION_CREATE_NOTIFICATION_MOTION,
      desc: constants.NOTIFICATION_CREATE_NOTIFICATION_MOTION_DESCRIPTION,
      isSelected: false,
      icon: motion,
      type: 'ai.duclo.MotionDetected',
      eventClass: ['motion-start', 'motion-end'],
      resource: Utils.getNotificationResources().motion,
    },
  ]);

  useEffect(() => {
    if (Object.keys(getStepsData()).length != 0) {
      if (getStepsData()?.selectedEvents?.length > 0) {
        const selectedList = getStepsData()?.selectedEvents;
        setSelectedEvents(selectedList);
        callFunction(selectedList);
      }
    }
    if(!props?.isEdit){
      props?.setNotificationData([])
    }
  }, []);

  const callFunction = async (selectedList) => {
    const list = triggerList?.map((item, index) => {
      let isExist = false;
      selectedList?.map((selectedItem, i) => {
        if(props?.eventType === 'customEvent'){
          if (Utils.areEqual(item?.eventClass,selectedItem?.properties?.eventClass)) {
            isExist = true;
          }
        }
        else{
          if (item?.type == selectedItem?.type) {
            isExist = true;
          }
        } 
      });
      if (isExist) {
        return { ...item, isSelected: true };
      } else {
        return { ...item };
      }
    });
    let triggerEvents = await Promise.all(list);
    setTriggerList(triggerEvents);
  };

  const onHandleChange = async (e, item) => {
    if (e != undefined) {
      let updateTrigger = triggerList?.map((events, index) => {
        if (Utils.areEqual(events?.eventClass,item?.eventClass)) {
          if (events?.isSelected) {
            return { ...events, isSelected: false };
          } else {
            return { ...events, isSelected: true };
          }
          } else {
            return { ...events };
          }
        });
      let list = await Promise.all(updateTrigger);
      setTriggerList(list);  
      const selectedEvents = list.filter((device) => device?.isSelected);
      setSelectedEvents(selectedEvents);
    }
  };

  const createTriggerData = () => {
    const triggers = [];
    const orEvent = {
      type: 'op',
      logic: 'or',
    };
    selectedEvents?.map((events, index) => {
      if (index !== 0 || index === 6) {
        triggers.push(orEvent);
      }
      const item = {
        type: events?.type,
        properties: {
          eventClass: events?.eventClass,
          ...(events?.internalEvent?.length > 0 && {
            internalEvent: events?.internalEvent,
          }),
        },
      };
      triggers.push(item);
    });
    const stepData = {};
    if (props?.isEdit) {
      stepData['triggers'] = triggers;
      stepData['selectedEvents'] = selectedEvents;
      stepData['editItem'] = getStepsData()?.editItem;
      stepData['devices'] = getStepsData()?.devices;
      stepData['conditions'] = getStepsData()?.conditions;
      stepData['actions'] = getStepsData()?.actions;
      stepData['subscribers'] = getStepsData()?.subscribers;
      stepData['locationId'] = getStepsData()?.locationId;
    } else {
      stepData['triggers'] = triggers;
      stepData['selectedEvents'] = selectedEvents;
    }
    setStepData(stepData);
    props.nextStep(stepData);
  };
  
  const virtualLineIncluded = triggerList.filter(item => item.type === 'ai.duclo.virtualLineDetected');
  const virtualLineExcluded = triggerList.filter(item => item.type !== 'ai.duclo.virtualLineDetected');

  return (
    <div className="create-notification">
      <StepIndicator {...props} />
      <div className="trigger-type-step-two">
        <div className="step-title">
          {props?.eventType === 'customEvent'? constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER_LINE_CROSSING :constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER}
        </div>
        <div className="step-title-desc">
          {constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER_DESC}
        </div>
        {(props?.eventType === 'customEvent' ? virtualLineIncluded : virtualLineExcluded)?.map((item, index) => {
          return (
            <div key={item?.properties?.eventClass} className="trigger-event-conatiner">
              <div className="left-container">
                <img className="event-icon" alt="" src={item.icon}></img>
                <div className="title-discription-conatiner">
                  <div className="event-title">{item.name}</div>
                  <div className="event-discription">{item.desc}</div>
                </div>
              </div>

              <input
                checked={item.isSelected}
                name={item.name}
                className={props?.eventType === 'customEvent'?'input-radio-events-trigger':"input-check-events"}
                type={props?.eventType === 'customEvent'?"radio":"checkbox"}
                onChange={(e) => onHandleChange(e, item)}
              />
            </div>
          );
        })}
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={selectedEvents?.length === 0}
          onClick={() => createTriggerData()}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Two;
