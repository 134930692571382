import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PrimaryButton } from '../../../components/common';
import { constants } from '../../../helpers';
import './modalForms.scss';

const DeleteView = ({ deleteView, cancelDeleting, selectedView }) => {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <>
      <Row>
        <div className="delete-view-modal-description">
          {constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_DESCRIPTION1}
          {selectedView?.name}
          {constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_DESCRIPTION2}
        </div>
      </Row>
      <Row>
        <Col>
          <div className="save-view-button-wrapper">
            <PrimaryButton
              className="btn"
              type="submit"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => cancelDeleting()}
            >
              {constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_CANCEL_LABEL}
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-outline-secondary w-100 mt-2"
              type="submit"
              loader={showLoader}
              loaderClassName='delete-view-loader'
              height="56px"
              fontSize="1.125rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_96')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                setShowLoader(true);
                deleteView();
              }}
            >
              {constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_DELETE_LABEL}
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DeleteView;
