import { ErrorMessage, useField } from "formik";

const ModalSelect = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);

  /*=== Generate selection options*/
  const generateOptions = () => {
    if (options !== "undefined") {
      return options.map((item, i) => {
        return (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        );
      });
    }
  };

  return (
    <div className=" row">
      {label && (
        <label
          className="col-sm-3 form-label col-form-label"
          htmlFor={field.name}
        >
          {label}
        </label>
      )}
      <div className={label ? "col-sm-7 " : "col"}>
        <select
          required
          className={`form-select border-line shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
        >
          <option disabled 
         
           value="">
            {props.selectplaceholder}
          </option>
          {generateOptions()}
        </select>
       
        <ErrorMessage component="div" name={field.name} className="error" />
      </div>
    </div>
  );
};
export default ModalSelect;