import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';

import { useCart } from '../../store/CartStore';
import { useSelector } from 'react-redux';
// import { getSelectedOrganization } from '../../store/reducers/AccountReducer';

import { Header } from '../../components/common';
import constants from '../../helpers/en';
import './PurchaseConfirmation.scss';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { Utils } from '../../helpers';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const PurchaseConfirmation = () => {
  const [show, setShow] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orgId = searchParams.get('orgId');

  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();

  const deleteCart = useCart((state) => state.deleteCart);
  const deleteOrder = useCart((state) => state.deleteOrder);
  const deleteOrgId = useCart((state) => state.deleteOrgId);
  const setOrderPending = useCart((state) => state.setOrderPending);

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);
  
  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const getPurchaseOrder = async () => {
    try {
      // Clear the cart and order
      deleteCart();
      deleteOrder();
      deleteOrgId();
      setOrderPending(false);
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShow(true);
    }
  };

  return (
    <div className="App purchase-confirmation">
      <Header
        backgroundColor={getComputedStyle(
          document.documentElement
        ).getPropertyValue('--primary_96')}
        cartFetch={false}
        numOfICartItems={0}
      />
      <Container
        className="container-top-offset purchase-confirmation-container"
        fluid
      >
        <Row className="mb-5">
          <Col>
            <Container>
              <div className="page-header mt-4 mb-5">
                <Row className="page-body-content-row">
                  <Col md={12} lg={12} xl={12} xs={12}>
                    <Card md={12} className="confirmation-card">
                      <Card.Body className="confirmation-card-body">
                        <Card.Title className="confirmation-card-title">
                          {constants.PURCHASE_CONFIRMATION_CARD_TITLE}
                        </Card.Title>
                        <div className="confirmation-card-body-wrapper">
                          <h3>
                            {constants.PURCHASE_CONFIRMATION_CARD_HEADLINE}
                          </h3>
                          <p>{constants.PURCHASE_CONFIRMATION_PAGE_OVERVIEW}</p>
                        </div>
                      </Card.Body>
                      <Card.Footer className="confirmation-card-footer d-grid gap-2">
                        <Button
                          variant="primary"
                          size="lg"
                          id={`btnAssignLicenses`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/customers/dashboard.html?orgId=${orgDetails.orgId}`
                            );
                          }}
                        >
                          <div className="confirmation-btn-label">
                            {
                              constants.PURCHASE_CONFIRMATION_BACK_TO_ORG_SETTINGS_BUTTON_LABEL
                            }
                          </div>
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col md={5} lg={5} xl={5} xs={12} className="text-end">
                    <Row className="page-body-content-row">
                      <Col>&nbsp;</Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PurchaseConfirmation;
