import PropTypes from 'prop-types';
const CheckField = ({ label, placeholder, type, onChange, checked, value }) => {
  return (
    <div className="selectField">
      {label && <label className={value && 'filled'}>{label}</label>}
      <input
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};

CheckField.propTypes = {
  email: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

CheckField.defaultProps = {
  label: '',
  placeholder: '',
  type: '',
};

export default CheckField;
