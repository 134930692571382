import { Utils } from '../../helpers/';

const TextBlock = (props) => {
  const {
    color = getComputedStyle(document.documentElement).getPropertyValue(
      '--greyscale_56'
    ),
  } = props;
  return (
    <div
      className={`site-text-block ${props.className}`}
      style={{
        color: color,
        fontSize: Utils.checkIfMobileView()
          ? props.mobileFontSize
          : props.fontSize,
        fontWeight: Utils.checkIfMobileView()
          ? props.mobileFontWeight
          : props.fontWeight,
        lineHeight: Utils.checkIfMobileView()
          ? props.mobileLineHeight
          : props.lineHeight,
      }}
    >
      {props.children}
    </div>
  );
};

TextBlock.defaultProps = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: ' 21px',
  mobileFontWeight: '400',
  mobileFontSize: '12px',
  mobileLineHeight: '16px',
};

export default TextBlock;
