import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardContent } from '@mui/material';

import Header from './Header';
import { constants, Utils } from '../../helpers';

import './LinkExpired.scss';

const LinkExpired = () => {
  return (
    <div className="App link-expired">
      <Header showNavigation={false} />
      <Container>
        <Row>
          <Col>
            <div className="link-expired-card-container">
              <Card className="link-expired-card">
                <CardContent>
                  <div className="link-expired-card-title">
                    {constants.CLIP_LANDING_PAGE_LINK_EXPIRED_TITLE}
                  </div>
                  <div className="link-expired-card-message">
                    {constants.CLIP_LANDING_PAGE_LINK_EXPIRED_REASON}
                  </div>
                </CardContent>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LinkExpired;
