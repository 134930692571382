import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import DatePicker from 'react-multi-date-picker';
import useEventsStore from '../../store/EventsStore';
import moment from 'moment';
import {
  DevicesFilter,
  DropdownList,
  MetricCard,
  SiteSpinner,
  SiteModal,
  DateTimePicker,
} from '../../components/common';
import { constants, Utils } from '../../helpers';
import { Section, TwoColumnLayout } from './components/';

import BaseChartConfig from './shared/BaseChartConfig';
import './CameraAveStreamingBitrate.scss';
import { useNavigate } from 'react-router-dom';
import { getSelectedDeviceFromFillter, setSelectedDeviceFromFillter } from '../../store/CustomerOrgDevicesStoreIDB';

const CameraAveStreamingBitrate = ({ custOrgData, devices , deviceType, camDeviceId}) => {
  // itemValue is set in number of days, except
  // the one for the Custom Range
  const pastTimeSeries = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
      itemValue: 1,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
      itemValue: 2,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
      itemValue: 7,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
      itemValue: 14,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
      itemValue: 30,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
      itemValue: 365,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
      itemValue: 730,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE,
      itemValue: 'custom',
    },
  ];

  const frequencies = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
      itemValue: 'hourly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
      itemValue: 'daily',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
      itemValue: 'weekly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
      itemValue: 'monthly',
    },
  ];

  const frequencyGetter = {
    hourly: Utils.getHourFromEpoch,
    daily: Utils.getDateFromEpoch,
    weekly: Utils.getWeekFromEpoch,
    monthly: Utils.getMonthFromEpoch,
  };

  const START_TIME = 1680307200000;
  const END_TIME = 1685343600000;

  const navigate = useNavigate();
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const calendarRef = useRef();
  const [filterDate, setFilterDate] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [showCustomRange, setShowCustomRange] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState();
  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);

  const [options, setOptions] = useState({
    ...BaseChartConfig,
    chart: {
      id: 'camera-ave-streaming-bitrate-line-chart',
      ...BaseChartConfig.chart,
    },
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          return `${value.toFixed(2)} Mbps`;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ],
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '0 Mbps',
    description:
      constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_HIGHLIGHT_DESCRIPTION,
  });
  const [metricArray, setMetricArray] = useState([
    {
      text: 'Front Door',
      subtext: 'San Diego, Front Door',
      value: '3 Mbps',
      color: 'light-blue',
    },
    {
      text: 'Meeting Room A',
      subtext: 'San Diego, Front Door',
      value: '3 Mbps',
      color: 'light-blue',
    },
    {
      text: 'Meeting Room B',
      subtext: 'San Diego, Front Door',
      value: '3 Mbps',
      color: 'light-blue',
    },
  ]);
  const [pastRange, setPastRange] = useState(-7);
  const [pastRangeLabel, setPassRangeLabel] = useState(
    constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
  );
  const [duration, setDuration] = useState('daily');
  const custOrgDataRef = useRef(custOrgData);

  useEffect(() => {
    if (custOrgData?.orgId) {
      custOrgDataRef.current = custOrgData;
    }
  }, [custOrgData?.orgId]);

  // const {
  //   getCustomerOrgDevices,
  //   setSelectedDeviceFromFillter,
  //   getSelectedDeviceFromFillter,
  // } = useCustomerOrgDevices();
  // const cameraDevices = getCustomerOrgDevices();

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  useEffect(() => {
    if (showCustomRange) {
      calendarRef?.current?.openCalendar();
      // setPastRange(0);
    }
  }, [showCustomRange]);

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };

  const [showDateTimeModal, setShowDateTimeModal] = useState(false);

  useEffect(() => {
    if (filterDate[0] && filterDate[1]) {
      setShowCustomRange(false);
      const startDate = new Date(filterDate[0]);
      const endDate = new Date(filterDate[1]);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 0);
      fetchData(0, duration, devices, startDate?.getTime(), endDate?.getTime());
    }
  }, [filterDate]);

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        await setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          await setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      await setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, devices);
    }
  };

  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );


  const dynamicWidth = series[0]?.data?.length * 45;
  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const chartContent = (
    <div className={dynamicWidth < window.innerWidth  ? "scrollable-chart-container" : "scrollable-chart-container lg-data"}>
      <Chart
        options={options}
        series={series}
        type="line"
        height={200}
        width={chartWidth}
      />
    </div>
  );

  const metricContent = (
    <>
      <MetricCard metricHighlight={highlight} metricsArray={metricArray} />
    </>
  );

  const topRightLinks = (
    <div className={ !deviceType && !camDeviceId? "filter-group":"filter-group-login-user"}>
      {/* Filter */}
      {
         !deviceType && deviceType !== '1' && <DropdownList
         defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
         hasSecondaryDropdown={true}
         items={filterContent}
         show={show}
         setShow={(status) => {
           setShow(status);
         }}
       />
      }
      
       <div  className={deviceType && deviceType === '1'?"mr-60":''}>
         {/* Past Range */}
      <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          if (selectedKey === 'custom') {
            setShowCustomRange(true);
            setShowDateTimeModal(true);
            calendarRef?.current?.openCalendar();
          } else {
            setFilterDate(new Date());
            setShowCustomRange(false);
            setShowDateTimeModal(false);
            setPastRange(-Math.abs(selectedKey));
          }
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
       </div>
     
      {/* Duration */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY}
        items={frequencies}
        show={rangeDay}
        dropdownRightAligned={true}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);

          switch (selectedKey.toUpperCase()) {
            case 'HOURLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS
              );
              break;

            case 'DAILY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
              );
              break;

            case 'WEEKLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR
              );
              break;

            default:
            // Do not do anything
          }
        }}
      />
    </div>
  );

  const getDeviceIdsString = async (devicList) => {
    const deviceSelected = await getSelectedDeviceFromFillter();
    let deviceIdsString = '';

    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.deviceId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const getDeviceDetails = (deviceId, isLocation) => {
    const currentCustOrgData = custOrgDataRef.current;
    let locationAreaName = '--';
    const devicesList = currentCustOrgData?.devices;
    const deviceData = devicesList?.find(
      (device) => device?.deviceId === deviceId
    );
    if (deviceData) {
      if (isLocation) {
        const locationsList = currentCustOrgData?.locations;
        const location = locationsList?.find(
          (location) => location?.locationId === deviceData?.locationId
        );
        if (location) {
          locationAreaName = `${location?.locationName}`;
        }
      } else {
        locationAreaName = deviceData?.deviceName;
      }
    }
    return locationAreaName;
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0) {
        setSeries([]);
        setMetricArray([]);
        setHasData(false)
        return;
      }      
      try {
        setShowLoader(true);
        const currentCustOrgData = custOrgDataRef.current;
        const baseUrl = `metrics/orgs/${currentCustOrgData?.orgId}/trend/device-bit-rate`;
        let date = new Date();
        const deviceIdsList = await getDeviceIdsString(devicesList);
        date.setDate(date.getDate() + newPastRange);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: currentCustOrgData?.orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: (newPastRange == -365 && newDuration == "hourly") ? "weekly" : (newPastRange == -730 && newDuration == "hourly") ? "monthly" :  newDuration,
            deviceIds: (deviceType && deviceType === '1') ? camDeviceId : deviceIdsList,
          },
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
          ...Utils.requestHeader(),
        });
        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          streamingBitrateAverage,
          streamingBitrateTotal,
          timeSeriesData,
          timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;

          if (Array.isArray(respData) && respData?.length > 0) {
            const sortedData = respData.sort((a, b) => a.timestamp - b.timestamp);
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter['hourly'];
                break;

                case -365:
                  timeSeriesData = sortedData;
                  timestampGetter = frequencyGetter['weekly'];
                  break;
  
                case -730:
                  timeSeriesData = sortedData;
                  timestampGetter = frequencyGetter["monthly"];
                  break;

              default:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter[newDuration];
            }
            const tableList = [];

            timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp);

            timeSeriesData.forEach((time) => {
              streamingBitrateAverage = 0;
              streamingBitrateTotal = 0;
              timestamp = timestampGetter(time.timestamp);

              xAxis.push(timestamp);

              if (Array.isArray(time.devices) && time.devices.length > 0) {
                time?.devices.forEach((device, index) => {
                  streamingBitrateTotal += parseFloat(device.avg);
                  streamingBitrateAverage = (
                    parseFloat(streamingBitrateTotal) /
                    parseFloat(time.devices.length) /
                    1000
                  ).toFixed(2);
                  const value = parseFloat(
                    device.avg / time.devices.length / 1000
                  );
                  tableList.push({
                    subtext: getDeviceDetails(device?.deviceId, true),
                    text: getDeviceDetails(device?.deviceId, false),
                    value: value.toFixed(2),
                    color: device?.avg < 0 ? 'pink' : 'light-blue',
                  });
                });
                yValues.push(streamingBitrateAverage);
              }
            });
            setMetricArray(
              tableList?.sort((a, b) => b.value - a.value)?.slice(0, 3)
            );
            let count = 0;
            tableList?.map((item) => {
              count = count + parseFloat(item?.value);
            });
            const tValue = (count / xAxis?.length);
            let finalTotalValue = Math.floor(tValue * 100) / 100;
            timeSeriesData?.length > 0 && setHighlight((highlight) => ({
              value: `${finalTotalValue.toFixed(2)} Mbps `,
              description: highlight?.description,
            }));

            // Set the y value of each tick on
            // x axis.
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
          else{
            setSeries([]);
            setMetricArray([]);
            setHasData(false);
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
          setShowToast(true);
          setUserMsg('Error fetching metric');
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
        setShowToast(true);
        setUserMsg(err.msg);
      }
    },
    []
  );

  useLayoutEffect(() => {
    fetchData(pastRange, duration, devices);

    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '0 Mbps',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [pastRange, duration, devices]);

  const handleDateChange = (data) => {
    setShowDateTimeModal(false);
    const date = data.selectedDate;
    setFilterDate(date);
  };

  return (
    <Section
      analyticsTitle={
        constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_TITLE
      }
      analyticsDescription={
        constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_DESCRIPTION
      }
      viewDetailsLinkLabel={constants.ANALYTICS_DASHBOARD_VIEW_DETAILS_LINK}
      viewDetailsLinkHandler={() => {
        navigate(`/reports.html/details?id=${3}&orgId=${custOrgData?.orgId}`, {
          state: {
            custOrgData: custOrgData,
            devices: devices,
          },
        });
      }}
      topRightLinks={topRightLinks}
      linkEnable = {series?.length > 0}
    >
      {showLoader ? (
        <div className="no-data-available">
          <SiteSpinner height="50px" width="50px"></SiteSpinner>
        </div>
      ) : (
        <div>
          {hasData ? (
            <TwoColumnLayout metric={metricContent} chart={chartContent} />
          ) : (
            defaultValue
          )}
        </div>
      )}
      {/* <div className="filterDate-image">
        <DatePicker
          ref={calendarRef}
          range
          minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
          maxDate={new Date()}
          className="rmdp-mobile customized-datepicker"
          value={filterDate}
          onChange={setFilterDate}
          //onClose={calendarRef?.current?.closeCalendar()}
          mobileButtons={[
            {
              type: 'button',
              className: 'rmdp-button rmdp-action-button',
              onClick: () => setFilterDate({}),
            },
          ]}
          render={<CustomRangePicker openCalendar value />}
        />
      </div> */}
      <SiteModal
        modalTitle="Select Date Range"
        showModal={showDateTimeModal}
        hideModal={() => {
          setShowDateTimeModal(false);
        }}
        classes="date-time-picker-modal-range"
        size="sm"
      >
        <DateTimePicker
          rangeShow={true}
          date={filterDate} 
          onSubmit={handleDateChange}
        />
      </SiteModal>
    </Section>
  );
};

export default CameraAveStreamingBitrate;
