import { useEffect, useState, useRef} from 'react';
import { useSearchParams } from 'react-router-dom';
import { constants, Utils, roles, AppDefaults } from '../../helpers';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import LocationsMap from './LocationsMap';
import { Container, Col, Row } from 'react-bootstrap';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteToast,
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { EditLocation, NewLocation } from '.';
// import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../store/policiesStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useSelector } from 'react-redux';
import { RxPlus } from 'react-icons/rx';
import './ManageLocations.scss';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import {
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';

function ManageLocations() {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [locationsData, setLocationsData] = useState([]);
  const [showAddLocationsModal, setShowAddLocationsModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false);
  const [editLocationsId, setEditLocationsId] = useState(null);
  const [editLocationsData, setEditLocationsData] = useState([]);
  const [lastSubmittedValues, setLastSubmittedValues] = useState([]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRemoveLocationModal, setShowRemoveLocationModal] = useState(false);
  const AddLocationformRef = useRef();
  const EditLocationformRef = useRef();

  //=== Store get/set actions
  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations
  );
  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData
  );
  const getCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocationsData
  );
  const getCustomerOrgEditLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgEditLocationsData
  );
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  // const custOrgData = customerOrgData?.find((user) => user.orgId === orgId);
  const [custOrgData, setCustOrgData] = useState({});
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();

  const breadList = [
    { url: loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER ? '/customers/manage.html' : `/customers/dashboard.html?orgId=${orgId}`, 
      title: loggedInUser.role !== roles.ROLE2VMS ?  
            constants.MANAGE_ORG_PAGE_TITLE : 
            constants.MANAGE_ORG_PAGE_ORG_TITLE 
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: custOrgData?.orgName,
    },
    {
      url: `/locations/manage.html?orgId=${orgId}`,
      title: constants.LOCATIONS_PAGE_TITLE,
    },
  ];

  useEffect(() => {
    const getOrg = async () => {
      const orgs = await getCustomerOrgData();
      const orgData = orgs?.find((user) => user.orgId === orgId);
      setCustOrgData(orgData);
    }
    getOrg();
  }, [orgId]);
  
  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDeviceListData(devices);
    };

    fetchDevices();
  }, []);

  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      if (getCustomerOrgLocationsData()?.length > 0) {
        const devicesList = await getCustomerOrgDevices();
        let locations = await getCustomerOrgLocationsData()?.map((location) => {
          const dataDevice = Utils.getTotalChildDevices(devicesList);
          const deviceExists = dataDevice?.filter(
            (device) => device.locationId === location.locationId
          );
          return {
            lat: location.lat,
            lng: location?.lng,
            deviceCount: deviceExists?.length,
            locationId: location?.locationId,
            locationName: location?.locationName,
            address: location?.addressLine1,
            orgId: location?.orgId,
            orgName: custOrgData?.orgName,
            city: location?.city,
            state: location?.state,
            country: location?.country,
            zipcode: location?.zipcode
          };
        });
        
        await setLocationsData(locations);
      }
    } catch (error) {
      setUserMsg(error);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchLocations();
    }
  }, [orgId]);

  const handleEditLocations = async (locationId) => {
    if (orgId && locationId) {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      const ediLocData = await getCustomerOrgEditLocationsData(locationId);
      setEditLocationsData(ediLocData);
      setEditLocationsId(locationId);
      setShowEditLocationsModal(true);
    }
  };

  const handleSubmit = () => {
    if (AddLocationformRef?.current) {
      AddLocationformRef?.current?.handleSubmit();
    }
    if(EditLocationformRef?.current){
      EditLocationformRef?.current?.handleSubmit();
    }
  };

  const handleFormValidityChange = (isValid) => {
    setIsFormValid(isValid);
  };

  const handleFormSubmittingChange = (submitting) => {
    setIsSubmitting(submitting);
  };

  const handleFormSubmittingValues = (submitting) => {
    setLastSubmittedValues(submitting);
  };

  return (
    <div className="App manage-locations-page">
      <Header breadcrumbData={breadList} />

      <PageWrapper className='mw-100'>
        <div className="page-header">
          <Container className='mw-100 w-100'>
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.LOCATIONS_PAGE_TITLE}
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="text-end page-header-buttons"
              >
                {getCustomerOrgPolicies().create_location && (
                  <PrimaryButton
                    className="btn btn-primary"
                    type="button"
                    width="auto"
                    onClick={() => {
                      setShowAddLocationsModal(true);
                    }}
                  >
                    <RxPlus
                      className="plus-icon-btn"
                      strokeWidth={'1.5px'}
                      size={13.33}
                    />
                    {constants.NEW_LOCATIONS_BUTTON_TITLE}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {/* Show error messages */}
        <SiteToast title="Error" show={!!userMsg} body={userMsg} />

        {/* New partner user - modal */}
        <SiteModal
          modalTitle={constants.NEW_LOCATIONS_PAGE_TITLE}
          showModal={showAddLocationsModal}
          hideModal={() => {
            setIsFormValid(false);
            setShowAddLocationsModal(false)
          }}
          classes="manage-location-main"
          modalFooter={
            <div>
              <PrimaryButton
                className="ms-2 btn btn-primary"
                type="button"
                onClick = {handleSubmit}
                disabled={!isFormValid}
                loader={isSubmitting}
                height="44px"
                width="auto"
                fontSize="0.875rem"
              >
                {constants.NEW_LOCATIONS_SUBMIT_BUTTON_TITLE}
              </PrimaryButton>
            </div>
          }
        >
          <NewLocation
            reloadData={() => {
              fetchLocations();
            }}
            AddLocationformRef={AddLocationformRef}
            onFormValidityChange={handleFormValidityChange}
            onFormSubmittingChange={handleFormSubmittingChange}
            hideModal={() => setShowAddLocationsModal(false)}
          />
        </SiteModal>

        {/* Edit partner user - modal */}
        <SiteModal
          modalTitle={constants.EDIT_LOCATIONS_PAGE_TITLE}
          showModal={showEditLocationsModal}
          hideModal={() => {
            setIsFormValid(false);
            setShowEditLocationsModal(false)
          }}
          classes="manage-location-main"
          modalFooter={
            <div>
              <PrimaryButton
                className="btn btn-primary-outline"
                type="button"
                width="auto"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                backgroundColor="transparent"
                disabledBackgroundColor="transparent"
                onClick={() => setShowRemoveLocationModal(true)}
                disabled={!getCustomerOrgPolicies()?.delete_location}
              >
                {constants.EDIT_LOCATIONS_REMOVE_BUTTON_TITLE}
              </PrimaryButton>
              <PrimaryButton
                className="ms-2 btn btn-primary"
                type="submit"
                loader={isSubmitting}
                height="44px"
                width="auto"
                fontSize="0.875rem"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                {constants.EDIT_LOCATIONS_SUBMIT_BUTTON_TITLE}
              </PrimaryButton>
            </div>
               
          }
        >
          <EditLocation
            locationId={editLocationsId}
            locationsData={editLocationsData}
            EditLocationformRef={EditLocationformRef}
            onFormValidityChange={handleFormValidityChange}
            onFormSubmittingChange={handleFormSubmittingChange}
            onFormSubmittingValues={handleFormSubmittingValues}
            showRemoveLocationModal={showRemoveLocationModal}
            setShowRemoveLocationModal={setShowRemoveLocationModal}
            reloadData={() => {
              fetchLocations();
            }}
            hideModal={() => setShowEditLocationsModal(false)}
          />
        </SiteModal>
      </PageWrapper>
      {/* Google Map with marker cluster */}
      {locationsData?.length > 0 && getCustomerOrgPolicies().view_location && (
        <LocationsMap
          locationPositions={locationsData}
          lastUpdatedLocation={lastSubmittedValues}
          handleEditLocation={(locationId) => handleEditLocations(locationId)}
        />
      )}
    </div>
  );
}

export default ManageLocations;
