import { Col, Row } from 'react-bootstrap';

const LicensesList = ({
  className,
  label,
  numberText,
  icon = null,
  children = null,
}) => {
  return (
    <div className={className}>
      <div className="card-header">
        <Row>
          <Col className="text-start" lg="7" xs="8">
            <h5 className="licences-title">{label}</h5>
          </Col>
          <Col
            className={`text-end ${
              numberText ? 'list-block-nav' : 'list-block-nav-icon'
            }`}
          >
            <div className="licenses-count">{numberText}</div>
            {icon && parseInt(numberText) > 0 && (
              <div className="licenses-icon">{icon}</div>
            )}
          </Col>
        </Row>
      </div>
      <div>{children && <div className="card-body">{children}</div>}</div>
    </div>
  );
};

LicensesList.defaultProps = {
  backgroundColor: '#F5F6F9',
  width: '100%',
};

export default LicensesList;
