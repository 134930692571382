import React from 'react';
import { SiteSpinner } from '../../../components/common';
import videoProcessing from '../../../assets/images/cameras/FootageThumb.svg';
import { constants } from '../../../helpers';

const LoadingCamera = () => {
  return (
    <>
      <div className="not-active-camera-details">
        <SiteSpinner />
        {/* <div className="simple-label">
          {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
        </div> */}
      </div>
      <img
        className="hide-image"
        src={videoProcessing}
        alt="video processing"
      />
    </>
  );
};

export default LoadingCamera;
