import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const useCustomerOrgUsers = create(
  persist(
    (set, get) => ({
      customerOrgUsersData: [],
      partnerOrgUsersData: [],
      accountId: '',

      getCustomerOrgUsers: async (route) => {
        if (!route) return;

        const fetchUserInitials = (user) => {
          return user
            ? `${user.firstName?.toUpperCase()?.charAt(0)}${user.lastName
                ?.toUpperCase()
                .charAt(0)}`
            : '';
        };

        const res = await axios.get(route, Utils.requestHeader());
        const response = res?.data;
        let result;

        // Set customer org users if request is successful
        if (response?.meta?.code === 200) {
          let usersListData = response?.data;
          if (usersListData) {
            const userListWithInitials = usersListData?.map((user) => {
              return {
                ...user,
                initials: fetchUserInitials(user),
              };
            });

            set({
              customerOrgUsersData: userListWithInitials,
            });
          }

          result = {
            status: 'success',
            msg: 'customer organizations users data retrieved`',
          };
        } else {
          set({
            customerOrgUsersData: [],
          });
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },

      getCustomerOrgUsersData: () => {
        return get().customerOrgUsersData;
      },

      setCustomerOrgUsers: (customerOrgUsers) => {
        if (!customerOrgUsers) return;

        set({
          customerOrgUsersData: customerOrgUsers,
        });
      },

      setPatnerOrgUser: (patnerOrgUser) => {
        if (!patnerOrgUser) return;

        set({
          partnerOrgUsersData: patnerOrgUser,
        });
      },

      getPartnerOrgUsersData: () => {
        return get().partnerOrgUsersData;
      },

      resetCustomerOrgUsers: () =>
        set((state) => {
          return {
            ...state,
            customerOrgUsersData: [],
            partnerOrgUsersData: [],
            accountId: '',
          };
        }),

      setCustomerOrgAccountId: (id) => {
        if (!id) return;

        set({
          accountId: id,
        });
      },

      resetCustomerOrgAccountId: () =>
        set((state) => {
          return {
            ...state,
            accountId: [],
          };
        }),

      getEditCustomerOrgUsersData: (accountId) => {
        try {
          let resultData = get().customerOrgUsersData;

          if (resultData?.length >= 1) {
            const editUserData = resultData?.find(
              (user) => user.accountId === accountId
            );
            return editUserData;
          }
          return null;
        } catch (error) {
          return error;
        }
      },
    }),
    {
      name: 'net.duclo.vms.customerorgusers',
      version: '1.0',
    }
  )
);
