import { Container, Col, Navbar, Nav, Row } from 'react-bootstrap';
import { constants, AppDefaults } from '../../helpers';
import { getOpenSourceConditions } from '../../store/reducers/AccountReducer';
import Store from '../../store/Store';

function Footer(props) {
  const openSourceConditions = Store.getState(getOpenSourceConditions)?.accounts?.openSourceConditions;
  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? 'hva'
      : 'dc';
  const appID =
  !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
    ? 'vms'
    : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
    ? 'oncloud'
    : 'vms';
  const directPPLink = `${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appID}/privacy`;
  const directTosLink = `${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appID}/tos`;
  return (
    <footer
      className="footer"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={11}>
            <Navbar
              expand="lg"
              className={
                props?.centeredLinks === 'true'
                  ? 'justify-content-center'
                  : 'justify-content-start'
              }
            >
               <Nav.Link href={openSourceConditions?.tosLink ?openSourceConditions.tosLink : directTosLink} target='_blank' rel="noopener noreferrer">
                {constants.FOOTER_NAV_TERMS_CONDITION}
              </Nav.Link>
              <Nav.Link href={openSourceConditions?.ppLink ?openSourceConditions.ppLink : directPPLink} target='_blank' rel="noopener noreferrer">
                {constants.FOOTER_NAV_PRIVACY_POLICY}
              </Nav.Link>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  backgroundColor: '#FFFFFF',
};

export default Footer;
