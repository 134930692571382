import { Card } from 'react-bootstrap';
import './MetricCard.scss';
import { constants } from '../../../src/helpers';

const MetricCard = ({ analyticsTitle, metricHighlight, metricsArray }) => {
  return (
    <Card className="metric-container">
      <Card.Body>
        <Card.Title>
          <div className="title-wrapper">
            <div className="title-data">
              {!Number.isNaN(metricHighlight.value) ? metricHighlight.value : 0}
            </div>
            <div className="title-description">
              {metricHighlight.description}
            </div>
          </div>
        </Card.Title>
        <div className="metric-body">
          {Array.isArray(metricsArray) &&
            metricsArray.map((metric, metricIndex) => (
              <div key={`metric-${metricIndex}`} className="body-wrapper">
                <div className="metric-source">
                  <div className="source-text">{metric.text}</div>
                  <div className="source-subtext">{metric.subtext}</div>
                </div>
                {analyticsTitle ===
                  constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE ||
                analyticsTitle ===
                  constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_TITLE ? (
                  <div className={`metric-data cameraAvailability`}>
                    {!Number.isNaN(metric.value) ? metric.value : 0}
                  </div>
                ) : (
                  <div className={`metric-data ${metric.color}`}>
                    {!Number.isNaN(metric.value) ? metric.value : 0}
                  </div>
                )}
              </div>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MetricCard;
