import './TwoColumnLayout.scss';

const TwoColumnLayout = ({ metric = <>metric 1</>, chart = <>chart 1</> }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="left-column">
        <div>{metric}</div>
      </div>
      <div className="right-column">
        <div>{chart}</div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;
