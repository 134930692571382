import moment from 'moment';
import { Utils } from '.';

export const dateClosest = (dates, testDate) => {
  let before = [];
  let after = [];

  dates?.forEach((currentDate, i) => {
    let diff =
      new Date(moment(Utils.getDate(currentDate)).add({ minutes: 0 })) -
      Utils.getDate(testDate);
    if (diff > 0) {
      before.push({
        diff: diff,
        index: i,
        date: new Date(moment(Utils.getDate(currentDate)).add({ minutes: 0 })),
      });
    } else if (diff < 0) {
      after.push({
        diff: diff,
        index: i,
        date: new Date(moment(Utils.getDate(currentDate)).add({ minutes: 0 })),
      });
    }
  });

  before.sort(function (a, b) {
    if (a.diff < b.diff) {
      return -1;
    }
    if (a.diff > b.diff) {
      return 1;
    }
    return 0;
  });

  after.sort(function (a, b) {
    if (a.diff > b.diff) {
      return -1;
    }
    if (a.diff < b.diff) {
      return 1;
    }
    return 0;
  });
  return { datesBefore: before, datesAfter: after };
};
