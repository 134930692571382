import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { constants, Utils } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { getMQTTConnection } from '../../store/reducers/StreamingReducer';
import { subscribeWithOrgIds } from '../../utils/connection/mqttConnection';
import './ManageOrganizations.scss';
import { useKeycloak } from '@react-keycloak/web';
import { PrimaryButton, SiteModal } from '../../components/common';
import { BsExclamation, BsExclamationLg } from 'react-icons/bs';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function CustomerLandingPage() {
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const navigate = useNavigate();
  let exportUrl = localStorage.getItem('incidentExportUrl');
  const [linkExpired, setLinkExpired] = useState(false);

  //=== Store get/set actions
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );

  // const fetchCustomerOrgData = useCallback(
  //   () => getCustomerOrgData(),
  //   [getCustomerOrgData]
  // );

  // BRAWN-4046: Redirect immediately to video wall
  useEffect(() => {
    if (exportUrl !== undefined && exportUrl !== null) {
      if (exportUrl === '0') {
        setLinkExpired(true);
      } else {
        fetch(exportUrl)
          // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
          .then((resp) =>
            resp.status === 200
              ? resp.blob()
              : Promise.reject('something went wrong')
          )
          .then((blob) => {
            const incidentName = `Incident-Zip-${new Date()}`;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `${incidentName}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            localStorage.removeItem('incidentExportUrl');
            if (loggedInUserData?.accountStatus !== 'INACTIVE') {
              navigate(`/cameras/devices.html`);
            }
          });
      }
    } else if (loggedInUserData?.accountStatus !== 'INACTIVE') {
      navigate(`/cameras/devices.html`);
    }
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const customerOrgData = await getCustomerOrgData();

    if (Array.isArray(customerOrgData)) {
      //=== Subscribe the topics for all added customer organizations
      subscribeWithOrgIds(customerOrgData);
    }
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const hideModalInvite = () => {
    setLinkExpired(false);
    localStorage.removeItem('incidentExportUrl');
    navigate(`/cameras/devices.html`);
  };

  return (
    <div className="App customer-landing-page">
      <div className="page-header">
        <Container>
          <Row className="g-4">
            <Col className="page-title text-start">
              {loggedInUserData?.firstName &&
                Utils.replaceStringValues(
                  constants.MANAGE_ORG_CUSTOMER_LANDING_PAGE_TITLE,
                  '$firstName',
                  `${loggedInUserData?.firstName}`
                )}
            </Col>
          </Row>
        </Container>
      </div>

      <SiteModal
        modalTitle={constants.INCIDENTS_EXPORT_FILE_LINK_EXPIRED_MODAL_TITLE}
        showModal={linkExpired}
        hideModal={() => hideModalInvite()}
        classes="send-invitation-partner remove-org-modal"
      >
        <div className="sub-title-modal">
          {constants.INCIDENTS_EXPORT_FILE_LINK_EXPIRED_MODAL_BODY_TEXT}
        </div>
        {
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div className="claimed-view warning">
                  {
                    <BsExclamationLg
                      size={40}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--warning_24')}
                    />
                  }
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_72')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                hideModalInvite();
              }}
            >
              {
                constants.INCIDENTS_EXPORT_FILE_LINK_EXPIRED_MODAL_BACK_TO_INCIDENT_BUTTON_LABEL
              }
            </PrimaryButton>
          </div>
        }
      </SiteModal>
    </div>
  );
}

export default CustomerLandingPage;
