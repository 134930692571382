import { useState, useEffect, useCallback } from 'react';
import { PrimaryButton } from '../../../components/common';
import { ccimg, step3Icon, TickOutlined } from '../../../assets/images';
import { useContext } from 'react';
import { DataContext } from './DataContextProvider';
import { Utils, constants } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  // setAllDevicesData,
  // getSelectedOrganization,
  getAllMqttDataFromResponse,
  getPassedDeviceInfoFromDeviceList,
  setPassedDeviceInfoFromDeviceList,
} from '../../../store/reducers/AccountReducer';
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';
import { DetailsContext } from './DetailsContextProvider';
import { getPlatformInfo } from '../../../store/reducers/StreamingReducer';
import { addDeviceAPI } from './addDeviceAPI';
import { mqttSubscribe } from '../../../utils/connection/mqttConnection';
import { DeviceTypeEnum } from '../../../helpers/enums';

import { useTranslation } from 'react-i18next';
import {
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const Status = {
  CLAIMING: 1,
  CLAIMED: 2,
  NOT_CLAIMED: 3,
};

const Three = (props) => {
  const expiredTime = 300;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const passedDeviceInfoFromDeviceList = useSelector(
    getPassedDeviceInfoFromDeviceList
  );
  const { info } = useContext(DetailsContext);
  localStorage.setItem('modalOpen', 1);
  const { datavalues } = useContext(DataContext);
  const [showLoader, setShowLoader] = useState(false);
  const [currentDeviceStatus, setCurrentDeviceStatus] = useState(
    Status.CLAIMING
  );
  const [remainingTime, setRemainingTime] = useState(expiredTime);
  const platformDetails = useSelector(getPlatformInfo);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;

  const goToDeviceList = (e) => {
    e.preventDefault();
    props.reloadData();
    props.hideModal();
  };

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  // useEffect(() => {
  //   const fetchMqttData = async () => {
  //     const allMqtt = await getAllMqttDataFromResponse();
  //     setAllMqttData(allMqtt);
  //   };

  //   fetchMqttData();
  // }, []);

  useEffect(() => {
    if (passedDeviceInfoFromDeviceList) {
      handleTryAgainClick();
      dispatch(setPassedDeviceInfoFromDeviceList(null));
    }
  }, []);

  useEffect(() => {
    if (!passedDeviceInfoFromDeviceList) {
      const timerInterval = setInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime((prevTime) => prevTime - 1);
        } else {
          setCurrentDeviceStatus(Status.NOT_CLAIMED);
          clearInterval(timerInterval);
        }
      }, 1000);
      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [remainingTime]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes('device/') || resource?.includes('devices/')) {
      const deviceAttributes = resource.split('/');
      if (
        Array.isArray(deviceAttributes) &&
        info?.deviceId === deviceAttributes[deviceAttributes.length - 1]
      ) {
        const addedDeviceStatus = { ...allMqttData?.msg?.properties };
        if (
          addedDeviceStatus?.connectionStatus?.toUpperCase() ===
            constants.DEVICES_RETURN_ONLINE_STATUS.toUpperCase() &&
          addedDeviceStatus?.deviceStatus?.toUpperCase() ===
            constants.DEVICES_CLAIMED_DEVICE_STATUS.toUpperCase()
        ) {
          setCurrentDeviceStatus(Status.CLAIMED);
        }
      }
    }
  }, [allMqttData]);

  const addOtherDevice = (e) => {
    e.preventDefault();
    axios
      .get(`device/orgs/${orgDetails?.orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            await setAllDevicesData(responseData);
          }
        }
      });
    props.firstStep();
  };

  const handleTryAgainClick = async (e) => {
    e?.preventDefault();
    setShowLoader(true);
    setRemainingTime(expiredTime - 1);
    setCurrentDeviceStatus(Status.CLAIMING);
    const information = { ...info };
    const { step2info } = information;
    delete information.step2info;
    delete information.channelCount;
    const deviceResponse = await addDeviceAPI(
      orgDetails,
      step2info,
      information
    );
    if (deviceResponse?.userMsg) {
      setShowLoader(false);
    } else {
      setShowLoader(false);
      if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
        const deviceSubscription = {
          topic: Utils.replaceStringValues(
            subscribeTopic[0],
            '${deviceId}',
            `${deviceResponse?.data?.deviceId}`
          ),
          qos: 0,
        };
        mqttSubscribe(deviceSubscription);
      }
    }
  };

  const getAddDeviceButtonProps = () => {
    // if (currentDeviceStatus === Status.NOT_CLAIMED) {
    //   return { onClick: handleTryAgainClick };
    // }
    if (info.deviceType === DeviceTypeEnum.NVR) {
      return {
        disabled: currentDeviceStatus !== Status.CLAIMED,
        onClick: () => {
          props.onDiscoverDevices();
          props.hideModal();
        },
      };
    } else {
      return { onClick: addOtherDevice };
    }
  };

  const getAddDeviceButtonText = () => {
    // if (currentDeviceStatus === Status.NOT_CLAIMED) {
    //   return constants.TRY_AGAIN_BUTTON_TITLE;
    // } else
    if (info.deviceType === DeviceTypeEnum.NVR) {
      return constants.DEVICES_CONTINUE_TEXT;
    } else {
      return constants.ADD_ANOTHER_CAMERA;
    }
  };

  const getBackToDevicesListButtonText = () => {
    if (
      info.deviceType === DeviceTypeEnum.NVR &&
      currentDeviceStatus !== Status.NOT_CLAIMED
    ) {
      return constants.DEVICES_SETUP_LATER_TEXT;
    } else {
      return constants.BACK_TO_DEVICES;
    }
  };

  return (
    <>
      <div className="ctn-box innner">
        <div id={'headerText'} className="header-view">
          <div className="titlename-header">
            {constants.DEVICES_ADD_DEVICE_TITLE}
          </div>
          <RxCross1
            className="site-modal-close"
            onClick={(e) => goToDeviceList(e)}
          />
        </div>
        <div className="mb-3">
          <div className="headerbox">
            <div className="stepIcon">
              <img src={step3Icon} alt="step3Icon" />
            </div>
            <div className="steptext">
              {constants.DEVICES_STEP_THREE_OF_THREE}
            </div>
          </div>

          <div className="qr-block">
            <div className="qr-image">
              <img
                src={datavalues[6] ? datavalues[6] : ccimg}
                alt="Device"
                className="qr-image"
              />
            </div>
          </div>
          {currentDeviceStatus === Status.CLAIMING && (
            <>
              <div className="titlename">{constants.DEVICE_CLAIMING_TEXT}</div>
              <div className="subtext">{t('DEVICE_STATUS_MESSAGE')}</div>
            </>
          )}
          {currentDeviceStatus === Status.CLAIMED && (
            <div className="titlename">
              {constants.DEVICE_CLAIMED_TITLE}{' '}
              <img src={TickOutlined} alt="TickOutlined" />
            </div>
          )}
          {currentDeviceStatus === Status.NOT_CLAIMED && (
            <>
              <div className="titlename">
                {constants.DEVICE_NOT_CLAIMED_TEXT}
              </div>
              <div className="subtext">
                {constants.DEVICE_NOT_CLAIMED_STATUS_MESSAGE}
              </div>
            </>
          )}
        </div>
        <PrimaryButton
          className="adddevice-btn"
          fontSize="0.875rem"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_white'
          )}
          type="submit"
          loader={showLoader}
          {...getAddDeviceButtonProps()}
        >
          {getAddDeviceButtonText()}
        </PrimaryButton>
        <PrimaryButton
          className="adddevice-btn"
          fontSize="0.875rem"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          type="submit"
          onClick={(e) => goToDeviceList(e)}
        >
          {getBackToDevicesListButtonText()}
        </PrimaryButton>
      </div>
    </>
  );
};

export default Three;
