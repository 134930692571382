import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-multi-date-picker';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import {
  DevicesFilter,
  DropdownList,
  MetricCard,
  SiteSpinner,
  SiteModal,
  DateTimePicker,
} from '../../components/common';
import { constants, Utils } from '../../helpers';
import { Section, TwoColumnLayout } from './components/';

import BaseChartConfig from './shared/BaseChartConfig';
import { useNavigate } from 'react-router';
import './CameraAvailability.scss';
import useEventsStore from '../../store/EventsStore';
import moment from 'moment';
import { useEffect } from 'react';
import { AppDefaults } from '../../../src/helpers';
import { getSelectedDeviceFromFillter, setSelectedDeviceFromFillter } from '../../store/CustomerOrgDevicesStoreIDB';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as CrossIcon } from '../../assets/images/CrossIcon.svg';

// itemValue is set in number of days, except
// the one for the Custom Range

const CameraAvailability = ({ custOrgData, devices, deviceType, camDeviceId }) => {
  const pastTimeSeries = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
      itemValue: 1,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
      itemValue: 2,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
      itemValue: 7,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
      itemValue: 14,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
      itemValue: 30,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
      itemValue: 365,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
      itemValue: 730,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE,
      itemValue: 'custom',
    },
  ];

  const frequencies = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
      itemValue: 'hourly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
      itemValue: 'daily',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
      itemValue: 'weekly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
      itemValue: 'monthly',
    },
  ];

  const frequencyGetter = {
    hourly: Utils.getHourFromEpoch,
    daily: Utils.getDateFromEpoch,
    weekly: Utils.getWeekFromEpoch,
    monthly: Utils.getMonthFromEpoch,
  };

  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const [showToast, setShowToast] = useState(false);
  const calendarRef = useRef();
  const [filterDate, setFilterDate] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [userMsg, setUserMsg] = useState();
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);
  const [options, setOptions] = useState({
    ...BaseChartConfig,
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          let retVal =
            parseInt(value) === 0 ? value.toFixed(2) : `${value.toFixed(2)}%`;

          return retVal;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ],
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '0%',
    description:
      constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_HIGHLIGHT_DESCRIPTION,
  });
  const [metricArray, setMetricArray] = useState([]);
  const [averageCameraOnlineTime, setAverageCameraOnlineTime] = useState(98);
  const [pastRange, setPastRange] = useState(-7);
  const [pastRangeLabel, setPassRangeLabel] = useState(
    constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
  );
  const [duration, setDuration] = useState('daily');
  // const {
  //   getCustomerOrgDevices,
  //   setSelectedDeviceFromFillter,
  //   getSelectedDeviceFromFillter,
  // } = useCustomerOrgDevices();
  const navigate = useNavigate();
  const custOrgDataRef = useRef(custOrgData);

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  useEffect(() => {
    if(custOrgData?.orgId) {
      custOrgDataRef.current = custOrgData;
    }
  }, [custOrgData?.orgId]);

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };
  const [showDateTimeModal, setShowDateTimeModal] = useState(false);

  useEffect(() => {
    if (filterDate[0] && filterDate[1]) {
      setShowCustomRange(false);
      const startDate = new Date(filterDate[0]);
      const endDate = new Date(filterDate[1]);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 0);
      fetchData(0, duration, devices, startDate?.getTime(), endDate?.getTime());
    }
  }, [filterDate]);

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT == AppDefaults.PROJECT_MEGATRON) {
      document.documentElement.style.setProperty(
        '--megatron-flag1',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--primary_96'
        )
      );
      document.documentElement.style.setProperty(
        '--megatron-flag2',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--brand_white'
        )
      );
    } else {
      document.documentElement.style.setProperty(
        '--megatron-flag1',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--primary_96'
        )
      );
      document.documentElement.style.setProperty(
        '--megatron-flag2',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--brand_white'
        ) + '00'
      );
    }
  }, []);

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        await setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          await setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      await setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, deviceData);
    }
  };
  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

const dynamicWidth = series[0]?.data?.length * 40;
const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const chartContent = (
    <div className={dynamicWidth < window.innerWidth  ? "scrollable-chart-container" : "scrollable-chart-container lg-data"}>
      <Chart
        options={options}
        series={series}
        type="line"
        height={200}
        width={chartWidth}
      />
    </div>
);

  const metricContent = (
    <>
      <MetricCard
        analyticsTitle={
          constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE
        }
        metricHighlight={highlight}
        metricsArray={metricArray}
      />
    </>
  );

  const topRightLinks = (
    <div className={ !deviceType && !camDeviceId? "filter-group":"filter-group-login-user"}>
      {/* Filter */}
      {
        !deviceType && deviceType !== '1' && <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
        hasSecondaryDropdown={true}
        items={filterContent}
        show={show}
        setShow={(status) => {
          setShow(status);
        }}
      />
      }
      
      {/* Past Range */}
      <div  className={deviceType && deviceType === '1'?"mr-60":''}>
        <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          if (selectedKey === 'custom') {
            setShowCustomRange(true);
            setShowDateTimeModal(true);
            calendarRef?.current?.openCalendar();
          } else {
            setFilterDate(new Date());
            setShowCustomRange(false);
            setShowDateTimeModal(false);
            setPastRange(-Math.abs(selectedKey));
          }
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
      </div>
      {/* Duration */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY}
        items={frequencies}
        show={rangeDay}
        dropdownRightAligned={true}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);

          switch (selectedKey.toUpperCase()) {
            case 'HOURLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS
              );
              break;

            case 'DAILY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
              );
              break;

            case 'WEEKLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR
              );
              break;

            default:
            // Do not do anything
          }
        }}
      />
    </div>
  );

  const getDeviceIdsString = async (devicList) => {
    const deviceSelected = await getSelectedDeviceFromFillter();
    let deviceIdsString = '';
    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.parentId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };
  const getDeviceDetails = (deviceId, isLocation) => {
    const currentCustOrgData = custOrgDataRef.current;
    let locationAreaName = '--';
    const devicesList = currentCustOrgData?.devices;
    const deviceData = devicesList?.find(
      (device) => device?.deviceId === deviceId
    );
    if (deviceData) {
      if (isLocation) {
        const locationsList = currentCustOrgData?.locations;
        const location = locationsList?.find(
          (location) => location?.locationId === deviceData?.locationId
        );
        if (location) {
          locationAreaName = `${location?.locationName}`;
        }
      } else {
        locationAreaName = deviceData?.deviceName;
      }
    }
    return locationAreaName;
  };
  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0) {
        setSeries([]);
        setMetricArray([]);
        setHasData(false);
        return;
      }
      try {
        const currentCustOrgData = custOrgDataRef.current;
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${currentCustOrgData?.orgId}/trend/device-connection-status`;
        const requestId = uuidv4();
        let date = new Date();
        date.setDate(date.getDate() + newPastRange);
        const deviceIdsList = await getDeviceIdsString(devicesList);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: currentCustOrgData?.orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: (newPastRange == -365 && newDuration == "hourly") ? "weekly" : (newPastRange == -730 && newDuration == "hourly") ? "monthly" :  newDuration,
            deviceIds: (deviceType && deviceType === '1') ? camDeviceId : deviceIdsList,
            requestTime: Date.now(),
            requestId: requestId,
          },
          ...Utils.requestHeader(requestId),
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
        });

        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          averagePercentageOnline,
          totalPercentageOnline,
          totalDevicesOnlinePercentages,
          timeSeriesData,
          timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;

          if (Array.isArray(respData) && respData?.length > 0) {
            const sortedData = respData.sort(
              (a, b) => a.timestamp - b.timestamp
            );
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = sortedData;
                timestampGetter = frequencyGetter['weekly'];
                break;

              case -730:
                timeSeriesData = sortedData;
                timestampGetter = frequencyGetter["monthly"];
                break;

              default:
                timeSeriesData = sortedData
                timestampGetter = frequencyGetter[newDuration];
            }

            totalDevicesOnlinePercentages = 0;
            const tableList = [];
            timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp);
            timeSeriesData.forEach((time) => {
              averagePercentageOnline = 0;
              totalPercentageOnline = 0;
              timestamp = timestampGetter(time.timestamp);

              xAxis.push(timestamp);

              if (Array.isArray(time.devices) && time.devices.length > 1) {
                time?.devices.forEach((device, index) => {
                  totalPercentageOnline += device.percentageOnline;
                  const tableItem = {
                    subtext: getDeviceDetails(device?.deviceId, true),
                    text: getDeviceDetails(device?.deviceId, false),
                    value: device?.percentageOnline + `%`,
                    color:
                      device?.percentageOnline < 100 ? 'pink' : 'light-blue',
                  };
                  tableList.push(tableItem);
                });

                averagePercentageOnline =
                  totalPercentageOnline / time.devices.length;
              } else {
                averagePercentageOnline = time?.devices[0].percentageOnline;
                const tableItem = {
                  subtext: getDeviceDetails(time?.devices[0]?.deviceId, true),
                  text: getDeviceDetails(time?.devices[0]?.deviceId, false),
                  value: time?.devices[0]?.percentageOnline + `%`,
                  color:
                    time?.devices[0]?.percentageOnline < 100
                      ? 'pink'
                      : 'light-blue',
                };
                tableList.push(tableItem);
              }

              yValues.push(averagePercentageOnline);

              totalDevicesOnlinePercentages += averagePercentageOnline;
            });

            const sortedListMetrics = [...tableList?.slice(0, 3)].sort(
              (a, b) => {
                const valueA = parseFloat(a.value);
                const valueB = parseFloat(b.value);
                return valueB - valueA; // Sort descending
              }
            );
            setMetricArray(sortedListMetrics);

            setAverageCameraOnlineTime(
              (totalDevicesOnlinePercentages / timeSeriesData.length).toFixed(2)
            );

            timeSeriesData?.length > 0 &&
              setHighlight((highlight) => ({
                value: `${(
                  totalDevicesOnlinePercentages / timeSeriesData.length
                ).toFixed(2)}%`,
                description: highlight?.description,
              }));

            // Set the y value of each tick on
            // x axis.
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
          else{
            setSeries([]);
            setMetricArray([]);
            setHasData(false);
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
          setShowToast(true);
          setUserMsg('Error fetching metric');
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
        setShowToast(true);
        setUserMsg(err.msg);
      }
    },
    []
  );

  useLayoutEffect(() => {
    fetchData(pastRange, duration, devices);

    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '0%',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [pastRange, duration, devices]);

  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    Utils.vmsLogger().log("useEffect for calender")
    if (calendarOpen) {
      Utils.vmsLogger().log("calender is open")
    } else {
      Utils.vmsLogger().log("calender is close")
    }
  }, [calendarOpen]);


const handleDateChange = (data) => {
  setShowDateTimeModal(false);
  const date = data.selectedDate;
  setFilterDate(date);
};

  return (
    <Section
      analyticsTitle={
        constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE
      }
      analyticsDescription={
        constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_DESCRIPTION
      }
      viewDetailsLinkLabel={constants.ANALYTICS_DASHBOARD_VIEW_DETAILS_LINK}
      viewDetailsLinkHandler={() => {
        navigate(`/reports.html/details?id=${1}&orgId=${custOrgData?.orgId}`, {
          state: {
            custOrgData: custOrgData,
            devices: devices,
          },
        });
      }}
      topRightLinks={topRightLinks}
      linkEnable={series?.length > 0}
    >
      {showLoader ? (
        <div className="no-data-available">
          <SiteSpinner height="50px" width="50px"></SiteSpinner>
        </div>
      ) : (
        <div>
          {hasData ? (
            <TwoColumnLayout metric={metricContent} chart={chartContent} />
          ) : (
            defaultValue
          )}
        </div>
      )}

      {/* <div className="date-picker-events" 
        ref={calendarRef}>
        <DatePicker
          ref={calendarRef}
          range
          minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
          maxDate={new Date()}
          className="rmdp-mobile customized-datepicker-reports calender-popup-style"
          value={filterDate}
          onChange={setFilterDate}
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
          onClose={handleCloseCalendar}
          render={({ openCalendar }) => (
            <>
              {true && 
              <div className="custom-header">
                <div className="custom-header-item">
                  Select Date Range
                </div>
                <div className="custom-header-cross">
                  <CrossIcon onClick={handleCloseCalendar} />
                </div>
              </div>}
              <CustomRangePicker openCalendar={openCalendar} value={filterDate} />
            </>
          )}
        />
      </div> */}
       <SiteModal
        modalTitle="Select Date Range"
        showModal={showDateTimeModal}
        hideModal={() => {
          setShowDateTimeModal(false);
        }}
        classes="date-time-picker-modal-range"
        size="sm"
      >
        <DateTimePicker
          rangeShow={true}
          date={filterDate} 
          onSubmit={handleDateChange}
        />
      </SiteModal>
    </Section>
  );
};

export default CameraAvailability;
