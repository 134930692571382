import { useState } from 'react';
import { SiteModal } from '../../../components/common/';
import NotificationSteps from './NotificationSteps';

const CreateNotification = (props) => {
  const [showModal, setShowModal] = useState(props.createNotification);

  const handleCloseModal = () => {
    setShowModal(false);
    props.setHideModal(false);
  };

  return (
    <SiteModal
      modalHeader={true}
      showModal={showModal}
      hideModal={() => handleCloseModal()}
    >
      <NotificationSteps
        hideModal={() => handleCloseModal()}
        step={props.stepShow}
        notificationData={props?.notificationData}
        setNotificationData={props?.setNotificationData}
      />
    </SiteModal>
  );
};

export default CreateNotification;
