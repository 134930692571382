export const indexClosest = (data, index) => {
  let before = [];
  let after = [];

  data?.forEach((current, i) => {
    let diff = current - index;
    if (diff > 0) {
      before.push({
        diff: diff,
        index: i,
        date: current,
      });
    } else if (diff < 0) {
      after.push({
        diff: diff,
        index: i,
        date: current,
      });
    }
  });

  before.sort(function (a, b) {
    if (a.diff < b.diff) {
      return -1;
    }
    if (a.diff > b.diff) {
      return 1;
    }
    return 0;
  });

  after.sort(function (a, b) {
    if (a.diff > b.diff) {
      return -1;
    }
    if (a.diff < b.diff) {
      return 1;
    }
    return 0;
  });
  return { dataBefore: before, dataAfter: after };
};
