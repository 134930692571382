import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown, HiOutlineLocationMarker } from 'react-icons/hi';
import { isObject } from 'lodash';

import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';

import { AppDefaults, Utils, constants } from '../../helpers';

import './LocationSelector.scss';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { setSelectedDeviceFromFillter } from '../../store/CustomerOrgDevicesStoreIDB';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';

const LocationSelector = ({
  variant = 'default',
  height,
  minWidth,
  persist = true,
  callback,
}) => {
  const [isLocationDataLoaded, setIsLocationDataLoaded] = useState(false);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const [selectedLocation, setSelectedLocation] = useState(
    constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL
  );
  const [locations, setLocations] = useState(null);
  const [bearerToken, setBearerToken] = useState(null);

  const { keycloak } = useKeycloak();
  // const { getOrganizations, getCustomerOrgData } = useOrganizations();
  const {
    getCustomerOrgLocationsData,
    setLocationSelection,
    getSelectedLocation,
    getCustomerOrgLocations,
  } = useCustomerOrgLocations();
  // const { setSelectedDeviceFromFillter } = useCustomerOrgDevices();

  const accountId = loggedInUserData?.accountId;
  let orgDetails;

  useEffect(() => {
    const bearerToken = keycloak?.token;
    if (bearerToken) {
      setBearerToken(bearerToken);
      localStorage.setItem('vmsAuthToken', bearerToken);
    }
  }, [keycloak]);

  useEffect(() => {
    if (bearerToken) {
      init(bearerToken);
    }
  }, [JSON.stringify(bearerToken)]);

  useEffect(() => {
    const selectedLocation = getSelectedLocation(accountId);
    if (isObject(selectedLocation) && selectedLocation?.locationName) {
      setSelectedLocation(selectedLocation?.locationName);
    }
    // Get all locations data for user
    // fetchLocations();
    let orgLocations = getCustomerOrgLocationsData();
    setLocations(orgLocations.length > 0 ? [...orgLocations] : []);
    setIsLocationDataLoaded(true);
  }, [getCustomerOrgLocationsData()]);

  const init = async (token) => {
    if (token) {
      if (!orgDetails) {
        // Get all org data for user
        await fetchOrgDetails();
        const orgs = await getCustomerOrgData();
        orgDetails = orgs[0];
      }

      if (orgDetails && (!Array.isArray(locations) || locations.length < 1)) {
        // Get all locations data for user
        await fetchLocations();
      }
    }
  };

  const fetchOrgDetails = async () => {
    try {
      await getOrganizations('/partner/orgs', {
        headers: {
          Authorization: 'Bearer ' + bearerToken,
        },
        credentials: 'include',
        withCredentials: true,
      });
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const fetchLocations = async () => {
    const selectedLocation = getSelectedLocation(accountId);
    try {
      if (orgDetails) {
        // If the user organization's list of locations hasn't
        // been saved to the local cache yet, let's retrieve
        // them again and save to local storage.
        let fetchResult = await getCustomerOrgLocations(
          `partner/orgs/${orgDetails?.orgId}/locations`
        );
        if (fetchResult.status === 'success') {
          setIsLocationDataLoaded(true);
          let orgLocations = getCustomerOrgLocationsData();
          setLocations(orgLocations.length > 0 ? [...orgLocations] : []);
        }

        if (isObject(selectedLocation) && selectedLocation?.locationName) {
          setSelectedLocation(selectedLocation?.locationName);
        }
      } else {
        throw new Error(
          'ERROR: No locations retrieved because organization ID was not specified'
        );
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const onSelectHandler = async (evtKey) => {
    // evtKey should be a valid location ID
    if (!evtKey) return;
    await setSelectedDeviceFromFillter([]);
    setSelectedLocation(getLocationName(evtKey));

    if (persist) {
      setLocationSelection(accountId, evtKey);
    }

    callback && callback(evtKey);
  };

  const getLocationName = (locationId) => {
    if (!locationId) return;

    if (locationId === AppDefaults.ALL_LOCATIONS_ID) {
      return constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL;
    }
    return locations.find((location) => location.locationId === locationId)
      .locationName;
  };

  const dropdownContent = isLocationDataLoaded
    ? locations?.length > 0 &&
      locations.map((location) => (
        <Dropdown.Item
          key={`location-${location.locationId}`}
          className={
            `location-selector-menu-item` +
            (selectedLocation === location.locationName ? ' active' : '')
          }
          eventKey={location.locationId}
        >
          {location.locationName}
        </Dropdown.Item>
      ))
    : constants.LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT;

  return (
    <Dropdown
      className={`location-selector ${
        loggedInUserData?.accountStatus === 'INACTIVE' && 'disabled'
      } ${variant}`}
      onSelect={onSelectHandler}
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        id="dropdown-basic"
        className={`location-selector-toggle ${variant}`}
        style={{ height: height || '44px', minWidth: minWidth || '200px' }}
      >
        <div className="location-selected-wrapper">
          <HiOutlineLocationMarker size={16} />{' '}
          <span className={`location-selected-name ${variant}`}>
            {selectedLocation}
          </span>
        </div>
        <HiOutlineChevronDown size={16} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`location-selector-menu ${variant}`}>
        <Dropdown.Item
          className={
            `location-selector-menu-item` +
            (!selectedLocation ||
            selectedLocation ===
              constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL ||
            selectedLocation === AppDefaults.ALL_LOCATIONS_ID
              ? ' active'
              : '') +
            ` ${variant}`
          }
          eventKey={AppDefaults.ALL_LOCATIONS_ID}
        >
          {constants.LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL}
        </Dropdown.Item>
        {dropdownContent}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LocationSelector;
