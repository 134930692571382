import React from 'react';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import { constants } from '../../helpers';
import useCustomRoles from '../../store/CustomRolesStore';

const RoleListTable = ({ columns, data, defaultSortByData = [] }) => {
  const navigate = useNavigate();
  const { setSelectedCustomRole } = useCustomRoles();
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy
    );

  const showRoleDetails = (data) => {
    // data?.original?.roleId &&
    setSelectedCustomRole(data?.original);
    navigate(`/roles/dashboard.html?roleId=${data?.original?.roleId}`);
  };

  /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
  return (
    <div className="table-responsive manage-techs-table">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={columnIndex}
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                      ? 'columnheader-desc'
                        : 'columnheader-asc'
                      : 'columnheader-asc'
                  }`}
                    onClick={() => {
                      column.toggleSortBy(!column.isSortedDesc, false);
                    }}
                    {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                    <span>
                      {column.isSortedDesc ? (
                        <VscChevronDown size={16} strokeWidth="1px" />
                      ) : (
                        <VscChevronUp size={16} strokeWidth="1px" />
                      )}
                    </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                style={{ cursor: 'pointer' }}
                key={rowIndex}
                {...row.getRowProps()}
                onClick={() => showRoleDetails(row)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_RECORDS_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RoleListTable;
