import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const ProgressBar = (props) => {
  return (
    <div style={{ width: props.width, height: props.height }}>
      <CircularProgressbarWithChildren
        value={props.percentage}
        strokeWidth={props.strokeWidth}
        maxValue ={props?.maxValue}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: props.background,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Customize transition animation
            transition: "stroke-dashoffset 0.5s ease 0s",
            // Rotate the path
            transform: "rotate(0)",
            transformOrigin: "center center"
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: props.trailStroke,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Rotate the trail
            transform: "rotate(0.25turn)",
            transformOrigin: "center center"
          },
          // Customize the text
          text: {
            // Text color
            fill: props.textFill,
            // Text size
            fontSize: props.fontSize,
          },
          // Customize background - only used when the `background` prop is true
          background: {
            fill: props.background,
          },
        }}
      >
        {(props.icon)
        &&
          <>{props.icon}</>
        }
      </CircularProgressbarWithChildren>
    </div>
  );
};

ProgressBar.defaultProps = {
  // percentage: 10,
  background: "#3e98c7",
  textFill: "#f88",
  fontSize: "16px",
  trailStroke: "#d6d6d6",
  width: "60px", 
  height: "60px",
  strokeWidth: 14,
  maxValue:100
};

export default ProgressBar;
