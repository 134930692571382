import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import { getSelectedOrganization } from '../../store/reducers/AccountReducer';
import { HiOutlineCreditCard, HiOutlineDocumentText } from 'react-icons/hi';
import axios from 'axios';

import { useCart } from '../../store/CartStore';
import {
  Header,
  BreadcrumbList,
  QuantityModifier,
} from '../../components/common';
import constants from '../../helpers/en';
import './PurchaseSummary.scss';
import { Utils, roles } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../store/reducers/OrganizationsReducer';

const PurchaseSummary = () => {
  const orgInfo = useSelector(getOrgInfo)
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();

  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgInfo.orgId}`,
      title: `${orgInfo.orgName}`,
    },
    {
      url: `/licenses/listing.html?orgId=${orgInfo.orgId}&orgName=${orgInfo.orgName}`,
      title: constants.LICENSES_PAGE_TITLE,
    },
    {
      url: `/products/listing.html?orgId=${orgInfo.orgId}&orgName=${orgInfo.orgName}`,
      title: constants.PURCHASE_BREADCRUMB_TITLE,
    },
    {
      url: '',
      title: constants.PURCHASE_SUMMARY_BREADCRUMB_TITLE,
    },
  ];

  const cart = useCart((state) => state.cart);
  const order = useCart((state) => state.order);
  const getCartItemQuantity = useCart((state) => state.getCartItemQuantity);
  const getCartTotalQuantity = useCart((state) => state.getCartTotalQuantity);
  const getCartItemCost = useCart((state) => state.getCartItemCost);
  const getCartTotalCost = useCart((state) => state.getCartTotalCost);
  const deleteFromCart = useCart((state) => state.deleteFromCart);
  const [cartContent, setCartContent] = useState([]);

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    setCartContent([...cart]);
    setTotalQuantity(parseInt(getCartTotalQuantity()));
  }, [cart]);

  const createCheckoutSession = async () => {
    try {
      const orderList = [];
      const paymentId = order?.paymentId;
      const orderId = order?.orderId;
      let checkoutSessionUrl;

      cart.forEach((license) => {
        orderList.push({
          productSku: license.sku,
          price: license.discountedPrice,
          quantity: license.quantity,
        });
      });

      const reqBody = {
        paymentId,
        currency: 'usd',
        orderDetails: [...orderList],
      };
      // create purchase order
      const resp = await axios.post(
        `/user/orgs/${orgDetails?.orgId}/orders/${orderId}/checkout`,
        reqBody,
        Utils.requestHeader()
      );

      const response = resp?.data;

      if (parseInt(response?.meta?.code) === 200) {
        checkoutSessionUrl = response?.data?.checkoutSessionUrl;
        if (checkoutSessionUrl) {
          window.location.replace(checkoutSessionUrl);
        }
      } else {
        // TODO: show toaster with an error message
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShow(true);
    }
  };

  return (
    <div className="App purchase-summary">
      <Header numOfICartItems={totalQuantity} />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              <div className="page-header mt-4 mb-5">
                <Row className="g-4">
                  <Col>
                    <BreadcrumbList list={breadList} />
                  </Col>
                </Row>
                <Row className="purchase-summary-block">
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <div className="purchase-summary-section">
                      <span className="purchase-summary-title">
                        {constants.PURCHASE_SUMMARY_PAGE_TITLE}
                      </span>
                      <p className="purchase-summary-description">
                        {t('PURCHASE_SUMMARY_PAGE_OVERVIEW')}
                      </p>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                    <Row className="purchase-summary-payments">
                      <Col>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className={
                            cart.length > 0
                              ? 'invoice-btn'
                              : 'invoice-btn disabled'
                          }
                          onClick={() => {
                            // TODO: to be implemented later
                            Utils.vmsLogger().log('pay with invoice');
                          }}
                        >
                          <HiOutlineDocumentText className="invoice-icon" />
                          <span
                            className={
                              cart.length > 0
                                ? 'purchase-button'
                                : 'purchase-button disabled'
                            }
                          >
                            {constants.SEND_ME_AN_INVOICE_BUTTON_LABEL}
                          </span>
                        </Button>
                        <Button
                          variant="primary"
                          size="sm"
                          className={
                            cart.length > 0
                              ? 'credit-card-btn'
                              : 'credit-card-btn disabled'
                          }
                          onClick={() => {
                            if (cart.length > 0) {
                              createCheckoutSession();
                            }
                          }}
                        >
                          <HiOutlineCreditCard className="credit-card-icon" />
                          <span className="purchase-button">
                            {constants.PAY_WITH_CREDIT_CARD_BUTTON_LABEL}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="purchase-summary-item">
                  <Col md={7} lg={7} xl={7} xs={12}>
                    <Row>
                      <Col className="purchase-summary-item-product-name purchase-summary-item-label">
                        {constants.PURCHASE_SUMMARY_PRODUCT_NAME_LABEL}
                      </Col>
                      <Col className="purchase-summary-item-quantity-price">
                        <Row>
                          <Col className="purchase-summary-item-quantity purchase-summary-item-label">
                            {constants.PURCHASE_SUMMARY_QUANTITY_LABEL}
                          </Col>
                          <Col className="purchase-summary-item-amount purchase-summary-item-label">
                            {constants.PURCHASE_SUMMARY_AMOUNT_LABEL}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {Array.isArray(cartContent)
                      ? cartContent.map((license) => {
                          const features =
                            license?.productDescription.split(' - ') || [];

                          return (
                            <Row key={`row-${license.sku}`}>
                              <Col
                                className="purchase-summary-card-wrapper"
                                key={`col-${license.sku}`}
                              >
                                <Card className="purchase-summary-card">
                                  <Card.Body className="purchase-summary-card-body">
                                    <div className="purchase-summary-card-body-wrapper">
                                      <div className="purchase-summary-card-body-product">
                                        <Card.Title className="purchase-summary-card-body-product-title">
                                          {license.displayName}
                                        </Card.Title>
                                        <div className="purchase-summary-card-body-product-description-wrapper">
                                          <div className="purchase-summary-card-body-product-description-features">
                                            <ul className="purchase-summary-card-body-product-description-features-list">
                                              <li
                                                key={`product-sku-${license.sku}`}
                                              >
                                                <span className="purchase-summary-card-body-sku">
                                                  {
                                                    constants.PURCHASE_SUMMARY_SKU_LABEL
                                                  }
                                                  : {license.sku}
                                                </span>
                                              </li>
                                              {Array.isArray(features)
                                                ? features.map(
                                                    (feature, featureIndex) => (
                                                      <li
                                                        key={`feature-${featureIndex}`}
                                                      >
                                                        <span className="purchase-summary-card-body-feature">
                                                          {feature}
                                                        </span>
                                                      </li>
                                                    )
                                                  )
                                                : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="purchase-summary-card-body-quantity-price">
                                        <div className="purchase-summary-card-body-quantity-modifier">
                                          <QuantityModifier
                                            sku={license.sku}
                                            quantity={
                                              getCartItemQuantity(license.sku)
                                                .quantity
                                            }
                                            quantityClassName="purchase-summary"
                                          />
                                        </div>
                                        <div className="purchase-summary-card-body-quantity-price-wrapper">
                                          <div className="purchase-summary-card-body-quantity-price">
                                            ${getCartItemCost(license.sku)}
                                          </div>
                                          <div
                                            className="link"
                                            onClick={(e) => {
                                              deleteFromCart(license.sku);
                                            }}
                                          >
                                            {
                                              constants.PURCHASE_REMOVE_LINK_TITLE
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          );
                        })
                      : null}
                  </Col>
                  <Col md={5} lg={5} xl={5} xs={12}>
                    <Row>
                      <Col>
                        {/* Intentionally left blank to align the card below */}
                        &nbsp;
                      </Col>
                    </Row>
                    <Row>
                      <Col className="purchase-summary-subtotal-total-wrapper">
                        <Card className="purchase-summary-subtotal-total-card">
                          <Card.Body className="purchase-summary-subtotal-total-card-body">
                            <div className="purchase-summary-subtotal-total-card-body-section">
                              <div className="purchase-summary-subtotal-total-card-body-subtotal">
                                {constants.PURCHASE_SUBTOTAL_TITLE}
                              </div>
                              <div className="purchase-summary-subtotal-total-card-body-subtotal-amount">
                                ${getCartTotalCost()}
                              </div>
                            </div>
                            <div className="purchase-summary-subtotal-total-card-body-tax-section">
                              <div className="purchase-summary-subtotal-total-card-body-tax">
                                {constants.PURCHASE_TAX_AND_FEES_TITLE}
                              </div>
                              <div className="purchase-summary-subtotal-total-card-body-invoice">
                                {constants.PURCHASE_TAX_AND_FEES_INVOICE_TITLE}
                              </div>
                            </div>
                            <hr />
                            <div className="purchase-summary-subtotal-total-card-body-total-section">
                              <div className="purchase-summary-subtotal-total-card-body-total">
                                {constants.PURCHASE_TOTAL_DUE_TITLE}
                              </div>
                              <div className="purchase-summary-subtotal-total-card-body-total-amount">
                                ${getCartTotalCost()}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PurchaseSummary;
