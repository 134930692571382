import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import RemoteVideoView from '../common/RemoteVideoView';
import '../../assets/css/timeline.scss';
import videoProcessing from '../../assets/images/cameras/videoProcessing.svg';
import { pause } from '../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeWithMQTT,
  publishWithMQTT,
  disconnectWithMQTT,
  getCurrentEventResource,
} from '../../utils/connection/mqttConnection';
import {
  getCDNInfo,
  getIsLiveViewEntitlementExist,
  getIsLiveViewEntitlementExpired,
  getLoadingStream,
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStream,
  getStreamBitmapData,
  setLoadingStream,
  setRemoteStream,
  setOfferrecived,
  getOfferRecivedStatus,
} from '../../store/reducers/StreamingReducer';
import constants from '../../helpers/en';
import {
  getAccountId,
  getDeviceInformation,
  getAllMqttDataFromResponse,
} from '../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../store/policiesStore';
import { useIdleTimer } from 'react-idle-timer';
import { TbCertificate2Off } from 'react-icons/tb';
import { CiStreamOn } from 'react-icons/ci';
import { TbLoader } from 'react-icons/tb';
import { FiCameraOff } from 'react-icons/fi';
import useLicensesStore from '../../store/LicensesStore';
import { AppDefaults, LicenseStatuses, Utils } from '../../helpers';

const Live = ({ handleClick, customer, page, isStopStreamClick = false, deviceId }) => {
  const intervalRef = useRef();
  const platformDetails = useSelector(getPlatformInfo);
  const deviceData = useSelector(getDeviceInformation);
  const remoteStream = useSelector(getRemoteStream);
  const bitmapObject = useSelector(getStreamBitmapData);
  const offerStatus = useSelector(getOfferRecivedStatus);
  const isLiveViewEntitlementExist = useSelector(getIsLiveViewEntitlementExist);
  const isLiveViewEntitlementExpired = useSelector(
    getIsLiveViewEntitlementExpired
  );
  const loadingStream = useSelector(getLoadingStream);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const dispatch = useDispatch();
  const { licenses, getLicenses } = useLicensesStore();
  const getIslicensesAttachStatus = useLicensesStore((state) => state.getIslicensesAttachStatus);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [streamStatus, setStreamStatus] = useState(false);
  const [isDeviceRestart, setIsDeviceRestart] = useState(false);
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(0);
  const [deviceDetails, setDeviceDetails] = useState(deviceData);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const onIdle = () => setState('Idle');
  const onActive = () => setState('Active');
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 3000,
    throttle: 500,
  });

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
      dispatch(setOfferrecived(false));
    };
  }, []);

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (
      getCurrentEventResource(resource) &&
      deviceId === responseDeviceId &&
      allMqttData?.msg?.properties
    ) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error'
      );
    } else if (
      getCurrentEventResource(allMqttData?.msg?.resource) &&
      allMqttData?.msg?.properties
    ) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error'
      );
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    if (deviceDetails) {
      let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
      let deficeInfoProperty = openDeviceDetails?.properties;
      mqttStatus &&
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify({ ...deficeInfoProperty })
        );
      let localStoreProperty = JSON.parse(
        localStorage.getItem('localStoreProperty')
      );
      deficeInfoProperty = mqttStatus
        ? { ...deficeInfoProperty, ...properties }
        : localStoreProperty && {
            ...deficeInfoProperty,
            ...localStoreProperty,
          };
      openDeviceDetails = {
        ...openDeviceDetails,
        properties: deficeInfoProperty,
      };
      mqttStatus &&
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify({ ...deficeInfoProperty })
        );
      setDeviceDetails(openDeviceDetails)
    }
  };

  useEffect(() => {
    if (!streamStatus) {
      if (
        deviceDetails?.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        isLiveViewEntitlementExist &&
        !isLiveViewEntitlementExpired
      ) {
        sendOffer()
      }
    }
  }, [streamStatus, isLiveViewEntitlementExist, isLiveViewEntitlementExpired]);

  useEffect(()=>{
    const currentActiveLicenses = getLicenses();
    const activeLicensesData = [...currentActiveLicenses];
    const currentTime = new Date().getTime();
    const newTimestamp = currentTime - 2000; // Subtract 2000 milliseconds (2 seconds)
    const filterList =  activeLicensesData.filter(
      (license) =>
        license?.deviceId === deviceDetails?.deviceId &&
       (license?.expiryDate > currentTime) && (license?.activeDate <= newTimestamp)
    )
    const licenses = filterList.filter(
      (license) =>
        license?.offeringType !== AppDefaults.ONBOARDING ||
      license?.licenseStatus !== LicenseStatuses.EXPIRED
    )
    if(getIslicensesAttachStatus() == 1 && licenses?.length > 0){
      if (!offerStatus) {
        const id = setInterval(() => {
          sendOffer()
        }, 10000);
        intervalRef.current = id;
      }
    }
    else{
      clearInterval(intervalRef?.current);
    }

    return () => {
      clearInterval(intervalRef?.current);
    };
  },[getIslicensesAttachStatus()])

  // useEffect(() => {
  //   if (remoteStream?.active) {
  //     clearInterval(intervalRef?.current);
  //   }
  // }, [remoteStream?.active]);

  const sendOffer = ()=>{
    let deviceStatus = {};
    setStreamStatus(true);
    publishWithMQTT(
      platformDetails?.mqtt,
      platformDetails?.p2p_server,
      deviceDetails,
      accountId
    );
    deviceStatus = {
      isStream: true,
    };
    setDeviceStatus(deviceStatus);
  }
 
  // Commented because double WebRTCConnections are creating. When redirecting or reloading Camera settings page. 
  // useEffect(() => {
  //   dispatch(setRemoteStream(null));
  //   if (mqttConnection) {
  //     publishWithMQTT(
  //       platformDetails?.mqtt,
  //       platformDetails?.p2p_server,
  //       deviceDetails,
  //       accountId
  //     );
  //     setDeviceStatus({
  //       isStream: true,
  //     });
  //     streamState();
  //   }
  // }, [mqttConnection]);

  useEffect(() => {
    //TODO DELETE LATER
    Utils.vmsLogger().log(
      'restart>>>>>>>>>>>>>>>>>>>>>>>>>',
      deviceDetails?.properties?.restart
    );
    Utils.vmsLogger().log(
      'live-stream>>>>>>>>>>>>>>>>>>>>>>>>>',
      deviceDetails?.properties?.['live-stream']
    );

    if (deviceDetails?.properties?.restart === 'in-progress') {
      setIsDeviceRestart(true);
      dispatch(setOfferrecived(false));
    } else {
      setIsDeviceRestart(false);
    }
    streamState();
  }, [deviceDetails]);

  useEffect(()=>{
    streamState()
  },[licenses])

  const streamState = () => {
    let deviceStatus = {};
    const currentActiveLicenses = getLicenses();
    const activeLicensesData = [...currentActiveLicenses];
    const currentTime = new Date().getTime();
    const newTimestamp = currentTime - 2000; // Subtract 2000 milliseconds (2 seconds)
    const filterList =  activeLicensesData.filter(
      (license) =>
        license?.deviceId === deviceDetails?.deviceId &&
       (license?.expiryDate > currentTime) && (license?.activeDate <= newTimestamp)
    )
    const licenses = filterList.filter(
      (license) =>
        license?.offeringType !== AppDefaults.ONBOARDING ||
      license?.licenseStatus !== LicenseStatuses.EXPIRED
    )
    
    if((licenses == undefined || licenses?.length <= 0)){
      deviceStatus = {
        isStream: false,
        icon: (
          <TbCertificate2Off
            color={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_56')}
            size={60}
          />
        ),
        label: constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED,
      };
      setDeviceStatus(deviceStatus);
    }
    else if (deviceDetails?.properties?.restart === 'in-progress') {
      deviceStatus = {
        isStream: false,
        icon: (
          <CiStreamOn
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_RESTARTING_MESSAGE,
      };
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.properties?.['fw-update'] ===
      ('in-progress' || 'completed')
    ) {
      deviceStatus = {
        isStream: false,
        icon: (
          <TbLoader
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_UPDATE_FIRMWARE,
      };
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.connectionStatus ===
      constants.DEVICES_OFFLINE_CONNECTION_STATUS
    ) {
      if (
        deviceDetails?.properties?.restart !== 'in-progress' &&
        deviceDetails?.properties?.['fw-update'] !==
          ('in-progress' || 'completed')
      ) {
        deviceStatus = {
          isStream: false,
          icon: (
            <FiCameraOff
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
              size={60}
            />
          ),
          label: constants.DEVICE_STATE_CAMERA_OFFLINE,
        };
        setDeviceStatus(deviceStatus);
      }
    } else if (deviceDetails?.properties?.['live-stream'] === 'stop') {
      deviceStatus = {
        isStream: false,
      };
      setDeviceStatus(deviceStatus);
    } else if (getStreamStatus(deviceDetails)) {
      setIsDeviceRestart(false);
      if (!offerStatus) {
        const id = setInterval(() => {
         sendOffer()
        }, 10000);
        intervalRef.current = id;
      }
    } else {
      deviceStatus = {
        isStream: true,
      };
      setDeviceStatus(deviceStatus);
    }
  };

  const getStreamStatus = (deviceDetails) => {
    if (isDeviceRestart) {
      if (
        deviceDetails?.connectionStatus ===
        constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        if (
          deviceDetails?.properties?.['live-stream'] === 'idle' ||
          deviceDetails?.properties?.['live-stream'] === 'in-progress'
        ) {
          return true;
        }
      }
    } else {
      if (offerStatus) {
        clearInterval(intervalRef?.current);
      }
      return false;
    }
  };

  const pauseClick = () => {
    if (isStopStreamClick) return;
    disconnectWithMQTT();
    handleClick(constants.DEVICES_SWITCH_TIMELINE_TITLE);
  };

  return (
    <div
      className="wrapper-app"
      onClick={() => {
        getCustomerOrgPolicies()?.view_camera_history && pauseClick();
      }}
    >
      {deviceStatus.isStream ? (
        <div className="stream-timeline-wrapper device-setting">
          {page !== 'settings' && (
            <div
              className={`timeline-icons live-stream ${
                remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
              }`}
            >
              <button className="live-button" onClick={() => pauseClick()}>
                <img src={pause} alt="pause icon" />
              </button>
            </div>
          )}
          <div
            className={`timeline-icons live ${
              remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
            }`}
          >
            <button className="live-button">
              {constants.LIVE_STREAM_OVERLAY_LABEL}
            </button>
          </div>
          {/* {remoteStream && ( */}
            <RemoteVideoView
              deviceDetails={deviceDetails}
              remoteStream={remoteStream}
              bitmapObject={bitmapObject}
            />
          {/* )} */}
          {loadingStream && (
            <div className="video-processing">
              <img src={videoProcessing} alt="video" />
            </div>
          )}
        </div>
      ) : (
        <div className="device-status-container">
          {deviceStatus.icon}
          <div className="title-status">{deviceStatus.label}</div>
        </div>
      )}
    </div>
  );
};

export default Live;
