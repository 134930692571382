const roles = {
  ROLE0VMS: 'role0#vms',
  ROLE1VMS: 'role1#vms',
  ROLE2VMS: 'role2#vms',
  ROLE3VMS: 'role3#vms',
  ROLE4VMS: 'role4#vms',
  ROLE5VMS: 'role5#vms'
}

export default roles;
