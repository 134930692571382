import { Outlet } from 'react-router-dom';
import EditUser from './EditUser';
import ManageUsers from './ManageUsers';
import NewUser from './NewUser';
import UsersListTable from './UsersListTable';

const Users = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { EditUser, ManageUsers, NewUser, Users, UsersListTable };
