import { useEffect, useLayoutEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';

const localConstants = {
  ORDER_STATUS: 'ORDER_STATUS',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  FULFILLMENT_STATUS: 'FULFILLMENT_STATUS',
  PURCHASE_DATE: 'PURCHASE_DATE',
};

const ListTable = ({
  colsDef = [],
  colSorting,
  rowData = [],
  orderStatusMap,
  cssClasses,
}) => {
  const [columns, setColumns] = useState([...colsDef]);
  const [rows, setRows] = useState([...rowData]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState(null);

  const handleSort = (field, columnType) => {
    if (!field) return;

    setSortField(field);

    const compare = (a, b) => {
      if (sortOrder === 'asc') {
        if (
          columnType === localConstants.ORDER_STATUS ||
          columnType === localConstants.PAYMENT_STATUS ||
          columnType === localConstants.FULFILLMENT_STATUS ||
          columnType === localConstants.PURCHASE_DATE
        ) {
          if (a[field].props.children[1] < b[field].props.children[1]) {
            return -1;
          }
          if (a[field].props.children[1] > b[field].props.children[1]) {
            return 1;
          }
        } else {
          if (a[field] < b[field]) {
            return -1;
          }
          if (a[field] > b[field]) {
            return 1;
          }
        }
      } else {
        if (
          columnType === localConstants.ORDER_STATUS ||
          columnType === localConstants.PAYMENT_STATUS ||
          columnType === localConstants.FULFILLMENT_STATUS ||
          columnType === localConstants.PURCHASE_DATE
        ) {
          if (a[field].props.children[1] > b[field].props.children[1]) {
            return -1;
          }
          if (a[field].props.children[1] < b[field].props.children[1]) {
            return 1;
          }
        } else {
          if (a[field] > b[field]) {
            return -1;
          }
          if (a[field] < b[field]) {
            return 1;
          }
        }
      }

      return 0;
    };

    if (Array.isArray(rows)) {
      rows.sort(compare);
    }

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const isGrandchildOverflown = (id, section) => {
    const grandChildEl =
      section === 'headerCell'
        ? document.querySelector(`#${id}`)?.lastChild?.firstChild
        : document.querySelector(`#${id}`);

    return (
      grandChildEl?.scrollHeight !== grandChildEl?.clientHeight ||
      grandChildEl?.scrollWidth !== grandChildEl?.clientWidth
    );
  };

  const renderHeader = (columns) => {
    const headerContent = (columns) => {
      if (!Array.isArray(columns)) {
        return <th></th>;
      }

      return columns.map((col) => {
        let thContent =
          colSorting === true ? (
            <th id={`header${col?.field}`} key={`header${col?.field}`}>
              <div
                key={`headerDiv${col?.field}`}
                className="header-sort"
                onClick={() => {
                  switch (col.field.toUpperCase()) {
                    case 'ORDERSTATUS':
                      handleSort(col.field, localConstants.ORDER_STATUS);
                      break;

                    case 'PAYMENTSTATUS':
                      handleSort(col.field, localConstants.PAYMENT_STATUS);
                      break;

                    case 'FULFILLMENTSTATUS':
                      handleSort(col.field, localConstants.FULFILLMENT_STATUS);
                      break;

                    case 'PAYMENTUPDATEDATE':
                      handleSort(col.field, localConstants.PURCHASE_DATE);
                      break;

                    default:
                      handleSort(col.field);
                  }
                }}
              >
                {isGrandchildOverflown(`header${col?.field}`, 'headerCell') ? (
                  <OverlayTrigger
                    key={`triggerHeader${col.field}`}
                    placement="top"
                    overlay={
                      <Tooltip key={`tooltipHeader${col.field}`}>
                        {col.displayName}
                      </Tooltip>
                    }
                  >
                    <div key={`columnDiv${col?.field}`} className="column-name">
                      {col?.displayName}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div key={`columnDiv${col?.field}`} className="column-name">
                    {col?.displayName}
                  </div>
                )}
                {sortField === col?.field && sortOrder === 'desc' ? (
                  <VscChevronUp
                    key={`sortIcon${col?.field}`}
                    className="sort-icon"
                  />
                ) : (
                  <VscChevronDown
                    key={`sortIcon${col?.field}`}
                    className="sort-icon"
                  />
                )}
              </div>
            </th>
          ) : (
            <th key={`header${col?.field}`}>
              <div key={`columnDiv${col?.field}`} className="column-name">
                {col?.displayName}
              </div>
            </th>
          );

        return thContent;
      });
    };

    return <tr className="table-columns">{headerContent(columns)}</tr>;
  };

  const rowContent = (row, rowIdx) => {
    let content = [];

    if (!Array.isArray(columns)) return <></>;

    columns.forEach((col, colIdx) => {
      let cellId = 'cell' + rowIdx + colIdx;
      let colContent;

      if (isGrandchildOverflown(cellId, 'rowCell')) {
        colContent = (
          <td id={cellId} key={cellId}>
            <OverlayTrigger
              key={`triggerCell${rowIdx}${col.field}`}
              placement="top"
              overlay={
                <Tooltip key={`tooltipCell${rowIdx}${col.field}`}>
                  {row[col.field]}
                </Tooltip>
              }
            >
              <div key={`divCell${rowIdx}${col.field}`}>{row[col.field]}</div>
            </OverlayTrigger>
          </td>
        );
      } else {
        colContent = (
          <td id={cellId} key={cellId}>
            {row[col.field]}
          </td>
        );
      }

      content.push(colContent);
    });

    return content;
  };

  useEffect(() => {
    setColumns([...colsDef]);
    setRows([...rowData]);
  }, [rowData, colsDef]);

  // When the browser window is resized, we rehydrate the columns and rows states
  // so that the table is re-rendered to determine the list of popovers to enable
  // dynamically.
  useLayoutEffect(() => {
    const rehydrate = () => {
      setColumns([...colsDef]);
      setRows([...rowData]);
    };
    window.addEventListener('resize', rehydrate);
    return () => window.removeEventListener('resize', rehydrate);
  }, [colsDef, rowData]);

  return (
    <Table className={cssClasses}>
      <thead>{renderHeader(columns)}</thead>
      <tbody>
        {Array.isArray(rows) && rows.length > 0 ? (
          rows.map((row, rowIndex) => (
            <tr key={`license${rowIndex}`}>{rowContent(row, rowIndex)}</tr>
          ))
        ) : (
          <tr key={`license$-no-data`}>
            <td colSpan={columns?.length || '1'}>
              <div>Nothing to display</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ListTable;
