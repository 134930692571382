import { useState, useEffect } from 'react';
import { constants, Utils } from '../../../../helpers';
import { RxMinus, RxPlus } from 'react-icons/rx';
import ReactSlider from 'react-slider';
import React from 'react';
import { PrimaryButton } from '../../../../components/common';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './FocusSettingBlock.scss';

const FocusSettingBlock = ({
  deviceInfos,
  devicePublishId,
  accountId,
  uuid,
  deviceId,
  hasManagePermission,
}) => {
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const resourceList = Utils.getDeviceSettingResource(2);
  const capFocus = Utils.getCurrentTabProperty(capabilityList, resourceList[0]);

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  
  const focusHold = (status) => {
    if (status) {
      publishDeviceSetting(
        { 'focus-control': '+1' },
        resourceList[0],
        publishPayload
      );
    } else {
      publishDeviceSetting(
        { 'focus-control': '-1' },
        resourceList[0],
        publishPayload
      );
    }
  };
  const focusStop = (status) => {
    publishDeviceSetting(
      { 'focus-control': '0' },
      resourceList[0],
      publishPayload
    );
  };

  const zoomHold = (status) => {
    if (status) {
      publishDeviceSetting(
        { 'zoom-control': '+1' },
        resourceList[0],
        publishPayload
      );
    } else {
      publishDeviceSetting(
        { 'zoom-control': '-1' },
        resourceList[0],
        publishPayload
      );
    }
  };
  const zoomStop = (status) => {
    publishDeviceSetting(
      { 'zoom-control': '0' },
      resourceList[0],
      publishPayload
    );
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title focus-tab">
        <label>{constants.DEVICES_TAB_FOCUS_SETTINGS}</label>
      </div>

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'focus-control',
        capFocus
      ) ||
      Utils.getPropertyShowStatus(curDeviceStatus, 'zoom-control', capFocus) ? (
        <div className="tab-sub-title">
          {constants.DEVICES_TAB_FOCUS_SETTINGS_SUB_TITLE}
        </div>
      ) : null}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'focus-control',
        capFocus
      ) && (
        <div className="focus-activity-zone">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_FOCUS}
          </div>
          <div className="focus-container">
            <div
              onMouseDown={() => {
                if (!canChangeSettings) {
                  return;
                }
                focusHold(false);
              }}
              onMouseUp={() => {
                if (!canChangeSettings) {
                  return;
                }
                focusStop(false);
              }}
              className="left-container"
              aria-disabled={!canChangeSettings}
            >
              <div className="label-left">
                {constants.DEVICES_TAB_FOCUS_NEAR}
              </div>
              <div className="btn-container">
                <RxMinus
                  strokeWidth={'0.1px'}
                  size={13.33}
                  color={'#fff'}
                  aria-disabled={!canChangeSettings}
                />
              </div>
            </div>
            <div className="middle-container">
              <div className={`bar-container`}></div>
            </div>
            <div
              onMouseDown={() => {
                if (!canChangeSettings) {
                  return;
                }
                focusHold(true);
              }}
              onMouseUp={() => {
                if (!canChangeSettings) {
                  return;
                }
                focusStop(true);
              }}
              className="right-container-focus"
              aria-disabled={!canChangeSettings}
            >
              <div className="label-right">
                {constants.DEVICES_TAB_FOCUS_FAR}
              </div>
              <div className="btn-container">
                <RxPlus
                  strokeWidth={'0.1px'}
                  size={13.33}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  aria-disabled={!canChangeSettings}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'zoom-control',
        capFocus
      ) && (
        <div className="focus-activity-zone">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_FOCUS_ZOOM_TITLE}
          </div>
          <div className="focus-container">
            <div
              onMouseDown={() => {
                if (!canChangeSettings) {
                  return;
                }
                zoomHold(false);
              }}
              onMouseUp={() => {
                if (!canChangeSettings) {
                  return;
                }
                zoomStop(false);
              }}
              className="left-container"
              aria-disabled={!canChangeSettings}
            >
              <div className="label-left">{constants.DEVICES_TAB_ZOOM_OUT}</div>
              <div className="btn-container">
                <RxMinus
                  strokeWidth={'0.1px'}
                  size={13.33}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  aria-disabled={!canChangeSettings}
                />
              </div>
            </div>
            <div className="middle-container">
              <div className={`bar-container`}></div>
            </div>
            <div
              onMouseDown={() => {
                if (!canChangeSettings) {
                  return;
                }
                zoomHold(true);
              }}
              onMouseUp={() => {
                if (!canChangeSettings) {
                  return;
                }
                zoomStop(true);
              }}
              className="right-container-focus"
              aria-disabled={!canChangeSettings}
            >
              <div className="label-right">{constants.DEVICES_TAB_ZOOM_IN}</div>
              <div className="btn-container">
                <RxPlus
                  strokeWidth={'0.1px'}
                  size={13.33}
                  color={'#fff'}
                  aria-disabled={!canChangeSettings}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'simple-focus-enable',
        capFocus
      ) && (
        <PrimaryButton
          className="btn btn-primary mt-3"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            const settingFocus = {
              'simple-focus-enable': constants.PROPERTY_STATUS_TRUE,
            };
            publishDeviceSetting(settingFocus, resourceList[0], publishPayload);
          }}
          disabled={!canChangeSettings}
        >
          {constants.DEVICES_SIMPLE_FOCUS}
        </PrimaryButton>
      )}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'focus-enable',
        capFocus
      ) && (
        <PrimaryButton
          className="btn btn-primary-outline mt-3"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            const settingFocus = {
              'focus-enable': constants.PROPERTY_STATUS_TRUE,
            };
            publishDeviceSetting(settingFocus, resourceList[0], publishPayload);
          }}
          disabled={!canChangeSettings}
        >
          {constants.DEVICES_SIMPLE_INITIALIZE}
        </PrimaryButton>
      )}
    </div>
  );
};

export default FocusSettingBlock;
