import { Container } from 'react-bootstrap';
import { Header, TextBlock } from '../components/common';
import PageWrapper from '../pages/PageWrapper';
import { constants } from '../helpers';

function ComingSoon() {
  return (
    <div className="App">
      <Header />
      <PageWrapper>
        {/* Main content start*/}
        <section className="main-content">
          <Container className="h-100">
            <div className="text-center mt-5 mb-5">
              <TextBlock fontWeight="500" fontSize="50px" lineHeight="60px">
                {constants.PAGE_COMING_SOON_TITLE}
              </TextBlock>
            </div>
          </Container>
        </section>
        {/* Main content end*/}
      </PageWrapper>
    </div>
  );
}

export default ComingSoon;
