import playStore from '../assets/images/playstore.svg';
import appleStore from '../assets/images/applestore.svg';
import { AppDefaults, constants } from '../helpers';
import BrandLogoSvg from '../assets/images/BrandLogo.svg';
import MegatronLogoSvg from '../assets/images/MegatronLogo.svg';
import { useTranslation } from 'react-i18next';

const MobileBrowser = () => {
  const { t } = useTranslation();
  return (
    <div className="mobile-browser">
      {process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? (
        <img className="img-logo" src={MegatronLogoSvg} alt="" />
      ) : (
        <img className="img-logo" src={BrandLogoSvg} alt="" />
      )}
      <div className="bottom-container">
        <div className="duclo-connect">
          {t('MOBILE_BROWSER_PAGE_TITLE')}

        </div>
        <div className="download-message">
          {constants.MOBILE_BROWSER_PAGE_SUB_TITLE}
        </div>
        <div className="browser-app-message">
          {constants.MOBILE_BROWSER_PAGE_MESSAGE}
        </div>
        <img className="store-btn" src={appleStore} alt=""></img>
        <img className="store-btn plastore-btn" src={playStore} alt=""></img>
      </div>
      <div className="need-help">
        {constants.MOBILE_BROWSER_PAGE_NEED_HELP_LABEL}
      </div>
    </div>
  );
};
export default MobileBrowser;
