import { getItem, setItem } from './indexDB/db';

export const getSnapshotImages = async () => {
  const result = await getItem('snapshotImages');
  return result || {};
};

export const setSnapshotImages = async (data) => {
  return await setItem('snapshotImages', data);
};

export const getSnapshotImage = async () => {
  const result = await getItem('snapshotImage');
  return result || {};
};

export const setSnapshotImage = async (data) => {
  return await setItem('snapshotImage', data);
};
