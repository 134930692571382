import { Outlet } from 'react-router-dom';
import ManageRoles from './ManageRoles';
import NewRole from './NewRole';
import RoleListTable from './RoleListTable'
import CustomRoleDetails from './RoleListTable'

const Roles = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { ManageRoles, Roles, NewRole, RoleListTable };