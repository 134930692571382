import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';


export const useDeviceSnapshots = create(
  persist(
    (set, get) => ({
      deviceSnapshots: {},
      
      getDeviceSnapshots: (deviceId) => {
        if (!deviceId) return null;

        let snapData = get().deviceSnapshots;
        if (snapData?.[deviceId]) {
          // const splited = snapData?.[deviceId].split('/');
          // const imageName = splited?.[splited?.length - 1];
          // const timestampText = imageName.split('.jpg')[0];
          // const snapDate = new Date(Number(timestampText) * 1000);
          // return new Date().getTime() - snapDate.getTime() > 60 * 60 * 24 * 1000
          //   ? null
          //   : snapData?.[deviceId];
          return snapData?.[deviceId];
        }
        return null;
      },

      setDeviceSnapshots: (deviceId, snapshot) => {
        if (!deviceId || !snapshot) return;

        let snapData = get().deviceSnapshots;
        snapData[deviceId] = snapshot;
        set({ 
          deviceSnapshots: snapData,
        });
      },

      resetDeviceSnapshots: () => {        
        set({
          deviceSnapshots: {},
        });
      },
     
    }),
    {
      name: 'net.duclo.vms.useDeviceSnapshots',
      version: '1.9',
    }
  )
);
