import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import { MuuriComponent as Cameras } from 'muuri-react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Header,
  SiteToast,
  Spinner,
  SiteSpinner,
} from '../../components/common/';
import PageWrapper from '../PageWrapper';
import DeviceItem from './DeviceItem';

import { AppDefaults, constants, Utils } from '../../helpers';

import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { getCDNInfo } from '../../store/reducers/StreamingReducer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   getAllDevicesData,
//   setAllDevicesData,
// } from '../../store/reducers/AccountReducer';

import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';

import './MultiViewSelector.scss';
import { setLiveCameraIds } from '../../store/reducers/StreamingReducer';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const MAX_NUM_DEVICES = 4;

const MultiViewSelector = () => {
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const {
    getCustomerOrgLocations,
    getCustomerOrgLocationsData,
    getSelectedLocation,
  } = useCustomerOrgLocations();

  const {
    fetchCustomerOrgDevices,
    getCustomerOrgDevices,
    getLiveViewDevices,
    setLiveViewDevices,
  } = useCustomerOrgDevices();

  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const { getState } = devicesMQTTStore;

  const navigate = useNavigate();
  const videoWallRef = useRef(null);
  const dispatch = useDispatch();

  const accountId = loggedInUserData?.accountId;
  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();

  const userPolicies = getLoggedInUserPolicies();
  const state = getState();
  let currentSelectedLocation = getSelectedLocation(accountId);

  const [isContentReady, setIsContentReady] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [showLocation, setShowLocation] = useState(false);
  const [currentSession, setCurrentSession] = useState(uuidv4());
  const cdnInfo = useSelector(getCDNInfo);
  const [hasDevices, setHasDevices] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([
    ...getLiveViewDevices(),
  ]);
  const [numOfSelectedDevices, setNumOfSelectedDevices] = useState(0);

  const [devices, setDevices] = useState([]);
  const [deviceLocationAreas, setDeviceLocationAreas] = useState([]);
  const [initialDevices, setInitialDevices] = useState([]);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  const selectDevice = (deviceId, isChecked) => {
    let currentDevices = [...selectedDevices],
      deviceIndex;

    if (!deviceId) return;

    if (numOfSelectedDevices < MAX_NUM_DEVICES) {
      if (!isChecked) {
        setNumOfSelectedDevices(parseInt(numOfSelectedDevices) - 1);
        deviceIndex = currentDevices.indexOf(deviceId);
        currentDevices.splice(deviceIndex, 1);
        setSelectedDevices([...currentDevices]);
      } else {
        setNumOfSelectedDevices(parseInt(numOfSelectedDevices) + 1);
        setSelectedDevices([...currentDevices, deviceId]);
      }
    } else {
      if (!isChecked) {
        setNumOfSelectedDevices(parseInt(numOfSelectedDevices) - 1);
        deviceIndex = currentDevices.indexOf(deviceId);
        currentDevices.splice(deviceIndex, 1);
        setSelectedDevices([...currentDevices]);
      }
    }
  };

  const confirmHandler = () => {
    // Save list of device IDs to store
    Utils.vmsLogger().log('set cameras from here');
    setLiveViewDevices(selectedDevices);
    dispatch(setLiveCameraIds(selectedDevices));

    // Navigate back to the /live route
    navigate('/cameras/live.html');
  };

  const actionButtonGroup = (
    <>
      <Button variant="primary" className="action-button" onClick={() => {}}>
        {`${numOfSelectedDevices} / ${
          devices?.length > MAX_NUM_DEVICES ? MAX_NUM_DEVICES : devices?.length
        }`}
      </Button>
      <Button
        variant="primary"
        className="action-button"
        onClick={() => {
          navigate('/cameras/live.html');
        }}
      >
        {constants.CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL}
      </Button>
      <Button
        variant="primary"
        className={`action-button${
          numOfSelectedDevices < 1 ? ' disabled' : ''
        }`}
        onClick={confirmHandler}
      >
        {constants.CAMERAS_LIVE_ACTION_BUTTONS_CONFIRM_LABEL}
      </Button>
    </>
  );

  const fetchSelectedDevices = async (ld) => {
    if (ld?.length > 0) {
      await new Promise((resolve) => {
        setSelectedDevices([...ld]);
        setNumOfSelectedDevices(ld.length);
        resolve();
      });
    }
  };

  const fetchDevices = useCallback(async () => {
    try {
      // Get all locations for customer's organization
      await getCustomerOrgLocations(
        `partner/orgs/${orgDetails?.orgId}/locations`
      );
      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${orgDetails?.orgId}/devices`
      );

      const userOrgLocations = getCustomerOrgLocationsData();
      const locationIds = new Set();
      let deviceArea,
        currentArea,
        cameraDevice,
        filteredLocationArea,
        filteredDevices;

      if (fetchResult.status === 'success') {
        setShowLocation(true);
        const cameraDevices = Utils.getTotalChildDevices(
          getCustomerOrgDevices()
        );
        const locationAreas = [];
        let deviceAreaName;

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);
          deviceAreaName = '';

          currentArea = userOrgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas:
                currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [...deviceArea.areas],
            });
          }

          cameraDevices[i].locationName = currentArea?.locationName;
          deviceAreaName = currentArea?.areas.find(
            (area) => area.areaId === cameraDevices[i].areaId
          )?.areaName;

          cameraDevices[i].areaName = deviceAreaName;
          cameraDevices[i].locationAreaNames =
            currentArea?.locationName + ' - ' + deviceAreaName;
          cameraDevices[i].checkboxEnabled = true;

          filteredLocationArea = locationAreas.find(
            (locationArea) =>
              locationArea?.locationId === cameraDevices[i]?.locationId
          ).areas;

          cameraDevices[i].areas = filteredLocationArea
            ? [...filteredLocationArea]
            : [];

          cameraDevices[i].style = null;
        }

        await new Promise((resolve) => {
          setInitialDevices(
            cameraDevices?.length > 0 ? [...cameraDevices] : []
          );
          // dispatch(
          //   setAllDevicesData(
          //     cameraDevices?.length > 0 ? [...cameraDevices] : []
          //   )
          // );
          resolve();
        });

        if (_.isObject(currentSelectedLocation)) {
          if (
            currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
          ) {
            filteredDevices = cameraDevices.filter(
              (device) =>
                device?.locationId === currentSelectedLocation?.locationId
            );
          } else {
            filteredDevices =
              cameraDevices?.length > 0 ? [...cameraDevices] : [];
          }
        } else {
          filteredDevices = cameraDevices?.length > 0 ? [...cameraDevices] : [];
        }

        // If there is a specific location that was
        // selected previously, we filter the list of
        // devices to only those belonging to that location.
        await new Promise((resolve) => {
          setDevices(filteredDevices?.length > 0 ? [...filteredDevices] : []);
          resolve();
        });

        setDeviceLocationAreas(
          locationAreas?.length > 0 ? [...locationAreas] : []
        );
        setHasDevices(filteredDevices?.length > 0);
        setIsContentReady(true);
      } else {
        if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
          setShowToast(true);
          setUserMsg(fetchResult?.msg);
        }
      }
    } catch (err) {
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
      setIsContentReady(false);
    }
  }, []);

  const fetchCameraWallData = useCallback(async () => {
    // Retrieve all devices
    fetchDevices();
    disconnectWithMQTT();
  }, []);

  const init = async (liveDevices) => {
    await fetchSelectedDevices(liveDevices);

    setCurrentSession(uuidv4());
    fetchCameraWallData();
  };

  useEffect(() => {
    let liveDevices = getLiveViewDevices();
    setNumOfSelectedDevices(liveDevices?.length || 0);

    init(liveDevices);
  }, [orgDetails?.orgId]);

  const cameraWallContent = hasDevices ? (
    <Cameras
      id="videoWall"
      ref={videoWallRef}
      instantLayout
      dragEnabled={false}
      dragContainer={document.body}
      // The dragStartPredicate allows us to suppress the drag start event
      // temporarily so that the click event can be triggered immediately.
      // DO NOT remove this prop or set the 'distance' property to a number
      // less than 1.
      dragStartPredicate={{ distance: 1, delay: 0 }}
      layout={{
        fillGaps: false,
        horizontal: false,
        alignRight: false,
        alignBottom: false,
        rounding: true,
      }}
      containerClass={'cameras'}
      itemClass={'cameras-item'}
      itemVisibleClass={'cameras-item-shown'}
      itemHiddenClass={'cameras-item-hidden'}
      itemPositioningClass={'cameras-item-positioning'}
      itemDraggingClass={'cameras-item-dragging'}
      itemReleasingClass={'cameras-item-releasing'}
      itemPlaceholderClass={'cameras-item-placeholder'}
    >
      {Array.isArray(devices) &&
        devices.map((device, deviceIndex) => (
          <DeviceItem
            key={device.deviceId}
            deviceElemId={`camera${deviceIndex}`}
            inlineStyle={device?.style}
            initialChecked={selectedDevices.includes(device.deviceId)}
            orgId={orgDetails?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={device.locationAreaNames}
            numOfSelectedDevices={selectedDevices.length}
            disabled={
              !(parseInt(selectedDevices.length) < MAX_NUM_DEVICES) &&
              !selectedDevices.includes(device.deviceId)
            }
            showZoomOptions={false}
            checkboxCallback={selectDevice}
            selectedDevices={selectedDevices}
            conStatus={device.connectionStatus || 'offline'}
            hubId={device.deviceId}
            {...device}
          />
        ))}
    </Cameras>
  ) : (
    <div className="no-cameras-container">
      <div className="no-cameras-notice">
        {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
      </div>
      {userPolicies.install_device && userPolicies.claim_device && (
        <div className="no-cameras-cta">
          <Button
            className="no-cameras-add-device-btn"
            variant="outline-secondary"
          >
            {constants.CAMERAS_VIDEO_WALL_ADD_DEVICE_BUTTON_LABEL}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className="App live-selection-view">
      <Header showLocation={showLocation} showCart={false} />
      <div className="main-wrapper">
        <PageWrapper className="video-wall-background" fluid>
          <Row className="mb-5 video-wall-wrapper">
            <Col>
              <Container className="video-wall-container">
                <div className="page-header mt-4 mb-5">
                  <Row>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col className="cameras-container">
                      {!isContentReady ? (
                        <div
                          className="position-absolute top-50"
                          style={{ left: '47%' }}
                        >
                          <SiteSpinner
                            height="50px"
                            width="50px"
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                          />
                          <div className="mt-2 text-white">
                            {constants.LOADING}
                          </div>
                        </div>
                      ) : (
                        cameraWallContent
                      )}
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </PageWrapper>
        <div className={`action-buttons`}>
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="me-2" aria-label="Button group">
              {actionButtonGroup}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        {/* Modals */}
      </div>
    </div>
  );
};

export default MultiViewSelector;
