import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

const ListBlock = (props) => {
  const navigate = useNavigate();
  const {
    backgroundColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--list_block_background_color'),
  } = props;
  return (
    <div className={props.className ? props.className : 'list-block'}>
      <div className="card-header">
        <Row>
          <Col className="text-start" lg="7" xs="8">
            <h5 className="card-title">{props.label}</h5>
          </Col>
          <Col
            className={`text-end ${
              props.navigateText ? 'list-block-nav' : 'list-block-nav-icon'
            }`}
          >
            {props.navigateUrl && (
              <>
                {props.navigateText && props?.navigateType === 'click' ? (
                  <div className="no-underline" onClick={props.navigateUrl}>
                    {props.navigateText}
                  </div>
                ) : props.navigateText ? (
                  <Link className="no-underline" to={props.navigateUrl}>
                    {props.navigateText}
                  </Link>
                ) : (
                  <MdOutlineArrowForwardIos
                    onClick={() => {
                      navigate(props.navigateUrl);
                    }}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="card list-block-item"
        style={{
          width: props.width,
          minHeight: props.height,
          backgroundColor: backgroundColor,
        }}
      >
        {props.children && <div className="card-body">{props.children}</div>}
      </div>
    </div>
  );
};

ListBlock.defaultProps = {
  width: '100%',
  height: '76px',
};

export default ListBlock;
