import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { HiOutlineCalendar } from 'react-icons/hi';
import moment from 'moment';

import {
  ListBlock,
  PrimaryButton,
  SiteModal,
} from '../../../../components/common';
import { constants, LicenseStatuses, Utils, AppDefaults } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';
import AddLicenseForm from './AddLicenseForm';
import DetachLicenseForm from './DetachLicenseForm';

import './LicensesBlock.scss';
import { setOfferrecived } from '../../../../store/reducers/StreamingReducer';
import { useDispatch } from 'react-redux';

const DATE_FORMAT = 'DD MMM YYYY';

const LicensesBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  deviceTimeZone,
  showAddModal,
  hideAddModalCallback,
  showRemoveModal,
  hideRemoveModalCallback,
  deviceInfos,
  hasManagePermission,
}) => {
  const { licenses, getLicenses, setLicenses, setIslicensesAttachStatus } = useLicensesStore();
  const navigate = useNavigate();
  const [deviceLicenses, setDeviceLicenses] = useState([]);
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [licenseName, setLicenseName] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(showAddModal);
  const [showDetachLicenseModal, setShowDetachLicenseModal] =
    useState(showRemoveModal);
  const dispatch = useDispatch();

  let lastExpirationDate;
  useEffect(() => {
    try {
      if (!Array.isArray(licenses) || licenses.length < 1) {
        fetchLicenses();
      } else {
        if (licenses.length > 0) {
          const updatedLicenses = getLicenses();
          const currentTime = new Date().getTime();
          const filterList =  updatedLicenses.filter(
            (license) =>
              license?.deviceId === deviceId &&
             (license?.expiryDate > currentTime)
          )
          const finalList = filterList.filter(
            (license) =>
              license?.offeringType !== AppDefaults.ONBOARDING ||
            license?.licenseStatus !== LicenseStatuses.EXPIRED
          )
          const deviceLicenses = [
            ...finalList,
          ];
          if (deviceLicenses?.length > 0) {
            setIslicensesAttachStatus(1);
          } else {
            dispatch(setOfferrecived(false));
            setIslicensesAttachStatus(2);  
          }

          if (deviceLicenses.length > 1) {
            setDeviceLicenses([
              ...deviceLicenses.filter(
                (deviceLicense) => deviceLicense?.offeringType !== AppDefaults.ONBOARDING
              ),
            ]);
          } else {
            setDeviceLicenses([...deviceLicenses]);
          }
        }
      }
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  }, [JSON.stringify(licenses)]);

  useEffect(() => {
    setShowAddLicenseModal(showAddModal);
  }, [showAddModal]);

  const fetchLicenses = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      await new Promise((resolve, reject) => {
        const custOrgLicenses = getLicenses();
        const currentTime = new Date().getTime();

        const filterList =  custOrgLicenses.filter(
          (license) =>
            license?.deviceId === deviceId &&
           (license?.expiryDate > currentTime)
        )
        const finalList = filterList.filter(
          (license) =>
            license?.offeringType !== AppDefaults.ONBOARDING || license?.licenseStatus !== LicenseStatuses.EXPIRED
        )
        const deviceLicenses = [
          ...finalList,
        ];
        setAvailableLicenses([...custOrgLicenses]);

        if (deviceLicenses.length > 1) {
          setDeviceLicenses([
            ...deviceLicenses.filter(
              (deviceLicense) => deviceLicense?.offeringType !== AppDefaults.ONBOARDING
            ),
          ]);
        } else {
          setDeviceLicenses([...deviceLicenses]);
        }

        resolve();
      });
    } else {
      throw new Error(
        'ERROR: there was problem retrieving the list of licenses for the organization'
      );
    }
  };

  const detachLicenseHandler = (detachLicenseName, detachLicenseKey) => {
    if (!detachLicenseName || !detachLicenseKey) return;

    setLicenseName(detachLicenseName);
    setLicenseKey(detachLicenseKey);
    setShowDetachLicenseModal(true);
  };

  const calculateAdjustedExpiryDate = (deviceLicense) => {
    const adjustedDate = moment(deviceLicense.expiryDate - (deviceLicense.gracePeriod * 24 * 60 * 60 * 1000));    
    return adjustedDate.format(DATE_FORMAT);
  };

  return (
    <>
      <ListBlock
        className="list-block license-block"
        contentWrapperClass="text-start"
        label={constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
      >
        <Row>
          <Col>
            {/* to do : change later when work on locations part <Mapimage /> */}
            <ul className="license-features">
              {Array.isArray(deviceLicenses) && deviceLicenses.length > 0 ? (
                deviceLicenses.map((deviceLicense, dlIndex) => {
                  const today = moment().unix() * 1000;

                  if (!lastExpirationDate) {
                    if (deviceLicense?.offeringType === AppDefaults.ONBOARDING) {
                      lastExpirationDate = today;
                    } else {
                      lastExpirationDate = deviceLicense.expiryDate;
                    }
                  } else if (lastExpirationDate < deviceLicense.expiryDate) {
                    lastExpirationDate = deviceLicense.expiryDate;
                  }

                  return (
                    <li key={`devLicense${dlIndex}`}>
                      <Container>
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <div className="license-prop">
                                  {constants.DEVICES_TAB_LICENSES_LICENSE_TYPE}
                                </div>
                                <div className="license-prop-value">
                                  {deviceLicense.productDisplayName}
                                </div>
                              </Col>
                              <Col>
                                <div
                                  className={`license-action${
                                    deviceLicense?.offeringType === AppDefaults.ONBOARDING || !hasManagePermission
                                      ? ' hidden'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    detachLicenseHandler(
                                      deviceLicense.productDisplayName,
                                      deviceLicense.licenseKey
                                    )
                                  }
                                >
                                  {
                                    constants.DEVICES_TAB_LICENSES_DETACH_LICENSE
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="license-prop">
                                  {
                                    constants.DEVICES_TAB_LICENSES_LICENSE_START_DATE
                                  }
                                </div>
                                <div className="license-prop-value">
                                  <HiOutlineCalendar size={20} />{' '}
                                  <span className="value-wrapper">
                                    {moment(deviceLicense.activeDate).format(
                                      DATE_FORMAT
                                    )}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <div className="spacer"></div>
                            </Row>
                            <Row>
                              <Col>
                                <div className="license-prop">
                                  {
                                    constants.DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE
                                  }
                                </div>
                                <div className="license-prop-value">
                                  <HiOutlineCalendar size={20} />{' '}
                                  <span className="value-wrapper">
                                    {calculateAdjustedExpiryDate(deviceLicense)}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </li>
                  );
                })
              ) : Array.isArray(availableLicenses) &&
                availableLicenses.length < 1 ? (
                <li>
                  <Container>
                    <Row>
                      <Col>
                        <Row>
                          <div className="no-licenses-attached">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                          </div>
                        </Row>
                        <Row>
                          <div className="no-licenses-available">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE}
                          </div>
                        </Row>
                        {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                          <Row>
                            <div className="purchase-button-container">
                              <PrimaryButton
                                width="200px"
                                onClick={() => {
                                  navigate(
                                    `/products/listing.html?orgId=${orgId}&orgName=${orgName}`
                                  );
                                }}
                              >
                                {
                                  constants.DEVICES_TAB_LICENSES_PURCHASE_LICENSE
                                }
                              </PrimaryButton>
                            </div>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </li>
              ) : (
                    <li>
                      <Row>
                        <Col>
                        <Row>
                          <div className="no-licenses-attached">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                          </div>
                        </Row>
                        <Row>
                          <div className="no-licenses-available-sub">
                            {constants.DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE}
                          </div>
                        </Row>
                        </Col>
                      </Row>
                    </li>
              )}
            </ul>
          </Col>
        </Row>
      </ListBlock>
      {/* MODALS */}
      {/* Attach License Modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_MODAL_TITLE,
          '$deviceName',
          deviceName
        )}
        showModal={showAddLicenseModal}
        hideModal={() => {
          setShowAddLicenseModal(false);
          if (
            hideAddModalCallback &&
            hideAddModalCallback.constructor !== String &&
            hideAddModalCallback.constructor !== Number &&
            hideAddModalCallback.constructor !== Object &&
            hideAddModalCallback.constructor !== Array
          ) {
            hideAddModalCallback && hideAddModalCallback(false);
          }
        }}
        classes="add-license-modal"
      >
        <AddLicenseForm
          deviceId={deviceId}
          deviceTimeZone={deviceTimeZone}
          orgId={orgId}
          expiryDate={lastExpirationDate}
          deviceLicenses={deviceLicenses}
          callBack={() => {
            fetchLicenses();
            setShowAddLicenseModal(false);
            hideAddModalCallback && hideAddModalCallback(false);
          }}
        />
      </SiteModal>
      {/* Detach License */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.DEVICES_TAB_LICENSES_DETACH_LICENSE_MODAL_TITLE,
          '$licenseName',
          licenseName
        )}
        showModal={showDetachLicenseModal}
        hideModal={() => {
          setShowDetachLicenseModal(false);
          if (
            hideRemoveModalCallback &&
            hideRemoveModalCallback.constructor !== String &&
            hideRemoveModalCallback.constructor !== Number &&
            hideRemoveModalCallback.constructor !== Object &&
            hideRemoveModalCallback.constructor !== Array
          ) {
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }
        }}
        classes="detach-license-modal"
      >
        <DetachLicenseForm
          deviceId={deviceId}
          licenseKey={licenseKey}
          orgId={orgId}
          callBack={() => {
            fetchLicenses();
            setShowDetachLicenseModal(false);
            hideRemoveModalCallback && hideRemoveModalCallback(false);
          }}
        />
      </SiteModal>
    </>
  );
};

export default LicensesBlock;
