import { Row, Col } from 'react-bootstrap';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { constants } from '../../helpers';

const checkAvailableLicenses = (callback) => {
  const handleClick = () => {
    callback && callback(true);
  };

  return (
    <Row className="m-t-16">
      <Col>
        <div className="active-license-holder">
          <div>
            <AiOutlineExclamationCircle height={'20px'} />
          </div>
          <div className="text-holder">
            <span className="info-text">
              {constants.ACTIVE_LICENSE_POPUP_TITLE}
            </span>
            <span className="active-lic-text" onClick={handleClick}>
              {constants.ACTIVATE_LICENSES}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default checkAvailableLicenses;
