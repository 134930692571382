import React, { useCallback, useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { MuuriComponent } from 'muuri-react';
import './LiveGridStructure.scss';
import {
  getIsLeftMenuOpen,
  getSelectedView,
  getViewList,
  setSelectedView,
  setViewList,
} from '../../../store/reducers/ViewsReducer';
import { Utils, constants } from '../../../helpers';
import ImageGridItem from './ImageGridItem';
import EditCamera from './EditCamera';
import { SiteSpinner } from '../../../components/common';
import { getAllDevicesData } from '../../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getSnapshotImages } from '../../../store/StreamingStoreIDB';

const ImageGridStructure = ({
  moveTimeline,
  liveSnapshot,
  time,
  cdnValue,
  timeZone,
  orgDetails,
  devicesData,
  currentViewDeviceList,
  setCurrentViewDeviceList,
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  // const [orgDetails, setOrgDetails] = useState();
  const viewList = useSelector(getViewList);
  const selectedView = useSelector(getSelectedView);
  // const currentViewDeviceList = useSelector(getCurrentViewDeviceList);
  // const [devicesData, setDevicesData] = useState([]);
  const isLeftMenuOpen = useSelector(getIsLeftMenuOpen);
  const itemCount = currentViewDeviceList?.length || 0;
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [isChangedSize, setIsChangedSize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snapshotImages, setSnapshotImages] = useState({});

  const loadAllSnapshots = useCallback(async () => {
    const allSnapshots = await getSnapshotImages();
    setSnapshotImages(allSnapshots);
  }, []);

  // const loadCustomerOrgData = useCallback(async () => {
  //   const orgs = await getCustomerOrgData();
  //   setOrgDetails(orgs?.[0] || {});
  // }, []);

  // const debouncedLoadCustomerOrgData = useDebouncedCallback(
  //   loadCustomerOrgData,
  //   1000
  // );

  const debouncedLoadAllSnapshots = useDebouncedCallback(
    loadAllSnapshots,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      // if (data.key === 'customerOrgData') {
      //   await debouncedLoadCustomerOrgData();
      // }
      if (data.key === 'snapshotImages') {
        await debouncedLoadAllSnapshots();
      }
    };
    observerInstance.addObserver(handleUpdate);
    // debouncedLoadCustomerOrgData();
    debouncedLoadAllSnapshots();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [ debouncedLoadAllSnapshots]);

  useEffect(() => {
    // const fetchDevices = async () => {
    //   const allDevices = await getAllDevicesData();
    //   setDevicesData(allDevices);
    //   setLoading(false);
    // };

    // fetchDevices();
    const timeout = setTimeout(() => {
      if (devicesData && devicesData.length) {
        setLoading(false);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const containerElem = document.getElementsByClassName(
      'camera-tile-container'
    )?.[0];
    if (containerElem) {
      const rowCol = Utils.getGridItemRowColumn(itemCount);
      const styles = calculateDimensions(...rowCol);
      containerElem.style.width = styles.rowWidth + 'px';
      containerElem.style.height = styles.columnHeight + 'px';
      const timelineElem =
        document.getElementsByClassName('multiview-timeline')?.[0];
      const mainHeaderElem = document.getElementsByClassName(
        'device-wall-main-container-header'
      )?.[0];
      if (timelineElem && mainHeaderElem) {
        timelineElem.style.width = mainHeaderElem.offsetWidth + 'px';
      }
    }
    setIsChangedSize(!isChangedSize);
    window.dispatchEvent(new Event('resize'));
  }, [
    currentViewDeviceList,
    isLeftMenuOpen,
    itemCount,
    document.getElementsByClassName('main-content-container')?.[0]?.clientWidth,
  ]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setIsChangedSize(!isChangedSize);
    });
  }, []);

  useEffect(() => {
    const mainContainerElem = document.getElementsByClassName(
      'main-content-container'
    )?.[0];
    const containerElem = document.getElementsByClassName(
      'camera-tile-container'
    )?.[0];
    if (mainContainerElem && containerElem) {
      const marginTop =
        mainContainerElem.clientHeight - containerElem.clientHeight - 20;
      containerElem.style.marginTop = marginTop / 2 + 'px';
    }
  }, [isChangedSize]);

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.find(
        (device) => device.deviceId === deviceId
      );
      return deviceData;
    }
    return null;
  };

  const handleDragStart = (item) => {
    setDraggingItemId(item.getKey());
  };

  const handleDragEnd = async () => {
    setDraggingItemId(null);
    if (gridRef.current) {
      const reorderedDeviceIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      setCurrentViewDeviceList(reorderedDeviceIds);
      const updatedView = Utils.getUpdatedViewPayload(
        selectedView,
        reorderedDeviceIds.filter((a) => !a.includes('edit'))
      );
      const response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        updatedView,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        }
      );
      const resData = response?.data;

      if (resData?.meta.code === 200) {
        dispatch(setSelectedView(updatedView));
        const updatedViewList = viewList.map((view) => {
          if (view.viewId === selectedView.viewId) {
            return updatedView;
          } else {
            return view;
          }
        });
        dispatch(setViewList(updatedViewList));
      }
    }
  };

  const calculateDimensions = (rows, columns) => {
    const container = document.getElementsByClassName(
      'main-content-container'
    )?.[0];
    const containerWidth = container?.clientWidth;
    const containerHeight = container?.clientHeight;
    if (!containerWidth || !containerHeight) return;

    const aspectRatio = 16 / 9;
    const rowWidth = isLeftMenuOpen
      ? (containerWidth - 5) / columns
      : containerWidth / columns;
    const columnHeight = containerHeight / rows;

    let childWidth = rowWidth;
    let childHeight = childWidth / aspectRatio;

    if (childHeight > columnHeight) {
      childHeight = columnHeight;
      childWidth = childHeight * aspectRatio;
    }

    return { rowWidth: childWidth * columns, columnHeight: childHeight * rows };
  };

  return (
    <div className={`camera-tile-container`}>
      {loading ? (
        <div className="loading-screen" style={{ position: 'absolute' }}>
          <SiteSpinner />
          <div className="simple-label">
            {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
          </div>
        </div>
      ) : (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={currentViewDeviceList.length > 1}
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          layoutOnInit={false}
          layoutDuration={0}
          layoutEasing={'linear(0, 0, 0)'}
          hideDuration={600}
          hideEasing={'linear(0, 0, 0)'}
          showDuration={0}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          visibleStyles={{
            opacity: 0,
          }}
          hiddenStyles={{
            opacity: 0,
          }}
        >
          {currentViewDeviceList.map((deviceId, index) => (
            <div
              key={deviceId}
              onMouseEnter={() => setHoveredDeviceId(deviceId)}
              onMouseLeave={() => setHoveredDeviceId(null)}
              className={`item-grid ${
                draggingItemId !== deviceId ? '' : 'dragged-item'
              }
              ${hoveredDeviceId === deviceId ? 'add-hover' : ''}
            `}
              style={Utils.getResponsiveStyleProps(itemCount)}
            >
              {getDeviceInfo(deviceId) ? (
                <ImageGridItem
                  key={deviceId}
                  layout={deviceId}
                  device={getDeviceInfo(deviceId)}
                  deviceId={deviceId}
                  moveTimeline={moveTimeline}
                  liveSnapshot={liveSnapshot}
                  time={time}
                  cdnValue={cdnValue}
                  camera={index + 1}
                  timeZone={timeZone}
                  snapshotImages={snapshotImages}
                />
              ) : (
                <EditCamera />
              )}
            </div>
          ))}
        </MuuriComponent>
      )}
    </div>
  );
};

export default ImageGridStructure;
