import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Utils, constants } from '../../../../helpers';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../../store/DevicesMQTTStore';
import { useDeviceSnapshots } from '../../../../store/DeviceSnapshotsStore';
import {
  mqttPublish,
  mqttSubscribe,
} from '../../../../utils/connection/mqttConnection';
import { FiCameraOff } from 'react-icons/fi';
import FakeSnapshot from '../../../../assets/images/cameras/snapshots/fake-snapshot.png';
import './LineCrossingSnapShot.scss';
import { DefaultDevice } from '../../../../assets/images';
import _ from 'lodash';

const LineCrossingSnapShot = ({
  orgId,
  cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  currentLayoutStatus = false,
  clickHandler = null,
  conStatus,
  hubId,
  isScrolled,
  displayDeviceStatus,
  setImageWidth,
  aspectRatio,
  customNotification=false,
  ...props
}) => {
  const currentTime = Math.round(new Date().getTime() / 1000);
  const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
  const [latestSnapshots, setLatestSnapshots] = useState({});

  const { getState, subscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const { setDeviceSnapshots } = useDeviceSnapshots();
  const accountId = loggedInUserData.accountId;
  const [snapshotURL, setSnapshotURL] = useState(
    deviceSnapshots[deviceId] ? deviceSnapshots[deviceId] : imageURL
  );
  const [zoomLevel, setZoomLevel] = useState('1x');
  useEffect(() => {
    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    const deviceSubscription = {
      topic: `d/notify/${accountId}/${state.getSessionId()}`,
      qos: 0,
    };

    // Subscribe to the app topic
    //mqttSubscribe(appSubscription);
    state.setIsPublisherSubscribed(true);

    // Subscribe  to the device topic
    mqttSubscribe(deviceSubscription);
    state.setIsReceiverSubscribed(true);

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));
    if (displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS) {
      publishSnapshotRequest();
    }
    // Retrieve latest snapshot every 10 seconds
    // const interval = setInterval(() => {
    //   publishSnapshotRequest();
    // }, 10000);

    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  useEffect(() => {
    const fetchLatestSnapshot = async (lastSnapshot) => {
      if (!lastSnapshot || !cdnInfo) {
        return;
      }

      let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

      const snapshotImage = new Image();
      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
        setDeviceSnapshots(deviceId, snapshotImage.src);
      };

      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      const date = Utils.fetchDateInUnix(lastSnapshot);
      snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
    };
    const lastSnapshot = state.latestSnapshots[deviceId]
      ? state.latestSnapshots[deviceId]
      : 0;
    const lastEvent = state.latestEvents[deviceId]
      ? state.latestEvents[deviceId]
      : 0;
    let deviceSnapshot;
    if (lastSnapshot && currentTime - lastEvent > 300) {
      deviceSnapshot = lastSnapshot;
    } else if (lastEvent) {
      deviceSnapshot = lastEvent;
    }
    if (deviceSnapshot) {
      fetchLatestSnapshot(deviceSnapshot);
    }
  }, [latestSnapshots]);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };
    if (deviceId) {
      mqttPublish(context);
    }
  }, []);

  return (
    <div
      key={deviceId}
      id={deviceElemId}
      className={`cam-line-cross`}
    >
        {conStatus !== 'offline' ? (
          <div className={customNotification? 'cam-line-cross-device-wall-snapshot-1x-custom':`cam-line-cross-device-wall-snapshot-${zoomLevel}`}>
                <img
                  id="img-snapshot"
                  src={snapshotURL}
                  onError={(e) => e.target.src = `${DefaultDevice}`}
                  className={`live-snapshot-image ${
                    aspectRatio === 1 ? 'image-aspect' : ''
                  } ${customNotification && 'responsive-image'}` }
                  onLoad={() => {
                    setImageWidth(
                      document.getElementById('img-snapshot')?.offsetWidth
                    );
                  }}
                  alt={`Snapshot of ${deviceName}`}
                />
          </div>
        ) : (
          <div className={`cam-line-cross-offline`}>
            <div className={'cam-line-cross-offline-device-wall-content'}>
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                />
              </div>
              <div className='offline-label'>{constants.NOTIFICATION_MESSAGE_SNAPSHOT_NOT_AVAILABLE}</div>
            </div>
          </div>
        )}
    </div>
  );
};

export default LineCrossingSnapShot;