const megaTron = {
  //Header Tabs
  OPERATION_METRICES: true,
  SEARCH_DASHBOARD: true,
  LIVE: true,
  INCIDENT_MANAGEMENT: true,
  CAMERA_VIDEO_WALL: true,
  NOTIFICATION: true,
  EVENTS: true,

  //Other Modules
  ADD_DEVICE_MODULE: true,
  ADD_NEW_LOCATION: true,
  INVITE_PARTNER: true,
  REMOVE_PARTNER: true,
  PRODUCT_LISTING: false,
};

export { megaTron };
