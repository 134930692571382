const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="lds-ring">
        {[...Array(4)].map((_, idx) => (
          <div key={idx}></div>
        ))}
      </div>
    </div>
  );
};

export default Loader;
