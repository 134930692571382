import { GoogleMap, LoadScript, Marker, useJsApiLoader  } from '@react-google-maps/api';
import React from 'react';

const LocationsMap = ({ locationPositions, ...props }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_URL,
    libraries: ["places"]
  })
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const googleMarker =
    () => `<svg width="42" height="54" viewBox="0 0 42 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" filter="url(#filter0_f_4779_119498)">
    <ellipse cx="21" cy="42" rx="12" ry="4" fill="black"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 42C23.626 42 37 29.0471 37 16C37 7.16344 29.8366 0 21 0C12.1634 0 5 7.16344 5 16C5 29.0471 18.374 42 21 42ZM21 22C24.3137 22 27 19.3137 27 16C27 12.6863 24.3137 10 21 10C17.6863 10 15 12.6863 15 16C15 19.3137 17.6863 22 21 22Z" fill='${getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary_40')}'/>
    <path d="M36.5 16C36.5 22.3662 33.229 28.76 29.5855 33.5916C27.7687 36.001 25.8739 38.002 24.2789 39.3957C23.4806 40.0932 22.7655 40.6315 22.1794 40.9921C21.8863 41.1725 21.6335 41.3034 21.4248 41.3879C21.2095 41.4751 21.072 41.5 21 41.5C20.928 41.5 20.7905 41.4751 20.5752 41.3879C20.3665 41.3034 20.1137 41.1725 19.8206 40.9921C19.2345 40.6315 18.5194 40.0932 17.7211 39.3957C16.1261 38.002 14.2313 36.001 12.4145 33.5916C8.77101 28.76 5.5 22.3662 5.5 16C5.5 7.43959 12.4396 0.5 21 0.5C29.5604 0.5 36.5 7.43959 36.5 16Z" stroke="url(#paint0_linear_4779_119498)"/>
    <defs>
    <filter id="filter0_f_4779_119498" x="1" y="30" width="40" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_4779_119498"/>
    </filter>
    <linearGradient id="paint0_linear_4779_119498" x1="5" y1="0" x2="37" y2="42" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EBF3FF"/>
    <stop offset="1" stop-color='${getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary_40')}'/>
    </linearGradient>
    </defs>
    </svg>
    `;
  return (
    isLoaded && 
    <GoogleMap
        mapContainerStyle={{
          height: '168px',
          width: '100%',
        }}
        options={{
          mapTypeControl: false,
          gestureHandling: 'none',
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        zoom={2}
        center={{ lat: locationPositions.lat, lng: locationPositions?.lng }}
        mapContainerClassName="location-map"
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={{
            lat: locationPositions.lat,
            lng: locationPositions?.lng,
          }}
          icon={'data:image/svg+xml;base64,' + window.btoa(googleMarker())}
        ></Marker>
      </GoogleMap>    
  );
};
export default LocationsMap;
