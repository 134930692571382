import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment-timezone';
import { getAccountId } from '../../../../store/reducers/AccountReducer';
import {
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStreamAudio,
  getRemoteStreams,
  setRemoteStreams,
  setRemoteStreamsAudio,
} from '../../../../store/reducers/StreamingReducer';
import { publishWithMQTTs } from '../../../../utils/connection/mqttConnection';
import videoProcessing from '../../../../assets/images/cameras/videoProcessing.svg';
import '../../../../assets/css/timeline.scss';
import { ArrowRight } from 'react-bootstrap-icons';
import { constants } from '../../../../helpers';

const LiveCamera = ({
  layout,
  device,
  deviceName,
  muteAudio,
  onLiveClick,
  hubId,
}) => {
  const remoteStream = useSelector(getRemoteStreams)?.[device];
  const remoteStreamAudio = useSelector(getRemoteStreamAudio)?.[device];
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRemoteStreams({ id: device, stream: null }));
    dispatch(setRemoteStreamsAudio({ id: device, audio: null }));
    const deviceDetails = {
      deviceId: device,
      gatewayId: hubId,
    };
    if (mqttConnection) {
      publishWithMQTTs(
        platformDetails.mqtt,
        platformDetails.p2p_server,
        deviceDetails,
        accountId
      );
    }
  }, [mqttConnection]);

  useEffect(() => {
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      remoteVideo.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    try {
      if (remoteStreamAudio) {
        const remoteAudio = remoteAudioRef.current;
        remoteAudio.srcObject = remoteStreamAudio;
      }
    } catch (error) {}
  }, [remoteStreamAudio]);

  return (
    <>
      {remoteStream?.active ? (
        <span
          className={hovered ? 'hovered' : ''}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <span className={hovered ? 'hovered device-name' : 'device-name'}>
            {deviceName}
          </span>
          <span
            onClick={() => onLiveClick()}
            className={hovered ? 'hovered detail-camera' : 'detail-camera'}
          >
            {constants.CAMERA_DETAIL}
            <ArrowRight width={18} />
          </span>
          <video
            id={`video${layout}${layout}`}
            width="auto"
            height="100%"
            ref={remoteVideoRef}
            autoPlay={true}
            playsInline={true}
          />
          <audio
            id={`audio${layout}${layout}`}
            ref={remoteAudioRef}
            autoPlay={true}
            playsInline={true}
            controls={false}
            muted={hovered && !muteAudio}
          />
          <canvas id={`canvas${layout}${layout}`} width="764" height="450" />
        </span>
      ) : (
        <div className="videoprocesing-image-loader">
          <img
            className="video-processing"
            src={videoProcessing}
            alt="video processing"
          />
        </div>
      )}
    </>
  );
};

export default LiveCamera;
