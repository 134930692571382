import { forwardRef, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { TbDeviceComputerCamera } from 'react-icons/tb';
import './DropdownList.scss';

const DropdownList = ({
  dropdownClassName,
  isFromSearch = false,
  variant = 'outline-primary',
  size = 'sm',
  defaultLabel = 'Select an option',
  items = [],
  hasSecondaryDropdown = false,
  onSelectHandler,
  show = false,
  setShow = false,
  dropdownRightAligned
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultLabel);
  //  const toggleShow = () => setShow((prev) => setShow(!prev));
  const customToggle = forwardRef(({ children, onClick }, ref) => {
    return (
      <div
        className={`${
          !isFromSearch ? 'custom-dropdown-toggle' : 'search-dahboard-camera'
        }`}
        onClick={onClick}
      >
        {isFromSearch && <TbDeviceComputerCamera size={20} />}
        {isFromSearch ? (
          <div className="device-selected">{children}</div>
        ) : (
          children
        )}

        <HiOutlineChevronDown
          color={
            isFromSearch
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  '--greyscale_40'
                )
              : getComputedStyle(document.documentElement).getPropertyValue(
                  '--primary_40'
                )
          }
          size={isFromSearch ? 16 : 20}
        />
      </div>
    );
  });

  const getItemName = (itemVal) => {
    let targetItemName;

    if (!itemVal || !items) return;

    targetItemName = items.find(
      (item) => item.itemValue.toString() === itemVal.toString()
    );

    return targetItemName?.itemName;
  };

  const selectHandler = (evtKey) => {
    if (!evtKey) return;

    setSelectedValue(getItemName(evtKey));
    onSelectHandler && onSelectHandler(evtKey);
  };

  return (
    <Dropdown
      show={show}
      onToggle={(isOpen) => setShow && setShow(isOpen)}
      onSelect={selectHandler}
    >
      <Dropdown.Toggle
        variant={variant}
        size={size}
        id="dropdown-basic"
        as={customToggle}
        className={dropdownClassName}
      >
        {selectedValue}
      </Dropdown.Toggle>
      <Dropdown.Menu className={dropdownRightAligned && 'right-aligned-dropdown-menu'}>
        {hasSecondaryDropdown ? (
          <div className="secondary-dropdown-container device-filter">
            {items}
          </div>
        ) : (
          items.length > 0 &&
          items.map((item, itemIndex) => (
            <Dropdown.Item
              key={`listItem-${itemIndex}`}
              eventKey={item.itemValue}
              onClick={item.itemOnClick}
            >
              {item.itemName}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownList;
