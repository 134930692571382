import { Outlet } from 'react-router-dom';
import Device from './listDevice/Device';
import DeviceDashboard from './listDevice/DeviceDashboard';
import DeviceList from './listDevice/DeviceList';

const Devices = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Devices, Device, DeviceDashboard, DeviceList };