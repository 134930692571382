import {
  People,
  CarFront,
  Sunglasses,
  Dropbox,
  TrainFront,
} from 'react-bootstrap-icons';
import { ReactComponent as CarEvent } from '../assets/images/CarEvent.svg';
import { ReactComponent as FaceEvent } from '../assets/images/FaceEvent.svg';
import { ReactComponent as PeopleEvent } from '../assets/images/PeopleEvent.svg';
import { ReactComponent as LicensePlate } from '../assets/images/LicensePlate.svg';
import { ReactComponent as Audio } from '../assets/images/Audio.svg';
import { ReactComponent as Motion } from '../assets/images/Motion.svg';

export const eventSearchIcons = (obj) => {
  switch (obj) {
    case 'person':
    case 'top':
    case 'hat':
    case 'bottom':
      return (
        <div className="event-image-search">
          <PeopleEvent height="36" width="36" />
        </div>
      ); 
    case 'face':
      return (
        <div className="event-image-search">
          <FaceEvent height="36" width="36" />
        </div>
      );      
    case 'car':
    case 'vehicle':
    case 'truck':
    case 'motorcycle':
    case 'bicycle':
      return (
        <div className="event-image-search">
          <CarEvent height="36" width="36" />
        </div>
      );
    case 'glasses':
      return (
        <div className="event-image-search">
          <Sunglasses height="36" width="36" />
        </div>
      );
    case 'train':
      return (
        <div className="event-image-search">
          <TrainFront height="36" width="36" />
        </div>
      );
    case 'licenseplate':
      return (
        <div className="event-image-search">
          <LicensePlate height="36" width="36" />
        </div>
      );
    case 'sound':
      return (
        <div className="event-image-search">
          <Audio height="36" width="36" />
        </div>
      );
    case 'motion':
    case 'motion-start':
    case 'motion-end':
      return (
        <div className="event-image-search">
          <Motion height="36" width="36" />
        </div>
      );
      
    default:
      return (
        <div className="event-image-search">
          <Dropbox height="36" width="36" />
        </div>
      );
  }
};

