import { setItem, getItem } from '../indexDB/db';

const persistState = (store) => (next) => async (action) => {
  const result = next(action);
  const state = store.getState();
  await setItem('reduxState', state);
  return result;
};

export const loadState = async () => {
  return (await getItem('reduxState')) || undefined;
};

export default persistState;
