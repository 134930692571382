import { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
} from '@mui/material';
import { HiMiniChevronDown } from 'react-icons/hi2';

import './MaterialSelectList.scss';

const MaterialSelectList = ({
  forwardProps = {},
  options,
  selectLabel,
  placeholder,
  updatedValue,
  name,
  defaultValue,
  disabled,
  iconComponent,
  callback,
}) => {
  const [selectedValue, setSelectedValue] = useState(updatedValue);

  const handleChange = (event) => {
    let value = event?.target?.value;
    setSelectedValue(value);
    callback && callback(value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        {selectLabel && (
          <InputLabel id="simple-select-label">{selectLabel}</InputLabel>
        )}
        <Select
          {...forwardProps}
          className="main-select"
          labelId="simple-select-label"
          id="simple-select"
          IconComponent={iconComponent}
          name={name}
          value={updatedValue}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
        >
          {Array.isArray(options)
            ? options?.map((option, optionIndex) => (
                <MenuItem
                  key={`${option.value}-${optionIndex}`}
                  value={option.value ?? ' '}
                >
                  {option.label}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MaterialSelectList;
