import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const PosStore = create(
  persist(
    (set, get) => ({
      posDataList:[],
      atrributeList:[],
      isHeaderChecked:0,
      isUploadDone:0,

      getPosDataList: () => {
        return get().posDataList;
      },

      setPosDataList: (posDataList) => {
        if (!posDataList) return;
        set({
            posDataList: posDataList,
        });
      },
      getAtrributeList: () => {
        return get().atrributeList;
      },

      setIsheaderChecked: (isHeaderChecked) => {
        set({
          isHeaderChecked: isHeaderChecked,
        });
      },
      getIsHeaderChecked: () => {
        return get().isHeaderChecked;
      },
      
      setIsUploadDone: (isUploadDone) => {
        set({
          isUploadDone: isUploadDone,
        });
      },
      getIsUploadDone: () => {
        return get().isUploadDone;
      },
      
      setAtrributeList: (atrributeList) => {
        if (!atrributeList) return;
        set({
          atrributeList: atrributeList,
        });
      },

      resetPosDataList: () =>
      set((state) => {
        return {
          ...state,
          posDataList: [],
          isUploadDone:0,
          atrributeList:[],
        };
      }),
    }),
    {
        name: 'net.duclo.vms.tags',
        version: '1.0',
      }
  )
);
