import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CommonBlock = (props) => {
  const {
    backgroundColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--greyscale_88'),
  } = props;
  return (
    <div
      style={{ width: props.width, height: props.height }}
      className={`w-100 ${props.className}` ? props.className : ''}
    >
      <Container
        className={`site-block ${props.blockType}-block`}
        style={{ backgroundColor: backgroundColor }}
      >
        {props.blockType === 'default' ? (
          <div
            className={`site-block-content ${props.blockType}-block-content ${
              props.contentWrapperClass ? props.contentWrapperClass : ''
            }`}
          >
            <div
              className={`site-block-title ${props.blockType}-block-title ${
                props.titleClass ? props.titleClass : ''
              }`}
            >
              {props.label}
            </div>
            <div
              className={`site-block-value ${props.blockType}-block-value ${
                props.valueClass ? props.valueClass : ''
              }`}
            >
              {props.value && props.value}
            </div>
          </div>
        ) : (
          <Row className={`${props.blockType === 'warning' ? 'h-100' : ''}`}>
            {props.icon && (
              <Col
                md={4}
                sm={4}
                xs={4}
                className={`${props.blockType}-block-icon`}
              >
                {props.icon}
              </Col>
            )}
            <Col
              className={`site-block-content ${props.blockType}-block-content ${
                props.contentWrapperClass ? props.contentWrapperClass : ''
              }`}
            >
              <div
                className={`site-block-title ${props.blockType}-block-title ${
                  props.titleClass ? props.titleClass : ''
                }`}
              >
                {props.label}
              </div>
              <div
                className={`site-block-value ${props.blockType}-block-value ${
                  props.valueClass ? props.valueClass : ''
                }`}
              >
                {props.value && props.value}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

CommonBlock.defaultProps = {
  blockType: 'default',
  width: '100%',
  height: '76px',
};

export default CommonBlock;
