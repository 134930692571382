import { useState, useEffect } from 'react';
import FormSteps from './FormSteps';
import DetailsContextProvider from './DetailsContextProvider';
import DataContextProvider from './DataContextProvider';
import { SiteModal, PrimaryButton } from '../../../components/common/';
import { PlusIcon } from '../../../assets/images';
import { constants } from '../../../helpers';
import DiscoveredDevices from './DiscoveredDevices';
import { useDispatch } from 'react-redux';
import { resetData } from '../../../store/reducers/NVRDeviceReducer';
import { setDoNotRefreshDeviceList } from '../../../store/reducers/AccountReducer';
import { setIsFetchLicensesDataAgain } from '../../../store/reducers/StreamingReducer';

const AddDevice = (props) => {
  const reloadData = () => props.reloadData();
  const dispatch = useDispatch();
  const setShowClaimDeviceModal = () => props.setShowClaimDeviceModal();
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showDiscoveredDevicesModel, setShowDiscoveredDevicesModel] =
    useState(false);
  const handleShow = () => setShowAddOrgModal(true);
  localStorage.setItem('modalOpen', 0);

  const handleClose = () => {
    setShowAddOrgModal(false);
    setShowClaimDeviceModal(false);
  };

  useEffect(() => {
    showHideDiscovedDevicesPopup(props.isDirectToScannedPopup);
  }, [props.isDirectToScannedPopup]);

  useEffect(() => {
    setShowAddOrgModal(props.showClaimDeviceModal);
  }, [props.showClaimDeviceModal]);

  const DeviceModal = () => {
    return (
      <SiteModal
        modalHeader={true}
        showModal={showAddOrgModal}
        hideModal={() => handleClose}
      >
        <FormSteps
          reloadData={() => reloadData()}
          hideModal={() => handleClose()}
          onDiscoverDevices={() => showHideDiscovedDevicesPopup(true)}
        />
      </SiteModal>
    );
  };

  const showHideDiscovedDevicesPopup = (value) => {
    setShowDiscoveredDevicesModel(value);
    dispatch(setDoNotRefreshDeviceList(value));
  };

  const handleCloseDiscoveredDeviceModel = () => {
    showHideDiscovedDevicesPopup(false);
    dispatch(setIsFetchLicensesDataAgain());
    dispatch(resetData());
    reloadData();
  };

  // const DiscoveredDevicesModal = () => {
  //   return (
  //     <SiteModal
  //       modalHeader={false}
  //       modalTitle={constants.DISCOVERED_DEVICES_MODEL_HEADER}
  //       showModal={showDiscoveredDevicesModel}
  //       hideModal={handleCloseDiscoveredDeviceModel}
  //       classes="discovered-devices"
  //       size="lg"
  //     >
  //       <DiscoveredDevices
  //         hideModalHandler={handleCloseDiscoveredDeviceModel}
  //       />
  //     </SiteModal>
  //   );
  // };

  return (
    <DetailsContextProvider>
      <DataContextProvider>
        {/* <PrimaryButton
          className=" device-btn-list device-btn-space"
          type="button"
          width="136px"
          height="44px"
          fontSize="14px"
          onClick={handleShow}
        >
          <img src={PlusIcon} style={{ marginRight: '11px' }} alt="plusicon" />
          {constants.DEVICES_ADD_DEVICE_TITLE}
        </PrimaryButton> */}
        <DeviceModal />
        {/* <DiscoveredDevicesModal /> */}
        <SiteModal
          modalHeader={false}
          modalTitle={constants.DISCOVERED_DEVICES_MODEL_HEADER}
          showModal={showDiscoveredDevicesModel}
          hideModal={handleCloseDiscoveredDeviceModel}
          classes="discovered-devices"
          size="lg"
        >
          <DiscoveredDevices
            hideModalHandler={handleCloseDiscoveredDeviceModel}
          />
        </SiteModal>
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default AddDevice;
