import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { SiteSpinner } from '../../components/common';

function POSlinking() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(true);
  let orgId = searchParams.get('orgId');
  let fileId = searchParams.get('fileId');
  useEffect(() => {
    const token = localStorage.getItem('vmsAuthToken');
    if (
      (token == null && token == 'null') ||
      token == 'undefined' ||
      token == ''
    ) {
      if (fileId) {
        localStorage.setItem('posOrgId', orgId);
      }
      if (process.env.REACT_APP_HIDE_LOGIN === 'true') {
        navigate(`/login`);
      } else {
        navigate(`/`);
      }
      return;
    } else {
      navigate(`/app/pos?orgId=${orgId}`);
    }
  }, []);

  return (
    <div className="App">
      {showLoader && (
        <div className="app-margin-partner-invite">
          <SiteSpinner height="100px" width="100px"></SiteSpinner>
        </div>
      )}
    </div>
  );
}

export default POSlinking;
