import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useModuleStore = create(
  persist(
    (set, get) => ({
      moduleStatus: {},

      getModuleStatus: () => {
        return get().moduleStatus;
      },

      setModuleStatus: (moduleStatus) => {
        if (!moduleStatus) return;

        set({
            moduleStatus: moduleStatus,
        });
      },

      resetModuleStatus: () =>
        set((state) => {
          return {
            ...state,
            moduleStatus: {},
          };
        }),
    }),
    {
      name: 'net.duclo.vms.module',
      version: '1.0',
    }
  )
);
