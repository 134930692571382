import Store from './Store';
import { getItem, setItem } from './indexDB/db';

export const getAllScannedDevices = async () => {
  return await getItem('allScannedDevices');
};

export const setAllScannedDevices = async (devices) => {
  return await setItem('allScannedDevices', devices);
};

export const getNVRScannedDevices = async () => {
  return await getItem('nvrScannedDevices');
};

export const setNVRScannedDevices = async (devices) => {
  return await setItem('nvrScannedDevices', devices);
};

export const getDevicesListOfCurrOrg = async () => {
  return await getItem('devicesListOfCurrOrg');
};

export const setDevicesListOfCurrOrg = async (devices) => {
  return await setItem('devicesListOfCurrOrg', devices);
};
