const BaseChartConfig = {
  chart: {
    id: 'line-chart',
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
    },
    selection: {
      enabled: false,
      type: 'x',
    },
    background: {
      position: 'top',
      shade: 'light',
      type: 'linear',
      gradientToColors: ['#6dd5ed', '#2193b0'],
      opacityFrom: 1,
      opacityTo: 0.3,
    },
    // Toolbar config options below are set for future
    // reference only -- they are not actually being
    // used right now.
    toolbar: {
      autoSelected: 'pan',
      show: true, // hide hamburrger menu
      position: 'top', // Set position of toolbar
      tools: {
        download: false, // Enable download tool
        selection: false, // Enable selection tool
        zoom: false, // Enable zoom tool
        zoomin: false, // Enable zoom in tool
        zoomout: false, // Enable zoom out tool
      },
      offsetY: -20,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: 2,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20
    }
  },
};

export default BaseChartConfig;
