import { Utils, constants } from '../../../helpers';
import './notificationdevices.scss';
import { useState, useRef, useEffect } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { HiOutlineMinus } from 'react-icons/hi';
import { TbDeviceComputerCamera } from 'react-icons/tb';
import { icontick } from '../../../assets/images';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useNotificationStore } from '../../../store/CreateNotificationStore';

const NotificationDevices = (props) => {
  const devices = props.deviceList;
  const [locationAreas, setLocationAreas] = useState([]);

  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [selectedEvents, setSelectedEvents] = useState(
    props?.isEdit
      ? getStepsData()?.selectedEvents
      : props?.stepData?.selectedEvents
  );

  useEffect(() => {
    init();
  }, [devices]);

  const init = async () => {
    if (!Array.isArray(devices)) {
      return;
    }
    let updateDeviceList = devices?.map((deviceItem, index) => {
      const check = checkCapabilitySupportedDevices(deviceItem);
      if (check) {
        return { ...deviceItem, deviceChecked: 4 };
      } else if (getStepsData()?.devices?.length > 0) {
        const deviIdExist = getStepsData()?.devices?.find(
          (item) => item === deviceItem?.deviceId
        );
        if (deviIdExist) {
          return { ...deviceItem, deviceChecked: 1 };
        } else {
          return { ...deviceItem };
        }
      } else {
        return {
          ...deviceItem,
        };
      }
    });
    let deviceList = await Promise.all(!updateDeviceList ? [] : updateDeviceList);

    let locations = [];
    let locationIndex;
    deviceList?.forEach((device) => {
      locationIndex = locations?.findIndex(
        (location) => location?.locationId === device?.locationId
      );
      if (locationIndex === -1) {
        locations?.push({
          locationId: device?.locationId,
          locationName: device?.locationName,
          areas: device?.areas,
        });
      } else {
        device?.areas?.forEach((deviceArea) => {
          if (
            locations[locationIndex].areas?.findIndex(
              (area) => area?.areaId === deviceArea?.areaId
            ) === -1
          ) {
            locations[locationIndex]?.areas?.push(deviceArea?.areaId);
          }
        });
      }
    });
    let list = locations?.map(async (location, index) => {
      let areas = location?.areas?.flat();
      let listArea = areas?.map((area, index) => {
        return {
          ...area,
          areaDevice: deviceList?.filter(
            (device) => device?.areaId === area?.areaId
          ),
          isAreaChecked: getAreaStatus(area, deviceList),
        };
      });
      let deviceUpdated = await Promise.all(!listArea ? [] : listArea);
      return {
        ...location,
        areas: deviceUpdated,
        isChecked: getLocationStatus(deviceUpdated),
      };
    });
    let deviceLocation = await Promise.all(list);
    setLocationAreas([...deviceLocation]);
  };

  useEffect(() => {
    if (locationAreas?.length > 0) {
      props.setDeviceData(locationAreas);
    }
    else{
      props.setDeviceData([]);
    }
  }, [locationAreas]);

  const getLocationStatus = (areaList) => {
    if (areaList) {
      let locationChecked = 0;
      let isAllDeviceSelected = 0;
      for (let i = 0; i < areaList?.length; i++) {
        if (areaList[i]?.isAreaChecked === 1) {
          locationChecked = locationChecked + 1;
        } else if (areaList[i]?.isAreaChecked === 3) {
          isAllDeviceSelected = 3;
        }
      }
      if (isAllDeviceSelected === 3) {
        return 3;
      }
      if (locationChecked === areaList?.length && areaList?.length > 0) {
        return 1;
      } else if (locationChecked != 0 && locationChecked < areaList?.length) {
        return 3;
      } else if (locationChecked === 0) {
        return 2;
      }
    }
  };

  const getAreaStatus = (area, deviceList) => {
    const areaDevice = deviceList.filter(
      (device) => device?.areaId === area?.areaId
    );
    if (areaDevice) {
      let countCheck = 0;
      let disableCount = 0;
      for (let i = 0; i < areaDevice?.length; i++) {
        if (areaDevice[i]?.deviceChecked === 1) {
          countCheck = countCheck + 1;
        } else if (areaDevice[i]?.deviceChecked === 4) {
          disableCount = disableCount + 1;
        }
      }
      if (
        countCheck + disableCount === areaDevice?.length &&
        areaDevice?.length > 0
      ) {
        return 1;
      } else if (countCheck != 0 && countCheck < areaDevice?.length) {
        return 3;
      } else if (countCheck === 0) {
        return 2;
      }
    }
  };

  const updateExpandedItem = (item) => {
    const list = [...locationAreas];
    list.map((data, i) => {
      if (item.locationId == data.locationId) {
        if (data.isExpanded) {
          list[i].isExpanded = false;
        } else {
          list[i].isExpanded = true;
        }
      } else {
        list[i].isExpanded = false;
      }
    });
    setLocationAreas(list);
  };

  const updateExpandedItemArea = async (item, locationPostion) => {
    let locationArea = [...locationAreas];
    let list = locationArea[locationPostion]?.areas;
    let listUpdated = list.map((data, i) => {
      if (item?.areaId == data?.areaId) {
        if (data?.isExpanded) {
          return {
            ...data,
            isExpanded: false,
          };
        } else {
          return {
            ...data,
            isExpanded: true,
          };
        }
      } else {
        return {
          ...data,
          isExpanded: false,
        };
      }
    });
    let areaupdated = await Promise.all(listUpdated);
    const updatedList = {
      ...locationArea[locationPostion],
      areas: areaupdated,
    };
    let locationList = locationArea.map((data, i) => {
      if (locationArea[locationPostion]?.locationId == data.locationId) {
        return { ...updatedList };
      } else {
        return { ...data };
      }
    });
    let locationListUpdated = await Promise.all(locationList);
    setLocationAreas(locationListUpdated);
  };

  const checkCapabilitySupportedDevices = (dataDevices) => {
    const capbilityList = dataDevices?.capDetails?.capabilities;
    const selectedTrigers = [...selectedEvents];
    let isDeviceDisable = true;

    for (let i = 0; i < selectedTrigers?.length; i++) {
      const capItems = selectedTrigers[i];
      for (let j = 0; j < capbilityList?.length; j++) {
        const events = capbilityList[j];
        if (events?.resource == capItems?.resource) {
          isDeviceDisable = false;
          if (events?.resource === Utils.getNotificationResources().person) {
            isDeviceDisable =
              events?.properties?.hasOwnProperty('vehicle-detection') ||
              events?.properties?.hasOwnProperty('person-detection')
                ? false
                : true;
          } else if (
            events?.resource === Utils.getNotificationResources().loitering
          ) {
            isDeviceDisable = events?.properties?.hasOwnProperty(
              'virtual-area-loitering'
            )
              ? false
              : true;
          } else {
            isDeviceDisable = false;
          }
        }
      }
    }
    return isDeviceDisable;
  };

  const parentCheckBox = async (item, locationPostion, areaItem) => {
    const listData = [...locationAreas];
    let checkedItem = listData.map((data, i) => {
      if (item?.locationId == data?.locationId) {
        if (areaItem) {
          return { ...data, isChecked: 3 };
        } else if (data?.isChecked === 1) {
          return { ...data, isChecked: 2 };
        } else if (data?.isChecked === 2) {
          return { ...data, isChecked: 1 };
        } else {
          return { ...data, isChecked: 1 };
        }
      } else {
        return { ...data };
      }
    });
    let list = await Promise.all(checkedItem);
    let listArea = list[locationPostion]?.areas;
    let listUpdated = listArea.map((data, i) => {
      if (areaItem) {
        if (data?.areaId === areaItem?.areaId) {
          if (data?.isAreaChecked === 1) {
            return {
              ...data,
              isAreaChecked: 2,
            };
          } else if (data?.isAreaChecked === 2) {
            return {
              ...data,
              isAreaChecked: 1,
            };
          } else if (data?.isAreaChecked === 3) {
            return {
              ...data,
              isAreaChecked: 2,
            };
          } else {
            return {
              ...data,
              isAreaChecked: 1,
            };
          }
        } else {
          return {
            ...data,
          };
        }
      } else if (list[locationPostion]?.isChecked === 1) {
        return {
          ...data,
          isAreaChecked: 1,
        };
      } else if (list[locationPostion]?.isChecked === 2) {
        return {
          ...data,
          isAreaChecked: 2,
        };
      } else {
        return {
          ...data,
          isAreaChecked: 2,
        };
      }
    });
    let areaupdated = await Promise.all(listUpdated);
    let areaList;
    const fillter = areaupdated?.map(async (dataArea, i) => {
      const deviceListUpdated = dataArea?.areaDevice?.map(
        (data, indexDevice) => {
          if (areaItem) {
            if (
              data?.areaId === areaItem?.areaId &&
              data?.deviceChecked !== 4
            ) {
              if (areaItem?.isAreaChecked === 3) {
                return {
                  ...data,
                  deviceChecked: 2,
                };
              } else if (data?.deviceChecked === 1) {
                return {
                  ...data,
                  deviceChecked: 2,
                };
              } else if (data?.deviceChecked === 2) {
                return {
                  ...data,
                  deviceChecked: 1,
                };
              } else {
                return {
                  ...data,
                  deviceChecked: 1,
                };
              }
            } else {
              return {
                ...data,
              };
            }
          } else if (
            (list[locationPostion]?.isChecked === 1 ||
              dataArea?.isAreaChecked === 1) &&
            data?.deviceChecked !== 4
          ) {
            return {
              ...data,
              deviceChecked: 1,
            };
          } else if (
            (list[locationPostion]?.isChecked === 2 ||
              dataArea?.isAreaChecked === 2) &&
            data?.deviceChecked !== 4
          ) {
            return {
              ...data,
              deviceChecked: 2,
            };
          } else {
            return {
              ...data,
              deviceChecked: data?.deviceChecked === 4 ? 4 : 2,
            };
          }
        }
      );
      if (deviceListUpdated) {
        let deviceUpdated = await Promise.all(deviceListUpdated);
        return { ...dataArea, areaDevice: deviceUpdated };
      }
    });
    areaList = await Promise.all(fillter);
    const areaDeviceCombineList = areaList ? areaList : areaupdated;
    let countCheck = 0;

    for (let i = 0; i < areaDeviceCombineList?.length; i++) {
      if (areaDeviceCombineList[i]?.isAreaChecked === 1) {
        countCheck = countCheck + 1;
      }
    }
    const locationArea =
      countCheck === areaDeviceCombineList?.length
        ? {
            ...list[locationPostion],
            isChecked: 1,
          }
        : countCheck != 0 && countCheck < areaDeviceCombineList?.length
        ? {
            ...list[locationPostion],
            isChecked: 3,
          }
        : countCheck === 0 && {
            ...list[locationPostion],
            isChecked: 2,
          };
    const updatedList = {
      ...locationArea,
      areas: areaDeviceCombineList,
    };
    let locationList = list.map((data, i) => {
      if (list[locationPostion]?.locationId == data.locationId) {
        return { ...updatedList };
      } else {
        return { ...data };
      }
    });
    let locationListUpdated = await Promise.all(locationList);
    setLocationAreas(locationListUpdated);
  };

  const deviceCheckbox = async (
    locationItem,
    locationIndex,
    areaItem,
    areaIndex,
    deviceItem,
    devicesIndex
  ) => {
    const locAreaDeviceList = [...locationAreas];
    const areaList = locAreaDeviceList[locationIndex]?.areas;
    const deviceList = areaList[areaIndex]?.areaDevice;

    let updateDeviceList = deviceList?.map((device, dindex) => {
      if (
        device?.deviceId === deviceItem?.deviceId &&
        device?.deviceChecked !== 4
      ) {
        if (device?.deviceChecked === 1) {
          return { ...device, deviceChecked: 2 };
        } else if (device?.deviceChecked === 2) {
          return { ...device, deviceChecked: 1 };
        } else {
          return { ...device, deviceChecked: 1 };
        }
      } else {
        return { ...device };
      }
    });
    let devicePromise = await Promise.all(updateDeviceList);

    let countCheck = 0;
    let disableCount = 0;
    for (let i = 0; i < devicePromise?.length; i++) {
      if (devicePromise[i]?.deviceChecked === 1) {
        countCheck = countCheck + 1;
      } else if (devicePromise[i]?.deviceChecked === 4) {
        disableCount = disableCount + 1;
      }
    }
    const locationDevices =
      countCheck + disableCount === devicePromise?.length
        ? {
            ...areaList[areaIndex],
            isAreaChecked: 1,
          }
        : countCheck != 0 && countCheck < devicePromise?.length
        ? {
            ...areaList[areaIndex],
            isAreaChecked: 3,
          }
        : countCheck === 0 && {
            ...areaList[areaIndex],
            isAreaChecked: 2,
          };
    const updateArea = {
      ...locationDevices,
      areaDevice: devicePromise,
    };

    let updateAreaList = areaList?.map((area, index) => {
      if (area?.areaId === areaItem?.areaId) {
        return { ...updateArea };
      } else {
        return { ...area };
      }
    });

    let countCheckArea = 0;
    for (let i = 0; i < updateAreaList?.length; i++) {
      if (updateAreaList[i]?.isAreaChecked === 1) {
        countCheckArea = countCheckArea + 1;
      }
    }

    let areaPromise = await Promise.all(updateAreaList);
    const locationDevicesUpdate =
      countCheck === devicePromise?.length
        ? {
            ...locAreaDeviceList[locationIndex],
            isChecked:
              countCheckArea === updateAreaList?.length
                ? 1
                : countCheckArea != 0 && countCheckArea < updateAreaList?.length
                ? 3
                : 2,
          }
        : countCheck != 0 && countCheck < devicePromise?.length
        ? {
            ...locAreaDeviceList[locationIndex],
            isChecked: 3,
          }
        : countCheck === 0 && {
            ...locAreaDeviceList[locationIndex],
            isChecked: 2,
          };

    const updateLocation = {
      ...locationDevicesUpdate,
      areas: areaPromise,
    };

    let updateLocationList = locAreaDeviceList?.map((location, index) => {
      if (location?.locationId === locationItem?.locationId) {
        return { ...updateLocation };
      } else {
        return { ...location };
      }
    });
    let locationPromise = await Promise.all(updateLocationList);
    setLocationAreas(locationPromise);
  };

  return (
    <div className="device-filters-notification">
      {Array.isArray(locationAreas) && locationAreas?.length > 0 ? (
        locationAreas.map((location, locationIndex) => {
          return (
            <div key={locationIndex} className="parent-layout">
              <div className="list-devices">
                <div className="left-conatiner">
                  {location?.isExpanded ? (
                    <SlArrowUp
                      stroke={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_08')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_08')}
                      onClick={() => updateExpandedItem(location)}
                    />
                  ) : (
                    <SlArrowDown
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_black')}
                      onClick={() => updateExpandedItem(location)}
                    />
                  )}

                  <div className="parent-title">{location.locationName}</div>
                </div>
                <div
                  onClick={() =>
                    parentCheckBox(location, locationIndex, false, false)
                  }
                  className={`right-container  ${
                    location.isChecked === 1 || location.isChecked === 3
                      ? 'selected-tick'
                      : ''
                  }`}
                >
                  {/* <HiOutlineMinus size={16} color={getComputedStyle(document.documentElement).getPropertyValue('--brand_white')} /> */}
                  {location.isChecked === 1 && (
                    <img className="icon-image" alt="" src={icontick}></img>
                  )}
                  {location.isChecked === 3 && (
                    <HiOutlineMinus
                      size={16}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                    />
                  )}
                </div>
              </div>

              {/* Area list fillters */}

              {location.isExpanded &&
                Array.isArray(location?.areas) &&
                location?.areas?.map((areaItem, areaIndex) => {
                  return (
                    <div key={areaItem?.areaId} className="area-layout">
                      <div className="area-container">
                        <div className="left-conatiner">
                          {areaItem?.isExpanded ? (
                            <SlArrowUp
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--brand_black')}
                              onClick={() =>
                                updateExpandedItemArea(areaItem, locationIndex)
                              }
                            />
                          ) : (
                            <SlArrowDown
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--brand_black')}
                              onClick={() =>
                                updateExpandedItemArea(areaItem, locationIndex)
                              }
                            />
                          )}

                          <div className="parent-title">
                            {areaItem?.areaName}
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            parentCheckBox(
                              location,
                              locationIndex,
                              areaItem,
                              false
                            )
                          }
                          className={`right-container  ${
                            areaItem?.isAreaChecked === 1 ||
                            areaItem?.isAreaChecked === 3
                              ? 'selected-tick'
                              : ''
                          }`}
                        >
                          {' '}
                          {areaItem?.isAreaChecked === 1 && (
                            <img
                              className="icon-image"
                              alt=""
                              src={icontick}
                            ></img>
                          )}
                          {areaItem?.isAreaChecked === 3 && (
                            <HiOutlineMinus
                              size={16}
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--brand_white')}
                            />
                          )}
                        </div>
                      </div>

                      {/* Device list fillters */}
                      {areaItem?.isExpanded &&
                        Array.isArray(areaItem?.areaDevice) &&
                        areaItem?.areaDevice.map(
                          (areaDevice, areaDeviceIndex) => {
                            return (
                              <div
                                className="device-container"
                                key={`area-details-${areaItem?.areaId}-${areaDeviceIndex}`}
                              >
                                <div className="left-container">
                                  <TbDeviceComputerCamera
                                    size={22}
                                    color={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue('--greyscale_40')}
                                  />
                                  <div className="title-capbility-reason-layout">
                                    <div className="parent-title">
                                      {areaDevice?.deviceName}
                                    </div>
                                    {areaDevice?.deviceChecked === 4 && (
                                      <div className="reason-title-layout">
                                        <RiErrorWarningLine
                                          color={getComputedStyle(
                                            document.documentElement
                                          ).getPropertyValue('--brand_primary')}
                                          size={15}
                                        />
                                        <div className="reason-title">
                                          {
                                            constants.NOTIFICATION_CREATE_NOTIFICATION_EVENT_NOT_SUPPORTED
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    deviceCheckbox(
                                      location,
                                      locationIndex,
                                      areaItem,
                                      areaIndex,
                                      areaDevice,
                                      areaDeviceIndex
                                    )
                                  }
                                  className={`right-container  ${
                                    areaDevice?.deviceChecked === 1
                                      ? 'selected-tick'
                                      : areaDevice?.deviceChecked === 4
                                      ? 'event-not-supported'
                                      : ''
                                  }`}
                                >
                                  {/* <HiOutlineMinus size={16} color={getComputedStyle(document.documentElement).getPropertyValue('--brand_white')} /> */}
                                  {areaDevice?.deviceChecked === 1 && (
                                    <img
                                      className="icon-image"
                                      alt=""
                                      src={icontick}
                                    ></img>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                })}
            </div>
          );
        })
      ) : (
        <div className="no-device-layout">
          <div className="text-no-device">
            {constants.SELECT_LOCATION_TO_SHOW_DEVICE}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDevices;
