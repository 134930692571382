import { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from '../../../../components/forms';
import { DeviceStatusEnum, Utils, constants } from '../../../../helpers';
import { PrimaryButton } from '../../../../components/common';
import { useCallback } from 'react';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../../store/DevicesMQTTStore';
import { mqttPublish } from '../../../../utils/connection/mqttConnection';

const NotAuthenticatedControl = ({
  selectedDevice,
  scannedDevices,
  hubId,
  selectedDeviceLoaderHandler,
}) => {
  const formikRef = useRef();
  const resourceList = Utils.getDeviceSettingResource(11);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const appTopic = `a/notify/${hubId}`;

  const validateNotAuthenticated = Yup.object({
    username: Yup.string().required(constants.USERNAME_ERROR_MESSAGE),
    password: Yup.string().required(constants.PASSWORD_ERROR_MESSAGE),
  });

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const setPublishRequest = useCallback((resourceURL, propertiesPayload) => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: tid,
        to: hubId,
        from: accountId,
        msg: {
          action: 'set',
          resource: resourceURL,
          properties: propertiesPayload,
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  }, []);

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        applyForAll: false,
      }}
      validationSchema={validateNotAuthenticated}
      onSubmit={(values) => {
        selectedDeviceLoaderHandler(true);
        if (values.applyForAll) {
          scannedDevices?.forEach((device) => {
            if (
              device.deviceAuthStatus === DeviceStatusEnum.NOT_AUTHENTICATED
            ) {
              const properties = {
                channel: device.channel,
                un: values.username,
                pw: values.password,
              };
              setPublishRequest(resourceList[1], properties);
            }
          });
        } else {
          const properties = {
            channel: selectedDevice?.channel,
            un: values.username,
            pw: values.password,
          };
          setPublishRequest(resourceList[1], properties);
        }
        setTimeout(() => {
          selectedDeviceLoaderHandler(false);
        }, 10000);
      }}
      innerRef={formikRef}
    >
      {({ values, dirty, isValid, handleSubmit, handleChange }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          <div className="username-field">
            <TextField
              placeholder={constants.USERNAME_TEXT}
              name="username"
              type="text"
              removebottommargin="true"
              removetopmargin="true"
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              placeholder={constants.PASSWORD_TEXT}
              name="password"
              type="password"
              removebottommargin="true"
              removetopmargin="true"
              onChange={handleChange}
            />
          </div>
          <div className="apply-all-creds">
            <input
              type="checkbox"
              id="applyForAll"
              name="applyForAll"
              className="form-check-input"
              value={values.applyForAll}
              onChange={handleChange}
            />
            <label className="apply-all-check-label" htmlFor="applyForAll">
              {constants.APPLY_CREDENTIAL_FOR_ALL_DEVICES_TEXT}
            </label>
          </div>
          <PrimaryButton
            className="mt-4"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            disabled={!(dirty && isValid)}
          >
            {constants.AUTHENTICATE_DEVICE_BUTTON_TEXT}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default NotAuthenticatedControl;
