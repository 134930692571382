import { useEffect, useRef, useState } from 'react';
import { Accordion, Alert, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField } from '../../../../components/forms';
import { Field, Formik } from 'formik';
import { constants, Utils } from '../../../../helpers';
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
} from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';
import timezones from '../../../../data/support/timezone.json';
import { publishHubDeviceSetting } from '../shared/PublishDeviceSettings';
import './HubInfoBlock.scss';
import {
  getLocationsData,
  getDeviceInformation,
} from '../../../../store/reducers/AccountReducer';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';
import LocationSelectField from '../../../../components/forms/LocationSelectField';
import { publishFirmwareDeviceSetting } from "../shared/PublishDeviceSettings";
import * as Yup from 'yup';
import { PrimaryButton, SiteModal, TextBlock } from '../../../../components/common';
import { getManufacturerSKUList } from '../../../../store/reducers/NVRDeviceReducer';

const HubInfoBlock = ({
  deviceId,
  deviceInfos,
  deviceDetails,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  moveDeviceDetailCallback,
  deviceNameLocationChangeEvent,
  updateStore,
  accountId,
  uuid,
  devicePublishId,
  updateFirmware,
  hasManagePermission,
  sessionId 
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [deviceName, setDeviceName] = useState(deviceInfos?.deviceName);
  const [deviceInfo, setDeviceInfo] = useState(deviceInfos);
  const [selectedAreaLocation, setSelectedAreaLocation] = useState({});
  const zoneTimeList = timezones?.data;
  const timezone = deviceInfos?.properties?.timezone;
  const idLocation = deviceInfos?.locationId;
  const [locationId, setlocationId] = useState(idLocation);

  const [currentUserTimezone, setCurrentUserTimezone] = useState(timezone);
  const [currentTime, setCurrentTime] = useState(
    moment
      .tz(
        moment(),
        timezone
          ? findTimeZoneFromSelectedValue(timezone)?.location
          : moment.tz.guess()
      )
      .format('MMM DD, YYYY hh:mm:ss a')
  );
  const locationsData = useSelector(getLocationsData);
  const deviceChannel = useSelector(getDeviceInformation);
  const deviceState = deviceDetails?.deviceStatus;
  const connectionState = deviceDetails?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
      hasManagePermission;
  const resourceList = Utils.getDeviceSettingResource(0);
  const neworkProperty = deviceInfos?.properties;
	const resourceListUpdate = Utils.getDeviceSettingResource(6);
  const tid = Math.floor(new Date().getTime() / 1000.0);
	const publishPayload = {
		transactionID: tid,
		accountId: accountId,
		deviceId: deviceId,
		hubId: deviceInfos?.gatewayId,
		uuid: sessionId,
		devicePublishId: devicePublishId,
	};
  const [updateDeviceFirmwareModal, setUpdateDeviceFirmwareModal] = useState(false)
  const [updateCloudConnectModal, setUpdateCloudConnectModal] = useState(false)
  const manufacturerSKUList = useSelector(getManufacturerSKUList);

	useEffect(() => {
    setDeviceInfo(deviceInfos);
  }, [deviceInfos]);

  const onUpdateFirmware = () => {
		const properties = {
			"manual-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceListUpdate[2], publishPayload, false);
	};

	const onUpdateDeviceFirmware = () => {
		const properties = {
			"manual-dev-update": constants.PROPERTY_STATUS_TRUE,
		};
		updateStore(properties);
		publishFirmwareDeviceSetting(properties, resourceListUpdate[3], publishPayload, false);
	};

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    if (currentUserTimezone) {
      let secTimer = setInterval(() => {
        setCurrentTime(
          moment
            .tz(
              moment(),
              findTimeZoneFromSelectedValue(currentUserTimezone)
                ?.location
            )
            .format('MMM DD, YYYY hh:mm:ss a')
        );
      }, 1000);
      return () => clearInterval(secTimer);
    }
  }, [currentUserTimezone]);

  useEffect(() => {
    setCurrentUserTimezone(timezone);
    setlocationId(deviceInfos?.locationId);
  }, [deviceInfos]);

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  useEffect(() => {
    moveDeviceLocation(selectedAreaLocation);
  }, [deviceName, locationId]);

  useEffect(() => {
    if (Object.keys(selectedAreaLocation)?.length !== 0) {
      const getLocationSelected = findTimeZoneFromSelectedValue(selectedAreaLocation?.timezone || currentUserTimezone);
      setlocationId(selectedAreaLocation?.locationId);
      setCurrentUserTimezone(getLocationSelected?.location);
    }
  }, [selectedAreaLocation]);

  const moveDeviceLocation = async (slectedAreaLocation) => {
    try {
      const reqBody = {
        oldLocationId: deviceInfos?.locationId,
        oldAreaId: deviceInfos?.areaId,
        olddeviceName: deviceInfos?.deviceName,
        newLocationId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.locationId
            : locationId,
        newAreaId:
          Object.keys(slectedAreaLocation).length === 0
            ? deviceInfos?.areaId
            : slectedAreaLocation.areaId,
        deviceName: deviceName ? deviceName : deviceInfos?.deviceName,
        orgId: deviceInfos?.orgId,
        deviceId: deviceInfos?.deviceId,
      };
      moveDeviceDetailCallback(reqBody);
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };


  const validateAuthenticated = Yup.object({
    deviceName: Yup.string()
      .max(40, constants.DEVICE_NAME_MAX_LENGTH_MESSAGE)
  });

  const onDeviceNameChange = (e, setFieldValue) => {
    if (e?.target?.value?.length <= 40) {
      deviceNameLocationChangeEvent();
      setDeviceName(e.target.value);
      setFieldValue('deviceName', e.target.value);
    }else{
      setFieldValue('deviceName', e.target.value);
      setDeviceName(deviceInfos?.deviceName)
    }
  };

  const avlbleDeviceVersion = deviceInfos?.properties?.['avlbl-device-ver'];
	const firmwareVersion = deviceInfos?.properties['firmware-version'];

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      {showMsg && (
        <Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
          <div className='d-flex'>
            <div>
              <HiOutlineExclamationCircle
                size={20}
                stroke={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_24')}
              />
            </div>
            <div className='text-alignment'>
              {!userMsg ? (
                <p>{userMsg}</p>
              ) : (
                <>
                  <Alert.Heading>
                    {constants.UNABLE_TO_SAVE_DEVICE_INFO_HEADING}
                  </Alert.Heading>
                  <p>{constants.UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE}</p>
                </>
              )}
            </div>
         </div>
        </Alert>
      )}
      {deviceInfos && (
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            deviceName: deviceInfos?.deviceName,
            volume: 2,
            sensitivity: 2,
            deviceDegrees: '',
            noiseReduction: false,
            audioOutVolume: 2,
            areaData: deviceInfos?.areaId,
            locationData: locationId,
            timezone: findTimeZoneFromSelectedValue(currentUserTimezone ? currentUserTimezone : timezone)?.location,
          }}
          validationSchema={validateAuthenticated}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setVariant('danger');
            setUserMsg('');
            setSubmitting(false);
            saveAudioSettingsCallback(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2">
                    <label>{constants.CHANNELS_INFO_DEVICE_NAME_TITLE}</label>
                  </div>
                  <TextField
                    required={true}
                    removebottommargin="true"
                    removetopmargin="true"
                    label=""
                    placeholder={constants.DEVICE_INFO_MEETING_ROOM_TITLE}
                    name="deviceName"
                    value={values.deviceName}
                    type="text"
                    onChange={(e) => onDeviceNameChange(e, setFieldValue)}
                  />
                </Col>
              </Row>
              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_DEVICE_TITLE}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICE_INFO_DEVICE_STATUS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {curDeviceStatus ===
                      constants.DEVICES_RETURN_ONLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-online" />
                      ) : curDeviceStatus ===
                        constants.DEVICES_RETURN_OFFLINE_STATUS ? (
                        <FaCircle size={10} className="status-icon-offline" />
                      ) : (
                        <FaCircle size={10} className="status-icon-entered" />
                      )}
                      <div className="block-content">{curDeviceStatus}</div>
                    </div>
                  </Col>
                </Row>
                {curDeviceStatus ===
                  constants.DEVICES_RETURN_OFFLINE_STATUS && (
                  <Alert variant="danger">
                    <div className='d-flex'>
                      <div>
                        <HiOutlineInformationCircle
                          size={20}
                          stroke={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_24')}
                        />
                      </div>
                      <div className='text-alignment'>
                        {userMsg !== '' ? (
                          <p>{userMsg}</p>
                        ) : (
                          <>
                            {constants.CHANNELS_INFO_CANNOT_AUTH_DEVICE_TITLE}
                            <p>
                              {t('CHANNELS_INFO_CANNOT_AUTH_DEVICE_CONTENT')}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </Alert>
                )}
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MANUFACTURER_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {Utils.getManufacturer(deviceInfos?.manufacturer)}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MODEL_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.modelNumber}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_SERIAL_NO_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.serialNo}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.CHANNELS_INFO_CHANNELS_COUNT_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {manufacturerSKUList?.find(
                          (item) => item.model === deviceInfos.modelNumber
                        )?.channelCount || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.macAddress}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.CHANNELS_INFO_DEVICE_FIRMWARE_TITLE}
                    </div>

                    <div className="hub-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {firmwareVersion 
                        ? firmwareVersion 
                        : '--'}
                    </div>
                  </Col>
                </Row>
                {connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && avlbleDeviceVersion && firmwareVersion?.toLowerCase() !== avlbleDeviceVersion?.toLowerCase() && (
									<Row className="settings-block">
										<Col>
											<div className="alert alert-danger info-update" role="alert">
												<div className="alert-message">
													<HiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue("--error_24")} className="alert-icon" />
												</div>
												<p>
													{constants.CHANNELS_INFO_UPDATE_AVAILABLE}{" "}
													<span
														onClick={() => setUpdateDeviceFirmwareModal(true)}
														style={{
															cursor: "pointer",
															textDecoration: "underline",
															textDecorationColor: getComputedStyle(document.documentElement).getPropertyValue("--error_24"),
														}}>
														{constants.HERE_LABEL}
													</span>
												</p>
											</div>
										</Col>
									</Row>
								)}
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {t('CHANNELS_INFO_FIRMWARE_TITLE')}
                    </div>
                    <div className="hub-info-block-content">
                      {/* TODO : This static value here -- gonna changed later based on API data */}
                      {deviceInfos?.properties?.['edge-app-version']
                        ? deviceInfos?.properties?.['edge-app-version']
                        : '--'}
                    </div>
                  </Col>
                </Row>
                {connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS && deviceInfos?.properties?.['avlbl-duclo-ver'] &&
                  deviceInfos?.properties?.['edge-app-version'] !==
                    deviceInfos?.properties?.['avlbl-duclo-ver'] && (
                    <Row className="settings-block">
                      <Col>
                        <div
                          className="alert alert-danger info-update"
                          role="alert"
                        >
                          <div className="alert-message">
                            <HiOutlineExclamationCircle
                              size={15}
                              stroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_24')}
                              className="alert-icon"
                            />
                          </div>
                          <p>
                            {constants.CHANNELS_INFO_UPDATE_AVAILABLE}{' '}
                            <span
                              onClick={() => setUpdateCloudConnectModal(true)}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--error_24'),
                              }}
                            >
                              {constants.HERE_LABEL}
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
              </div>
              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_NETWORK_TITLE}</label>
              </div>
              <div className="device-info-section">
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_IP_TYPE_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['ip-type'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_IP_ADDRESS_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['ip-address'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_MAC_ADDRESS_TEXT}
                    </div>
                    <div className="hub-info-block-content">
                      {deviceInfos?.macAddress}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_SUBNET_MASK_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['subnet-mask'] || '--'}
                    </div>
                  </Col>
                </Row>
                <Row className="settings-block">
                  <Col>
                    <div className="hub-info-block-heading">
                      {constants.DEVICES_TAB_NETWORK_DNS1_TITLE}
                    </div>
                    <div className="hub-info-block-content">
                      {neworkProperty?.['primary-dns'] || '--'}
                    </div>
                  </Col>
                </Row>
                {neworkProperty?.['secondary-dns'] && (
                  <Row className="settings-block">
                    <Col>
                      <div className="hub-info-block-heading">
                        {constants.DEVICES_TAB_NETWORK_DNS2_TITLE}
                      </div>
                      <div className="hub-info-block-content">
                        {neworkProperty?.['secondary-dns'] || '--'}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>

              <div className="section-heading mb-2 mt-4">
                <label>{constants.CHANNELS_INFO_LOCATION_TEXT}</label>
              </div>
              <div className="device-info-section">
                <Accordion>
                  <Accordion.Item>
                    <div className="channel-info radio-wrapper">
                      {locationsData?.map((location) => (
                        <div className="radiotitle" key={location.locationId}>
                          <Col>
                            <div
                              style={{
                                color: getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--greyscale_08'),
                              }}
                            >
                              {location.locationName}
                            </div>
                          </Col>
                          <Col xs={1} md={1} lg={1} className="makingBig">
                            <Field
                              type="radio"
                              name="locationId"
                              value={values.locationData}
                              checked={
                                values.locationData === location.locationId
                              }
                              onClick={() => {
                                if (!canChangeSettings) {
                                  return;
                                }
                                deviceNameLocationChangeEvent();
                                const selectedArea = location.areas.find(
                                  (area) => area.isDefault
                                );
                                const selectedLocationArea = {
                                  areaId:
                                    selectedArea?.areaId ||
                                    location?.areas?.[0]?.areaId,
                                  locationId: location.locationId,
                                  timezone:
                                    location?.timezone || moment.tz.guess(),
                                };
                                setFieldValue(
                                  'locationData',
                                  selectedLocationArea?.locationId
                                );
                                setFieldValue(
                                  'areaData',
                                  selectedLocationArea?.areaId
                                );
                                setSelectedAreaLocation(selectedLocationArea);
                                moveDeviceLocation(selectedLocationArea);
                              }}
                            />
                          </Col>
                        </div>
                      ))}
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
              <Row className="settings-block">
                <Col>
                  <div className="section-heading mb-2 mt-4">
                    <label>{constants.CHANNELS_INFO_DATE_TIME_TITLE}</label>
                  </div>

                  <div className="hub-info-block-heading">
                    {constants.DEVICE_INFO_CURRENT_TIME_TITLE}
                  </div>
                  <div className="hub-info-block-content time-zone-select">
                    {currentTime}
                  </div>
                  <div className="hub-info-block-content device">
                    <LocationSelectField
                      name="timezone"
                      options={zoneTimeList}
                      label=""
                      value={values.timezone}
                      onChange={(evebr) => {
                        if (!canChangeSettings) {
                          return;
                        }
                        let selectedVal = evebr?.target?.value;
                        const getLocationSelected = findTimeZoneFromSelectedValue(selectedVal);
                        setCurrentUserTimezone(getLocationSelected.location);
                        setFieldValue('timezone', getLocationSelected.label);
                        const property = {
                          timezone: selectedVal.toString(),
                        };
                        updateStore(property);
                        publishHubDeviceSetting(property, resourceList[1], {
                          deviceId: deviceId,
                          devicePublishId: devicePublishId,
                          hubId: deviceInfos?.gatewayId,
                          uuid: uuid,
                          accountId: accountId,
                        });
                      }}
                    />
                  </div>
                  <div className="label-timezone device-setting">
                    {constants.DEVICE_ADD_TIMEZONE_MESSAGE}
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {/* update device firmware - modal */}
      <SiteModal
        modalTitle={constants.UPDATE_DEVICE_FIRMWARE}
        showModal={updateDeviceFirmwareModal}
        hideModal={() => {
          setUpdateDeviceFirmwareModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        > 
        {constants.UPDATE_DEVICE_FIRMWARE_TITLE_INFO}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary-outline mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setUpdateDeviceFirmwareModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_CANCEL_BUTTON_LABEL}
        </PrimaryButton>
       
        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => {
            onUpdateDeviceFirmware();
            setUpdateDeviceFirmwareModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_BUTTON_LABEL}
        </PrimaryButton>
      </SiteModal>
      {/* update cloud connect firmware - modal */}
      <SiteModal
        modalTitle={t('UPDATE_CLOUD_CONNECT_FIRMWARE_TITLE')}
        showModal={updateCloudConnectModal}
        hideModal={() => {
          setUpdateCloudConnectModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {constants.UPDATE_DEVICE_FIRMWARE_TITLE_INFO}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary-outline mb-2"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setUpdateCloudConnectModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_CANCEL_BUTTON_LABEL}
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => {
            onUpdateFirmware()
            setUpdateCloudConnectModal(false);
          }}
        >
          {constants.UPDATE_FIRMWARE_BUTTON_LABEL}
        </PrimaryButton>
      </SiteModal>
    </div>
  );
};

export default HubInfoBlock;
