import React from 'react';
import Timeline from 'react-visjs-timeline';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import { dateClosest } from '../../helpers/dateClosest';
import '../../assets/css/timeline.scss';
import { previous, next, play, noVideo, pause } from '../../assets/images';
import { connect } from 'react-redux';
import Loader from '../../components/common/Loader';
import {
  setMetaData,
  setCDNInfo,
  setLiveStream,
} from '../../store/reducers/StreamingReducer';
import { getUnixDate } from '../../helpers/commonUtils';
import { getTimelineData } from '../../helpers/timelineData';
import constants from '../../helpers/en';
import {
  connectWithWebSocket,
  sendPauseCVR,
  sendPlayCVR,
} from '../../utils/connection/wssConnection';
import RemoteVideoView from '../common/RemoteVideoView';
import { Utils } from '../../helpers';

class Playback extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    // delete later: keeping this for static timeline
    // const currentTime = "November 8, 2022 03:35:52";
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.streaming?.deviceStartDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ minutes: 2, seconds: 20 })
          .toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ days: 2, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      selectedOption: 'Mins',
      activeTime: new Date(currentTime),
      timeZone: '',
      unixTime: moment(new Date(currentTime)).unix(),
      currentTimeNow: currentTime,
      posts: [],
      cdn: {},
      activeImage: '',
      metaData: [],
      minsView: true,
      secsView: false,
      daysView: false,
      clickAction: false,
      loading: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
    };
  }

  componentDidMount = () => {
    var zone = moment.tz.guess();
    const abbr = moment.tz(zone).format('z');
    this.setState({
      timeZone: abbr,
    });
    this.fetchCVRMetaData();
    this.fetchTimeStampImage('first');
    if (this.props?.streaming?.platform) {
      // connectWithWebSocket(this.props?.streaming?.platform);
      connectWithWebSocket(this.props?.streaming?.platform,'', this.props?.account?.accountId, this.props?.account?.deviceInformation?.orgId);
    }
  };

  shouldComponentUpdate = () => {
    return true;
  };

  fetchCVRMetaData = () => {
    this.props.setMetaData([]);
    const startTime = this.props?.streaming?.deviceStartDate;
    const endTime = getUnixDate(this.state.currentTimeNow);
    if (startTime && endTime) {
      axios
        .get(
          `timeline/device/${this.props?.account?.deviceInformation?.deviceId}/metadata?startTime=${startTime}&endTime=${endTime}000`
        )
        .then((response) => {
          if (response?.data?.data) {
            const metaData = response.data.data;
            this.getItemsMetaData(metaData);
          }
        });
    }
  };

  fetchTimeStampImage = (check) => {
    const unixTime = getUnixDate(this.state.activeTime);
    const cdnValue = this.props?.streaming?.cdnInfo;
    const bucket = (cdnValue?.bucket).replace(
      '${deviceId}',
      this.props?.account?.deviceInformation?.deviceId
    );
    const date = Utils.fetchDateInUnix(unixTime);
    fetch(
      `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${unixTime}.jpg`,
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response.ok) {
          this.setState({
            activeImage: response?.url,
          });
          // Todo: Delete later
          Utils.vmsLogger().log('image - recieved', response?.url);
        } else {
          if (check === 'check') {
            this.setState({
              activeImage: noVideo,
            });
          }
          // TODO: Below code for testing purpose
          if (check === 'first') {
            this.setState({
              activeImage: this.props?.streaming?.snapshotImage,
            });
          }
        }
      })
      .catch((error) => {
        // Todo: Delete later
        Utils.vmsLogger().log('error comes in catch', error);
      });
  };

  // Todo: Delete later
  // currentTimeTickHandler = (event) => {Utils.vmsLogger().log("currentTimeTickHandler: ", event)};
  // clickHandler = (event) => {Utils.vmsLogger().log("clickHandler: ", event)};
  // mouseOverHandler = (event) => {Utils.vmsLogger().log("mouseOverHandler: ", event)};
  // mouseMoveHandler = (event) => {Utils.vmsLogger().log("mouseMoveHandler: ", event)};
  // timechangeHandler = (event) => {Utils.vmsLogger().log("timechangeHandler: ", event)};
  // timechangedHandler = (event) => {Utils.vmsLogger().log("timechangedHandler: ", event)};

  rangeChangeHandler = (event) => {
    if (event.byUser) {
      if (this.state.selectedOption === 'Mins') {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.selectedOption === 'Days') {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.selectedOption === 'Secs') {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
        this.fetchTimeStampImage();
      }

      if (this.state.cvrMode) {
        this.setState({
          CVRSnapMode: true,
        });
        sendPauseCVR();
      }
    } else {
      if (this.state.minsView) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (event.byUser) {
      setTimeout(() => {
        this.fetchTimeStampImage('check');
        this.setState({
          loading: false,
        });
      }, 500);
      if (this.state.cvrMode) {
        this.setState({
          CVRSnapMode: false,
        });
        sendPlayCVR(
          this.state.activeTime,
          this.props?.account?.deviceInformation?.deviceId
        );
      }
    }
  };

  onClick = (dates, currentDate, type) => {
    const data = dateClosest(dates, currentDate);
    if (type === 'previous') {
      const previousTime = data.datesAfter[0].date;
      this.timeline.current.$el.moveTo(new Date(previousTime));
    } else {
      const nextTime = data.datesBefore[0].date;
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onPreviousIconClick = () => {
    this.timeline.current.$el.moveTo(
      moment(this.state.activeTime).subtract({ days: 10 }).toDate()
    );
  };

  onNextIconClick = () => {
    this.timeline.current.$el.moveTo(
      moment(this.state.activeTime).add({ days: 3 }).toDate()
    );
  };

  onClickMins = () => {
    switch (this.state.selectedOption) {
      case 'Mins':
        this.setState({
          minsView: false,
          daysView: true,
          secsView: false,
          selectedOption: 'Days',
          clickAction: true,
        });
        break;
      case 'Days':
        this.setState({
          minsView: false,
          daysView: false,
          secsView: true,
          selectedOption: 'Secs',
          clickAction: true,
        });
        break;
      case 'Secs':
        this.setState({
          minsView: true,
          daysView: false,
          secsView: false,
          selectedOption: 'Mins',
          clickAction: true,
        });
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.setState({
        clickAction: false,
      });
    }, 800);
  };

  onGoLive = () => {
    this.onPauseCVR();
    this.props.handleClick(constants.DEVICES_SWITCH_LIVE_TITLE);
  };

  onPauseCVR = () => {
    sendPauseCVR();
    this.setState({
      cvrMode: false,
    });
    clearInterval(this.CVRAutoPlay);
  };

  onPlayCVR = () => {
    sendPlayCVR(
      this.state.activeTime,
      this.props?.account?.deviceInformation?.deviceId
    );
    this.setState({
      cvrMode: true,
    });

    if (this.state.selectedOption === 'Days') {
      this.CVRAutoPlay = setInterval(() => {
        this.setState({
          activeTime: moment(this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        });
      }, 1000);
    } else {
      this.CVRAutoPlay = setInterval(() => {
        this.timeline.current.$el.moveTo(
          moment(this.state.activeTime).add({ seconds: 1 }).toDate()
        );
      }, 1000);
    }
  };

  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  getItems = () => {
    if (this.state.minsView) {
      return this.state.minsMetaData;
    } else if (this.state.daysView) {
      return this.state.daysMetaData;
    } else if (this.state.secsView) {
      return this.state.secsMetaData;
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 2, seconds: 20 };
    const daysAdjustment = { days: 1, hours: 6 };
    const secsAdjustment = { seconds: 40 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState({
      minsMetaData: minsData,
      daysMetaData: daysData,
      secsMetaData: secsData,
    });
  };

  handleClipButtonClick = () => {
    const newClip = {
      position: 0, // Start position of the clip
      duration: 10, // Initial duration of the clip (10 seconds)
    };
    this.setState({ clipper: newClip });
  };

  handleClipDrag = (e, { x }) => {
    if (!this.state.clipper) return;

    const newPosition = Math.max(x, 0); // Ensure the clip doesn't go beyond the timeline start
    const newPositionInSeconds =
      (newPosition / this.timeline.current.width) * this.state.duration; // Convert pixel position to seconds

    const newClip = { ...this.state.clipper, position: newPositionInSeconds };
    this.setState({ clipper: newClip });
  };

  handleClipResize = (e, { size }) => {
    if (!this.state.clipper) return;

    const newDuration = Math.max(
      (size.width / this.timeline.current.width) * this.state.duration,
      this.state.tickDuration
    ); // Convert pixel size to seconds
    const newClip = { ...this.state.clipper, duration: newDuration };
    this.setState({ clipper: newClip });
  };

  render() {
    return (
      <div className="wrapper-app">
        <div className="stream-timeline-wrapper">
          <div className="scrubber-image-view">
            {this.state.loading && <Loader />}
            {this.state.activeImage && (
              <div className="active-image">
                <img src={this.state.activeImage} alt="thumbnail image" />
              </div>
            )}

            {this.state.cvrMode &&
              !this.state.CVRSnapMode &&
              this.props.streaming?.liveStream && (
                <>
                  <RemoteVideoView
                    remoteStream={this.props?.streaming?.liveStream}
                  />
                  This is a test
                </>
              )}

            <div className="timeline-icons">
              {!this.state.cvrMode && (
                <button onClick={() => this.onPlayCVR()}>
                  <img src={play} alt="icon" />
                </button>
              )}

              {this.state.cvrMode && (
                <button onClick={() => this.onPauseCVR()}>
                  <img src={pause} alt="icon" />
                </button>
              )}

              <button onClick={() => this.onPreviousIconClick()}>
                <img src={previous} alt="previous icon" />
              </button>
              <button onClick={() => this.onNextIconClick()}>
                <img src={next} alt="next icon" />
              </button>
              <button onClick={() => this.onClickMins()} className="mins">
                {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                {this.state.selectedOption === 'Days' ? 'Days' : ''}
                {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
              </button>
            </div>
            <div className="timeline-icons golive">
              <button onClick={() => this.onGoLive()} className="golive-button">
                {constants.LIVE_STREAM_GO_LIVE_BUTTON}
              </button>
            </div>
          </div>
          {/* Todo: Need below functionality later */}
          {/* <div
            className="previous-block"
            onClick={() =>
              this.onClick(dates, this.state.activeTime, 'previous')
            }
          ></div>
          <div
            className="next_block"
            onClick={() => this.onClick(dates, this.state.activeTime, 'next')}
          ></div> */}
          <div className={`scrubber-wrapper`}>
            <div className={`active_time`}>
              {moment(this.state.activeTime).format(
                'MMM DD, YYYY - hh:mm:ss A'
              )}{' '}
              {this.state.timeZone}
            </div>
            {!this.state.clickAction && this.state.metaDataHere && (
              <div className={`scrubber`}>
                <Timeline
                  ref={this.timeline}
                  items={this.getItems()}
                  options={this.getOptions()}
                  rangechangeHandler={(event) => this.rangeChangeHandler(event)}
                  rangechangedHandler={(event) =>
                    this.rangeChangedHandler(event)
                  }
                  // delete later:
                  // currentTimeTickHandler={(event) => this.currentTimeTickHandler(event)}
                  // clickHandler={(event) => this.clickHandler(event)}
                  // mouseOverHandler={(event) => this.mouseOverHandler(event)}
                  // mouseMoveHandler={(event) => this.mouseMoveHandler(event)}
                  // timechangeHandler={(event) => this.timechangeHandler(event)}
                  // timechangedHandler={(event) => this.timechangedHandler(event)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMetaData,
  setCDNInfo,
  setLiveStream,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
});

export default connect(mapStoreStateToProps, mapDispatchToProps)(Playback);
