import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';

export const useAccountStatus = create(
  persist(
    (set, get) => ({
      isActNotActivated: false,
      isActNotEnabled: false,

      getIsActNotActivated: () => {
        return get().isActNotActivated;
      },

      setIsActNotActivated: (status) => {
        set({
          isActNotActivated: status,
        });
      },

      resetIsActNotActivated: () => {
        set({
          isActNotActivated: false,
        });
      },

      getIsActNotEnabled: () => {
        return get().isActNotEnabled;
      },

      setIsActNotEnabled: (status) => {
        set({
          isActNotEnabled: status,
        });
      },

      resetIsActNotEnabled: () => {
        set({
          isActNotEnabled: false,
        });
      },
    }),
    {
      name: 'net.duclo.vms.useAccountStatus',
      version: '1.1.0',
    }
  )
);
