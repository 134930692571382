import React from 'react';
import { Navigate } from 'react-router-dom';
import { Radio, RadioGroup } from '@mui/material';
import Timeline from 'react-visjs-timeline';
import { Rnd } from 'react-rnd';
import moment from 'moment';
import axios from 'axios';
import { dateClosest } from '../../helpers/dateClosest';
import {
  clipper,
  camera,
  fullscreen,
  mute,
  next,
  noVideo,
  pause,
  play,
  previous,
  success,
  unmute,
  settings,
  ptzCircles,
  calender,
  minimize,
  moviewarning,
} from '../../assets/images';
import { connect } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  TextBlock,
  TimeInput,
} from '../../components/common';
import { AppDefaults, constants, Utils } from '../../helpers/';
import {
  setChannelTimeStamp,
  setEndOfStream,
  setStreamLoader,
} from '../../store/reducers/StreamingReducer';
import {
  getEventsScubberData,
  getTimelineData,
  updatedTimelineData,
} from '../../helpers/timelineData';
import {
  sendPauseCVR,
  sendPlayCVR,
  sendSpeedCommand,
} from '../../utils/connection/wssConnection';
import {
  disconnectWithMQTT,
  downloadPlaybackStream,
  downloadStream,
  mqttPublish,
} from '../../utils/connection/mqttConnection';
import { setDeviceInformation } from '../../store/reducers/AccountReducer';
import TimelineControlsLive from './TimelineControlsLive';
import PlaybackControlsView from './PlaybackControlsView';
import useEventsStore from '../../store/EventsStore';
import ImageFetch from '../../pages/cameras/components/ImageFetch';
import { SettingOptions } from './SettingOptions';

// React Icons
import { FiCameraOff, FiFilm } from 'react-icons/fi';
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import {
  HiOutlineChevronLeft,
  HiOutlineDotsVertical,
  HiOutlineFolderAdd,
} from 'react-icons/hi';

import CreateClip from '../../pages/cameras/modalForms/CreateClip';
import NewIncident from '../../pages/incidents/modalForms/NewIncident';

import 'moment-timezone';
import '../../assets/css/timeline.scss';
import publishDeviceSetting from './PublishDeviceSettings';
import DateTimePicker from '../common/DateTimePicker';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { renderTimestamp } from '../../utils/webRTC/webRTCwsHandler';

import colors from '../../assets/css/_colors.scss';
import { MdClose, MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';
import ReactSlider from 'react-slider';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import { findTimeZoneFromSelectedValue } from '../../helpers/commonUtils';
import { usePoliciesStore } from '../../store/policiesStore';
import { setSnapshotImage } from '../../store/StreamingStoreIDB';
import { ReactComponent as NoLicenseIcon } from '../../assets/images/icons/Union.svg';
import ImageFetchLoading from '../../pages/cameras/components/ImageFetchLoading';

const clipperDefaults = {
  clipper: {
    x: 518.5,
    y: 0,
    height: '30px',
    width: 102.9,
    defaultDurationInPixels: 102.9,
    minDurationInPixels: 0,
    maxDurationInPixels: 0,
    currentDurationInPixels: 102.9,
    currentDurationInSeconds:
      AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS,
  },
  StartTimeOffsetInSeconds: 15,
  EndTimeOffsetInSeconds: 15,
  clipStartTime: null,
  clipStartTimeError: false,
  clipEndTime: null,
  clipEndTimeError: false,
  isClipStartTimeUTC: false,
  isClipEndTimeUTC: false,
  invalidNewStartTime: false,
  invalidNewEndTime: false,
  showClipper: false,
};

class TimelineControls extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    this.clipperMarkerRef = React.createRef();
    this.clipperRef = React.createRef();
    this.clipperRndRef = React.createRef();
    this.digitalZoomRef = React.createRef();
    // delete later: keeping this for static timeline
    // const currentTime = "November 8, 2022 03:35:52";
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.streaming?.cvrStartDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 2, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 2, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        selectable: false,
      },
      selectedOption: 'Mins',
      activeTime: new Date(currentTime),
      previousActiveTime: new Date(currentTime),
      midPointTime: new Date(currentTime),
      timelineChangeInProgress: false,
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      activeImage: '',
      rawMetaData: [],
      minsView: true,
      secsView: false,
      daysView: false,
      clickAction: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      liveStreamMode: true,
      manualClicked: false,
      fullscreen: false,
      fullscreenBackup: false,
      muteAudio: props?.streaming?.isReloadedStream,
      deviceId: null,
      eventsDataCheck: [],
      eventCategory: [],
      eventTimestamp: null,
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      fakeTime: true,
      customDeviceMetaData: null,
      customEventsDotsData: null,
      holdEventsDotsData: [],
      reloadAsFilterTriggered: props?.streaming?.reloadAsFilterTriggered,
      showDateTimeModal: false,
      selectedDate: new Date(),
      selectedTime: new Date().setHours(0, 0, 0),
      filterdate: '',
      speedIndex: 0,
      showSettings: false,
      mainSettingSelected: null,
      subSettingSelected: {
        2: constants.CAMERA_SETTINGS_OPT_EVENT_BY_EVENT,
        3: constants.CAMERA_SETTINGS_OPT_REGULAR,
      },
      redirectAllSettings: false,
      currentIncidents: [],
      assignedIncidentId: null,
      confirmLoader: false,
      showShareVideoClipModal: false,
      showAddToIncidentModal: false,
      showAddToIncidentError: false,
      showAddToIncidentConfirmationModal: false,
      showAddedToIncident: false,
      showCreateClipModal: false,
      showCreateNewIncidentModal: false,
      showPostCreateClipForm: false,
      showButtonLoader: false,
      submitNewIncidentForm: false,
      isNewIncidentFormComplete: false,
      shouldScrollToTop: false,
      duration: 100,
      ...clipperDefaults,
      timelineWidth: 0,
      timelineMidpoint: 0,
      timelinePixelsPerSecond: 0,
      timePickerStartTimeValue: null,
      timePickerEndTimeValue: null,
      timeEntryErrorMessageType: 0,
      defaultActiveTime: new Date(currentTime),
      tempActiveTime: 0,
      createClipLoader: false,
      assignedClipId: null,
      clipStartTimeErrorMsg: '',
      clipEndTimeErrorMsg: '',
      clipStatus: null,
      clipObject: null,
      clipNewIncidentId: null,
      proceedToIncident: false,
      proceedToClipDetails: false,
      canCreateClip: false,
      createClipRequestSubmitted: false,
      createdClipViewed: false,
      shouldSubmitCreateClipRequest: false,
      shouldResetCreateClipForm: false,
      resetLevel: 1,
      ptzZoom: 0,
      ptzProps: {},
      curDeviceStatus: '',
      capabilities: [],
      deviceStatus: '',
      connectionStatus: '',
      callCountAPI: false,
      cvrStartDate: '',
      selectedScrubSpeedOption: constants.CAMERA_SETTINGS_OPT_EVENT_BY_EVENT,
      showZoomSlider: false,
      digitalZoomPanningState: {},
      storageData: null,
      hideFilmIcon: true,
      jumpedDate: '',
      appliedTagFilters: '',
      digitalZoomSliderValue: 1,
      digitalZoomValue: 1,
      accountAllDevices: [],
      eventsState: false,
      activityStateTimeline: false,
      isLicenseExpire: false,
      selectedEventTime: '',
      isRunningPTZEvent: false,
    };
    this.idleTimer = React.createRef();
    this.settingsWrapper = React.createRef();
    this.settingsIcon = React.createRef();
    this.speedList = ['1X', '2X', '4X', '8X'];
    this.outsideClickSettingPopup = this.outsideClickSettingPopup.bind(this);
    this.exitHandler = this.exitHandler.bind(this);
    this.filterObj = {};
    this.mqttState = devicesMQTTStore.getState();
    this.resourceList = Utils.getDeviceSettingResource(10);
    this.channelTimeStamp = null;
    this.serverAutoPlay = null;
    this.checkTimestamp = null;
    this.isWindowHidden = false;
    this.pauseRequestSent = false;
    this.userPolicies = usePoliciesStore.getState()?.loggedInUserPolicies;
    this.isTimelineScrubbing = false;
    this.minOptionClicked = false;
  }

  exitHandler() {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.exitFullScreen();
    }
  }

  // Component Lifecycle Methods
  componentDidMount = async () => {
    document.addEventListener('click', this.outsideClickSettingPopup);
    document.addEventListener(
      'webkitfullscreenchange',
      this.exitHandler,
      false
    );
    document.addEventListener('mozfullscreenchange', this.exitHandler, false);
    document.addEventListener('fullscreenchange', this.exitHandler, false);
    document.addEventListener('MSFullscreenChange', this.exitHandler, false);
    this.updateTimeZone();
    this.moveTimeline();
    this.updateEventCVRMode();
    this.fetchCurrentIncidents();
    setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
    this.initClipperProperties();
    this.fetchStorageDetail();
    const devices = await getAllDevicesData();
    this.setState({ accountAllDevices: devices });
  };

  componentDidUpdate = (props) => {
    if (
      props?.streaming?.cvrStartDate &&
      props?.streaming?.cvrStartDate !== '' &&
      props?.streaming?.cvrStartDate !== this.state.cvrStartDate
    ) {
      this.setState({
        cvrStartDate: props?.streaming?.cvrStartDate,
      });
    }
    if (document.hidden && this.state.liveStreamMode && !this.isWindowHidden) {
      this.isWindowHidden = true;
    } else if (
      !document.hidden &&
      this.state.liveStreamMode &&
      this.isWindowHidden
    ) {
      clearInterval(this.CVRAutoPlay);
      this.isWindowHidden = false;
      this.moveTimeline();
    }
    if (
      !this.state.liveStreamMode &&
      !this.state.cvrMode &&
      this.state.CVRSnapMode
    ) {
      clearInterval(this.CVRAutoPlay);
    }
    if (this.state.deviceId !== props.deviceId) {
      this.setState({
        deviceId: props?.deviceId,
      });
      this.handleRemoveSnapshotImage();
      // this.props.setSnapshotImage(null);
      this.updateTimeZone();
      this.updateDeviceDetails();
      if (
        this.state.internalEventFromSearch === props.internalEventFromSearch &&
        (useEventsStore.getState()?.eventCVRMode === 'null' ||
          useEventsStore.getState()?.eventCVRMode === false)
      ) {
        this.onGoLive();
      }
    }
    if (this.state.callCountAPI) {
      this.fetchEventsData();
      this.setState({
        callCountAPI: false,
      });
    }

    if (this.state.appliedTagFilters !== this.props?.appliedFilter) {
      this.setState(
        {
          appliedTagFilters: this.props?.appliedFilter,
        },
        () => {
          this.fetchCVRMetaData();
        }
      );
    }

    if (props?.timezone && this.state.timezoneValue !== props.timezone) {
      this.setState({
        timezoneValue: props?.timezone,
      });
      this.updateTimeZone();
    }
    if (this.state.eventCategory !== props.category) {
      this.setState({
        eventCategory: props?.category,
      });
      this.updateEventsPlotting();
    }

    if (
      this.state.eventTimestamp !== props.eventTimestamp &&
      props.eventTimestamp !== null
    ) {
      if (this.state.cvrMode) {
        sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
      }
      this.setState(
        {
          eventTimestamp: props?.eventTimestamp,
        },
        () => {
          setTimeout(() => {
            this.props?.setStreamLoader(false);
            this.goToEvent(props?.eventTimestamp);
          }, 1500);
        }
      );
      if (this.CVRAutoPlay) {
        clearInterval(this.CVRAutoPlay);
      }
      if (
        this.state.internalEventFromSearch !== props.internalEventFromSearch
      ) {
        setTimeout(() => {
          this.setState({
            internalEventFromSearch: props.internalEventFromSearch,
          });
        }, 1000);
      }
    }

    if (this.state.customEventsDotsData !== props.eventDotsData) {
      if (props.eventDotsData !== null && Array.isArray(props.eventDotsData)) {
        this.setState({
          customEventsDotsData: props.eventDotsData,
        });

        let eventClassFilter =
          'face, person, vehicle, licenseplate, explosion, glass-breaking, scream, gun-shot, sound';
        if (this.props.appliedFilter !== '') {
          eventClassFilter = this.props.appliedFilter;
        }

        const output = props.eventDotsData?.map((item) => ({
          eventMeta: item.eventMeta,
          deviceId: item.src?.srcId,
          eventTimestamp: Number(item.t),
        }));
        const allData = [];

        output?.forEach((insideEvent, i) => {
          insideEvent?.eventMeta?.events?.forEach((actualEvent, ij) => {
            actualEvent?.event?.forEach((newEvent, index) => {
              if (
                props.category?.includes(newEvent?.eventType?.toLowerCase()) &&
                eventClassFilter !== '' &&
                eventClassFilter?.includes(newEvent.eventClass.toLowerCase()) &&
                insideEvent.deviceId ===
                  this.props?.account?.deviceInformation?.deviceId
              ) {
                const eData = {
                  id: `${i}-${ij}-${index}`,
                  class: newEvent.eventClass.toLowerCase(),
                  timestamp: insideEvent?.eventTimestamp,
                };
                allData.push(eData);
              }
            });
          });
        });

        if (allData.length > 0) {
          const eventData = allData.map(function (i) {
            return i.class;
          });
          const obj = {
            start: props?.eventDotsData[0]?.t,
            data: eventData,
            type: 'point',
          };

          this.setState({
            holdEventsDotsData: [...this.state.holdEventsDotsData, obj],
          });
        }
      }
    }

    if (
      props.customMetaData &&
      this.state.customDeviceMetaData !== props.customMetaData &&
      !this.isTimelineScrubbing
    ) {
      if (props.customMetaData !== null) {
        const newObj = {
          start: props.customMetaData.start * 1000,
          end: props.customMetaData.end * 1000,
        };
        this.setState({
          customDeviceMetaData: props.customMetaData,
        });
        this.plotUpdatedSlot(newObj);
      }
    }
    if (
      this.state.reloadAsFilterTriggered !==
      props.streaming.reloadAsFilterTriggered
    ) {
      this.setState({
        reloadAsFilterTriggered: props.streaming.reloadAsFilterTriggered,
      });
      this.onPauseCVR();
    }
    if (
      this.props?.account?.deviceInformation?.properties?.Zoom &&
      this.state.resetLevel !==
        this.props?.account?.deviceInformation?.properties?.Zoom
    ) {
      this.setState({
        resetLevel: this.props?.account?.deviceInformation?.properties?.Zoom,
      });
    }
    if (
      Object.prototype.toString.call(
        this.props?.account?.deviceInformation?.properties?.['ptz-zoom']
      ) !== '[object String]' &&
      this.state.ptzZoom !==
        this.props?.account?.deviceInformation?.properties?.['ptz-zoom']
    ) {
      this.setState({
        ptzZoom:
          this.props?.account?.deviceInformation?.properties?.['ptz-zoom'],
      });
      setTimeout(() => {
        this.getPTZZoomStatus(this.state.deviceId);
      }, 3000);
    }

    if (
      !props?.streaming?.endOfStream &&
      this.state.cvrMode &&
      !this.state.liveStreamMode &&
      (this.serverAutoPlay === undefined || this.serverAutoPlay === null)
    ) {
      clearInterval(this.CVRAutoPlay);
      this.moveServerTimeline();
    }

    if (
      !props?.streaming?.endOfStream &&
      this.state.cvrMode &&
      !this.state.liveStreamMode &&
      (this.checkTimestamp === null || this.checkTimestamp === undefined) &&
      !this.isTimelineScrubbing
    ) {
      this.checkChannelTimestamp();
    }

    if (
      props?.streaming?.endOfStream &&
      this.state.cvrMode &&
      this.serverAutoPlay !== null &&
      this.speedList[this.state.speedIndex] === '1X'
    ) {
      this.startClientSideTimer();
    }
    if (props.isLiveStream !== this.state.liveStreamMode) {
      this.props?.setIsLiveStream(this.state.liveStreamMode);
    }
    if (
      Object.keys(this.state.digitalZoomPanningState).length === 0 &&
      this.digitalZoomRef?.current
    ) {
      this.setState({
        digitalZoomPanningState:
          this.digitalZoomRef?.current?.instance?.transformState,
      });
    }

    if (this.state.eventsState !== useEventsStore.getState()?.pauseVideo) {
      this.setState(
        {
          cvrMode: false,
          liveStreamMode: false,
          CVRSnapMode: true,
          eventsState: useEventsStore.getState()?.pauseVideo,
        },
        () => {
          this.onPauseCVR();
        }
      );
    }
    if (this.state.activityStateTimeline !== props.activityStatus) {
      if (props.activityStatus) {
        this.onPauseCVR();
      }
      this.setState({ activityStateTimeline: props.activityStatus });
    }

    if (
      this.props?.licenseExpire !== this.state.isLicenseExpire &&
      this.state.liveStreamMode
    ) {
      this.setState({
        isLicenseExpire: this.props?.licenseExpire,
      });
      if (this.props?.licenseExpire) {
        this.props.setStreamLoader(false);
      }
    }
  };

  handleRemoveSnapshotImage = async () => {
    try {
      // Set the snapshot image to null
      await setSnapshotImage({});
      // Handle any state updates or UI changes here after successful update
    } catch (error) {}
  };

  moveServerTimeline = () => {
    this.serverAutoPlay = setInterval(() => {
      const renderTimeStamp = renderTimestamp();
      if (
        this.channelTimeStamp &&
        renderTimeStamp &&
        this.channelTimeStamp !== renderTimeStamp
      ) {
        this.timeline?.current?.$el?.moveTo(
          moment(Utils.getDate(renderTimeStamp))
        );
        this.channelTimeStamp = renderTimeStamp;
      } else {
        this.channelTimeStamp = renderTimeStamp;
      }
    }, 250);
  };

  checkChannelTimestamp = () => {
    let reConnectionCount = 0;
    let newTimestamp;
    this.checkTimestamp = setInterval(() => {
      this.props?.setEndOfStream(false);
      const renderTimeStamp = renderTimestamp();
      if (renderTimeStamp === newTimestamp) {
        this.props?.setStreamLoader(true);
        this.setState({
          cvrMode: false,
        });
        if (reConnectionCount === 0) {
          reConnectionCount++;
        } else {
          reConnectionCount = 0;
          sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
          setTimeout(() => {
            sendPlayCVR(
              this.state.activeTime,
              this.props?.account?.deviceInformation?.deviceId,
              this.speedList[this.state.speedIndex]
            );
            this.setState({
              CVRSnapMode: false,
              cvrMode: true,
            });
          }, 1500);
        }
      } else {
        reConnectionCount = 0;
        newTimestamp = renderTimeStamp;
        // if (this.props?.streaming?.streamLoader) {
        //   this.props?.setStreamLoader(false);
        // }
        if (!this.state.cvrMode && !this.state.CVRSnapMode) {
          this.setState({
            cvrMode: true,
          });
        }
      }
    }, 8000);
  };

  startClientSideTimer = async () => {
    clearInterval(this.CVRAutoPlay);
    sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
    this.CVRAutoPlay = setInterval(() => {
      this.timeline?.current?.$el?.moveTo(
        moment(this.state.activeTime).add({ seconds: 1 }).toDate()
      );
    }, 1000);
    clearInterval(this.serverAutoPlay);
    clearInterval(this.checkTimestamp);
    this.serverAutoPlay = null;
    this.checkTimestamp = null;
    await this.props?.setChannelTimeStamp(false);
  };

  updateDeviceDetails = () => {
    let deviceDetails = this.props?.account?.deviceInformation;
    const curDeviceStatus = Utils.getDeviceStatus(
      deviceDetails?.deviceStatus,
      deviceDetails?.connectionStatus
    );
    this.setState({
      connectionStatus: deviceDetails?.connectionStatus,
      deviceStatus: deviceDetails?.deviceStatus,
      curDeviceStatus: curDeviceStatus,
    });
    if (deviceDetails?.connectionStatus === 'offline') {
      this.onPauseCVR();
    }
  };

  fetchDeviceData = async () => {
    axios
      .get(
        `device/orgs/${this.props?.account?.deviceInformation?.orgId}/devices/${this.props?.account?.deviceInformation?.deviceId}/v2`,
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            this.getCapabiltiesForDevice(responseData);
          }
        }
      });
  };

  getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        let response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          let responseJson = await response.json();

          deviceData.capDetails = responseJson;
          this.props.setDeviceInformation(deviceData);
          const ptzProps = Utils.getCurrentTabProperty(
            responseJson?.capabilities,
            this.resourceList[0]
          );
          this.setState({
            ptzProps: ptzProps,
            capabilities: responseJson?.capabilities,
          });
          const curDeviceStatus = Utils.getDeviceStatus(
            deviceData?.deviceStatus,
            deviceData?.connectionStatus
          );
          const PTZStatus = Utils.getPTZShowStatus(
            curDeviceStatus,
            'ptz-support',
            ptzProps
          );
          if (PTZStatus) {
            this.getPTZZoomStatus(
              this.props?.account?.deviceInformation?.deviceId
            );
          }
        }
      } catch (error) {
        Utils.vmsLogger().log(error);
      }
    }
  };

  outsideClickSettingPopup = (event) => {
    if (
      this.settingsWrapper?.current?.contains(event?.target) !== undefined &&
      this.state.isRunningPTZEvent
    ) {
      this.setState({
        isRunningPTZEvent: false,
      });
      this.onChangeDeviceSettings('ptz', 'stop');
    }
    if (
      this.settingsWrapper.current &&
      !this.settingsWrapper.current.contains(event.target) &&
      this.settingsIcon.current &&
      !this.settingsIcon.current.contains(event.target)
    ) {
      this.setState({
        showSettings: !this.state.showSettings,
      });
    }
  };

  shouldComponentUpdate = () => {
    return true;
  };

  // Getter Methods
  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 0 };
    const daysAdjustment = { days: 0 };
    const secsAdjustment = { seconds: 0 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState(
      {
        minsMetaData: minsData,
        daysMetaData: daysData,
        secsMetaData: secsData,
      },
      () => {
        switch (this.state.selectedOption) {
          case 'Mins':
            this.updateEventsPlotting();
            break;
          case 'Days':
            this.updateEventsPlottingInRangeDays();
            break;
          case 'Secs':
            this.updateEventsPlottingInRangeSecs();
            break;
          default:
            break;
        }
      }
    );
  };

  getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => a.start - b.start);
    // Initialize the result array with the first object
    const result = [];
    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      if (result.length === 0 && arr[i].start <= endTime) {
        result.push(arr[i]);
      } else {
        const currentAge = arr[i].start;
        // const previousAge = result[result.length - 1].start;
        // Check if the difference is greater than or equal to 3
        if (currentAge <= endTime) {
          result.push(arr[i]);
        }
      }
    }

    return result;
  };

  getNewDurationInSeconds = (startEpochTime, endEpochTime) => {
    if (!startEpochTime || !endEpochTime) {
      return;
    }

    // Convert start and end times to moment objects
    // const startTimeMoment = moment.unix(startEpochTime / 1000); // Convert milliseconds to seconds
    const startTimeMoment = moment(startEpochTime);

    // const endTimeMoment = moment.unix(endEpochTime / 1000); // Convert milliseconds to seconds
    const endTimeMoment = moment(endEpochTime);

    // Calculate the difference between start and end times in seconds
    // return endTimeMoment.diff(startTimeMoment) / 1000;
    return endTimeMoment.diff(startTimeMoment, 'seconds');
  };

  updateEventCVRMode = () => {
    if (!this.props?.eventCVRMode && this.props?.eventTimestamp) {
      this.setState({
        CVRSnapMode: true,
        liveStreamMode: false,
        cvrMode: false,
        manualClicked: true,
      });
    }
  };

  goToEvent = async (timestamp) => {
    const metaData = await this.fetchCVRMetaData(
      Utils.getDate(timestamp / 1000)
    );
    const inRange = this.checkMetaInRange(timestamp, metaData);
    this.updateTimeZone(new Date(timestamp));
    // clear local timer is any
    clearInterval(this.CVRAutoPlay);
    clearInterval(this.checkTimestamp);
    this.checkTimestamp = null;

    this.timeline?.current?.$el.moveTo(Utils.getDate(timestamp / 1000), [
      { duration: 0 },
    ]);
    if (inRange) {
      this.props?.setStreamLoader(true);
      if (this.state.liveStreamMode || this.state.cvrMode) {
        if (this.state.liveStreamMode) {
          disconnectWithMQTT();
        }
        this.setState(
          {
            cvrMode: true,
            liveStreamMode: false,
            liveSnapshot: false,
            CVRSnapMode: false,
            selectedEventTime: timestamp,
          },
          () => {
            sendPlayCVR(
              Utils.getDate(timestamp / 1000),
              this.props?.account?.deviceInformation?.deviceId,
              this.speedList[this.state.speedIndex]
            );
          }
        );
        if (this.props?.streaming?.endOfStream) {
          this.props?.setEndOfStream(false);
        }
      } else {
        this.setState(
          {
            CVRSnapMode: true,
            liveSnapshot: false,
          },
          () => {
            this.props?.setStreamLoader(false);
          }
        );
      }
    } else {
      this.props?.setEndOfStream(true);
    }
    setTimeout(() => {
      clearInterval(this.CVRAutoPlay);
    }, 1500);
  };

  checkMetaInRange = (timestamp, metaArray) => {
    let start = 0;
    let end = metaArray.length - 1;

    while (start <= end) {
      let middle = Math.floor((start + end) / 2);

      if (
        Math.trunc(timestamp / 1000) >=
          Math.trunc(metaArray[middle].start / 1000) &&
        Math.trunc(timestamp / 1000) < Math.trunc(metaArray[middle].end / 1000)
      ) {
        return true;
      } else if (metaArray[middle].end < timestamp) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }
    return false;
  };

  updateTimeZone = (selectedDate) => {
    const getLocationSelected = findTimeZoneFromSelectedValue(
      this?.props?.hubDetails?.properties?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();

    const UTCDate = moment(selectedDate ? selectedDate : new Date())
      .tz(zone)
      .format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = (isGolive) => {
    const liveTime = new Date();
    if (this.state.liveStreamMode || isGolive) {
      clearInterval(this.CVRAutoPlay);
      this.setState(
        {
          activeTime: liveTime,
        },
        () => {
          if (this.state.selectedOption === 'Days') {
            this.CVRAutoPlay = setInterval(() => {
              this.timeline?.current?.$el?.moveTo(
                moment(new Date()).add({ seconds: 1 }).toDate()
              );
            }, 1000);
          } else {
            this.CVRAutoPlay = setInterval(() => {
              this.timeline?.current?.$el?.moveTo(
                moment(new Date()).add({ seconds: 1 }).toDate()
              );
            }, 1000);
          }
        }
      );
    }
  };

  componentWillUnmount() {
    document.removeEventListener(
      'webkitfullscreenchange',
      this.exitHandler,
      false
    );
    document.removeEventListener(
      'mozfullscreenchange',
      this.exitHandler,
      false
    );
    document.removeEventListener('fullscreenchange', this.exitHandler, false);
    document.removeEventListener('MSFullscreenChange', this.exitHandler, false);
    this.setState({
      internalEventFromSearch: false,
    });
    useEventsStore.setState({
      pauseVideo: false,
      videoFilteredDateObj: {
        filterdate: new Date().getTime(),
        selectedDate: new Date(),
        selectedTime: new Date().setHours(0, 0, 0),
        isToJumpDate: false,
      },
    });
    this.props?.setStreamLoader(false);
    clearInterval(this.CVRAutoPlay);
    clearInterval(this.serverAutoPlay);
    clearInterval(this.checkTimestamp);
    this.checkTimestamp = null;
    document.removeEventListener('click', this.outsideClickSettingPopup);
    if (this.state.cvrMode) {
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
    }
  }

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 2, seconds: 21 }).toDate(),
        min: moment(this.state.cvrStartDate)
          .subtract({ minutes: 2, seconds: 21 })
          .toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
        min: moment(this.state.cvrStartDate)
          .subtract({ days: 1, hours: 6, seconds: 1 })
          .toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
        min: moment(this.state.cvrStartDate).subtract({ seconds: 41 }).toDate(),
      });
    }
  };

  fetchCVRMetaData = async (eventTime) => {
    let minOptions;
    if (
      this.state.selectedOption === 'Mins' ||
      this.state.selectedOption === 'Secs'
    ) {
      minOptions = { minutes: 3 };
    } else if (this.state.selectedOption === 'Days') {
      minOptions = { days: 1 };
    }
    let startTime = new Date(
      moment(eventTime ? eventTime : this.state.activeTime).subtract(minOptions)
    ).getTime();

    if (this.state.cvrStartDate !== '' && startTime < this.state.cvrStartDate) {
      startTime = this.state.cvrStartDate;
    }

    const endTime = new Date(
      moment(eventTime ? eventTime : this.state.activeTime).add(minOptions)
    ).getTime();

    if (startTime && endTime) {
      const response = await axios.get(
        `timeline/device/${
          this.props?.account?.deviceInformation?.deviceId
        }/metadata?startTime=${
          startTime < this.state.cvrStartDate
            ? this.state.cvrStartDate
            : startTime
        }&endTime=${endTime}`,
        Utils.requestHeader()
      );
      if (response?.data?.data) {
        const metaData = response.data.data;
        this.props?.setLastMetaTimeStamp(metaData?.[metaData.length - 1]?.end);
        this.setState(
          {
            rawMetaData: metaData,
            jumpedDate: moment(
              eventTime ? eventTime : this.state.activeTime
            ).add(minOptions),
          },
          () => {
            this.fetchEventsData(eventTime);
          }
        );
        this.getItemsMetaData(metaData);
        return metaData;
      }
    }
  };

  // API GET Request Methods
  fetchStorageDetail = async () => {
    if (this.props?.account?.deviceInformation?.orgId) {
      try {
        const res = await axios.get(
          `partner/orgs/${this.props?.account?.deviceInformation?.orgId}/storageData`,
          Utils.requestHeader()
        );

        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          this.setState({ storageData: responseData?.data }, () => {
            if (responseData?.data && responseData?.data.allocatedStorage) {
              this.setState({
                hideFilmIcon:
                  responseData.data.usedStorage >=
                  responseData.data.allocatedStorage
                    ? true
                    : false,
              });
            }
          });
        } else {
          if (res?.code) {
            Utils.vmsLogger().error(`${res.code}: ${res.message}`);
          } else if (responseData?.data) {
            Utils.vmsLogger().error(responseData?.data?.userMsg);
          }
        }
      } catch (error) {
        Utils.vmsLogger().error(error);
      }
    }
  };

  fetchEventsData = (moveTime) => {
    const timeOptions =
      this.state?.selectedOption?.toUpperCase() === 'MINS'
        ? { minutes: 3 }
        : this.state?.selectedOption?.toUpperCase() === 'DAYS'
        ? { days: 1 }
        : this.state?.selectedOption?.toUpperCase() === 'SECS'
        ? { seconds: 120 }
        : { minutes: 3 };

    const endTime = Utils.getUnixDate(
      moment(moveTime ? moveTime : this.state.activeTime).add(timeOptions)
    );
    const startTime = Utils.getUnixDate(
      moment(moveTime ? moveTime : this.state.activeTime).subtract(timeOptions)
    );

    if (
      startTime &&
      startTime > 0 &&
      endTime &&
      this.props?.account?.deviceInformation?.orgId &&
      this.props?.account?.deviceInformation?.deviceId
    ) {
      axios
        .get(
          this.generateEventCountURL(endTime, startTime),
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];

            let timeAdjustment;
            if (this.state?.selectedOption?.toUpperCase() === 'MINS') {
              timeAdjustment = { minutes: 0 };
            } else if (this.state?.selectedOption?.toUpperCase() === 'DAYS') {
              timeAdjustment = { days: 0 };
            } else if (this.state?.selectedOption?.toUpperCase() === 'SECS') {
              timeAdjustment = { seconds: 0 };
            }

            const massagedData = getEventsScubberData(
              finalData,
              timeAdjustment
            );
            const filteredData = [];
            massagedData.forEach((eventData) => {
              this.state.rawMetaData.forEach((meta) => {
                if (
                  eventData?.start >= meta?.start &&
                  eventData?.start <= meta?.end
                ) {
                  filteredData.push(eventData);
                }
              });
            });
            this.setState(
              {
                eventsDataCheck: filteredData,
              },
              () => {
                if (this.state?.selectedOption?.toUpperCase() === 'MINS') {
                  this.updateEventsPlottingInRange(filteredData);
                } else if (
                  this.state?.selectedOption?.toUpperCase() === 'DAYS'
                ) {
                  this.updateEventsPlottingInRangeDays(filteredData);
                } else if (
                  this.state?.selectedOption?.toUpperCase() === 'SECS'
                ) {
                  this.updateEventsPlottingInRangeSecs(filteredData);
                }
              }
            );
          }
        });
    }
  };

  generateEventCountURL = (endTime, startTime) => {
    let aggByOption = '';
    if (this.state?.selectedOption?.toUpperCase() === 'MINS') {
      aggByOption = 'minute';
    } else if (this.state?.selectedOption?.toUpperCase() === 'DAYS') {
      aggByOption = 'day';
    } else if (this.state?.selectedOption?.toUpperCase() === 'SECS') {
      aggByOption = 'second';
    }

    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';

    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }

    const eventCountURL = `timeline/orgs/${
      this.props?.account?.deviceInformation?.orgId
    }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${
      this.props?.account?.deviceInformation?.deviceId
    }&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
      vehicleFilter ?? ''
    }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
      soundFilter ?? ''
    }&eventClasses=${faceFilter ?? ''}&aggBy=${aggByOption}&bottomLeftX=${
      this.props?.snapshotCoordinates?.bottomLeftX ?? ''
    }&bottomLeftY=${
      this.props?.snapshotCoordinates?.bottomLeftY ?? ''
    }&topRightX=${this.props?.snapshotCoordinates?.topRightX ?? ''}&topRightY=${
      this.props?.snapshotCoordinates?.topRightY ?? ''
    }`;

    return eventCountURL;
  };

  fetchCurrentIncidents = async () => {
    try {
      let incidents;

      const res = await axios.get(
        `/incident/orgs/${this.props?.account?.deviceInformation?.orgId}/incidents`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      // Set incidents if request is successful
      if (responseData?.meta?.code === 200) {
        incidents = responseData?.data?.incidents;
        if (Array.isArray(incidents) && incidents.length > 0) {
          // Sort incidents by incident summary in ascending order
          // and then save to state
          this.setState({
            currentIncidents: [
              ...incidents.sort((a, b) => {
                if (a?.summary?.toLowerCase() > b?.summary?.toLowerCase()) {
                  return 1;
                }

                if (a?.summary?.toLowerCase() < b?.summary?.toLowerCase()) {
                  return -1;
                }

                return 0;
              }),
            ],
          });
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  onClick = (dates, currentDate, type) => {
    const data = dateClosest(dates, currentDate);
    if (type === 'previous') {
      const previousTime = data.datesAfter[0].date;
      this.timeline.current.$el.moveTo(new Date(previousTime));
    } else {
      const nextTime = data.datesBefore[0].date;
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onSkipChanges = (nextPreviousClick) => {
    sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
    let skipSeconds = 0;
    if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_5SEC_SKIP
    ) {
      skipSeconds = 5;
    } else if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_10SEC_SKIP
    ) {
      skipSeconds = 10;
    } else if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_20SEC_SKIP
    ) {
      skipSeconds = 20;
    } else if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_30SEC_SKIP
    ) {
      skipSeconds = 30;
    }
    clearInterval(this.CVRAutoPlay);
    if (nextPreviousClick === 'nextClicked') {
      let currentTime = moment(this.state.activeTime)
        .add({ seconds: skipSeconds })
        .toDate();
      this.setState({
        activeTime: moment(this.state.activeTime)
          .add({ seconds: skipSeconds })
          .toDate(),
      });
      this.timeline.current.$el.moveTo(
        moment(this.state.activeTime).add({ seconds: skipSeconds }).toDate()
      );
      if (this.state.cvrMode) {
        setTimeout(() => {
          sendPlayCVR(
            currentTime,
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex]
          );
        }, 1000);
      }
    } else {
      let currentTime = moment(this.state.activeTime)
        .subtract({ seconds: skipSeconds })
        .toDate();
      this.setState({
        activeTime: moment(this.state.activeTime)
          .subtract({ seconds: skipSeconds })
          .toDate(),
      });
      this.timeline.current.$el.moveTo(
        moment(this.state.activeTime)
          .subtract({ seconds: skipSeconds })
          .toDate()
      );
      if (this.state.cvrMode) {
        setTimeout(() => {
          sendPlayCVR(
            currentTime,
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex]
          );
        }, 1000);
      }
    }
  };

  onPreviousIconClick = () => {
    if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_EVENT_BY_EVENT
    ) {
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
      const events = [...this.state.eventsDataCheck];
      const activeScrubber = Utils.getUnixDate(this.state.activeTime);
      this.setState({
        liveSnapshot: false,
        moveTimelineImage: false,
      });
      let result = events?.map((a) => Utils.getUnixDate(a.start));
      const data = dateClosest(result, activeScrubber);
      if (data && data.datesAfter && data.datesAfter.length) {
        const nextTime = data?.datesAfter?.[0]?.date;
        if (this.state.cvrMode) {
          this.props?.setStreamLoader(true);
          setTimeout(() => {
            this.timeline.current.$el.moveTo(new Date(nextTime));
            sendPlayCVR(
              nextTime,
              this.props?.account?.deviceInformation?.deviceId,
              this.speedList[this.state.speedIndex]
            );
          }, 1500);
        } else if (!this.state.cvrMode) {
          this.timeline.current.$el.moveTo(new Date(nextTime));
        }
      }
    } else {
      this.onSkipChanges('previousClicked');
    }
  };

  onNextIconClick = () => {
    if (
      this.state.selectedScrubSpeedOption ===
      constants.CAMERA_SETTINGS_OPT_EVENT_BY_EVENT
    ) {
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
      const events = [...this.state.eventsDataCheck];
      const activeScrubber = Utils.getUnixDate(this.state.activeTime);
      this.setState({
        liveSnapshot: false,
        moveTimelineImage: false,
      });
      let result = events?.map((a) => Utils.getUnixDate(a.start));
      const data = dateClosest(result, activeScrubber);
      if (data && data.datesBefore && data.datesBefore.length) {
        const nextTime = data?.datesBefore?.[0]?.date;
        if (this.state.cvrMode) {
          setTimeout(() => {
            this.timeline.current.$el.moveTo(new Date(nextTime));
            sendPlayCVR(
              nextTime,
              this.props?.account?.deviceInformation?.deviceId,
              this.speedList[this.state.speedIndex]
            );
          }, 1500);
        } else if (!this.state.cvrMode) {
          this.timeline.current.$el.moveTo(new Date(nextTime));
        }
      }
    } else {
      this.onSkipChanges('nextClicked');
    }
  };

  onMuteIconClick = () => {
    const audioElem = document.getElementById('audio');
    if (audioElem) {
      if (this.state.muteAudio) {
        audioElem.play();
      } else {
        audioElem.pause();
      }
    }

    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onClickMins = () => {
    this.minOptionClicked = true;
    switch (this.state.selectedOption?.toUpperCase()) {
      case 'MINS':
        this.setState(
          {
            minsView: false,
            daysView: true,
            secsView: false,
            selectedOption: 'Days',
            clickAction: true,
          },
          () => {
            this.fetchEventsData();
          }
        );
        break;

      case 'DAYS':
        this.setState(
          {
            minsView: false,
            daysView: false,
            secsView: true,
            selectedOption: 'Secs',
            clickAction: true,
          },
          () => {
            this.fetchEventsData();
            this.moveTimeline();
          }
        );
        break;

      case 'SECS':
        // Switch back to "Mins" option
        this.enableMinutesOption();
        break;

      default: // there is no default option
    }

    setTimeout(() => {
      this.minOptionClicked = false;
      if (!this.state.cvrMode && !this.state.liveStreamMode) {
        this.timeline.current.$el.moveTo(
          moment(this.state.activeTime).toDate()
        );
      }
    }, 2000);
    setTimeout(() => {
      this.setState({
        clickAction: false,
      });
    }, 300);
  };

  onGoLive = async () => {
    this.handleRemoveSnapshotImage();
    this.updateTimeZone();
    if (this.state.cvrMode || this.state.CVRSnapMode) {
      // Clear all playback related interval
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId); // Send Stop playback web socket request
      clearInterval(this.serverAutoPlay);
      clearInterval(this.checkTimestamp);
      this.checkTimestamp = null;
      this.serverAutoPlay = null;
      this.props.setChannelTimeStamp(false);
    }
    const liveTime = new Date(); // Set live time to move timeline
    this.setState({
      liveStreamMode: true,
      manualClicked: false,
      filterdate: null,
      selectedDate: new Date(),
      selectedTime: new Date().setHours(0, 0, 0),
      activeTime: liveTime,
    });
    // this.timeline.current.$el.moveTo(liveTime);

    useEventsStore.setState({
      videoFilteredDateObj: {
        filterdate: new Date().getTime(),
        selectedDate: new Date(),
        selectedTime: new Date().setHours(0, 0, 0),
        isToJumpDate: false,
      },
      selectedTimestamp: null,
    });

    if (
      (this.state.CVRSnapMode || this.state.cvrMode) &&
      !this.state.liveStreamMode
    ) {
      this.moveTimeline(true);
      this.setState(
        {
          CVRSnapMode: false,
          cvrMode: false,
        },
        () => {
          // in live mode endOfStream should false as it is specific to playback
          this.props?.setEndOfStream(false);
        }
      );
    }
    const deviceDetails = this.props?.account?.deviceInformation;
    const displayDeviceStatus = Utils.getDeviceStatus(
      deviceDetails?.deviceStatus,
      deviceDetails?.connectionStatus
    );
    if (
      displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
      !this.state.isLicenseExpire
    ) {
      this.props?.setStreamLoader(true);
      // do not pass offer when device connection status is offline
      this.props.handleClick(constants.DEVICES_SWITCH_LIVE_TITLE);
    }
    await this.fetchCVRMetaData(new Date());
  };

  onPauseCVR = (e) => {
    if (this.props?.streaming?.streamLoader) this.props?.setStreamLoader(false);
    if (this.props?.streaming?.endOfStream) this.props?.setStreamLoader(false);
    clearInterval(this.CVRAutoPlay);
    if (this.state.cvrMode) {
      clearInterval(this.CVRAutoPlay);
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
      this.setState({
        cvrMode: false,
        CVRSnapMode: true,
      });

      clearInterval(this.serverAutoPlay);
      clearInterval(this.checkTimestamp);
      this.checkTimestamp = null;
      this.serverAutoPlay = null;
    }
    if (this.state.liveStreamMode) {
      disconnectWithMQTT();
      this.setState({
        CVRSnapMode: true,
        liveStreamMode: false,
        liveSnapshot: true,
      });
    }
    this.setState({
      manualClicked: true,
    });

    // this.fetchCVRMetaData();
  };

  onPlayCVR = async () => {
    this.props?.setStreamLoader(true);
    this.setState(
      {
        cvrMode: true,
        liveStreamMode: false,
        CVRSnapMode: false,
        selectedEventTime: Utils.getUnixDate(this.state.activeTime) * 1000,
      },
      async () => {
        const metaData = await this.fetchCVRMetaData();
        const timestamp = new Date(this.state.activeTime).getTime();
        const inRange = this.checkMetaInRange(timestamp, metaData);
        // when click on play check whether metadata is available for that time or not
        if (inRange) {
          this.props?.setStreamLoader(true);
          this.props?.setEndOfStream(false);
          sendPlayCVR(
            this.state.activeTime,
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex]
          );
        } else {
          this.props?.setStreamLoader(false);
          this.props?.setEndOfStream(true);
          this.startClientSideTimer();
        }
      }
    );
  };

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 2, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();

    if (event.byUser && this.state.fakeTime) {
      if (!this.isTimelineScrubbing) {
        this.isTimelineScrubbing = true;
        this.props?.setStreamLoader(false);
      }
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
          defaultActiveTime: timeInMin,
          midPointTime: timeInMin,
        });

        // Was timeline being scrolled
        if (this.state.timelineChangeInProgress === false) {
          // Save previous midpoint time
          this.setState({
            previousActiveTime: this.state.midPointTime,
          });
          // Signal start of timeline scroll
          this.setState({
            timelineChangeInProgress: true,
          });
        }
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
          defaultActiveTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
          defaultActiveTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        disconnectWithMQTT();
        this.setState({
          liveStreamMode: false,
        });
      }
      if (!this.pauseRequestSent) {
        this.pauseRequestSent = true;
        sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
      }
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        const newPreviousMidPointTime = moment(
          this.state.midPointTime
        ).toDate();

        this.setState({
          previousActiveTime: newPreviousMidPointTime,
        });
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
          defaultActiveTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
          midPointTime: moment(event.start)
            .add({ minutes: 2, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
          defaultActiveTime: moment(event.start)
            .add({ days: 1, hours: 6 })
            .toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
          defaultActiveTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  hideShowdateTimeModal = (status) => {
    this.setState({
      showDateTimeModal: status,
    });
  };

  onChangeSpeed = () => {
    let newSpeedIndex;
    if (this.state.speedIndex === this.speedList.length - 1) {
      newSpeedIndex = 0;
    } else {
      newSpeedIndex = this.state.speedIndex + 1;
    }
    sendSpeedCommand(this.speedList[newSpeedIndex]);

    this.setState({
      speedIndex: newSpeedIndex,
    });
  };

  onClickSettingsIcon = () => {
    if (!this.state?.ptzProps?.['ptz-support']) {
      this.fetchDeviceData();
    }
    this.setState({
      showSettings: !this.state.showSettings,
      mainSettingSelected: null,
    });
  };

  onClickSetting = (id) => {
    setTimeout(() => {
      if (id === 4) {
        this.setState({
          redirectAllSettings: true,
        });
      } else {
        this.setState({
          mainSettingSelected: id,
        });
      }
    }, 100);
  };

  onClickBackToSettings = () => {
    setTimeout(() => {
      this.setState({
        mainSettingSelected: null,
      });
    }, 100);
  };

  onClickSubOption = (suboption, id) => {
    let subsettings = this.state.subSettingSelected;
    subsettings[id] = suboption;
    this.setState({
      subSettingSelected: subsettings,
    });

    if (id === 3) {
      this.onChangeDeviceSettings('fisheye', suboption);
    }

    if (id === 2) {
      this.setState({
        selectedScrubSpeedOption: suboption,
      });
    }
  };

  onChangeDeviceSettings = (type, action, degree) => {
    const mqttState = devicesMQTTStore.getState();
    const changeRequestInfo = {
      deviceId: this.state.deviceId,
      hubId: this.props?.hubId,
      accountId: this.props?.account.accountId,
      sessionId: mqttState.sessionId,
      properties: {},
    };
    if (type === 'ptz') {
      changeRequestInfo[
        'resource'
      ] = `ch/${this.state.deviceId}/${this.resourceList[0]}`;
      if (action !== 'stop' && action !== 'reset') {
        this.setState({
          isRunningPTZEvent: true,
        });
      } else {
        this.setState({
          isRunningPTZEvent: false,
        });
      }
      if (action === 'step' || action === 'start') {
        changeRequestInfo.properties = {
          'ptz-pt': action,
          'ptz-degree': degree.toString(),
        };
      } else if (action === 'stop') {
        changeRequestInfo.properties = {
          'ptz-pt': action,
        };
      } else if (action === 'reset') {
        changeRequestInfo.properties = {
          'ptz-zoom-reset': 'true',
        };
      } else if (action === 'zoomIn') {
        changeRequestInfo.properties = {
          'ptz-zoom': '+1',
        };
      } else if (action === 'zoomOut') {
        changeRequestInfo.properties = {
          'ptz-zoom': '-1',
        };
      }
    } else if (type === 'fisheye') {
      changeRequestInfo[
        'resource'
      ] = `ch/${this.state.deviceId}/${this.resourceList[1]}`;
      changeRequestInfo.properties = {
        'ptz-fish-eye': action,
      };
    }
    publishDeviceSetting(changeRequestInfo);
  };

  getPTZZoomStatus = (deviceId) => {
    const mqttState = devicesMQTTStore.getState();
    const tid = Math.floor(new Date().getTime());
    const context = {
      topic: `a/notify/${this.props?.hubId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: this.props?.hubId,
        from: `app`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/${this.resourceList[0]}`,
          properties: {
            'ptz-current-state': 'true',
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState?.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  showMainOption = (option) => {
    const ptzPermission = Utils.getPTZShowStatus(
      this.state.curDeviceStatus,
      'ptz-support',
      this.state.ptzProps
    );
    const ptzDisabled =
      (option.id === 1 && !this.state.liveStreamMode) ||
      (option.id === 1 && !ptzPermission);
    const scrubSpeedDisabled = option.id === 2 && this.state.liveStreamMode;
    const settingsDisabled =
      option.id === 4 && !this.userPolicies.view_device_settings;
    return (
      <div
        className={`option-details ${
          ptzDisabled || scrubSpeedDisabled || settingsDisabled
            ? 'option-details-disabled'
            : ''
        }`}
        onClick={() => {
          if (ptzDisabled || scrubSpeedDisabled || settingsDisabled) return;
          this.onClickSetting(option.id);
        }}
        key={option.id}
        disabled={ptzDisabled || scrubSpeedDisabled || settingsDisabled}
      >
        <div>
          {option.icon ? (
            <img
              src={
                ptzDisabled || scrubSpeedDisabled || settingsDisabled
                  ? option?.disabledIcon
                  : option.icon
              }
              alt="icon"
              className="icon"
            />
          ) : null}
          {option.name}
        </div>
        <div>
          {this.state.subSettingSelected[option.id]}
          <IoIosArrowForward
            size={12}
            color={colors.brand_white}
            className="arrow-forward"
          />
        </div>
      </div>
    );
  };

  showSettingOptions = () => {
    return (
      <div
        className={`setting-options-wrapper ${
          this.state.mainSettingSelected === 1
            ? 'setting-ptz-right'
            : 'setting-opt-right'
        }`}
        ref={this.settingsWrapper}
      >
        <div className="setting-options">
          {SettingOptions.map((option) =>
            !this.state.mainSettingSelected ? (
              this.showMainOption(option)
            ) : (
              <div key={option.id}>
                {this.state.mainSettingSelected === option.id &&
                option.id !== 1 ? (
                  <div className="suboption-details">
                    <div
                      className="back"
                      onClick={() => this.onClickBackToSettings()}
                    >
                      <IoIosArrowBack
                        size={12}
                        color={colors.brand_white}
                        className="arrow-back"
                      />
                      {constants.CAMERA_SETTINGS_BACK}
                    </div>
                    {option.subOptions &&
                      option.subOptions.map((subOption) => (
                        <div
                          className={`suboption ${
                            subOption ===
                            this.state.subSettingSelected[option.id]
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() =>
                            this.onClickSubOption(subOption, option.id)
                          }
                        >
                          {subOption}
                        </div>
                      ))}
                  </div>
                ) : null}
                {this.state.mainSettingSelected === option.id &&
                option.id === 1 ? (
                  <div className="suboption-details">
                    <div
                      className="back-1"
                      onClick={this.onClickBackToSettings}
                    >
                      <IoIosArrowBack
                        size={12}
                        color={colors.brand_white}
                        className="arrow-back"
                      />
                      {constants.CAMERA_SETTINGS_BACK}
                    </div>
                  </div>
                ) : null}
              </div>
            )
          )}
        </div>
        {this.state.mainSettingSelected === 1 ? (
          <div className="ptz-settings">
            <div className="outer-circle">
              <div className="circle">
                <img src={ptzCircles} alt="" />
              </div>
              <div
                className="arrow-circle up"
                //TODO : remove commented code
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 90)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 90)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <IoIosArrowUp size={12} className="arrow" />
              </div>
              <div
                className="arrow-circle down"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 270)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 270)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <IoIosArrowDown size={12} className="arrow" />
              </div>
              <div
                className="arrow-circle right"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 0)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 0)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <IoIosArrowForward size={12} className="arrow" />
              </div>
              <div
                className="arrow-circle left"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 180)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 180)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <IoIosArrowBack size={12} className="arrow" />
              </div>
              <div
                className="solid-circle-cover up"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 45)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 45)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <div className="solid-circle" />
              </div>
              <div
                className="solid-circle-cover down"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 225)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 225)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <div className="solid-circle" />
              </div>
              <div
                className="solid-circle-cover right"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 315)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 315)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <div className="solid-circle" />
              </div>
              <div
                className="solid-circle-cover left"
                // onClick={() => this.onChangeDeviceSettings('ptz', 'step' , 135)}
                onMouseDown={() =>
                  this.onChangeDeviceSettings('ptz', 'start', 135)
                }
                // onMouseUp={() => this.onChangeDeviceSettings('ptz', 'stop')}
              >
                <div className="solid-circle" />
              </div>
            </div>
            <div style={{ marginRight: '10px', width: '30px' }}>
              {Utils.getPTZShowStatus(
                this.state.curDeviceStatus,
                'ptz-zoom',
                this.state.ptzProps
              ) && (
                <div className="zoom-section">
                  <BiZoomIn
                    size={16}
                    color={colors.brand_white}
                    // onClick={() => this.onChangeDeviceSettings('ptz', 'zoomIn')}
                    onMouseDown={() => {
                      if (
                        !this.state.ptzProps['ptz-zoom-range'] ||
                        this.state.resetLevel >=
                          Number(this.state.ptzProps['ptz-zoom-range']?.max)
                      ) {
                        return;
                      }
                      this.onChangeDeviceSettings('ptz', 'zoomIn');
                    }}
                    // onMouseUp={() => {
                    //   if (
                    //     !this.state.ptzProps['ptz-zoom-range'] ||
                    //     this.state.resetLevel >=
                    //       Number(this.state.ptzProps['ptz-zoom-range']?.max)
                    //   ) {
                    //     return;
                    //   }
                    //   this.onChangeDeviceSettings('ptz', 'stop');
                    // }}
                    className={`reset ${
                      this.state.ptzProps['ptz-zoom-range'] &&
                      this.state.resetLevel <
                        Number(this.state.ptzProps['ptz-zoom-range']?.max)
                        ? 'reset-active'
                        : 'reset-disable'
                    }`}
                    disabled={
                      !this.state.ptzProps['ptz-zoom-range'] ||
                      this.state.resetLevel >=
                        Number(this.state.ptzProps['ptz-zoom-range']?.max)
                    }
                  />
                  <BiZoomOut
                    size={16}
                    color={colors.brand_white}
                    // onClick={() => this.onChangeDeviceSettings('ptz', 'zoomOut')}
                    onMouseDown={() => {
                      if (
                        !this.state.ptzProps['ptz-zoom-range'] ||
                        this.state.resetLevel <=
                          Number(this.state.ptzProps['ptz-zoom-range']?.min)
                      ) {
                        return;
                      }
                      this.onChangeDeviceSettings('ptz', 'zoomOut');
                    }}
                    // onMouseUp={() => {
                    //   if (
                    //     !this.state.ptzProps['ptz-zoom-range'] ||
                    //     this.state.resetLevel <=
                    //       Number(this.state.ptzProps['ptz-zoom-range']?.min)
                    //   ) {
                    //     return;
                    //   }
                    //   this.onChangeDeviceSettings('ptz', 'stop');
                    // }}
                    className={`reset ${
                      this.state.ptzProps['ptz-zoom-range'] &&
                      this.state.resetLevel >
                        Number(this.state.ptzProps['ptz-zoom-range']?.min)
                        ? 'reset-active'
                        : 'reset-disable'
                    }`}
                    disabled={
                      !this.state.ptzProps['ptz-zoom-range'] ||
                      this.state.resetLevel <=
                        Number(this.state.ptzProps['ptz-zoom-range']?.min)
                    }
                  />
                </div>
              )}
              {Utils.getPTZShowStatus(
                this.state.curDeviceStatus,
                'ptz-zoom-reset',
                this.state.ptzProps
              ) && (
                <div
                  className={`reset ${
                    this.state.ptzProps['ptz-zoom-range'] &&
                    this.state.resetLevel >
                      Number(this.state.ptzProps['ptz-zoom-range']?.min)
                      ? 'reset-active'
                      : 'reset-disable'
                  }`}
                  onClick={() => {
                    if (
                      !this.state.ptzProps['ptz-zoom-range'] ||
                      this.state.resetLevel <=
                        Number(this.state.ptzProps['ptz-zoom-range']?.min)
                    ) {
                      return;
                    }
                    this.onChangeDeviceSettings('ptz', 'reset');
                  }}
                >
                  {constants.CAMERA_SETTINGS_RESET_ZOOM}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  showTimeEntryError = () => {
    let errorNum = this.state.timeEntryErrorMessageType,
      errorMessage;

    if (isNaN(errorNum)) return;

    switch (parseInt(errorNum)) {
      // New start time is not in view
      case 1:
        errorMessage =
          constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_ENTERED_TIME_NOT_IN_VIEW_ERROR;
        break;

      // New start time is after current end time
      case 2:
        errorMessage = constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_START_TIME_ERROR;
        break;

      // New end time is beyond the time of last recorded chunk
      case 3:
        errorMessage =
          constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_END_TIME_NOT_IN_VIEW_ERROR;
        break;

      // New end time is before current start time
      case 4:
        errorMessage = constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_END_TIME_ERROR;
        break;

      // Duration is more than 240 seconds
      case 5:
        errorMessage =
          constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_EXCEED_MAX_DURATION_TIME_ERROR;
        break;

      case 6:
        errorMessage =
          constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_LESS_THAN_MIN_DURATION_TIME_ERROR;
        break;

      default:
        errorMessage = constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_GENERIC_ERROR;
    }

    return errorMessage;
  };

  onSubmit = async (data) => {
    this.props?.setStreamLoader(true);
    this.filterObj = data;
    let date = data.filterdate;
    if (
      this.state.cvrStartDate !== '' &&
      date.getTime() < this.state.cvrStartDate
    ) {
      date = moment(this.state.cvrStartDate).toDate();
    }
    let parsed = moment
      .tz(moment(date, 'YYYY/MM/DD hh:mm:ss A'), this.state.timeZone)
      .format('MMMM Do, YYYY - hh:mm:ss A z');

    this.setState({
      selectedTime: data.selectedTime,
      selectedDate: data.selectedDate,
      showDateTimeModal: false,
      filterdate: parsed,
    });

    if (this.state.fullscreenBackup) {
      this.enterFullScreen();
      this.setState({
        fullscreenBackup: false,
      });
    }

    const metaData = await this.fetchCVRMetaData(
      Utils.getDate(date.getTime() / 1000)
    );
    const inRange = this.checkMetaInRange(date.getTime(), metaData);

    if (inRange) {
      this.jumpToDate(date.getTime());
    } else {
      if (metaData.length && metaData[0].start < date.getTime()) {
        this.checkMissingData(date.getTime(), true);
      } else {
        this.getMetaDataByFilterdate(date.getTime(), false);
      }
    }
  };

  jumpToDate = (date) => {
    if (this.state.cvrMode) {
      sendPauseCVR(this.props?.account?.deviceInformation?.deviceId);
    }
    if (this.state.liveStreamMode) disconnectWithMQTT();
    clearInterval(this.checkTimestamp);
    this.checkTimestamp = null;
    useEventsStore.setState({
      videoFilteredDateObj: {
        filterdate: date,
        selectedDate: this.filterObj.selectedDate,
        selectedTime: this.filterObj.selectedTime,
        isToJumpDate: true,
      },
    });
    this.updateTimeZone(Utils.getDate(date / 1000));
    if (this.state.activeTime !== Utils.getDate(date / 1000)) {
      this.timeline.current.$el.moveTo(Utils.getDate(date / 1000), [
        { duration: 0 },
      ]);
    }
    if (this.state.liveStreamMode || this.state.cvrMode) {
      this.setState(
        {
          cvrMode: true,
          liveStreamMode: false,
          liveSnapshot: false,
          CVRSnapMode: false,
          selectedEventTime: date,
        },
        () => {
          sendPlayCVR(
            moment(Utils.getDate(date)),
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex],
            true
          );
        }
      );
    } else {
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
      });
      this.props?.setStreamLoader(false);
    }

    if (
      this.state.internalEventFromSearch !== this.props.internalEventFromSearch
    ) {
      setTimeout(() => {
        this.setState({
          internalEventFromSearch: this.props.internalEventFromSearch,
        });
      }, 1000);
    }
  };

  getMetaDataByFilterdate = async (date, isFromPlay) => {
    await axios
      .get(
        `timeline/device/${
          this.props?.account?.deviceInformation?.deviceId
        }/metadata?startTime=${date}&endTime=${new Date().getTime()}000`,
        Utils.requestHeader()
      )
      .then((response) => {
        if (response?.data?.data) {
          const metaData = response.data.data;
          this.getItemsMetaData(metaData);
          this.setState(
            {
              rawMetaData: metaData,
            },
            () => {
              if (!isFromPlay) {
                this.checkMissingData(date, false);
              }
            }
          );
        }
      });
  };

  checkMissingData = (date, isOldData) => {
    let crossingMeta = true;
    for (let range of this.state.rawMetaData) {
      if (range?.start <= date && date < range?.end) {
        crossingMeta = false;
        this.jumpToDate(date);
        break;
      } else if (isOldData && range?.start > date) {
        crossingMeta = false;
        this.getMetaDataByFilterdate(date, false);
        break;
      } else if (!isOldData && range?.start > date) {
        crossingMeta = false;
        this.jumpToDate(range.start);
        break;
      }
    }
    if (crossingMeta) {
      if (this.state.rawMetaData.length > 0) {
        const lastMetaDate =
          this.state.rawMetaData?.[this.state.rawMetaData.length - 1]?.end;
        let parsed = moment
          .tz(
            moment(new Date(lastMetaDate), 'YYYY/MM/DD hh:mm:ss A'),
            this.state.timeZone
          )
          .format('MMMM Do, YYYY - hh:mm:ss A z');
        this.props.showErrorToaster(
          constants.CAMERAS_LIVE_JUMP_TO_DATE_NO_METADATA + parsed
        );
      }
      this.props?.setStreamLoader(false);
    }
  };

  resetClipperProperties = () => {
    this.setState({
      ...clipperDefaults,
    });
  };

  rangeChangedHandler = async (event) => {
    if (
      moment
        .tz(
          moment(event.start).add({ minutes: 2, seconds: 20 }),
          this.state.timeZone
        )
        .isDST()
    ) {
      this.updateTimeZone(moment(event.start).add({ minutes: 2, seconds: 20 }));
    }

    if (this.state.cvrMode && !this.minOptionClicked) {
      const scrubTime =
        this.state.selectedOption === 'Days'
          ? new Date(moment(event.start).add({ days: 1, hours: 6 })).getTime()
          : new Date(
              moment(event.start).add({ minutes: 2, seconds: 20 })
            ).getTime();
      const inRange = this.checkMetaInRange(scrubTime, this.state.rawMetaData);
      if (inRange) {
        clearInterval(this.CVRAutoPlay);
        if (this.props?.streaming?.endOfStream) {
          this.props?.setEndOfStream(false);
          this.props?.setStreamLoader(true);
          sendPlayCVR(
            moment(scrubTime).add({ seconds: 1 }).toDate(),
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex]
          );
        }
      } else {
        this.props?.setStreamLoader(false);
        if (!this.props?.streaming?.endOfStream) {
          this.startClientSideTimer();
          this.props?.setEndOfStream(true);
        }
      }
    }

    if (event.byUser) {
      if (this.isTimelineScrubbing) {
        this.isTimelineScrubbing = false;
      }
      this.updateTimeZone(moment(event.start).add({ minutes: 2, seconds: 20 }));
      const time =
        this.state.selectedOption === 'Days'
          ? moment(event.end).subtract({ days: 1, hours: 6 }).toDate()
          : moment(event.end).subtract({ minutes: 2, seconds: 20 }).toDate();

      this.setState({
        selectedEventTime: Utils.getUnixDate(time) * 1000,
      });

      const scrubStopTimeDiff = new Date() - time;
      if (scrubStopTimeDiff <= 6000) {
        this.onGoLive();
      } else {
        const scrubTime =
        this.state.selectedOption === 'Days'
          ? new Date(moment(event.start).add({ days: 1, hours: 6 })).getTime()
          : new Date(
              moment(event.start).add({ minutes: 2, seconds: 20 })
            ).getTime();
        const metaData = await this.fetchCVRMetaData(scrubTime);
        const inRange = this.checkMetaInRange(scrubTime, metaData);
        if (
          (this.state.cvrMode ||
            (!this.state.manualClicked && time < new Date())) &&
          inRange
        ) {
          clearInterval(this.checkTimestamp);
          this.checkTimestamp = null;
          this.props?.setStreamLoader(true);
          this.setState({
            CVRSnapMode: false,
          });
          sendPlayCVR(
            this.state.activeTime,
            this.props?.account?.deviceInformation?.deviceId,
            this.speedList[this.state.speedIndex]
          );

          clearInterval(this.CVRAutoPlay);
          this.props?.setEndOfStream(false);
        }

        if (!inRange) {
          this.props?.setStreamLoader(false);
          clearInterval(this.CVRAutoPlay);
          setTimeout(() => {
            this.startClientSideTimer();
          }, 500);
          this.props?.setEndOfStream(true);
        }

        this.setState({
          moveTimelineImage: false,
        });

        if (!this.state.manualClicked) {
          this.setState({
            cvrMode: true,
          });
        }
        if (this.pauseRequestSent) {
          this.pauseRequestSent = false;
        }

        // Signal end of timeline scroll
        this.setState({
          timelineChangeInProgress: false,
        });

        if (this.state.showClipper) {
          this.updateStartAndEndTimes();
        }
      }
    } else if (!(this.state.liveStreamMode || this.state.cvrMode)) {
      this.fetchCVRMetaData();
    } else {
      if (
        this.state.jumpedDate !== '' &&
        this.state.jumpedDate &&
        this.state.cvrMode
      ) {
        const diff = this.state.jumpedDate - this.state.activeTime;
        if (diff <= 60000 && !this.props?.streaming?.streamLoader) {
          await this.fetchCVRMetaData();
        }
      }
    }
  };

  handleAddToIncident = async () => {
    let isClipAddedToIncident = false;

    try {
      const incidentId = this.state.assignedIncidentId;

      this.setState({ confirmLoader: true });

      if (!incidentId || !this.state.clipStartTime || !this.state.clipEndTime) {
        throw new Error('ERROR: missing parameters');
      }

      // Required fields.
      const reqBody = {
        deviceId: this.props?.account?.deviceInformation?.deviceId,
        clipStartTime: this.state.clipStartTime,
        clipEndTime: this.state.clipEndTime,
      };

      const res = await axios.post(
        `/incident/orgs/${this.props?.account?.deviceInformation?.orgId}/incidents/${incidentId}/clips`,
        reqBody,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData.meta?.code === 200) {
        isClipAddedToIncident = true;
        this.setState({
          showAddToIncidentModal: false,
        });
        this.setState({
          showAddToIncidentConfirmationModal: true,
        });
        // this.resetClipperProperties();
      } else {
        // TODO: show an error
        Utils.vmsLogger().error(res?.meta?.userMsg);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      this.setState({ confirmLoader: false });
      return isClipAddedToIncident;
    }
  };

  handleEnableShareVideoClipModal = (isVisible) => {
    this.setState({ showShareVideoClipModal: isVisible });
  };

  resetClipperProperties = () => {
    this.setState({
      ...clipperDefaults,
    });
  };

  enableMinutesOption = () => {
    this.setState(
      {
        minsView: true,
        daysView: false,
        secsView: false,
        selectedOption: 'Mins',
        clickAction: true,
      },
      () => {
        this.fetchEventsData();
      }
    );
  };

  // Video Player Actions
  enterFullScreen = () => {
    if (this.state.digitalZoomPanningState?.scale > 1) {
      const { setTransform } = this.digitalZoomRef?.current;
      setTransform(0, 0, 1);
    }
    this.setState({
      fullscreen: true,
      digitalZoomSliderValue: 1,
    });
    this.props.enterFullScreen();
  };

  exitFullScreen = () => {
    if (this.state.digitalZoomPanningState?.scale > 1) {
      const { setTransform } = this.digitalZoomRef?.current;
      setTransform(0, 0, 1);
    }
    this.setState({
      fullscreen: false,
      digitalZoomSliderValue: 1,
    });
    this.props.exitFullScreen();
  };

  downloadLiveImage = () => {
    // This function capture image while live stream in going on
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    downloadStream(`${deviceName} - ${time}`);
  };

  fetchTimeStampImage = (deviceName, time) => {
    // This function download snapshot from s3 bucket while playback is in paused mode
    const unixTime = Utils.getUnixDate(this.state.activeTime);
    const cdnValue = this.props?.streaming?.cdnInfo;
    const bucket = (cdnValue?.bucket).replace(
      '${deviceId}',
      this.props?.account?.deviceInformation?.deviceId
    );
    const date = Utils.fetchDateInUnix(unixTime);
    fetch(
      `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${unixTime}.jpg`,
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response.ok) {
          Utils.vmsLogger().log('image - recieved', response?.url);
          fetch(response?.url, {
            credentials: 'include',
          })
            .then((response) => response.blob())
            .then((blob) => {
              let blobUrl = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.download = `${deviceName} - ${time}.png`;
              a.href = blobUrl;
              document.body.appendChild(a);
              a.click();
            });
        }
      })
      .catch((error) => {
        // Todo: Delete later
        Utils.vmsLogger().log('error comes in catch', error);
      });
  };

  downloadPlaybackImage = async () => {
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    if(this.state.liveSnapshot) {
      this.downloadLiveSnapshot();
    } else if(this.state.CVRSnapMode) {
      this.fetchTimeStampImage(deviceName, time);
    }
    if (!this.state.CVRSnapMode && this.state.cvrMode) {
      downloadPlaybackStream(`${deviceName} - ${time}`);
    }
  };

  downloadLiveSnapshot = () => {
    // This function is used when we pause the live stream and download live snapshot. 
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    let a = document.createElement('a');
    const liveSnapEle = document.getElementsByClassName(`live-snapshot`);
    if(liveSnapEle) {
      a.download = `${this.props?.account?.deviceInformation?.deviceName} - ${time}.png`;
      a.href = liveSnapEle[0].src;
      document.body.appendChild(a);
      a.click();
    }
  }

  enableClipper = async (e) => {
    let lastMetaDataEndTime, initialStartTime, medianTime;

    this.toggleClipper(e);

    // Ensure we have the latest meta data
    await this.fetchCVRMetaData();

    // Get last meta data chunk's end time
    if (
      Array.isArray(this.state.rawMetaData) &&
      this.state.rawMetaData.length > 0
    ) {
      lastMetaDataEndTime =
        this.state.rawMetaData[this.state.rawMetaData.length - 1]?.end;
      initialStartTime = moment(lastMetaDataEndTime)
        .subtract(30, 'seconds')
        .valueOf();
    }

    // Get the median time
    medianTime = moment(this.state.activeTime).valueOf();

    // If the end time of the last recorded chunk happened much earlier
    // then the default median time, we move the timeline to right and
    // set the median time to where it's supposed to be afterwards.
    const medianTimeMoment = moment(medianTime);
    const lastMetaDataEndTimeMoment = moment(lastMetaDataEndTime);

    if (lastMetaDataEndTimeMoment.isBefore(medianTimeMoment)) {
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });

      // Reposition the clipper so that its right edge aligns with the last
      // recorded chunk's right edge
      this.setState({
        clipper: {
          ...this.state.clipper,
          maxDurationInPixels: this.state.clipper.maxDurationInPixels - 60,
          x:
            this.state.clipper.x -
            AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS *
              3.43,
        },
      });

      // Move the timeline such that the end time of the last
      // recorded chunk is on the center of the timeline
      this.timeline?.current?.$el?.moveTo(moment(lastMetaDataEndTime), () => {
        this.setState({
          moveTimelineImage: false,
        });
      });
    }

    this.setState({
      clipStartTime: initialStartTime,
      timePickerStartTimeValue: initialStartTime,
      clipEndTime: lastMetaDataEndTime,
    });
  };

  toggleClipper = (e) => {
    if (!e) return;

    // Reset all clipper configurations to their defaults
    this.initClipperProperties();

    // If the clipper isn't already enabled, then do the following:
    if (this.state.showClipper === false) {
      // (1) Track the current selected mode of the player
      this.setState({ preClipperSelectedOption: this.state.selectedOption });
      // (2) Pause the video player if it's currently playing
      if (this.state.cvrMode === true || this.state.liveStreamMode === true) {
        this.onPauseCVR(e);
      }
      // (3) Ensure we are in "Mins" mode
      if (this.state.selectedOption?.toUpperCase() !== 'MINS') {
        this.setState({ selectedOption: 'Mins' });
        this.enableMinutesOption();
      }
      // (4) Reset the clickAction state
      setTimeout(() => {
        this.setState({
          clickAction: false,
        });
      }, 300);
    }

    // Toggle the clipper
    this.setState({ showClipper: !this.state.showClipper });
  };

  // Timeline and Scrubber Methods
  initClipperProperties = () => {
    const timelineWidth = this.timeline.current?.$el?.props?.lastWidth;
    // const pixelsPerSecond =
    //   timelineWidth / AppDefaults.CAMERA_VIDEO_TIMELINE_MAX_DURATION_IN_SECONDS;
    // 3.703
    const pixelsPerSecond = (
      parseInt(timelineWidth) /
      AppDefaults.CAMERA_VIDEO_TIMELINE_MAX_NUMBER_OF_TICKS /
      AppDefaults.CAMERA_VIDEO_TIMELINE_NUMBER_OF_SECONDS_PER_TICK
    ).toFixed(4);

    const clipperProps = {
      timelineWidth: timelineWidth,
      timelineMidpoint: timelineWidth / 2,
      timelinePixelsPerSecond: pixelsPerSecond,
      clipper: {
        x: timelineWidth / 2 - AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH,
        width:
          30 * pixelsPerSecond +
          AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH * 2,
        defaultDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS *
          pixelsPerSecond,
        minDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS *
            pixelsPerSecond +
          16,
        maxDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS *
          pixelsPerSecond,
        currentDurationInPixels:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS *
          pixelsPerSecond,
        currentDurationInSeconds:
          AppDefaults.INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS,
      },
    };

    this.setState({ ...clipperProps });
  };

  plotUpdatedSlot = (newObj) => {
    if (newObj?.end && this.state.holdEventsDotsData.length) {
      const newArray = this.getAgesWithGreaterDifference(
        this.state.holdEventsDotsData,
        newObj?.end
      );
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      const mergedItem = [...this.state.minsMetaData, ...minsData];

      if (newArray.length > 0) {
        const massagedData = getEventsScubberData(newArray, minsAdjustment);
        const newData = [...this.state.eventsDataCheck, ...massagedData];
        this.props?.setLastMetaTimeStamp(newObj?.end);
        this.setState(
          {
            minsMetaData: mergedItem,
            rawMetaData: [...this.state.rawMetaData, ...minsData],
            eventsDataCheck: newData,
          },
          () => {
            setTimeout(() => {
              this.updateEventsPlotting(newData);
              const filteredData = this.state.holdEventsDotsData.filter(
                (data) =>
                  !newArray.some(
                    (arr) =>
                      data.start === arr.start || data.start <= newObj.end
                  )
              );

              this.setState({
                holdEventsDotsData: filteredData,
              });
            }, 500);
          }
        );
      }
    } else if (newObj?.end) {
      this.props?.setLastMetaTimeStamp(newObj?.end);
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      this.setState(
        {
          minsMetaData: [...this.state.minsMetaData, ...minsData],
          rawMetaData: [...this.state.rawMetaData, ...minsData],
        },
        () => {
          this.updateEventsPlotting();
        }
      );
    }
  };

  updateEventsPlotting = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }
    const mergedItem = [...this.state.minsMetaData, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  updateEventsPlottingInRange = (eventsData) => {
    this.timeline?.current?.$el?.setItems([
      ...eventsData,
      ...this.state.minsMetaData,
    ]);
  };

  updateEventsPlottingInRangeDays = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.daysMetaData, ...newData];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.timeline?.current?.$el?.setItems(filteredArr);
    this.updateEventsPlotting(eventsData);
  };

  updateEventsPlottingInRangeSecs = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }
    const mergedItem = [...this.state.secsMetaData, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.timeline?.current?.$el?.setItems(filteredArr);
    // this.updateEventsPlotting(eventsData);
  };

  updateClipperPosition = () => {
    // this.rnd.updatePosition({ x: 200, y: 0 });
    this.rnd.position = { x: 200, y: 0 };
  };

  updateStartAndEndTimes = () => {
    const midPointTimeMoment = moment
      .utc(moment(this.state.midPointTime).valueOf())
      .tz(this.state.timeZone);

    const previousActiveTimeMoment = moment
      .utc(moment(this.state.previousActiveTime).valueOf())
      .tz(this.state.timeZone);

    const isNewMidPointTimeBefore = midPointTimeMoment.isBefore(
      previousActiveTimeMoment
    );

    const newMidPointTimeDifference = midPointTimeMoment.diff(
      previousActiveTimeMoment
    );

    const newMidPointTimeDiffInSeconds = Math.abs(
      moment.duration(newMidPointTimeDifference).asSeconds()
    );

    const currentStartTimeMoment = moment
      .utc(this.state.clipStartTime)
      .tz(this.state.timeZone);

    const currentEndTimeMoment = moment
      .utc(this.state.clipEndTime)
      .tz(this.state.timeZone);

    // Debug logs
    Utils.vmsLogger().log('this.state.midPointTime: ', this.state.midPointTime);
    Utils.vmsLogger().log(
      'this.state.previousActiveTime: ',
      this.state.previousActiveTime
    );
    Utils.vmsLogger().log('this.state.midPointTime: ', this.state.midPointTime);
    Utils.vmsLogger().log('isNewMidPointTimeBefore: ', isNewMidPointTimeBefore);
    Utils.vmsLogger().log(
      'newMidPointTimeDiffInSeconds: ',
      newMidPointTimeDiffInSeconds
    );
    Utils.vmsLogger().log('currentStartTimeMoment: ', currentStartTimeMoment);
    Utils.vmsLogger().log('currentEndTimeMoment: ', currentEndTimeMoment);

    if (isNewMidPointTimeBefore) {
      // Subtract difference from current Start and End Times
      this.setState({
        clipStartTime: currentStartTimeMoment
          .clone()
          .subtract(newMidPointTimeDiffInSeconds, 'seconds'),
        clipEndTime: currentEndTimeMoment
          .clone()
          .subtract(newMidPointTimeDiffInSeconds, 'seconds'),
      });
    } else {
      // Add difference to current Start and End Times
      this.setState({
        clipStartTime: currentStartTimeMoment
          .clone()
          .add(newMidPointTimeDiffInSeconds, 'seconds'),
        clipEndTime: currentEndTimeMoment
          .clone()
          .add(newMidPointTimeDiffInSeconds, 'seconds'),
      });
    }
  };

  setCallCountAPI = () => {
    this.setState({
      callCountAPI: true,
    });
  };

  digitalZoomIn = () => {
    try {
      const { centerView } = this.digitalZoomRef.current;
      if (this.state.digitalZoomValue < 5) {
        centerView(this.state.digitalZoomValue + 0.5);
        this.setState({
          digitalZoomSliderValue: this.state.digitalZoomSliderValue + 1,
          digitalZoomValue: this.state.digitalZoomValue + 0.5,
        });
      }
      if (this.props?.showFetchImageGrid) {
        this.props.setHideFetchImageGrid();
      }
    } catch (err) {
      Utils.vmsLogger().error('zoom error', err);
    }
  };

  digitalZoomOut = () => {
    try {
      const { centerView } = this.digitalZoomRef.current;
      if (this.state.digitalZoomValue > 1) {
        centerView(this.state.digitalZoomValue - 0.5);
        this.setState({
          digitalZoomSliderValue: this.state.digitalZoomSliderValue - 1,
          digitalZoomValue: this.state.digitalZoomValue - 0.5,
        });
      }
      if (this.props?.showFetchImageGrid) {
        this.props.setHideFetchImageGrid();
      }
    } catch (err) {
      Utils.vmsLogger().error('zoom out error', err);
    }
  };

  getDigitalZoomOptions = () => {
    const digitalZoomProps = {
      ref: this.digitalZoomRef,
      initialScale: this.state.digitalZoomPanningState?.scale ?? 1,
      initialPositionX: this.state.digitalZoomPanningState?.positionX ?? 0,
      initialPositionY: this.state.digitalZoomPanningState?.positionY ?? 0,
      wheel: { disabled: true },
      doubleClick: { disabled: true },
      maxScale: 5,
      minScale: 1,
      panning:
        this.state.digitalZoomPanningState?.scale === 1
          ? { disabled: true }
          : { disabled: false },
    };

    return digitalZoomProps;
  };

  onDigitalZoomPanningStart = (divId) => {
    document.getElementById(divId).style.cursor = 'move';
  };

  onDigitalZoomPanningStop = (event, divId) => {
    document.getElementById(divId).style.cursor = 'auto';
    this.setState({
      digitalZoomPanningState: event.state,
    });
  };

  onDigitalZoomTransformed = (event) => {
    this.props?.setDigitalZoomScale(event.state.scale);
    this.setState({
      digitalZoomPanningState: event.state,
    });
  };

  render() {
    const { isRemoteStreamPlay, cvrStartDate } = this.props.streaming;
    const deviceDetails = this.props?.account?.deviceInformation;
    const displayDeviceStatus =
      deviceDetails?.displayDeviceStatus ||
      Utils.getDeviceStatus(
        deviceDetails?.deviceStatus,
        deviceDetails?.connectionStatus
      );

    if (this.state.redirectAllSettings) {
      return (
        <Navigate
          to={`/devices/dashboard.html?deviceId=${this.state.deviceId}`}
        />
      );
    }

    if (this.state.proceedToIncident && this.state.assignedIncidentId) {
      let incidentId = this.state.assignedIncidentId;
      this.setState({ assignedIncidentId: null });

      return (
        <Navigate
          to={`/incidents/details.html?incidentId=${incidentId}&activeTab=0`}
        />
      );
    }

    if (this.state.proceedToClipDetails) {
      return (
        <Navigate
          to={`/clips/details.html?clipId=${this?.state?.clipObject?.clipId}&startTime=${this?.state?.clipStartTime}&timezone=${this.props.timezone}&cdnInfo=${this?.props?.streaming?.cdnInfo}&deviceId=${this?.props?.account?.deviceInformation?.deviceId}&clipStatus=${this?.state?.clipObject?.status}`}
        />
      );
    }

    return (
      <div className="wrapper-app live-streaming">
        <div className="digital-zoom-main">
          {this.state.showZoomSlider ? (
            <div className="zoom-range-slider-main">
              <MdOutlineZoomIn
                style={
                  this.state.digitalZoomPanningState?.scale === 5
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'auto' }
                }
                size={16}
                onClick={() => this.digitalZoomIn()}
              />
              <div className="zoom-range-field-wrap">
                <div className="zoom-range-field-container">
                  <ReactSlider
                    className="zoom-range-slider"
                    trackClassName="zoom-range-slider-track"
                    thumbClassName="zoom-range-slider-thumb"
                    min={1}
                    max={9}
                    renderTrack={(props, state) => <div {...props} />}
                    value={this.state.digitalZoomSliderValue}
                    renderThumb={(props, state) => <div {...props} />}
                    onChange={(value) => {
                      if (value > this.state.digitalZoomSliderValue) {
                        this.digitalZoomIn();
                      } else {
                        this.digitalZoomOut();
                      }
                    }}
                    invert
                    orientation="vertical"
                  />
                </div>
              </div>
              <MdOutlineZoomOut
                style={
                  this.state.digitalZoomPanningState?.scale === 1
                    ? { pointerEvents: 'none' }
                    : { pointerEvents: 'auto' }
                }
                size={16}
                onClick={() => this.digitalZoomOut()}
              />
            </div>
          ) : null}

          {!this.state.mainSettingSelected ? (
            <div className="zoom-button-main">
              {!this.state.showZoomSlider ? (
                <MdOutlineZoomIn
                  size={16}
                  onClick={() => this.setState({ showZoomSlider: true })}
                />
              ) : (
                <MdClose
                  size={16}
                  onClick={() => this.setState({ showZoomSlider: false })}
                />
              )}
            </div>
          ) : null}
        </div>
        <div className="live-zoom-scale">
          {this.state.liveStreamMode && (
            <div
              className={`stream-timeline-wrapper-live liveview ${
                this.props?.streaming?.streamLoader && this.state.liveStreamMode
                  ? `d-block`
                  : `d-flex`
              }`}
              id="live-zoom"
            >
              {this.props?.streaming?.streamLoader && (
                <div className="live-snapshot-div">
                  <div className="loading-spinner">
                    <SiteSpinner />
                  </div>
                  <img
                    id="img-snapshot"
                    src={this.props?.liveSnapshotImg}
                    alt=""
                    className="cam-live-img-snapshot"
                    onError={(e) => (e.target.style.display = 'none')}
                  />
                </div>
              )}
              {displayDeviceStatus ===
                constants.DEVICES_RETURN_OFFLINE_STATUS ||
              displayDeviceStatus ===
                constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
                <div className="live-offline-camera">
                  <FiCameraOff
                    size={48}
                    style={{
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_56'),
                    }}
                  />
                  {constants.DEVICE_STATE_CAMERA_OFFLINE}
                </div>
              ) : this.state.isLicenseExpire ? (
                <div className="live-offline-camera">
                  <NoLicenseIcon className="no-license-icon" />
                  {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                </div>
              ) : this.props?.account?.deviceInformation?.connectionStatus !==
                'offline' ? (
                <TransformWrapper
                  {...this.getDigitalZoomOptions()}
                  onPanningStart={() =>
                    this.onDigitalZoomPanningStart('live-zoom')
                  }
                  onPanningStop={(event) =>
                    this.onDigitalZoomPanningStop(event, 'live-zoom')
                  }
                  onTransformed={(event) =>
                    this.onDigitalZoomTransformed(event)
                  }
                >
                  <TransformComponent
                    wrapperClass="live-zoom-wrapper"
                    contentClass="live-zoom-content"
                  >
                    <TimelineControlsLive
                      muteAudio={this.state.muteAudio}
                      showFetchImageGrid={this.props.showFetchImageGrid}
                      hideFetchImageGrid={() =>
                        this.props.setHideFetchImageGrid()
                      }
                      callCountAPI={() => this.setCallCountAPI()}
                      deviceId={
                        this.props?.account?.deviceInformation?.deviceId
                      }
                      isLicenseExpired={this.props?.isLicenseExpired}
                    />
                  </TransformComponent>
                </TransformWrapper>
              ) : (
                <div className="live-offline-camera">
                  <FiCameraOff
                    size={48}
                    style={{
                      color: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_56'),
                    }}
                  />
                  {constants.DEVICE_STATE_CAMERA_OFFLINE}
                </div>
              )}
            </div>
          )}
        </div>
        {!this.state.liveStreamMode && (
          <div className="live-zoom-scale">
            <div className="stream-timeline-wrapper-live liveview d-flex">
              {/* TODO DELETE LATER */}
              {/* <div className="events-icons-wrapper">
              <span
                className="icons-image previous-icon"
                onClick={() => this.onPreviousIconClick()}
              >
                <ArrowLeft width={18} />
              </span>
              <span
                className="icons-image next-icon"
                onClick={() => this.onNextIconClick()}
              >
                <ArrowRight width={18} />
              </span>
            </div> */}
              <div className="scrubber-image-view" id="zoom-playback">
                {this.props?.streaming?.streamLoader && (
                  <div className="loading-snapshot-div">
                    <div className="loading-spinner">
                      <SiteSpinner />
                    </div>
                    <ImageFetchLoading
                      moveTimeline={this.state.moveTimelineImage}
                      time={this.state.activeTime}
                      cdnValue={this.props?.streaming?.cdnInfo}
                      deviceId={
                        this.props?.account?.deviceInformation?.deviceId
                      }
                      selectedEventTime={this.state.selectedEventTime}
                    />
                  </div>
                )}
                {this.state.activeTime &&
                  this.state.CVRSnapMode &&
                  !this.props.streaming.streamLoader && (
                    <div
                      className={`active-image ${
                        this.state.activeImage === noVideo ? 'novideo' : ''
                      }`}
                    >
                      {this.state.CVRSnapMode &&
                        this.state.showClipper &&
                        !this.props.streaming.streamLoader && (
                          <div className="add-to-incident-wrapper">
                            <div
                              className="back-to-live"
                              onClick={() => {
                                this.setState({
                                  clipStartTimeError: false,
                                  clipStartTimeErrorMsg: '',
                                  invalidNewStartTime: false,
                                  clipEndTimeError: false,
                                  clipEndTimeErrorMsg: '',
                                  invalidNewEndTime: false,
                                  showClipper: false,
                                });
                              }}
                            >
                              <HiOutlineChevronLeft className="add-to-incident-left-arrow" />
                            </div>
                          </div>
                        )}
                      <TransformWrapper
                        {...this.getDigitalZoomOptions()}
                        onPanningStart={() =>
                          this.onDigitalZoomPanningStart('zoom-playback')
                        }
                        onPanningStop={(event) =>
                          this.onDigitalZoomPanningStop(event, 'zoom-playback')
                        }
                        onTransformed={(event) =>
                          this.onDigitalZoomTransformed(event)
                        }
                      >
                        <TransformComponent
                          wrapperClass="snapshot-zoom-wrapper"
                          contentClass="snapshot-zoom-content"
                        >
                          <ImageFetch
                            moveTimeline={this.state.moveTimelineImage}
                            liveSnapshot={this.state.liveSnapshot}
                            time={this.state.activeTime}
                            cdnValue={this.props?.streaming?.cdnInfo}
                            deviceId={
                              this.props?.account?.deviceInformation?.deviceId
                            }
                            showFetchImageGrid={this.props.showFetchImageGrid}
                            hideFetchImageGrid={() =>
                              this.props.setHideFetchImageGrid()
                            }
                            callCountAPI={() => this.setCallCountAPI()}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </div>
                  )}
                {this.state.cvrMode &&
                !this.state.CVRSnapMode &&
                !this.props?.streaming?.endOfStream ? (
                  <>
                    <TransformWrapper
                      {...this.getDigitalZoomOptions()}
                      onPanningStart={() =>
                        this.onDigitalZoomPanningStart('zoom-playback')
                      }
                      onPanningStop={(event) =>
                        this.onDigitalZoomPanningStop(event, 'zoom-playback')
                      }
                      onTransformed={(event) =>
                        this.onDigitalZoomTransformed(event)
                      }
                    >
                      <TransformComponent
                        wrapperClass="playback-zoom-wrapper"
                        contentClass="playback-zoom-content"
                      >
                        <PlaybackControlsView
                          muteAudio={this.state.muteAudio}
                          showFetchImageGrid={this.props.showFetchImageGrid}
                          hideFetchImageGrid={() =>
                            this.props.setHideFetchImageGrid()
                          }
                          callCountAPI={() => this.setCallCountAPI()}
                          deviceId={
                            this.props?.account?.deviceInformation?.deviceId
                          }
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </>
                ) : this.state.cvrMode && this.props?.streaming?.endOfStream ? (
                  <>
                    <img
                      className="video-processing"
                      src={noVideo}
                      alt="video processing"
                    />
                  </>
                ) : null}
              </div>
              {/* Todo: Need below functionality later */}
              {/* <div
            className="previous-block"
            onClick={() =>
              this.onClick(dates, this.state.activeTime, 'previous')
            }
          ></div>
          <div
            className="next_block"
            onClick={() => this.onClick(dates, this.state.activeTime, 'next')}
          ></div> */}
            </div>
          </div>
        )}
        {this.state.showSettings ? this.showSettingOptions() : null}
        <div className="timeline-input-error-wrapper">
          <div className="timeline-input-error-container">
            {this.state.showClipper && this.state.clipStartTimeError && (
              <div className="input-error start-time-input-error">
                {this.showTimeEntryError()}
              </div>
            )}
            {this.state.showClipper && this.state.clipEndTimeError && (
              <div className="input-error end-time-input-error">
                {this.showTimeEntryError()}
              </div>
            )}
          </div>
        </div>
        <div className="stream-timeline-wrapper timeline-controls-page">
          <div className={`scrubber-wrapper`}>
            {this.state.showClipper ? (
              <div className="clipper-controls-wrapper">
                <div className="clipper-time-overrides">
                  <TimeInput
                    classes="clipper-duration-start"
                    wrapperClasses="clipper-duration"
                    useCurrentTime={false}
                    initialTime={this.state.clipStartTime}
                    newTime={this.state.clipStartTime}
                    timeZone={this.state.timeZone}
                    isUTC={this.state.isClipStartTimeUTC}
                    isInvalid={this.state.invalidNewStartTime}
                    submitCallback={(newTime) => {
                      if (!newTime) return;

                      let clipperDiv = this.clipperRef?.current;
                      let clipperRect = clipperDiv?.getBoundingClientRect();
                      let clipperLeftPosition = clipperRect?.left;
                      let clipperRightPosition = clipperRect?.right;
                      let clipperRightPositionOffset =
                        clipperRightPosition -
                        this.state.clipper.currentDurationInPixels -
                        window.scrollX -
                        30;

                      // Since the input value is in 12-hour format without AM/PM
                      // info, we have to determine it from the current start time
                      const currentStartTimeAMPM = moment
                        .utc(this.state.clipStartTime)
                        .tz(this.state.timeZone)
                        .format('A');

                      const currentStartTimeDate = moment
                        .utc(this.state.clipStartTime)
                        .tz(this.state.timeZone)
                        .format('YYYY-MM-DD');

                      // We use the AMPM info extracted from the current start time
                      // to give the new start time the proper AMPM value
                      let newStartTimeUTC = moment
                        .tz(
                          `${currentStartTimeDate} ${newTime} ${currentStartTimeAMPM}`,
                          'YYYY-MM-DD hh:mm:ss A',
                          this.state.timeZone
                        )
                        .utc();

                      let newStartTimeMoment = moment
                        .utc(newStartTimeUTC.valueOf())
                        .tz(this.state.timeZone);

                      let currentStartTimeMoment = moment
                        .utc(this.state.clipStartTime)
                        .tz(this.state.timeZone);

                      let currentEndTimeMoment = moment
                        .utc(this.state.clipEndTime)
                        .tz(this.state.timeZone);

                      const isNewStartTimeBefore = newStartTimeMoment.isBefore(
                        currentStartTimeMoment
                      );

                      // Determine difference between current start time and new start time
                      const startTimeDiffMilliseconds = newStartTimeMoment.diff(
                        currentStartTimeMoment
                      );

                      const startTimeDiffSeconds = moment
                        .duration(startTimeDiffMilliseconds)
                        .asSeconds();

                      // Determine the difference in pixels
                      const startTimeDiffPixels =
                        startTimeDiffSeconds *
                        this.state.timelinePixelsPerSecond;

                      // Determine the new position on the timeline
                      const newStartTimePosition = isNewStartTimeBefore
                        ? parseInt(this.state.clipper.x) -
                          Math.abs(startTimeDiffPixels)
                        : parseInt(this.state.clipper.x) +
                          Math.abs(startTimeDiffPixels);

                      // Adjust the duration
                      const newDurationPixels = isNewStartTimeBefore
                        ? parseInt(this.state.clipper.currentDurationInPixels) +
                          Math.abs(startTimeDiffPixels)
                        : parseInt(this.state.clipper.currentDurationInPixels) -
                          Math.abs(startTimeDiffPixels);

                      const newDurationDifference =
                        newStartTimeMoment.diff(currentEndTimeMoment);

                      const newDurationDiffInSeconds = Math.abs(
                        moment.duration(newDurationDifference).asSeconds()
                      );

                      // Debug logs
                      Utils.vmsLogger().log('START TIME ====');
                      Utils.vmsLogger().log('newTime: ', newTime);
                      Utils.vmsLogger().log(
                        'currentStartTimeAMPM: ',
                        currentStartTimeAMPM
                      );
                      Utils.vmsLogger().log(
                        'currentStartTimeDate: ',
                        currentStartTimeDate
                      );
                      Utils.vmsLogger().log(
                        'newStartTimeMoment: ',
                        newStartTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'newStartTimeMoment.valueOf(): ',
                        newStartTimeMoment.valueOf()
                      );
                      Utils.vmsLogger().log(
                        'this.state.clipStartTime: ',
                        this.state.clipStartTime
                      );
                      Utils.vmsLogger().log(
                        'currentStartTimeMoment: ',
                        currentStartTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'currentEndTimeMoment: ',
                        currentEndTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'isNewStartTimeBefore: ',
                        isNewStartTimeBefore
                      );
                      Utils.vmsLogger().log(
                        'startTimeDiffSeconds: ',
                        startTimeDiffSeconds
                      );
                      Utils.vmsLogger().log(
                        'newDurationPixels: ',
                        newDurationPixels
                      );
                      Utils.vmsLogger().log(
                        'newDurationDifference: ',
                        newDurationDifference
                      );
                      Utils.vmsLogger().log(
                        'newDurationDiffInSeconds: ',
                        newDurationDiffInSeconds
                      );

                      if (newTime === 'Invalid date') {
                        this.setState({
                          clipStartTimeError: true,
                          invalidNewStartTime: true,
                        });

                        this.setState({ timeEntryErrorMessageType: 5 });

                        // this.showTimeEntryError('START');

                        return false;
                      } else if (
                        newStartTimePosition < 0 ||
                        currentEndTimeMoment.isBefore(newStartTimeMoment)
                      ) {
                        this.setState({
                          clipStartTimeError: true,
                        });
                        this.setState({ invalidNewStartTime: true });

                        if (newStartTimePosition < 0) {
                          this.setState({ timeEntryErrorMessageType: 1 });
                          return false;
                        }

                        if (currentEndTimeMoment.isBefore(newStartTimeMoment)) {
                          this.setState({ timeEntryErrorMessageType: 2 });
                        }

                        // this.showTimeEntryError('START');

                        return false;
                      } else if (
                        newDurationDiffInSeconds >
                        AppDefaults.INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS
                      ) {
                        this.setState({
                          clipStartTimeError: true,
                          invalidNewStartTime: true,
                          timeEntryErrorMessageType: 5,
                        });
                        // this.showTimeEntryError('START');
                      } else if (
                        newDurationDiffInSeconds <
                        AppDefaults.INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS
                      ) {
                        this.setState({
                          clipStartTimeError: true,
                          invalidNewStartTime: true,
                          timeEntryErrorMessageType: 6,
                        });
                      } else {
                        this.setState({
                          clipStartTimeError: false,
                          clipStartTimeErrorMsg: '',
                          invalidNewStartTime: false,
                          timeEntryErrorMessageType: 0,
                        });

                        this.setState({
                          clipper: {
                            ...this.state.clipper,
                            x: newStartTimePosition,
                            width:
                              newDurationPixels +
                              AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH *
                                2,
                            currentDurationInPixels: newDurationPixels,
                            currentDurationInSeconds:
                              newDurationPixels /
                              this.state.timelinePixelsPerSecond,
                          },
                        });

                        this.setState({
                          clipStartTime: newStartTimeMoment.valueOf(),
                          isClipStartTimeUTC: newStartTimeMoment._isUTC,
                        });

                        return true;
                      }
                    }}
                  />
                  <TimeInput
                    classes="clipper-duration-end"
                    wrapperClasses="clipper-duration"
                    useCurrentTime={false}
                    initialTime={this.state.clipEndTime}
                    newTime={this.state.clipEndTime}
                    timeZone={this.state.timeZone}
                    isUTC={this.state.isClipEndTimeUTC}
                    isInvalid={this.state.invalidNewEndTime}
                    submitCallback={(newTime) => {
                      if (!newTime) return;

                      let recordingDiv =
                        document.querySelector('.vis-item-overflow');
                      let recordingRect = recordingDiv?.getBoundingClientRect();
                      let recordingRightPosition = recordingRect?.right;

                      let clipperRightPositionOffset =
                        recordingRightPosition -
                        parseInt(this.state.clipper.currentDurationInPixels) -
                        window.scrollX -
                        30;

                      // Since the input value is in 12-hour format without AM/PM
                      // info, we have to determine it from the current end time
                      const currentEndTimeAMPM = moment
                        .utc(this.state.clipEndTime)
                        .tz(this.state.timeZone)
                        .format('A');

                      const currentEndTimeDate = moment
                        .utc(this.state.clipEndTime)
                        .tz(this.state.timeZone)
                        .format('YYYY-MM-DD');

                      // We use the AMPM info extracted from the current end time
                      // to give the new end time the proper AMPM value
                      let newEndTimeUTC = moment
                        .tz(
                          `${currentEndTimeDate} ${newTime} ${currentEndTimeAMPM}`,
                          'YYYY-MM-DD hh:mm:ss A',
                          this.state.timeZone
                        )
                        .utc();

                      let newEndTimeMoment = moment
                        .utc(newEndTimeUTC.valueOf())
                        .tz(this.state.timeZone);

                      let currentEndTimeMoment = moment
                        .utc(this.state.clipEndTime)
                        .tz(this.state.timeZone);

                      let currentStartTimeMoment = moment
                        .utc(this.state.clipStartTime)
                        .tz(this.state.timeZone);

                      const isNewEndTimeAfter =
                        newEndTimeMoment.isAfter(currentEndTimeMoment);

                      // Determine difference between current end time and new end time
                      const endTimeDiffMilliseconds =
                        newEndTimeMoment.diff(currentEndTimeMoment);

                      const endTimeDiffSeconds = moment
                        .duration(endTimeDiffMilliseconds)
                        .asSeconds();

                      // Determine the difference in pixels
                      const endTimeDiffPixels =
                        endTimeDiffSeconds * this.state.timelinePixelsPerSecond;

                      // Determine the new position on the timeline
                      const newEndTimePosition = isNewEndTimeAfter
                        ? this.state.clipper.x + Math.abs(endTimeDiffPixels)
                        : this.state.clipper.x - Math.abs(endTimeDiffPixels);

                      // Adjust the duration
                      let newDurationPixels = isNewEndTimeAfter
                        ? parseInt(this.state.clipper.currentDurationInPixels) +
                          Math.abs(endTimeDiffPixels)
                        : parseInt(this.state.clipper.currentDurationInPixels) -
                          Math.abs(endTimeDiffPixels);

                      const newDurationDifference = newEndTimeMoment.diff(
                        currentStartTimeMoment
                      );

                      const newDurationDiffInSeconds = Math.abs(
                        moment.duration(newDurationDifference).asSeconds()
                      );

                      // Debug info
                      Utils.vmsLogger().log('END TIME ====');
                      Utils.vmsLogger().log('newTime: ', newTime);
                      Utils.vmsLogger().log(
                        'currentEndTimeAMPM: ',
                        currentEndTimeAMPM
                      );
                      Utils.vmsLogger().log(
                        'currentEndTimeDate: ',
                        currentEndTimeDate
                      );
                      Utils.vmsLogger().log(
                        'this.state.clipEndTime: ',
                        this.state.clipEndTime
                      );
                      Utils.vmsLogger().log(
                        'newEndTimeMoment: ',
                        newEndTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'newEndTimeMoment.valueOf(): ',
                        newEndTimeMoment.valueOf()
                      );
                      Utils.vmsLogger().log(
                        'currentEndTimeMoment: ',
                        currentEndTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'currentStartTimeMoment: ',
                        currentStartTimeMoment
                      );
                      Utils.vmsLogger().log(
                        'isNewEndTimeAfter: ',
                        isNewEndTimeAfter
                      );
                      Utils.vmsLogger().log(
                        'endTimeDiffSeconds: ',
                        endTimeDiffSeconds
                      );
                      Utils.vmsLogger().log(
                        'newDurationPixels: ',
                        newDurationPixels
                      );
                      Utils.vmsLogger().log(
                        'newDurationDifference: ',
                        newDurationDifference
                      );
                      Utils.vmsLogger().log(
                        'newDurationDiffInSeconds: ',
                        newDurationDiffInSeconds
                      );

                      if (newTime === 'Invalid date') {
                        this.setState({
                          clipEndTimeError: true,
                          invalidNewEndTime: true,
                        });

                        this.setState({ timeEntryErrorMessageType: 5 });
                        // this.showTimeEntryError('END');

                        return false;
                      } else if (
                        newEndTimePosition > this.state.timelineWidth ||
                        newEndTimeMoment.isBefore(currentStartTimeMoment) ||
                        newEndTimePosition > recordingRightPosition
                      ) {
                        this.setState({
                          clipEndTimeError: true,
                        });

                        this.setState({ invalidNewEndTime: true });

                        if (newEndTimePosition > this.state.timelineWidth) {
                          this.setState({ timeEntryErrorMessageType: 1 });
                          return false;
                        }

                        if (newEndTimePosition > recordingRightPosition) {
                          this.setState({ timeEntryErrorMessageType: 3 });
                          return false;
                        }

                        if (newEndTimeMoment.isBefore(currentStartTimeMoment)) {
                          this.setState({ timeEntryErrorMessageType: 4 });
                        }

                        // this.showTimeEntryError('END');

                        return false;
                      } else if (
                        newDurationDiffInSeconds >
                        AppDefaults.INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS
                      ) {
                        this.setState({
                          clipEndTimeError: true,
                          invalidNewEndTime: true,
                          timeEntryErrorMessageType: 5,
                        });
                        // this.showTimeEntryError('END');
                      } else if (
                        newDurationDiffInSeconds <
                        AppDefaults.INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS
                      ) {
                        this.setState({
                          clipEndTimeError: true,
                          invalidNewEndTime: true,
                          timeEntryErrorMessageType: 6,
                        });
                      } else {
                        this.setState({
                          clipEndTimeError: false,
                          clipEndTimeErrorMsg: '',
                          invalidNewEndTime: false,
                          timeEntryErrorMessageType: 0,
                        });

                        this.setState({
                          clipper: {
                            ...this.state.clipper,
                            width:
                              newDurationPixels +
                              AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH *
                                2,
                            currentDurationInPixels: newDurationPixels,
                            currentDurationInSeconds:
                              newDurationPixels /
                              this.state.timelinePixelsPerSecond,
                          },
                        });
                        this.setState({
                          clipEndTime: newEndTimeMoment.valueOf(),
                          isClipEndTimeUTC: newEndTimeMoment._isUTC,
                        });

                        return true;
                      }
                    }}
                  />
                </div>
                <div className="clipper-active-time active_time">
                  {moment
                    .tz(moment(this.state.activeTime), this.state.timeZone)
                    .format('MMM DD, YYYY - hh:mm:ss A z')}
                </div>
                <div className="clipper-action-container">
                  <div
                    className="clipper-bubble clipper-action"
                    onClick={() => {
                      this.setState({
                        clipStartTimeError: false,
                        clipStartTimeErrorMsg: '',
                        invalidNewStartTime: false,
                        clipEndTimeError: false,
                        clipEndTimeErrorMsg: '',
                        invalidNewEndTime: false,
                        showClipper: false,
                      });
                    }}
                  >
                    {
                      constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_CANCEL_BUTTON_LABEL
                    }
                  </div>
                  <div
                    className="clipper-bubble clipper-action"
                    onClick={() => {
                      this.setState({ shouldResetCreateClipForm: false });
                      this.setState({ showCreateClipModal: true });
                    }}
                  >
                    {constants.CAMERAS_LIVE_PLAYBACK_CLIPPER_SAVE_BUTTON_LABEL}
                  </div>
                </div>
              </div>
            ) : (
              <div className="timelines-controls-wrapper">
                {this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={(e) => {
                            // Toggle off the clipper
                            this.setState({
                              showClipper: false,
                            });

                            this.onPlayCVR();
                          }}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}

                      {(this.state.cvrMode || this.state.liveStreamMode) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}
                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.downloadLiveImage()}
                      >
                        <img src={camera} alt="icon" />
                      </span>
                      {!this.state.showClipper &&
                        (!this.state.hideFilmIcon ? (
                          <span
                            className="icons-image"
                            onClick={(e) => {
                              this.onPauseCVR(e);
                              this.enableClipper(e);
                            }}
                          >
                            <FiFilm className="icons-image-clipper" />{' '}
                          </span>
                        ) : (
                          <span className="icons-image">
                            <OverlayTrigger
                              key={1}
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {Utils.replaceStringWithKeyValueArray(
                                    constants.CLIP_STORAGE_TOOLTIP,
                                    [
                                      {
                                        key: '$allocatedStorage',
                                        value:
                                          this.state.storageData
                                            ?.allocatedStorage / 1024,
                                      },
                                    ]
                                  )}
                                </Tooltip>
                              }
                            >
                              <img
                                width={16}
                                src={moviewarning}
                                alt="icon"
                                className="dis-mouse"
                              />
                            </OverlayTrigger>
                          </span>
                        ))}
                      <button
                        onClick={() => {
                          if (this.state.showClipper === false) {
                            this.onClickMins();
                          }
                        }}
                        className={`mins ${
                          this.state.showClipper ? 'no-pointer' : ''
                        }`}
                      >
                        {this.state.selectedOption || ''}
                      </button>
                    </div>
                    <div className="live">
                      Live
                      <span
                        className="filter-date-image"
                        onClick={() => {
                          this.hideShowdateTimeModal(true);
                          if (this.state.fullscreen) {
                            this.exitFullScreen();
                            this.setState({
                              fullscreenBackup: true,
                            });
                          }
                        }}
                      >
                        <img src={calender} alt="" />
                      </span>
                    </div>
                    <div className={`active_time`}>
                      <div className="time">
                        {isRemoteStreamPlay &&
                          moment
                            .tz(
                              moment(this.state.activeTime),
                              this.state.timeZone
                            )
                            .format('hh:mm:ss A z')}
                      </div>
                      {!this.state.liveStreamMode ? (
                        <button
                          className={`speed ${
                            this.state.liveStreamMode ? 'speed-disabled' : ''
                          }`}
                          onClick={this.onChangeSpeed}
                          disabled={this.state.liveStreamMode}
                        >
                          {this.speedList[this.state.speedIndex]}
                        </button>
                      ) : null}
                      <div
                        id="settings"
                        className="icons-image"
                        ref={this.settingsIcon}
                      >
                        <img
                          src={settings}
                          alt="icon"
                          onClick={() => this.onClickSettingsIcon()}
                        />
                      </div>
                      <span
                        className="icons-image"
                        onClick={() =>
                          this.state.fullscreen
                            ? this.exitFullScreen()
                            : this.enterFullScreen()
                        }
                      >
                        <img
                          src={this.state.fullscreen ? minimize : fullscreen}
                          alt="icon"
                        />
                      </span>
                    </div>
                  </>
                )}
                {!this.state.liveStreamMode && (
                  <>
                    <div className="timeline-controls-icons">
                      {!(
                        this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked
                      ) && (
                        <span
                          onClick={(e) => {
                            this.onPlayCVR();
                          }}
                          className="icons-image"
                        >
                          <img width={16} src={play} alt="icon" />
                        </span>
                      )}
                      {(this.state.cvrMode ||
                        this.state.liveStreamMode ||
                        !this.state.manualClicked) && (
                        <span
                          onClick={(e) => this.onPauseCVR(e)}
                          className="icons-image"
                        >
                          <img width={16} src={pause} alt="icon" />
                        </span>
                      )}
                      <span
                        className="icons-image"
                        onClick={() => this.onPreviousIconClick()}
                      >
                        <img width={16} src={previous} alt="previous icon" />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.onNextIconClick()}
                      >
                        <img width={16} src={next} alt="next icon" />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.onMuteIconClick()}
                      >
                        <img
                          width={16}
                          src={this.state.muteAudio ? mute : unmute}
                          alt="icon"
                        />
                      </span>
                      <span
                        className="icons-image"
                        onClick={() => this.downloadPlaybackImage()}
                      >
                        <img src={camera} alt="icon" />
                      </span>
                      {!this.state.showClipper &&
                        (!this.state.hideFilmIcon ? (
                          <span
                            className="icons-image"
                            onClick={(e) => {
                              this.onPauseCVR(e);
                              this.enableClipper(e);
                            }}
                          >
                            <FiFilm className="icons-image-clipper" />{' '}
                          </span>
                        ) : (
                          <span className="icons-image">
                            <OverlayTrigger
                              key={2}
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {Utils.replaceStringWithKeyValueArray(
                                    constants.CLIP_STORAGE_TOOLTIP,
                                    [
                                      {
                                        key: '$allocatedStorage',
                                        value:
                                          this.state.storageData
                                            ?.allocatedStorage / 1024,
                                      },
                                    ]
                                  )}
                                </Tooltip>
                              }
                            >
                              <img
                                width={16}
                                src={moviewarning}
                                alt="icon"
                                className="dis-mouse"
                              />
                            </OverlayTrigger>
                          </span>
                        ))}

                      <button
                        onClick={() => {
                          if (this.state.showClipper === false) {
                            this.onClickMins();
                          }
                        }}
                        className={`mins ${
                          this.state.showClipper ? 'no-pointer' : ''
                        }`}
                      >
                        {this.state.selectedOption || ''}
                      </button>
                    </div>
                    {/* <div className="live">Live</div>
                  <div onClick={() => this.onGoLive()}>Go Live</div> */}
                    <div className={`active_time`}>
                      <>
                        {moment
                          .tz(
                            moment(this.state.activeTime),
                            this.state.timeZone
                          )
                          .format('MMM DD, YYYY - hh:mm:ss A z')}
                        <div className="date">
                          <span
                            className="filter-date-image"
                            onClick={() => {
                              this.hideShowdateTimeModal(true);
                              if (this.state.fullscreen) {
                                this.exitFullScreen();
                                this.setState({
                                  fullscreenBackup: true,
                                });
                              }
                            }}
                          >
                            <img src={calender} alt="" />
                          </span>
                        </div>
                      </>
                    </div>
                    <div className="golive-width">
                      <div className="golive-view">
                        <button
                          className="live"
                          onClick={() => this.onGoLive()}
                        >
                          {constants.CAMERAS_LIVE_PLAYBACK_GO_LIVE_BUTTON_LABEL}
                        </button>
                      </div>
                      {!this.state.liveStreamMode ? (
                        <button
                          className={`speed ${
                            this.state.liveStreamMode ? 'speed-disabled' : ''
                          }`}
                          onClick={this.onChangeSpeed}
                          disabled={this.state.liveStreamMode}
                        >
                          {this.speedList[this.state.speedIndex]}
                        </button>
                      ) : null}
                      <div
                        id="settings"
                        className="settings-icons-image"
                        ref={this.settingsIcon}
                      >
                        <img
                          src={settings}
                          alt="icon"
                          onClick={() => this.onClickSettingsIcon()}
                        />
                      </div>
                      <span
                        className="icons-image screen-size"
                        onClick={() =>
                          this.state.fullscreen
                            ? this.exitFullScreen()
                            : this.enterFullScreen()
                        }
                      >
                        <img
                          src={this.state.fullscreen ? minimize : fullscreen}
                          alt="icon"
                        />
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
            {!this.state.clickAction && this.state.metaDataHere && (
              <>
                <div
                  className={`scrubber${
                    this.state.showClipper ? ' midpoint-hidden' : ''
                  }`}
                >
                  <Timeline
                    ref={this.timeline}
                    options={this.getOptions()}
                    rangechangeHandler={(event) =>
                      this.rangeChangeHandler(event)
                    }
                    rangechangedHandler={(event) =>
                      this.rangeChangedHandler(event)
                    }
                  />
                </div>
                {this.state.CVRSnapMode &&
                  this.state.showClipper &&
                  !this.props.streaming.streamLoader && (
                    <div ref={this.clipperRef} className="clipper">
                      <Rnd
                        ref={(c) => {
                          this.rnd = c;
                        }}
                        className="clipper-resizable-container"
                        size={{
                          width: this.state.clipper.width,
                          height: this.state.clipper.height,
                        }}
                        enableResizing={{
                          top: false,
                          right: true,
                          bottom: false,
                          left: true,
                          topRight: false,
                          bottomRight: false,
                          bottomLeft: false,
                          topLeft: false,
                        }}
                        resizeHandleWrapperClass="clipper-handle"
                        resizeHandleComponent={{
                          left: (
                            <HiOutlineDotsVertical className="three-dots left-handle" />
                          ),
                          right: (
                            <HiOutlineDotsVertical className="three-dots right-handle" />
                          ),
                        }}
                        minWidth={this.state.clipper.minDurationInPixels}
                        maxWidth={this.state.clipper.maxDurationInPixels}
                        maxHeight={30}
                        position={{ x: this.state.clipper.x, y: 0 }}
                        on
                        onDrag={(e, d) => {
                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              d?.x,
                              this.state.clipper.width
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          // Set new clip start and end times
                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              Utils.getUnixDate(this.state.defaultActiveTime),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          this.setState({
                            activeTime: Utils.getDate(
                              beforeAndAfterTimes.beforeUnixTime / 1000
                            ),
                          });
                        }}
                        onDragStart={(e, d) => {
                          let clipperDiv = this.clipperRef?.current;
                          let clipperRect = clipperDiv?.getBoundingClientRect();
                          let clipperLeftPosition = clipperRect?.left;
                          let clipperRightPosition = clipperRect?.right;
                          let clipperRightPositionOffset =
                            clipperRightPosition -
                            parseInt(
                              this.state.clipper.currentDurationInPixels
                            ) -
                            window.scrollX -
                            30;
                          let newClipperPosition = d.x;

                          // First save current duration
                          const startTimeMoment = moment
                            .utc(this.state.clipStartTime)
                            .tz(this.state.timeZone);

                          // Convert milliseconds to seconds
                          const endTimeMoment = moment
                            .utc(this.state.clipEndTime)
                            .tz(this.state.timeZone);

                          // Disable previous errors
                          this.setState({
                            clipStartTimeError: false,
                            clipStartTimeErrorMsg: '',
                            invalidNewStartTime: false,
                            clipEndTimeError: false,
                            clipEndTimeErrorMsg: '',
                            invalidNewEndTime: false,
                            timeEntryErrorMessageType: 0,
                          });

                          // Save current duration
                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              currentDurationInSeconds: endTimeMoment.diff(
                                startTimeMoment,
                                'seconds'
                              ),
                            },
                          });

                          if (d.x < clipperLeftPosition) {
                            newClipperPosition = 0;
                          } else if (d.x > clipperRightPositionOffset) {
                            newClipperPosition = clipperRightPositionOffset;
                          }

                          // Toggle on highlight color
                          this.clipperMarkerRef.current.classList.add(
                            'highlight'
                          );

                          this.setState({
                            ...this.state.clipper,
                            x: newClipperPosition,
                            y: 0,
                          });
                        }}
                        onDragStop={(e, d) => {
                          let clipperDiv = this.clipperRef.current;
                          let clipperRect = clipperDiv.getBoundingClientRect();
                          let clipperLeftPosition = clipperRect.left;
                          let clipperRightPosition = clipperRect.right;
                          let clipperRightPositionOffset =
                            clipperRightPosition -
                            parseInt(
                              this.state.clipper.currentDurationInPixels
                            ) -
                            window.scrollX -
                            30;
                          let newClipperPosition = d.x;

                          if (d.x < clipperLeftPosition) {
                            newClipperPosition = 0;
                          } else if (d.x > clipperRightPositionOffset) {
                            newClipperPosition = clipperRightPositionOffset;
                          }

                          // Toggle off highlight color
                          this.clipperMarkerRef.current.classList.remove(
                            'highlight'
                          );

                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              x: newClipperPosition,
                              y: 0,
                            },
                          });

                          const newDurationInSeconds =
                            parseInt(
                              this.state.clipper.currentDurationInPixels
                            ) / this.state.timelinePixelsPerSecond;

                          // Convert start time in milliseconds to seconds
                          const startTimeMoment = moment
                            .utc(this.state.activeTime.valueOf())
                            .tz(this.state.timeZone);

                          // Add current duration in seconds to the start time
                          const endTimeMoment = startTimeMoment
                            .clone()
                            .add(newDurationInSeconds, 'seconds');

                          this.setState({
                            clipStartTime: startTimeMoment.valueOf(),
                            clipEndTime: endTimeMoment.valueOf(),
                          });
                        }}
                        dragAxis={'x'}
                        bounds=".vis-item-overflow"
                        onResize={(e, direction, ref, delta, position) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];

                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              currentDurationInPixels: rawWidth,
                              currentDurationInSeconds:
                                rawWidth / this.state.timelinePixelsPerSecond,
                            },
                          });

                          if (direction === 'left') {
                            this.clipperMarkerRef.current.classList.remove(
                              'engage-right'
                            );
                            this.clipperMarkerRef.current.classList.add(
                              'engage-left'
                            );
                          } else {
                            // Mouse pointer is on the right side
                            this.clipperMarkerRef.current.classList.remove(
                              'engage-left'
                            );
                            this.clipperMarkerRef.current.classList.add(
                              'engage-right'
                            );
                          }

                          // Toggle on highlight color
                          if (
                            rawWidth <
                              this.state.clipper.maxDurationInPixels - 1 &&
                            rawWidth > this.state.clipper.minDurationInPixels
                          ) {
                            this.clipperMarkerRef.current.classList.add(
                              'highlight'
                            );
                          } else {
                            this.clipperMarkerRef.current.classList.remove(
                              'highlight'
                            );
                          }

                          const beforeAndAfterOffsets =
                            Utils.calculateBeforeAndAfterOffsets(
                              this.state.timelineMidpoint,
                              position?.x,
                              rawWidth
                            );

                          if (!beforeAndAfterOffsets) {
                            return;
                          }

                          // Set new clip start and end times
                          const beforeAndAfterTimes =
                            Utils.getBeforeAndAfterUnixTimes(
                              Utils.getUnixDate(this.state.defaultActiveTime),
                              Math.ceil(
                                beforeAndAfterOffsets?.startOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddStartOffset,
                              Math.ceil(
                                beforeAndAfterOffsets?.endOffset /
                                  this.state.timelinePixelsPerSecond
                              ),
                              beforeAndAfterOffsets?.shouldAddEndOffset
                            );

                          if (!beforeAndAfterTimes) {
                            return;
                          }

                          if (direction === 'left') {
                            this.setState({
                              activeTime: Utils.getDate(
                                beforeAndAfterTimes.beforeUnixTime / 1000
                              ),
                            });
                          } else {
                            this.setState({
                              activeTime: Utils.getDate(
                                beforeAndAfterTimes.afterUnixTime / 1000
                              ),
                            });
                          }
                        }}
                        onResizeStart={(e, direction, ref) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];
                          const rawHeight = ref?.style?.height?.split('px');

                          // Disable previous errors
                          this.setState({
                            clipStartTimeError: false,
                            clipStartTimeErrorMsg: '',
                            invalidNewStartTime: false,
                            clipEndTimeError: false,
                            clipEndTimeErrorMsg: '',
                            invalidNewEndTime: false,
                            timeEntryErrorMessageType: 0,
                          });

                          const newClipperValues = {
                            clipper: {
                              ...this.state.clipper,
                              width: rawWidth,
                              height: rawHeight,
                              y: 0,
                            },
                          };

                          this.setState(newClipperValues);
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          const rawWidth = ref?.style?.width?.split('px')[0];
                          const rawHeight = ref?.style?.height?.split('px');

                          // Revert to default active time
                          this.setState({
                            clipper: {
                              ...this.state.clipper,
                              currentDurationInPixels: rawWidth,
                              currentDurationInSeconds:
                                rawWidth / this.state.timelinePixelsPerSecond,
                            },
                          });

                          // Toggle off highlight color
                          this.clipperMarkerRef.current.classList.remove(
                            'highlight'
                          );
                          this.clipperMarkerRef.current.classList.remove(
                            'engage-left'
                          );
                          this.clipperMarkerRef.current.classList.remove(
                            'engage-right'
                          );

                          const newDurationInSeconds =
                            this.state.clipper.currentDurationInPixels /
                            this.state.timelinePixelsPerSecond;

                          let startTimeMoment, endTimeMoment;

                          if (direction?.toUpperCase() === 'LEFT') {
                            // Convert start time in milliseconds to seconds
                            startTimeMoment = moment(
                              this.state.activeTime.valueOf()
                            ).tz(this.state.timeZone);

                            // Add current duration in seconds to the start time
                            endTimeMoment = startTimeMoment
                              .clone()
                              .add(newDurationInSeconds, 'seconds');

                            this.setState({
                              clipStartTime: moment
                                .utc(startTimeMoment)
                                .tz(this.state.timeZone)
                                .valueOf(),
                            });
                          } else {
                            // Convert start time in milliseconds to seconds
                            endTimeMoment = moment(
                              this.state.activeTime.valueOf()
                            ).tz(this.state.timeZone);

                            // Add current duration in seconds to the start time
                            startTimeMoment = endTimeMoment
                              .clone()
                              .subtract(newDurationInSeconds, 'seconds');

                            this.setState({
                              clipEndTime: moment
                                .utc(endTimeMoment)
                                .tz(this.state.timeZone)
                                .valueOf(),
                            });
                          }

                          // Save new duration in seconds
                          const newClipperValues = {
                            clipper: {
                              ...this.state.clipper,
                              ...position,
                              width: rawWidth,
                              height: rawHeight,
                              y: 0,
                              currentDurationInSeconds:
                                this.getNewDurationInSeconds(
                                  this.state.clipStartTime,
                                  this.state.clipEndTime
                                ),
                            },
                          };

                          this.setState(newClipperValues);
                        }}
                      >
                        <div
                          ref={this.clipperMarkerRef}
                          className="clipper-resizable-marker"
                        ></div>
                      </Rnd>
                    </div>
                  )}
              </>
            )}
          </div>
          {this.state.liveStreamMode && !this.state.showClipper && (
            <div className={`timeline-icons golive`}>
              <button onClick={() => this.onGoLive()} className="golive-button">
                {constants.TOP_HEADER_LIVE_NAV_TITLE}
              </button>
            </div>
          )}
        </div>
        {/* Modals */}
        {/* Create Clip / New Incident */}
        <SiteModal
          key={'create-clip-modal'}
          classes="create-clip-modal"
          wrapperClass="create-clip-modal-wrapper"
          shouldScrollToTop={this.state.shouldScrollToTop}
          modalTitle={
            this.state.showShareVideoClipModal
              ? constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_TITLE
              : this.state.showCreateNewIncidentModal
              ? constants.INCIDENTS_MODAL_NEW_INCIDENT_TITLE
              : constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_CLIP_TITLE
          }
          showModal={this.state.showCreateClipModal}
          hideModal={() => {
            if (this.state.createClipRequestSubmitted === true) {
              this.resetClipperProperties();
              this.setState({ showClipper: false });
              this.setState({ createdClipViewed: false });
            }

            this.setState({ createClipLoader: false });
            this.setState({ createClipRequestSubmitted: false });
            this.setState({ showCreateClipModal: false });
            this.setState({ showCreateNewIncidentModal: false });
            this.setState({ shouldResetCreateClipForm: true });
            this.setState({ shouldSubmitCreateClipRequest: false });
            this.setState({ showPostCreateClipForm: false });
            this.setState({ showShareVideoClipModal: false });
          }}
          modalFooter={
            this.state.showCreateNewIncidentModal ? (
              <>
                <div className="add-to-incident-button-wrapper">
                  <PrimaryButton
                    className="save-btn"
                    loader={this.state.showButtonLoader}
                    type="submit"
                    disabled={!this.state.isNewIncidentFormComplete}
                    fontSize={'18px'}
                    fontWeight={'600'}
                    onClick={(e) => {
                      e?.preventDefault();
                      this.setState({ submitNewIncidentForm: true });
                      this.setState({ showButtonLoader: true });
                      this.setState({ showPostCreateClipForm: true });
                    }}
                  >
                    {constants.INCIDENTS_MODAL_NEW_INCIDENT_CREATE_BUTTON_LABEL}
                  </PrimaryButton>
                </div>
                <div className="add-to-incident-button-wrapper">
                  <Button
                    className="add-to-incident-create-new-btn"
                    variant="outline-secondary"
                    onClick={(e) => {
                      this.setState({ showPostCreateClipForm: true });
                      this.setState({ showCreateNewIncidentModal: false });
                    }}
                  >
                    {constants.INCIDENTS_MODAL_NEW_INCIDENT_CANCEL_BUTTON_LABEL}
                  </Button>
                </div>
              </>
            ) : !this.state.showShareVideoClipModal ? (
              this.state.createClipRequestSubmitted === true ? (
                <>
                  <div className="mb-1 create-clip-button-wrapper">
                    <PrimaryButton
                      className="create-clip-watch-clip-btn"
                      type="button"
                      // disabled={!this.state.canCreateClip}
                      onClick={(e) => {
                        e?.preventDefault();
                        this.setState({ proceedToClipDetails: true });
                      }}
                    >
                      {
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_WATCH_CLIP_BUTTON_LABEL
                      }
                    </PrimaryButton>
                  </div>
                  <div className="mb-1 create-clip-button-wrapper">
                    <Button
                      className="create-clip-close-btn"
                      variant="outline-secondary"
                      loader={this.state.confirmLoader}
                      disabled={!this.state.canCreateClip}
                      onClick={(e) => {
                        e?.preventDefault();

                        if (this.state.createClipRequestSubmitted === true) {
                          this.resetClipperProperties();
                          this.setState({ showClipper: false });
                          this.setState({ createClipRequestSubmitted: false });
                        }

                        this.setState({
                          shouldResetCreateClipForm: true,
                        });
                        this.setState({ showCreateClipModal: false });
                        this.setState({ createClipRequestSubmitted: false });
                        this.setState({ createClipLoader: false });
                        this.setState({ createdClipViewed: false });
                        this.setState({ showPostCreateClipForm: false });
                      }}
                    >
                      {
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CLOSE_BUTTON_LABEL
                      }
                    </Button>
                  </div>
                </>
              ) : (
                <div className="mb-1 create-clip-button-wrapper">
                  <PrimaryButton
                    className="create-clip-save-btn"
                    type="button"
                    loader={this.state.createClipLoader}
                    disabled={
                      !this.state.canCreateClip ||
                      !this.state.clipStartTime ||
                      !this.state.clipEndTime
                    }
                    onClick={(e) => {
                      e?.preventDefault();
                      this.setState({ createClipLoader: true });
                      this.setState({ shouldSubmitCreateClipRequest: true });
                      this.setState({ createdClipViewed: false });
                    }}
                  >
                    {
                      constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_CLIP_BUTTON_LABEL
                    }
                  </PrimaryButton>
                </div>
              )
            ) : null
          }
        >
          {this.state.showCreateNewIncidentModal ? (
            <>
              <NewIncident
                shouldSubmitForm={this.state.submitNewIncidentForm}
                openModal={(showModal) => {
                  this.setState({ showCreateNewIncidentModal: showModal });
                }}
                enableButtonLoader={(enable) =>
                  this.setState({ showButtonLoader: enable })
                }
                setFormComplete={(bool) => {
                  this.setState({ isNewIncidentFormComplete: bool });
                }}
                newClipId={this.state.clipObject?.clipId}
                submitCallback={(incidentId) => {
                  if (!incidentId) return;

                  // Clip Form isn't viewed when you're adding a
                  // new incident
                  this.setState({ createdClipViewed: false });
                  // Save the new incident ID
                  this.setState({ clipNewIncidentId: incidentId });
                  // Prevent Create Clip form from being submitted again
                  this.setState({ shouldSubmitCreateClipRequest: false });
                  // Show the Post Clip Create Form
                  this.setState({ showPostCreateClipForm: true });
                  // Show the added to incident notification
                  this.setState({ showAddedToIncident: true });
                }}
              />
            </>
          ) : (
            <>
              <CreateClip
                cdnValue={this.props?.streaming?.cdnInfo}
                deviceId={this.props?.account?.deviceInformation?.deviceId}
                deviceTimezone={this.state.timeZone}
                startTime={this.state.clipStartTime}
                endTime={this.state.clipEndTime}
                canSubmitEnabler={(value) =>
                  this.setState({ canCreateClip: value })
                }
                shouldSubmitCreateClipRequest={
                  this.state.shouldSubmitCreateClipRequest
                }
                shouldResetCreateClipForm={this.state.shouldResetCreateClipForm}
                shouldShowPostCreateClipForm={this.state.showPostCreateClipForm}
                enableShareVideoClipModal={(shouldShowShareModal = false) =>
                  this.setState({
                    showShareVideoClipModal: shouldShowShareModal,
                  })
                }
                openNewIncidentModal={(bool) => {
                  this.setState({ shouldScrollToTop: true });
                  this.setState({ showCreateNewIncidentModal: bool });
                }}
                newIncidentId={this.state.clipNewIncidentId}
                saveClipObject={(clipObj) =>
                  this.setState({ clipObject: clipObj })
                }
                clipObject={this.state.clipObject}
                addedToIncident={this.state.showAddedToIncident}
                buttonLoaderCallback={(bool) =>
                  this.setState({ createClipLoader: bool || false })
                }
                callback={() => {
                  this.setState({ shouldScrollToTop: true });
                  // Prevent generating another clip request
                  this.setState({ shouldSubmitCreateClipRequest: false });
                  this.setState({ createClipRequestSubmitted: true });
                }}
                hideClipModal={() => {
                  this.setState({ showClipper: false });
                  this.setState({ showCreateClipModal: false });
                  this.setState({ createClipLoader: false });
                  this.setState({ shouldResetCreateClipForm: true });
                  this.setState({ createClipRequestSubmitted: false });
                  this.setState({ showPostCreateClipForm: false });
                  this.setState({ shouldSubmitCreateClipRequest: false });
                  this.setState({ showShareVideoClipModal: false });
                }}
              />
            </>
          )}
        </SiteModal>
        {/* Add to Incident */}
        <SiteModal
          key={'add-to-incident-modal'}
          classes="add-to-incident-modal"
          wrapperClass="add-to-incident-modal-wrapper"
          modalTitle={constants.CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT}
          showModal={this.state.showAddToIncidentModal}
          hideModal={() => {
            this.setState({ showAddToIncidentModal: false });
          }}
          modalFooter={
            <>
              <div className="mb-1 add-to-incident-button-wrapper">
                <PrimaryButton
                  className="add-to-incident-save-btn"
                  type="button"
                  loader={this.state.confirmLoader}
                  onClick={(e) => {
                    e?.preventDefault();
                    this.handleAddToIncident();
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
              <div className="add-to-incident-button-wrapper">
                <Button
                  className="add-to-incident-create-new-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    // TODO: implement new incident functionality
                    Utils.vmsLogger().log(
                      'Add To Incident - Create New Clicked'
                    );
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CREATE_NEW_INCIDENT_BUTTON_LABEL
                  }
                </Button>
              </div>
            </>
          }
        >
          <div
            className={`add-to-incident-error ${
              !this.state.showAddToIncidentError ? 'hidden' : ''
            }`}
          >
            <span className="add-to-incident-error-message">
              {constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_ERROR}
            </span>
          </div>
          <RadioGroup
            row
            aria-labelledby="add-to-incident-form-control-label-placement"
            name="incident"
            defaultValue={''}
            className="add-to-incident-radio-group"
            onChange={(e) => {
              if (!e) return;

              this.setState({ assignedIncidentId: e?.target?.value });
            }}
          >
            {Array.isArray(this.state.currentIncidents) &&
              this.state.currentIncidents.map((incident) => (
                <TextBlock
                  key={incident?.incidentId}
                  className="d-flex justify-content-between align-items-center add-to-incident-radio-row"
                >
                  <div className="add-to-incident-summary">
                    <HiOutlineFolderAdd className="add-to-incident-folder" />
                    <span className="add-to-incident-summary-title">
                      {incident?.summary}
                    </span>
                  </div>
                  <Radio
                    value={incident?.incidentId}
                    className="add-to-incident-radio"
                  />
                </TextBlock>
              ))}
          </RadioGroup>
        </SiteModal>
        {/* Add to Incident Confirmation */}
        <SiteModal
          key={'add-to-incident-confirmation-modal'}
          classes="add-to-incident-confirmation-modal"
          wrapperClass="add-to-incident-confirmation-modal-wrapper"
          modalTitle={
            constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_TITLE
          }
          showModal={this.state.showAddToIncidentConfirmationModal}
          hideModal={() => {
            this.setState({ showAddToIncidentConfirmationModal: false });
          }}
          modalFooter={
            <>
              <div className="mb-1 add-to-incident-confirmation-button-wrapper">
                <PrimaryButton
                  className="add-to-incident-confirmation-go-to-incident-btn"
                  type="button"
                  onClick={(e) => {
                    e?.preventDefault();
                    this.setState({ proceedToIncident: true });
                    this.setState({
                      showAddToIncidentConfirmationModal: false,
                    });
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_GO_TO_INCIDENT_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
              <div className="add-to-incident-confirmation-button-wrapper">
                <Button
                  className="add-to-incident-confirmation-back-to-video-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    this.setState({
                      showAddToIncidentConfirmationModal: false,
                    });
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_BACK_TO_VIDEO_BUTTON_LABEL
                  }
                </Button>
              </div>
            </>
          }
        >
          <div className="add-to-incident-confirmation-description">
            {
              constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_DESCRIPTION
            }
          </div>
          <div className="add-to-incident-confirmation-success-icon">
            <img src={success} alt="green circled check mark" />
          </div>
        </SiteModal>
        {/* Go to Date */}
        <SiteModal
          modalTitle={constants.CAMERAS_LIVE_GO_TO_DATE_MODAL_TITLE}
          showModal={this.state.showDateTimeModal}
          hideModal={() => {
            this.hideShowdateTimeModal(false);
          }}
          classes="date-time-picker-modal"
          size="sm"
        >
          <DateTimePicker
            date={this.state.selectedDate}
            time={this.state.selectedTime}
            minDate={cvrStartDate}
            timeZone={this.state.timeZone}
            onSubmit={this.onSubmit}
            offset={this.state.offset}
          />
        </SiteModal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAllDevicesData,
  setDeviceInformation,
  setChannelTimeStamp,
  setEndOfStream,
  setStreamLoader,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(TimelineControls);
