import { create } from 'zustand';

const useEventSelectionStore = create((set, get) => ({
  navigatedFromCameraDetail: false,
  eventsData: [],
  locationsData: [],
  areasData: [],
  devciesData: [],
  devicesListToPass: [],
  selectedData: [],
  allLocationsData: [],
  allAreasData: [],
  allDevicesData: [],
  isLocationListVisible : false,
  isAreaListVisible: false,
  isDeviceListVisible: false,
  filteredLocations: [],
  filteredAreas: [],
  filteredDevices: [],
  visibleItemCountForLocation : [],
  visibleItemCountForArea: [],
  visibleItemCountForDevice: [],
  selectedLocations: [],
  selectedAreas: [],
  selectedDevices: [],
  eventsData: [],
  eventTotal: [],
  selectedDateTOShow: [],
  lastSelectedDate: [],
  lastSelectedDateSubmit: [],
  lastSelectedTimeSubmit: [],
  filterDate: [],
  selectedTime: [],
  startTimeOfEvent: [],
  endTimeOfEvent: [],
  startTimePeriod: [],
  endTimePeriod: [],
  endTimeToPass: [],
  startTimeToPass: [],
  eventType: "",

  getPreSelectedEventType: () => {
    return get().eventType;
  },

  setPreSelectedEventType: (value) => {
    set({ eventType: value });
  },

  getNavigatedFromCameraDetail: () => {
    return get().navigatedFromCameraDetail;
  },

  setNavigatedFromCameraDetail: (value) => {
    set({ navigatedFromCameraDetail: value });
  },

  getPreSelectedLocation: () => {
    return get().locationsData;
  },

  setPreSelectedLocation: async (data) => {
    set({ locationsData: [] });
    set({
      locationsData: data,
    });
  },

  removePreSelectedLocations: () => set({ locationsData: [] }),

  getPreSelectedArea: () => {
    return get().areasData;
  },

  setPreSelectedArea: async (data) => {
    set({ areasData: [] });
    set({
      areasData: data,
    });
  },

  removePreSelectedArea: () => set({ areasData: [] }),

  getPreSelectedDevice: () => {
    return get().devciesData;
  },

  setPreSelectedDevice: async (data) => {
    set({ devciesData: [] });
    set({
      devciesData: data,
    });
  },

  removePreSelectedDevice: () => set({ devciesData: [] }),

  getPreSelectedDeviceListToPass: () => {
    return get().devicesListToPass;
  },

  setPreSelectedDeviceListToPass: async (data) => {
    set({ devicesListToPass: [] });
    set({
      devicesListToPass: data,
    });
  },

  removePreSelectedDeviceListToPass: () => set({ devicesListToPass: [] }),

  getSelectedData: () => {
    return get().selectedData;
  },

  setSelectedData: (value) => {
    set({ selectedData: value });
  },

  removeSelectedData: () => set({ selectedData: [] }),

  // Getter function for allLocationsData
  getPreSelectedAllLocationsData: () => {
    return get().allLocationsData;
  },

  // Setter function for allLocationsData
  setPreSelectedAllLocationsData: (data) => {
    set({ allLocationsData: [] });
    set((state) => ({ allLocationsData: data }));
  },

  // Remove function for allLocationsData
  removeAllLocationsData: () => {
    set((state) => ({ allLocationsData: [] }));
  },

  // Getter function for allAreasData
  getPreSelectedAllAreasData: () => {
    return get().allAreasData;
  },

  // Setter function for allAreasData
  setPreSelectedAllAreasData: (data) => {
    set({ allAreasData: [] });
    set((state) => ({ allAreasData: data }));
  },

  // Remove function for allAreasData
  removeAllAreasData: () => {
    set((state) => ({ allAreasData: [] }));
  },

  // Getter function for allDevicesData
  getPreSelectedAllDevicesData: () => {
    return get().allDevicesData;
  },

  // Setter function for allDevicesData
  setPreSelectedAllDevicesData: (data) => {
    set({ allDevicesData: [] });
    set((state) => ({ allDevicesData: data }));
  },

  // Remove function for allDevicesData
  removeAllDevicesData: () => {
    set((state) => ({ allDevicesData: [] }));
  },

  // Getter function for isLocationListVisible
  getPreSelectedIsLocationListVisible: () => {
    return get().isLocationListVisible;
  },

  // Setter function for isLocationListVisible
  setPreSelectedIsLocationListVisible: (value) => {
    set((state) => ({ isLocationListVisible: value }));
  },

  // Getter function for isAreaListVisible
  getPreSelectedIsAreaListVisible: () => {
    return get().isAreaListVisible;
  },

  // Setter function for isAreaListVisible
  setPreSelectedIsAreaListVisible: (value) => {
    set((state) => ({ isAreaListVisible: value }));
  },

  // Getter function for isDeviceListVisible
  getPreSelectedIsDeviceListVisible: () => {
    return get().isDeviceListVisible;
  },

  // Setter function for isDeviceListVisible
  setPreSelectedIsDeviceListVisible: (value) => {
    set((state) => ({ isDeviceListVisible: value }));
  },

  // Getter, setter, and remove functions for filteredLocations
  getPreSelectedFilteredLocations: () => {
    return get().filteredLocations;
  },

  setPreSelectedFilteredLocations: (data) => {
    set({ filteredLocations: [] });
    set({ filteredLocations: data });
  },

  removeFilteredLocations: () => {
    set({ filteredLocations: [] });
  },

  // Getter, setter, and remove functions for filteredAreas
  getPreSelectedFilteredAreas: () => {
    return get().filteredAreas;
  },

  setPreSelectedFilteredAreas: (data) => {
    set({ filteredAreas: [] });
    set({ filteredAreas: data });
  },

  removeFilteredAreas: () => {
    set({ filteredAreas: [] });
  },

  // Getter, setter, and remove functions for filteredDevices
  getPreSelectedFilteredDevices: () => {
    return get().filteredDevices;
  },

  setPreSelectedFilteredDevices: (data) => {
    set({ filteredDevices: [] });
    set({ filteredDevices: data });
  },

  removeFilteredDevices: () => {
    set({ filteredDevices: [] });
  },

  // Getter, setter, and remove functions for visibleItemCountForLocation
  getPreSelectedVisibleItemCountForLocation: () => {
    return get().visibleItemCountForLocation;
  },

  setPreSelectedVisibleItemCountForLocation: (data) => {
    set({ visibleItemCountForLocation: [] });
    set({ visibleItemCountForLocation: data });
  },

  removeVisibleItemCountForLocation: () => {
    set({ visibleItemCountForLocation: [] });
  },

  // Getter, setter, and remove functions for visibleItemCountForArea
  getPreSelectedVisibleItemCountForArea: () => {
    return get().visibleItemCountForArea;
  },

  setPreSelectedVisibleItemCountForArea: (data) => {
    set({ visibleItemCountForArea: [] });
    set({ visibleItemCountForArea: data });
  },

  removeVisibleItemCountForArea: () => {
    set({ visibleItemCountForArea: [] });
  },

  // Getter, setter, and remove functions for visibleItemCountForDevice
  getPreSelectedVisibleItemCountForDevice: () => {
    return get().visibleItemCountForDevice;
  },

  setPreSelectedVisibleItemCountForDevice: (data) => {
    set({ visibleItemCountForDevice: [] });
    set({ visibleItemCountForDevice: data });
  },

  removeVisibleItemCountForDevice: () => {
    set({ visibleItemCountForDevice: [] });
  },

  // Getter, setter, and remove functions for selectedLocations
  getPreSelectedLocations: () => {
    return get().selectedLocations;
  },

  setPreSelectedLocations: (data) => {
    set({ selectedLocations: [] });
    set({ selectedLocations: data });
  },

  removeSelectedLocations: () => {
    set({ selectedLocations: [] });
  },

  // Getter, setter, and remove functions for selectedAreas
  getPreSelectedAreas: () => {
    return get().selectedAreas;
  },

  setPreSelectedAreas: (data) => {
    set({ selectedAreas: [] });
    set({ selectedAreas: data });
  },

  removeSelectedAreas: () => {
    set({ selectedAreas: [] });
  },

  // Getter, setter, and remove functions for selectedDevices
  getPreSelectedDevices: () => {
    return get().selectedDevices;
  },

  setPreSelectedDevices: (data) => {
    set({ selectedDevices: [] });
    set({ selectedDevices: data });
  },

  removeSelectedDevices: () => {
    set({ selectedDevices: [] });
  },

   // Getter, setter, and remove functions for eventsData
   getPreSelectedEventsData: () => {
    return get().eventsData;
  },

  setPreSelectedEventsData: (data) => {
    set({ eventsData: [] });
    set({ eventsData: data });
  },

  removePreSelectedEventsData: () => {
    set({ eventsData: [] });
  },

    // Getter, setter, and remove functions for eventTotal
    getPreSelectedEventTotal: () => {
      return get().eventTotal;
    },
  
    setPreSelectedEventTotal: (data) => {
      set({ eventTotal: [] });
      set({ eventTotal: data });
    },
  
    removePreSelectedEventTotal: () => {
      set({ eventTotal: [] });
    },

    // Getter, setter, and remove methods for selectedDateToShow
    getPreSelectedDateToShow: () => get().selectedDateToShow,
    setPreSelectedDateToShow: (dates) => set({ selectedDateToShow: dates }),
    removePreSelectedDateToShow: () => set({ selectedDateToShow: [] }),

    // Getter, setter, and remove methods for lastSelectedDate
    getPreSelectedLastSelectedDate: () => get().lastSelectedDate,
    setPreSelectedLastSelectedDate: (date) => set({ lastSelectedDate: date }),
    removePreSelectedLastSelectedDate: () => set({ lastSelectedDate: [] }),

    // Getter, setter, and remove methods for lastSelectedDateSubmit
    getPreSelectedLastSelectedDateSubmit: () => get().lastSelectedDateSubmit,
    setPreSelectedLastSelectedDateSubmit: (date) => set({ lastSelectedDateSubmit: date }),
    removePreSelectedLastSelectedDateSubmit: () => set({ lastSelectedDateSubmit: [] }),

    // Getter, setter, and remove methods for lastSelectedTimeSubmit
    getPreSelectedLastSelectedTimeSubmit: () => get().lastSelectedTimeSubmit,
    setPreSelectedLastSelectedTimeSubmit: (time) => set({ lastSelectedTimeSubmit: time }),
    removePreSelectedLastSelectedTimeSubmit: () => set({ lastSelectedTimeSubmit: [] }),

    // Getter, setter, and remove methods for filterDate
    getPreSelectedFilterDate: () => get().filterDate,
    setPreSelectedFilterDate: (dates) => set({ filterDate: dates }),
    removePreSelectedFilterDate: () => set({ filterDate: [] }),

    // Getter, setter, and remove methods for selectedTime
    getPreSelectedSelectedTime: () => get().selectedTime,
    setPreSelectedSelectedTime: (time) => set({ selectedTime: time }),
    removePreSelectedSelectedTime: () => set({ selectedTime: [] }),

    // Getter, setter, and remove methods for startTimeOfEvent
    getPreSelectedStartTimeOfEvent: () => get().startTimeOfEvent,
    setPreSelectedStartTimeOfEvent: (time) => set({ startTimeOfEvent: time }),
    removePreSelectedStartTimeOfEvent: () => set({ startTimeOfEvent: [] }),

    // Getter, setter, and remove methods for endTimeOfEvent
    getPreSelectedEndTimeOfEvent: () => get().endTimeOfEvent,
    setPreSelectedEndTimeOfEvent: (time) => set({ endTimeOfEvent: time }),
    removePreSelectedEndTimeOfEvent: () => set({ endTimeOfEvent: [] }),

    // Getter, setter, and remove methods for startTimePeriod
    getPreSelectedStartTimePeriod: () => get().startTimePeriod,
    setPreSelectedStartTimePeriod: (period) => set({ startTimePeriod: period }),
    removePreSelectedStartTimePeriod: () => set({ startTimePeriod: [] }),

    // Getter, setter, and remove methods for endTimePeriod
    getPreSelectedEndTimePeriod: () => get().endTimePeriod,
    setPreSelectedEndTimePeriod: (period) => set({ endTimePeriod: period }),
    removePreSelectedEndTimePeriod: () => set({ endTimePeriod: [] }),

    // Getter, setter, and remove methods for endTimeToPass
    getPreSelectedStartTimeToPass: () => get().startTimeToPass,
    setPreSelectedStartTimeToPass: (time) => set({ startTimeToPass: time }),
    removePreSelectedStartTimeToPass: () => set({ startTimeToPass: [] }),

    // Getter, setter, and remove methods for endTimeToPass
    getPreSelectedEndTimeToPass: () => get().endTimeToPass,
    setPreSelectedEndTimeToPass: (time) => set({ endTimeToPass: time }),
    removePreSelectedEndTimeToPass: () => set({ endTimeToPass: [] }),

    resetStore: () => {
      set({
        navigatedFromCameraDetail: false,
        eventsData: [],
        locationsData: [],
        areasData: [],
        devciesData: [],
        devicesListToPass: [],
        selectedData: [],
        allLocationsData: [],
        allAreasData: [],
        allDevicesData: [],
        isLocationListVisible: false,
        isAreaListVisible: false,
        isDeviceListVisible: false,
        filteredLocations: [],
        filteredAreas: [],
        filteredDevices: [],
        visibleItemCountForLocation: [],
        visibleItemCountForArea: [],
        visibleItemCountForDevice: [],
        selectedLocations: [],
        selectedAreas: [],
        selectedDevices: [],
        eventTotal: [],
        selectedDateTOShow: [],
        lastSelectedDate: [],
        lastSelectedDateSubmit: [],
        lastSelectedTimeSubmit: [],
        filterDate: [],
        selectedTime: [],
        startTimeOfEvent: [],
        endTimeOfEvent: [],
        startTimePeriod: [],
        endTimePeriod: [],
        endTimeToPass: [],
        startTimeToPass: [],
      });
    },
}));

export default useEventSelectionStore;
