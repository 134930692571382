import './Spinner.scss';

const Spinner = ({
  height = '45',
  width = '45',
  strokeColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--custom_color_15'),
  strokeWidth = 10,
  fill = 'none',
}) => {
  return (
    <svg className="spinner" viewBox="0 0 50 50" height={height} width={width}>
      <circle
        className="path"
        cx={width}
        cy={height}
        r="20"
        fill={fill}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      ></circle>
    </svg>
  );
};

export default Spinner;
