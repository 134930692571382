import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useFiltersStore = create(
  persist(
    (set, get) => ({
      aplliedTags: '',
      tagList: [],
      removeTagPayload: [],

      getAppliedTags: () => {
        return get().aplliedTags;
      },

      setAppliedTags: (aplliedTags) => {
        set({
          aplliedTags: aplliedTags,
        });
      },

      getTagList: () => {
        return get().tagList;
      },

      setTagList: (tagList) => {
        set({
          tagList: tagList,
        });
      },

      getRemoveTagPayload: () => {
        return get().removeTagPayload;
      },

      setRemoveTagPayload: (payload) => {
        set({
          removeTagPayload: payload,
        });
      },
    }),
    {
      name: 'net.duclo.vms.filters',
      version: '1.0',
    }
  )
);

export default useFiltersStore;
