import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const useCustomerOrgClips = create(
  persist(
    (set, get) => ({
      customerOrgClipsData: {},

      getCustomerOrgClips: async (route) => {
        if (!route) return;

        const res = await axios.get(route, Utils.requestHeader());
        const response = res?.data;
        let result;

        // Set customer org users if request is successful
        if (response?.meta?.code === 200) {
          let clipsData = response?.data;
          if (clipsData) {
            set({
              customerOrgClipsData: clipsData,
            });
          }

          result = {
            status: 'success',
            msg: 'customer organizations clips data retrieved`',
          };
        } else {
          set({
            customerOrgClipsData: [],
          });
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },

      getCustomerOrgClipsData: (accountId) => {
        if (!accountId) return;

        return get().customerOrgClipsData[accountId];
      },

      setCustomerOrgClipsData: (customerOrgClips, accountId) => {
        if (!Array.isArray(customerOrgClips) || !accountId) return;

        set({
          customerOrgClipsData: {
            ...get().customerOrgClipsData,
            [accountId]: customerOrgClips,
          },
        });
      },

      resetCustomerOrgClipsData: () =>
        set((state) => {
          return {
            ...state,
            customerOrgClipsData: {},
          };
        }),
    }),
    {
      name: 'net.duclo.vms.customerorgclips',
      version: '1.0',
    }
  )
);
