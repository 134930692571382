import { Outlet } from 'react-router-dom';
import ManageLocations from './ManageLocations';
import NewLocation from './NewLocations';
import EditLocation from './EditLocations';

const Locations = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { EditLocation, Locations, ManageLocations, NewLocation };
