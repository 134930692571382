import { Card, Container, Col, Row } from 'react-bootstrap';
import { constants } from '../../../helpers';

import './Section.scss';

const Section = ({
  bgColor,
  analyticsTitle,
  analyticsDescription,
  viewDetailsLinkLabel,
  viewDetailsLinkHandler,
  topRightLinks,
  children,
  isFromOrgDetail = false,
  linkEnable
}) => {
  return (
    <div className={`section-wrapper ${isFromOrgDetail ? 'device-up-time-bg' :  bgColor ? bgColor : 'default-bg'}`}>
      <Container className="section-header mw-100">
        <Row className="d-flex flex-fill">
          <Col>
            <div className="d-flex justify-content-start align-items-center">
              <div className="section-title">{analyticsTitle}</div>
              <div className="section-view-details">
                <span
                  className={`view-details-link ${!linkEnable && 'disabled'}`}
                  onClick={() => {
                    viewDetailsLinkHandler && viewDetailsLinkHandler();
                  }}
                >
                  {viewDetailsLinkLabel}
                </span>
              </div>
            </div>
            <div className={`section-description d-block text-left ${isFromOrgDetail && 'uptime'}`}>
              {analyticsDescription}
            </div>
          </Col>
          <Col>
            <div className={`d-flex justify-content-end top-right-nav ${isFromOrgDetail && 'device-up-time'}`}>
              {topRightLinks}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="section-body mw-100">
        <Row>
          <div className="analytics-section">
            <Card.Body>{children}</Card.Body>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Section;
