import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Container, Col, Row } from 'react-bootstrap';

import {
  Header,
  SiteModal,
  SiteToast,
  Spinner,
} from '../../components/common/';
import PageWrapper from '../PageWrapper';
import { AppDefaults, constants } from '../../helpers';

import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';

import { HiOutlinePencil } from 'react-icons/hi';
import './LiveView.scss';
import Dashboard from '../multilive/components/Dashboard';
import { setIsOnMultiCameraViewPage } from '../../store/reducers/StreamingReducer';

const LiveView = ({ ...props }) => {
  const [showLocation, setShowLocation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [isContentReady, setIsContentReady] = useState(false);
  const { getLiveViewDevices, setCustomerOrgDevices } = useCustomerOrgDevices();
  const [viewDevices, setViewDevices] = useState([...getLiveViewDevices()]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (!Array.isArray(viewDevices) || viewDevices.length < 1) {
        navigate('/cameras/multiview-select.html');
      }
    } catch (err) {
    } finally {
    }
  }, []);

  useEffect(() => {
    dispatch(setIsOnMultiCameraViewPage(true));
    setViewDevices(viewDevices);
    return () => {
      dispatch(setIsOnMultiCameraViewPage(false));
    };
  }, [viewDevices]);

  return (
    <div className="App live-view">
      <Header showLocation={showLocation} showCart={false} />
      <div className="main-wrapper">
        <PageWrapper className="video-wall-background" fluid>
          <Row className="mb-5 video-wall-wrapper">
            <Col>
              <Container className="video-wall-container">
                <div className="page-header mt-4 mb-5">
                  <Row>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                      <span className="page-title">
                        {constants.CAMERAS_VIDEO_WALL_TITLE}
                      </span>
                      <div className="button-nav">
                        <Button
                          className="button-nav-item filter-button"
                          onClick={() => {
                            navigate('/cameras/multiview-select.html');
                          }}
                        >
                          <HiOutlinePencil size={15} className="edit-icon" />{' '}
                          {
                            constants.CAMERAS_LIVE_BUTTON_EDIT_MULTI_LIVE_VIEW_LABEL
                          }
                        </Button>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col className="cameras-container multilive-container">
                      {viewDevices.length && <Dashboard />}
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </PageWrapper>
        {/* Modals */}
      </div>
    </div>
  );
};

export default LiveView;
