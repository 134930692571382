import { useState } from 'react';

const PrimaryButton = (props) => {
  const defaultBackgroundColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--brand_primary');
  const defaultDisabledBackgroundColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--greyscale_88');
  const defaultHoverBackgroundColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary_24');
  const defaultColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--brand_white');
  const defaultHoverColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--brand_white');
  const defaultDisabledColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--greyscale_56');
  const defaultBorderColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--brand_primary');
  const defaultHoverBorderColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary_24');
  const defaultDisabledBorderColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--greyscale_88');
  const {
    backgroundColor = defaultBackgroundColor,
    disabledBackgroundColor = defaultDisabledBackgroundColor,
    hoverBackgroundColor = defaultHoverBackgroundColor,
    color = defaultColor,
    hoverColor = defaultHoverColor,
    disabledColor = defaultDisabledColor,
    borderColor = defaultBorderColor,
    hoverBorderColor = defaultHoverBorderColor,
    disabledBorderColor = defaultDisabledBorderColor,
  } = props;

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <button
      className={`${props.className} ${props?.loader ? 'pe-none' : ''}`}
      type={props.type}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: props.disabled
          ? disabledBackgroundColor
          : isHovering
            ? hoverBackgroundColor
            : backgroundColor,
        color: props.disabled ? disabledColor : isHovering ? hoverColor : color,
        borderColor: props.disabled
          ? disabledBorderColor
          : isHovering
            ? hoverBorderColor
            : borderColor,
        borderWidth: isHovering ? props.hoverBorderWidth : props.borderWidth,
        borderRadius: props.borderRadius,
        minHeight: props.height,
        height: props.height,
        width: props.width,
        borderStyle: props.borderStyle,
        fontSize: props.fontSize,
        lineHeight: props.lineHeight,
        fontWeight: props.fontWeight,
      }}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.loader ? (
        <div
          className={`${
            props?.loaderClassName ? props?.loaderClassName : ''
          } site-spinner`}
        ></div>
      ) : (
        <>{props?.children}</>
      )}
    </button>
  );
};

PrimaryButton.defaultProps = {
  type: 'submit',
  fontSize: '14px',
  borderWidth: '2px',
  hoverBorderWidth: '2px',
  borderRadius: '12px',
  height: '44px',
  width: '100%',
  borderStyle: 'solid',
  lineHeight: '20px',
};

export default PrimaryButton;
