import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePoliciesStore = create(
  persist(
    (set, get) => ({
      loggedInUserPolicies: [],
      customerOrgPolicies: [],

      getLoggedInUserPolicies: () => {
        return get().loggedInUserPolicies;
      },

      setLoggedInUserPolicies: (userPolicies) => {
        if (!userPolicies) return;

        set({
          loggedInUserPolicies: userPolicies,
        });
      },

      resetAllUserPolicies: () =>
        set((state) => {
          return {
            ...state,
            loggedInUserPolicies: [],
            customerOrgPolicies: [],
          };
        }),

      getCustomerOrgPolicies: () => {
        return get().customerOrgPolicies;
      },

      setCustomerOrgPolicies: (userPolicies) => {
        if (!userPolicies) return;

        set({
          customerOrgPolicies: userPolicies,
        });
      },

      resetCustomerOrgPolicies: () =>
        set((state) => {
          return {
            ...state,
            customerOrgPolicies: [],
          };
        }),
    }),
    {
      name: 'net.duclo.vms.policies',
      version: '1.0',
    }
  )
);
