import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';

import { Avatar, TextBlock } from '../../../components/common';
import { constants, Utils } from '../../../helpers';

import './ViewLogs.scss';

const ViewLogs = ({ viewLogEntries }) => {
  return (
    <>
      <div className="description">
        {constants.INCIDENTS_MODAL_VIEW_LOG_DESCRIPTION}
      </div>
      <Container>
        {Array.isArray(viewLogEntries) &&
          viewLogEntries.map((log) => (
            <Row>
              <Col>
                <div
                  key={log.createdDate}
                  className="mb-3 view-log-detail-wrapper"
                >
                  <TextBlock className="view-log-detail-content">
                    <div className="view-log-detail-avatar">
                      <Avatar
                        valueType={
                          log?.avatarURL &&
                          Utils.checkURLValidity(log?.avatarURL)
                            ? 'icon'
                            : 'text'
                        }
                        value={
                          log?.avatarURL &&
                          Utils.checkURLValidity(log?.avatarURL)
                            ? log?.avatarURL
                            : log?.initials
                        }
                        size="semiLarge"
                        avatarStyle="roundedCircle"
                      />
                    </div>
                    <div className="view-log-detail-creator">
                      <div className="view-log-detail-creator-name">
                        {log?.actionBy}
                      </div>
                      <div className="view-log-detail-creator-date">
                        {moment(log?.createdDate).format(
                          'MMM DD, YYYY hh:mm:ss A'
                        )}
                      </div>
                    </div>
                  </TextBlock>
                </div>
              </Col>
            </Row>
          ))}
      </Container>
    </>
  );
};

export default ViewLogs;
