import { PrimaryButton } from '../../../components/common';
import { AppDefaults, constants } from '../../../helpers';
import { warningCircle } from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';

import './modalForms.scss';

const MaxViewDevices = ({ closeModal, selectedViewDevices }) => {
  return (
    <>
      <div className="view-max-device-modal-wrapper">
        <div className="header-container">
          <div className="header-image">
            <img src={warningCircle} alt="" />
          </div>
          <RxCross1 className="site-modal-close" onClick={() => closeModal()} />
        </div>
        <div className="view-max-device-modal-title">
          {constants.CAMERAS_VIDEO_WALL_MAX_DEVICES_MODAL_TITLE}
        </div>
        <div className="view-max-device-modal-description">
          {constants.CAMERAS_VIDEO_WALL_MAX_DEVICES_MODAL_DESCRIPTION1}
          {selectedViewDevices.length}
          {constants.CAMERAS_VIDEO_WALL_MAX_DEVICES_MODAL_DESCRIPTION2}
          {AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT}
          {constants.CAMERAS_VIDEO_WALL_MAX_DEVICES_MODAL_DESCRIPTION3}
        </div>
        <PrimaryButton
          className="btn"
          type="submit"
          width="100%"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_white'
          )}
          height="44px"
          fontSize="14px"
          lineHeight="20px"
          fontWeight="600"
          onClick={() => {
            closeModal(true);
          }}
        >
          {constants.CAMERAS_VIDEO_WALL_MAX_DEVICES_MODAL_CLOSE_LABEL}
        </PrimaryButton>
      </div>
    </>
  );
};

export default MaxViewDevices;
