import { mqttPublish } from '../../utils/connection/mqttConnection';

export default function publishDeviceSetting(changeRequestInfo) {
  const tid = Math.floor(new Date().getTime());
  const context = {
    topic: `a/notify/${changeRequestInfo.hubId}`,
    payload: JSON.stringify({
      tid: `${tid}`,
      to: changeRequestInfo.hubId,
      from: `app`,
      msg: {
        action: 'set',
        resource: changeRequestInfo.resource,
        properties: changeRequestInfo.properties,
      },
      publish: `d/notify/${changeRequestInfo.accountId}/${changeRequestInfo.sessionId}`,
    }),
    qos: 0,
  };
  mqttPublish(context);
}
