import { Outlet } from 'react-router-dom';
import ClipDetails from './ClipDetails';
import ClipsList from './ClipsList';
import ClipDetailsLogs from './ClipDetailsLogs';
import Share from './Share';

const Clips = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { ClipDetails, Clips, ClipsList, ClipDetailsLogs, Share };
