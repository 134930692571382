import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const usePartnerOrgUsers = create(
  persist(
    (set, get) => ({
      partnerOrgUsersData: [],
      editPartnerAssignedCustomer: [],
      partnerAccountData: [],
      accountId: '',

      getPartnerOrgUsers: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result;

        // Set partner users if request is successful
        if (response?.meta?.code === 200) {
          let usersListData = response?.data;
          if (usersListData) {
            set({
              partnerOrgUsersData: usersListData,
            });
          }

          result = { status: 'success', msg: 'partner users data retrieved`' };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },

      getPartnerOrgUsersData: () => {
        return get().partnerOrgUsersData;
      },

      setPartnerOrgUsers: (customerOrgUsers) => {
        if (!customerOrgUsers) return;

        set({
          partnerOrgUsersData: customerOrgUsers,
        });
      },

      resetPartnerOrgUsers: () =>
        set((state) => {
          return {
            ...state,
            partnerOrgUsersData: [],
            editPartnerAssignedCustomer: [],
            partnerAccountData: [],
            accountId: '',
          };
        }),

      setPartnerOrgAccountId: (id) => {
        if (!id) return;

        set({
          accountId: id,
        });
      },

      setEditPartnerAssignedCustomer: (data) => {
        if (!data) return;
        if (data) {
          const customerOrgsData = [];
          data?.forEach((customer) => {
            customerOrgsData.push({ orgId: customer.orgId, checked: true });
          });
          set({
            editPartnerAssignedCustomer: customerOrgsData,
          });
        }
      },

      resetPartnerOrgAccountId: () =>
        set((state) => {
          return {
            ...state,
            accountId: [],
          };
        }),

      getEditPartnerUsersData: (accountId) => {
        try {
          let resultData = get().partnerOrgUsersData;

          if (resultData?.length >= 1) {
            return resultData?.find((user) => user.accountId === accountId);
          }
          return null;
        } catch (error) {
          return error;
        }
      },

      getPartnerAssignedCustomer: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result;

        // Set partner assigned customer orgs if request is successful
        if (response?.meta?.code === 200) {
          let usersListData = response?.data;
          if (usersListData?.organizations) {
            const customerOrgsData = [];
            usersListData?.organizations?.forEach((customer) => {
              customerOrgsData.push({ orgId: customer.orgId, checked: true });
            });
            set({
              editPartnerAssignedCustomer: customerOrgsData,
            });
          }

          result = {
            status: 'success',
            msg: 'partner user assigned customer organizations retrieved`',
          };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },

      getPartnerAssignedCustomerData: (partnerOrgId) => {
        const partnerAssignedCustomer = get().editPartnerAssignedCustomer;
        let assignedOrgData = [];
        if (
          Array.isArray(partnerAssignedCustomer) &&
          partnerAssignedCustomer.length > 0
        ) {
          partnerAssignedCustomer.forEach((customer) => {
            if (partnerOrgId !== customer.orgId) {
              assignedOrgData.push(customer);
            }
          });
        }
        return assignedOrgData;
      },

      getPartnerAccountData: async (route) => {
        if (!route) return;

        const res = await axios.get(
          route,
          Utils.requestHeader()
        );
        const response = res?.data;
        let result;

        // Set partner users if request is successful
        if (response?.meta?.code === 200) {
          let usersListData = response?.data;
          if (usersListData) {
            set({
              partnerAccountData: usersListData,
            });
          }

          result = {
            status: 'success',
            msg: 'logged in user account data retrieved',
          };
        } else {
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
        }
        return result;
      },
    }),
    {
      name: 'net.duclo.vms.partnerorgusers',
      version: '1.0',
    }
  )
);
