import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import {
  Avatar,
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { AppDefaults, constants, roles } from '../../helpers';
import { UsersListTable } from '../users';
import { EditUser, NewUser } from '.';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { FaCircle } from 'react-icons/fa';
import { RxPlus } from 'react-icons/rx';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { success } from '../../assets/images';
import useCustomRoles from '../../store/CustomRolesStore';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';

const ManageUsers = () => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  // const orgData = customerOrgData?.find((org) => org.orgId === orgId);
  const loggedInUser = getLoggedInUserData();
  // const orgNameBread = orgData?.orgName;
  const [orgNameBread, setOrgNameBread] = useState('');
  const breadList = [
    {
      url:  loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER ? '/customers/manage.html' : `/customers/dashboard.html?orgId=${orgId}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    { url: `/customers/dashboard.html?orgId=${orgId}`, title: orgNameBread },
    {
      url: `/users/manage.html?orgId=${orgId}`,
      title: constants.ORG_DASHBOARD_USER_SECTION_TITLE,
    },
  ];
  // const policyData = orgData?.policies?.find(
  //   (item) => item.policyName === 'update_other_account'
  // );
  // const policyEditStatus = policyData ? true : false;
  const [policyEditStatus, setPolicyEditStatus] = useState(false);
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  // TODO: delete later
  // const tokenParsed = keycloak.tokenParsed;
  const [tokenData, setTokenData] = useState(null);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [customerUsersData, setCustomerUsers] = useState([]);
  const [showUnlockSuccessModal, setShowUnlockSuccessModal] = useState(false);
  const [fetchCustomerOrgUserStatus, setFetchCustomerOrgUserStatus] =
    useState(true);
  const [modalTitleData, setModalTitleData] = useState(
    constants.NEW_CUSTOMER_TITLE
  );
  const [editModalTitleData, setEditModalTitleData] = useState(
    constants.EDIT_CUSTOMER_TITLE
  );
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [editAccountId, setEditAccountId] = useState(false);
  const [editAccountData, setEditAccountData] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);

  //=== Store get/set actions
  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers
  );
  const customerOrgUsersData = useCustomerOrgUsers(
    (state) => state.customerOrgUsersData
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations
  );
  const getEditCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getEditCustomerOrgUsersData
  );
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const { getCustomRoleList, roleList } = useCustomRoles();
  const [isRoleList, setIsRoleList] = useState([]);

  const filterCustomerUsers = () => {
    let resultData = getCustomerOrgUsersData();

    if (resultData?.length >= 1) {
      setCustomerUsers(
        resultData?.filter(
          (user) =>
            loggedInUser?.accountId !== user?.accountId &&
            user.role !== roles.ROLE1VMS
        )
      ); //=== Update state to load data
    }
  };

  useEffect(() => {
    const getOrgData = async () => {
      const orgs = await getCustomerOrgData();
      const org = orgs?.find((org) => org.orgId === orgId);
      const status = org?.policies?.find(
        (item) => item.policyName === 'update_other_account'
      );
      setPolicyEditStatus(status);
      setOrgNameBread(org?.orgName);
    };
    getOrgData();
  }, [orgId]);

  useEffect(() => {
    getCustomRoleList(`partner/orgs/${orgId}/roles`).then((res) => {
      if (res?.length > 0) {
        const roleOption = [];
        const custOrgAdmins = getCustomerOrgUsersData()?.filter(
          (item) => item?.role === roles.ROLE2VMS
        );
        res.forEach((role) => {
          if (role.roleId !== roles.ROLE1VMS) {
            if (
              custOrgAdmins?.length === 0 &&
              role.roleName === 'cust_org_admin'
            ) {
              let obj = {
                label: constants.ADMIN_USER_CUSTOM_ROLE_LABEL_SHORT,
                value: role.roleId,
              };
              roleOption.push(obj);
            } else if (custOrgAdmins?.length > 0) {
              let obj = {
                label:
                  role.roleName === 'cust_org_admin'
                    ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL_SHORT
                    : role.roleName === 'cust_org_user'
                    ? constants.NORMAL_USER_ROLE_LABEL
                    : role.roleName,
                value: role.roleId,
              };
              roleOption.push(obj);
            }
          }
        });
        setRoleOptions(roleOption);
      }
    });

    filterCustomerUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerOrgUsersData()]);

  const fetchRoles = async () => {
    const res = await getCustomRoleList(`partner/orgs/${orgId}/roles`);
    setIsRoleList(res);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (
      fetchCustomerOrgUserStatus &&
      token &&
      !tokenData &&
      customerUsersData.length >= 0
    ) {
      // TODO: delete later
      // setTokenData(token);
      fetchCustomersData(token); //=== Fetch the partner - customer user accounts list
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, tokenData]);

  const columnsData = [
    {
      Header: constants.NAME_TABLE_HEADER,
      accessor: 'name',
      maxWidth: 200,
      minWidth: 50,
      width: 180,
      Cell: (props) => {
        let name = props.value;
        let allRecords = props?.data;
        let ifDataExists = allRecords?.find(
          (row) => row?.accountId === props?.row?.original?.accountId
        );
        let letter = name
          .match(/(\b\S)?/g)
          .join('')
          .match(/(^\S|\S$)?/g)
          .join('')
          .toUpperCase();
        return (
          <span className="userName">
            <Avatar
              valueType={ifDataExists?.image?.url ? 'icon' : 'text'}
              value={
                ifDataExists?.image?.url ? ifDataExists?.image?.url : letter
              }
              size="medium"
              avatarStyle="roundedCircle"
            />
            {name}
          </span>
        );
      },
      sortType: 'basic',
    },
    {
      Header: constants.EMAIL_TABLE_HEADER,
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: constants.ROLE_TABLE_HEADER,
      accessor: 'role',
      maxWidth: 100,
      minWidth: 50,
      width: 90,
      Cell: (props) => {
        const roleValue = props.value;
        const roleName = isRoleList?.find(
          (role) => role.roleId === roleValue
        )?.roleName;
        return roleValue === roles.ROLE2VMS
          ? constants.ADMIN_USER_ROLE_LABEL
          : roleValue === roles.ROLE3VMS
          ? constants.NORMAL_USER_ROLE_LABEL
          : roleName ? roleName : '--';
      },
      sortType: 'basic',
    },
    {
      Header: constants.INCIDENTS_DETAILS_STATUS_TITLE,
      accessor: 'accountStatus',
      maxWidth: 150,
      minWidth: 50,
      width: 130,
      Cell: (props) => {
        const activeCheck = props.value;
        const isLocked = props.row.original.isLocked;
        return (
          <span className="activeIcon">
            {activeCheck === 'ACTIVE' && isLocked ? (
              <FaCircle size={10} className="status-icon-offline" />
            ) : activeCheck === 'ACTIVE' ? (
              <FaCircle size={10} className="status-icon-active" />
            ) : activeCheck === 'PENDING_ACTIVATION' ? (
              <FaCircle size={10} className="status-icon-invited" />
            ) : (
              <FaCircle size={10} className="status-icon-notactive" />
            )}
            {activeCheck === 'ACTIVE' && isLocked
              ? constants.LOCKED_ACCOUNT_TEST
              : activeCheck === 'ACTIVE'
              ? constants.ACTIVE_USER_STATUS_LABEL
              : activeCheck === 'PENDING_ACTIVATION'
              ? constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE
              : activeCheck === 'INACTIVE'
              ? constants.DEVICES_RETURN_DEACTIVATED_STATUS
              : constants.DEVICES_RETURN_DEACTIVATED_STATUS}
          </span>
        );
      },
      sortType: 'basic',
    },
    {
      Header: constants.LAST_ACTIVE_TABLE_HEADER,
      accessor: 'activeDate',
      maxWidth: 200,
      minWidth: 65,
      width: 170,
      Cell: (props) => {
        let retVal = '-';
        if (props.value !== 0) {
          retVal = moment(
            new Date(moment.unix(+props.value / 1000).toDate())
          ).format('D MMM YYYY hh:mm a');
        }
        return <span>{retVal}</span>;
      },
      sortType: 'basic',
    },
    {
      Header: constants.LAST_UPDATED_TABLE_HEADER,
      accessor: 'updatedDate',
      maxWidth: 250,
      minWidth: 65,
      width: 170,
      Cell: (props) => {
        let retVal = '-';
        if (props.value !== 0) {
          retVal = moment(
            new Date(moment.unix(+props.value / 1000).toDate())
          ).format('D MMM YYYY hh:mm a');
        }
        return <span>{retVal}</span>;
      },
      sortType: 'basic',
    },
  ];

  const sortingData = useMemo(
    () => [
      {
        id: 'name',
        desc: true,
      },
      {
        id: 'email',
        desc: true,
      },
      {
        id: 'role',
        desc: true,
      },
      {
        id: 'accountStatus',
        desc: true,
      },
      {
        id: 'activeDate',
        desc: true,
      },
      {
        id: 'updatedDate',
        desc: true,
      },
    ],
    []
  );

  const fetchCustomersData = async (token) => {
    if (token) {
      setFetchCustomerOrgUserStatus(false);
      await getCustomerOrgUsers(`partner/orgs/${orgId}/accounts`);
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
    }
  };

  const handleModalTitleUpdate = (data) => {
    setModalTitleData(data);
  };

  const handleEditModalTitleUpdate = (data) => {
    setEditModalTitleData(data);
  };

  const handleEditUser = async (userId) => {
    const ediUserData = await getEditCustomerOrgUsersData(userId);
    setEditAccountData(ediUserData);
    setEditAccountId(userId);
    setShowEditOrgModal(true);
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />

      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className="mw-100">
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.ORG_DASHBOARD_USER_SECTION_TITLE}
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="text-end page-header-buttons"
              >
                {getCustomerOrgPolicies().create_account && (
                  <PrimaryButton
                    className="btn btn-primary"
                    type="button"
                    width="auto"
                    onClick={() => {
                      setShowAddOrgModal(true);
                    }}
                  >
                    <RxPlus
                      className="plus-icon-btn"
                      strokeWidth={'1.5px'}
                      size={13.33}
                    />
                    {constants.MANAGE_ORG_ADD_USER_BUTTON_TITLE}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="list-block">
          <Container className="h-100 mw-100">
            {getCustomerOrgPolicies().view_account  && (
              <UsersListTable
                columns={columnsData}
                defaultSortByData={sortingData}
                data={customerUsersData}
                handleClickEditUser={handleEditUser}
                isRoleList={isRoleList}
              />
            )}
          </Container>
        </div>
      </PageWrapper>
      <SiteModal
        modalTitle={modalTitleData}
        showModal={showAddOrgModal}
        hideModal={() => setShowAddOrgModal(false)}
      >
        <NewUser
          reloadData={() => {
            fetchCustomersData(token);
          }}
          hideModal={() => setShowAddOrgModal(false)}
          modalTitle={constants.NEW_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleModalTitleUpdate(data);
          }}
          roleOption={roleOptions}
        />
      </SiteModal>

      <SiteModal
        modalTitle={editModalTitleData}
        showModal={showEditOrgModal}
        hideModal={() => setShowEditOrgModal(false)}
        classes="edit-customer-user-modal"
      >
        <EditUser
          policyEditStatus={policyEditStatus}
          accountId={editAccountId}
          accountData={editAccountData}
          reloadData={() => {
            fetchCustomersData(token);
          }}
          hideModal={() => setShowEditOrgModal(false)}
          modalTitle={constants.EDIT_CUSTOMER_TITLE}
          handleModalTitle={(data) => {
            handleEditModalTitleUpdate(data);
          }}
          showUnlockModal={() => setShowUnlockSuccessModal(true)}
          roleOption={roleOptions}
          customerOrgUsersData={getCustomerOrgUsersData()}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.ACCOUNT_UNLOCKED}
        showModal={showUnlockSuccessModal}
        hideModal={() => setShowUnlockSuccessModal()}
        classes="unlock-success-modal"
      >
        <div className="d-flex flex-column align-items-center gap-4 text-center">
          <Avatar
            value={success}
            valueType="icon"
            size="extraLarge"
            style="roundedCircle"
          />
          <div className="w-100">
            <PrimaryButton
              className="btn btn-primary w-100"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="100%"
              onClick={() => setShowUnlockSuccessModal(false)}
            >
              {constants.BACK_TO_USER_BUTTON_TEXT}
            </PrimaryButton>

            <PrimaryButton
              className="btn btn-outline-danger w-100 mt-2"
              type="button"
              height="44px"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              onClick={() => {
                setShowUnlockSuccessModal(false);
                handleEditUser(editAccountId);
              }}
            >
              {`Edit ${editAccountData?.firstName} ${editAccountData?.lastName} Profile`}
            </PrimaryButton>
          </div>
        </div>
      </SiteModal>
    </div>
  );
};

export default ManageUsers;
