import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment-timezone';
import {
  getLiveCameraIds,
  setLiveCameraIds,
} from '../../../../store/reducers/StreamingReducer';
// import { getAllDevicesData } from '../../../../store/reducers/AccountReducer';
import PlaybackControlsView from '../playback/PlaybackControlsView';
import '../../../../assets/css/timeline.scss';
import { MuuriComponent } from 'muuri-react';
import { useRef } from 'react';

const MultiControlsPlayback = ({ muteAudio, time, cdnValue, onClick }) => {
  const liveCameraIds = useSelector(getLiveCameraIds);
  // const devicesData = useSelector(getAllDevicesData);
  const devicesData = [];
  const dispatch = useDispatch();
  const gridRef = useRef();

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId
      );
      return `${deviceData[0]?.deviceName} - ${deviceData[0]?.locationName} - ${deviceData[0]?.areas?.[0]?.areaName}`;
    }
  };

  const handleDragEnd = () => {
    if (gridRef.current) {
      const reorderedCameraIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      dispatch(setLiveCameraIds(reorderedCameraIds));
    }
  };

  return (
    <div className="multi-live-camera-wrapper-image grid-container">
      {liveCameraIds?.length > 0 && (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={liveCameraIds.length > 1}
          instantLayout
          //dragContainer={document.body}
          columnRef="swap"
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          onDragEnd={handleDragEnd}
        >
          {liveCameraIds.map((liveCameraId, index) => (
            <div key={liveCameraId} className="liveone-wrapper grid-item">
              <PlaybackControlsView
                layout={index + 1}
                deviceName={getDeviceInfo(liveCameraId)}
                deviceId={liveCameraId}
                muteAudio={muteAudio}
                time={time}
                cdnValue={cdnValue}
                onLiveClick={() => onClick(liveCameraId)}
              />
            </div>
          ))}
        </MuuriComponent>
      )}
    </div>
  );
};

export default MultiControlsPlayback;
