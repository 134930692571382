import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Loader, PrimaryButton } from '../../../components/common';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { TextField } from '../../../components/forms';
import {
  step1of2IconLicense,
  licenseRemoveIcon,
  markIcon,
  errorIcon,
  invalidKeyIcon,
} from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';
import { constants } from '../../../helpers';

const One = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [orderIdError, setOrderIdError] = useState('');
  const [invalidObjectError, setInvalidObjectError] = useState('');
  const [initialValueOfButton, setInitialValueOfButton] = useState(false);
  let [licenseListForOrderId, setLicenseListForOrderId] = useState();
  const [orderNoSelected, setOrderNoSelected] = useState();
  const [orderAPIResponse, setOrderAPIResponse] = useState(false);
  const orderNumInputRef = useRef(null);
  const licenseKeyInputRef = useRef({});
  const scollToRef = useRef();
  const licenseNumberErrorRef = useRef();
  let { search } = useLocation();
  let orderAPIResponseFlag = false;
  const query = new URLSearchParams(search);
  const orgId = query.get('orgId');

  useEffect(() => {
    addKey(0);
  }, []);

  useEffect(() => {
    orderAPIResponseFlag = orderAPIResponse;
  }, [orderAPIResponse]);

  const validateOrderNo = Yup.object({
    orderNo: Yup.string().required(constants.ADD_LICENSE_ORDERNO_REQUIRED),
  });

  const validateOrderNoWithLicense = Yup.object({
    orderNo: Yup.string().required(constants.ADD_LICENSE_ORDERNO_REQUIRED),
    licenseKeys: Yup.array().of(
      Yup.string().optional(constants.ADD_LICENSE_ORDERNO_REQUIRED)
    ),
  });

  const [isStepDetailComplete, setIsStepDetailComplete] = useState(false);

  let [keys, setKeys] = useState([]);
  const [deletedKeys, setDeletedKeys] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [validLicenseKey, setValidLicenseKey] = useState(false);
  const [validLicenseKeyObj, setValidLicenseKeyObj] = useState({});
  const [keysUsed, setKeysUsed] = useState([]);
  const [keyListToPass, setKeyListToPass] = useState([]);

  const addKey = (index) => {
    const newKey = `Key${index}`;
    const updatedKeys = keys.filter((key) => key !== newKey);
    setKeys([...updatedKeys, newKey]);
    setInputValues({ ...inputValues, [newKey]: '' });
  };

  const handleInputChange = async (key, value, index, orderId) => {
    clearLicenseError();
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }));

    inputValues[key] = value;
    if (keys?.length > 0) {
      const filteredKeys = keys.filter(
        (key) => key !== null && key !== undefined
      );
      keys = filteredKeys;
    }
    const filteredInputValues = Object.fromEntries(
      Object.entries(inputValues).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );
    if (filteredInputValues) {
      setInputValues({ ...filteredInputValues });
    }
    if (!filteredInputValues || deletedKeys?.length === 0) {
      setInputValues({ ...inputValues, [key]: value });
    }

    if (value?.length === 41) {
      if (!keysUsed.includes(key)) {
        setKeysUsed([...keysUsed, key]);
      }
      if (keys?.length === 1 || !keysUsed.includes(key)) {
        const tempKey = keys[keys?.length - 1];
        const lastChar = tempKey?.charAt(tempKey?.length - 1);
        addKey(parseInt(lastChar) + 1);
      }
    }

    Object.fromEntries(
      Object.entries(filteredInputValues).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    if (filteredInputValues) {
      const moreThanOneKeys = Object.entries(filteredInputValues).length > 1;
      const maxKey = Object.keys(filteredInputValues)
        .filter((key) => /^Key\d+$/.test(key))
        .map((key) => Number(key.slice(3)))
        .reduce((max, num) => Math.max(max, num), -Infinity);

      if (filteredInputValues[`Key${maxKey}`] === '' && moreThanOneKeys) {
        let cleanListToPass = Object.entries(filteredInputValues)
          .filter(([key, _]) => key !== `Key${maxKey}`)
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        setKeyListToPass(cleanListToPass);
      } else {
        setKeyListToPass(filteredInputValues);
      }
    } else {
      setKeyListToPass([]);
    }

    Object.fromEntries(
      Object.entries(validLicenseKeyObj).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    if (value && value?.length > 40) {
      checkLicenseValue(orderId, value, key);
    }
    await allValuesCheckForLicense(
      orderId,
      filteredInputValues,
      validLicenseKeyObj
    );
  };

  const allValuesCheckForLicense = async (
    orderId,
    filteredInputValues,
    validLicenseKeyObjArg
  ) => {
    for (const key1 of Object.keys(filteredInputValues)) {
      try {
        await checkLicenseValue(orderId, filteredInputValues[key1], key1);
      } catch (error) {
        console.error(`API call failed for key ${key1}:`, error);
      }
    }
    const allValuesAreTrue = Object.values(validLicenseKeyObjArg).every(
      (value) => value === true
    );
    const allValuesAreFalse = Object.values(validLicenseKeyObjArg).every(
      (value) => value === false
    );

    if (Object.keys(validLicenseKeyObjArg).length === 0) {
      setValidLicenseKey(false);
      return;
    }
    if (Object.keys(validLicenseKeyObjArg).length === 1) {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else {
        setValidLicenseKey(false);
      }
    } else if (Object.keys(validLicenseKeyObjArg).length === 2) {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else if (allValuesAreFalse) {
        setValidLicenseKey(false);
      } else {
        const keyWithFalseValue = Object.entries(validLicenseKeyObjArg).find(
          ([key, value]) => value === false
        );

        const maxKey = Object.keys(validLicenseKeyObjArg)
          .filter((key) => /^Key\d+$/.test(key))
          .map((key) => Number(key.slice(3)))
          .reduce((max, num) => Math.max(max, num), -Infinity);

        if (
          filteredInputValues[`Key${maxKey}`] === '' &&
          keyWithFalseValue &&
          `Key${maxKey}` === keyWithFalseValue[0]
        ) {
          setValidLicenseKey(true);
        } else {
          setValidLicenseKey(false);
        }
      }
    } else {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else {
        const countFalseValues = Object.values(validLicenseKeyObjArg).filter(
          (value) => value === false
        ).length;
        const onlyOneKeyWithFalseValue = countFalseValues === 1;

        const maxKey = Object.keys(validLicenseKeyObjArg)
          .filter((key) => /^Key\d+$/.test(key))
          .map((key) => Number(key.slice(3)))
          .reduce((max, num) => Math.max(max, num), -Infinity);

        if (
          filteredInputValues[`Key${maxKey}`] === '' &&
          onlyOneKeyWithFalseValue
        ) {
          setValidLicenseKey(true);
        } else {
          setValidLicenseKey(false);
        }
      }
    }
  };

  const checkLicenseValue = async (orderId, value, key) => {
    try {
      const response = await axios.get(
        `/partner/orgs/${orgId}/orders/${orderId}/licenses/${value}`
      );
      if (response?.data?.meta?.code === 200) {
        validLicenseKeyObj[key] = true;
        let errorSection = document.getElementById(key + '-Error');
        if (errorSection) {
          errorSection.style.display = 'none';
        }
        let errorSectionIcon = document.getElementById(key + '-ErrorIcon');
        if (errorSectionIcon) {
          errorSectionIcon.src = markIcon;
        }
      } else {
        validLicenseKeyObj[key] = false;
        let errorSection = document.getElementById(key + '-Error');
        if (errorSection) {
          errorSection.style.display = 'block';
          if (response?.data?.meta?.code === 1849) {
            let errorSectionText = errorSection.querySelector('.erroMessage');
            if (errorSectionText) {
              errorSectionText.textContent = response.data.meta.userMsg;
            }
          }
        }
        let errorSectionIcon = document.getElementById(key + '-ErrorIcon');
        if (errorSectionIcon) {
          errorSectionIcon.src = errorIcon;
        }
      }
    } catch (error) {
      let errorSection = document.getElementById(key + '-Error');
      if (errorSection) {
        errorSection.style.display = 'block';
      }
      let errorSectionIcon = document.getElementById(key + '-ErrorIcon');
      if (errorSectionIcon) {
        errorSectionIcon.src = errorIcon;
      }
      validLicenseKeyObj[key] = false;
    }
  };

  const removeKeyAndField = async (index, key) => {
    let updatedKeyListToPass = [];
    deletedKeys.push(key);
    if (keys?.length > 1) {
      delete keys[index];
      setKeys(keys);
      const updatedInputValues = { ...inputValues };
      delete updatedInputValues[`Key${index}`];
      setInputValues(updatedInputValues);
      updatedKeyListToPass = updatedInputValues;
    }

    delete validLicenseKeyObj[`Key${index}`];

    updatedKeyListToPass = Object.fromEntries(
      Object.entries(updatedKeyListToPass).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    if (updatedKeyListToPass) {
      const moreThanOneKeys = Object.entries(updatedKeyListToPass).length > 1;
      const maxKey = Object.keys(updatedKeyListToPass)
        .filter((key) => /^Key\d+$/.test(key))
        .map((key) => Number(key.slice(3)))
        .reduce((max, num) => Math.max(max, num), -Infinity);

      if (updatedKeyListToPass[`Key${maxKey}`] === '' && moreThanOneKeys) {
        let cleanListToPass = Object.entries(updatedKeyListToPass)
          .filter(([key, _]) => key !== `Key${maxKey}`)
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        setKeyListToPass(cleanListToPass);
      } else {
        setKeyListToPass(updatedKeyListToPass);
      }
    } else {
      setKeyListToPass([]);
    }

    let validLicenseKeyObjTemp = Object.fromEntries(
      Object.entries(validLicenseKeyObj).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    await allValuesCheckForLicense(
      orderNoSelected,
      updatedKeyListToPass,
      validLicenseKeyObjTemp
    );
  };

  const handleManualOrderClick = () => {
    setIsStepDetailComplete(true);
  };

  const handleManualOrderClickWithKey = async () => {
    await axios
      .put(`/partner/orgs/${orgId}/orders/${orderNoSelected}/licenses`)
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          props.selectedOrderNumber(orderNoSelected);
          props.nextstepEvent(true);
          props.licenseResponseSuccessValue(true);
          scollToRef.current.scrollIntoView();
        } else {
          if (response?.data?.meta?.userMsg) {
            setOrderIdError(response?.data?.meta?.userMsg);
          }
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const clearLicenseError = () => {
    if (licenseNumberErrorRef.current) {
      licenseNumberErrorRef.current.innerText = "";
    }
  }

  const checkOrderNumber = async (event, cb, isPasted) => {
    if (!event) return;

    let returnValue = false;
    const orderNumInputField = orderNumInputRef?.current;
    const orderNum = isPasted ? event : event.target?.value;

    if (isPasted && orderNumInputField && orderNumInputField.value) {
      orderNumInputField.value = orderNum;
    }

    clearLicenseError();
    setOrderNoSelected(orderNum);
    setInitialValueOfButton(true);

    try {
      const response = await axios.get(`/partner/licenses/orders/${orderNum}`);

      switch (parseInt(response?.data?.meta?.code)) {
        case 200:
          let responseLicenseList = response?.data.data.filter(
            (item) => item.orderId === orderNum
          );
          if (
            Array.isArray(responseLicenseList) &&
            responseLicenseList.length > 0
          ) {
            // TODO: once the API starts supporting multiple recipients, we will need to redo this
            // part of the code to handle an array of email addresses instead of just one email
            // address instance.
            props?.setRecipientEmailAddress(
              responseLicenseList[0]?.recipientEmail
            );
          }

          setLicenseListForOrderId(responseLicenseList);
          setOrderIdError('');
          setOrderAPIResponse(true);
          orderAPIResponseFlag = true;
          cb && cb(event);
          returnValue = true;
          break;

        case 1833:
          setOrderAPIResponse(false);
          orderAPIResponseFlag = false;
          setOrderIdError(constants.LICENSE_ORDER_LICENSES_ALREADY_ADDED);
          break;

        case 1844:
          setOrderAPIResponse(false);
          orderAPIResponseFlag = false;
          setOrderIdError(constants.LICENSE_ORDER_NUMBER_DOES_NOT_EXIST);
          break;

        default:
          setOrderAPIResponse(false);
          orderAPIResponseFlag = false;
          setOrderIdError(constants.LICENSE_INVALID_ORDER_NUMBER);
      }
    } catch (error) {
      setOrderAPIResponse(false);
      orderAPIResponseFlag = false;
      setOrderIdError(error);
      console.error(error);
    } finally {
      return returnValue;
    }
  };

  return (
    <div ref={scollToRef} className="ctn-box innner">
      {showLoader && <Loader className="white-background" />}
      <div className="title-container">
        <div id={'headerText'} className="header-view">
          <div className="titlename-header">
            {constants.LICENSE_TAB_LICENSE_INFO}
          </div>
          <RxCross1
            className="site-modal-close"
            onClick={() => props.hideModal()}
          />
          {document.addEventListener('keydown', function (event) {
            if (
              event.key === 'Escape' ||
              event.key === 'Esc' ||
              event.keyCode === 27
            ) {
              props.hideModal();
            }
          })}
        </div>
        <div>
          <div className="stepIcon">
            <img src={step1of2IconLicense} alt="step1Icon" />
          </div>
          {!isStepDetailComplete && props.flowValue == 'Flow1' ? (
            <div className="steptext">{constants.LICENSE_STEP_ONE_OF_TWO}</div>
          ) : (
            <div className="steptext">{constants.LICENSE_STEP_TWO_OF_TWO}</div>
          )}
        </div>
      </div>

      {!isStepDetailComplete && props.flowValue == 'Flow1' ? (
        <div>
          <Formik
            initialValues={{
              orderNo: '',
            }}
            validationSchema={validateOrderNo}
            onSubmit={async (values, { setFieldError, setFieldTouched }) => {
              await axios
                .put(`/partner/orgs/${orgId}/orders/${values.orderNo}/licenses`)
                .then((response) => {
                  if (response?.data?.meta?.code === 200) {
                    setOrderIdError('');
                    props.selectedOrderNumber(values.orderNo);
                    props.nextstepEvent(true);
                    props.licenseResponseSuccessValue(true);
                    scollToRef.current.scrollIntoView();
                  } else {
                    if (response?.data?.meta?.userMsg) {
                      setOrderIdError(response?.data?.meta?.userMsg);
                    }
                    return;
                  }
                })
                .catch((error) => {
                  setOrderIdError(error);
                  console.error(error);
                });
            }}
          >
            {({ values, handleChange, handleSubmit, isValid, dirty }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <div className="titlename">
                  {constants.LICENSE_ORDER_NUMBER}
                </div>
                <div className="instruction">
                  {constants.LICENSE_ENTER_KEY_REQUIRED}
                </div>
                <>
                  <div>
                    <TextField
                      placeholder={
                        <>
                          {constants.LICENSE_ORDER_NO_TEXT}{' '}
                          <span
                            style={{
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_64'),
                            }}
                          >
                            {' '}
                            *
                          </span>
                        </>
                      }
                      name="orderNo"
                      type="text"
                      removebottommargin={true}
                      removetopmargin={true}
                      id="orderNumber"
                      ref={orderNumInputRef}
                      value={values.orderNo}
                      onKeyDown={(event) => {
                        if (!event) return;

                        if (event.key === 'Backspace') {
                          // This disables the Continue button and Enter
                          // Licenses Manually link
                          setOrderNoSelected(false);
                        }

                        if (
                          (!['Enter', 'NumpadEnter'].includes(event.key) &&
                            event.keyCode !== 13) ||
                          orderAPIResponseFlag === true
                        ) {
                          return;
                        } else {
                          if (orderNumInputRef?.current?.value?.includes(';')) {
                            orderAPIResponseFlag = false;
                            setOrderIdError(
                              constants.LICENSE_INVALID_ORDER_NUMBER
                            );
                            return;
                          } else {
                            values.orderNo = orderNumInputRef?.current?.value;
                            setOrderNoSelected(
                              orderNumInputRef?.current?.value
                            );
                            setOrderAPIResponse(false);
                            orderAPIResponseFlag = false;
                            checkOrderNumber(event, handleChange, false);
                            setOrderIdError(null);
                          }
                        }
                      }}
                      onPaste={(event) => {
                        setOrderAPIResponse(false);
                        orderAPIResponseFlag = false;
                        event.preventDefault();
                        const pastedData =
                          event.clipboardData.getData('text/plain');
                        const orderNumber = pastedData.replace(/[\s;]/g, '');
                        values.orderNo = orderNumber;
                        setOrderNoSelected(orderNumber);
                        checkOrderNumber(orderNumber, handleChange, true);
                      }}
                    />
                    {values.orderNo && !orderAPIResponseFlag && (
                      <div className="error errorStyle">{orderIdError}</div>
                    )}
                  </div>
                </>
                <Row className="justify-content-end">
                  {initialValueOfButton &&
                  orderNoSelected &&
                  orderNumInputRef?.current?.value &&
                  orderAPIResponse &&
                  orderIdError?.length === 0 ? (
                    <Col className="enterTextManullyHighlightedStyle">
                      <span onClick={() => handleManualOrderClick()}>
                        {constants.LICENSES_ENTER_TEXT}
                      </span>
                    </Col>
                  ) : (
                    <Col className="enterTextManullyStyle" disabled>
                      {constants.LICENSES_ENTER_TEXT}
                    </Col>
                  )}

                  <Col className="text-end">
                    <PrimaryButton
                      className="addlicense-btn btn2 btn-primary"
                      fontSize="14px"
                      height="44px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      width="auto"
                      type="submit"
                      disabled={
                        !(
                          initialValueOfButton &&
                          orderNoSelected &&
                          orderNumInputRef?.current?.value &&
                          orderAPIResponse &&
                          orderIdError?.length === 0
                        )
                      }
                    >
                      {constants.LICENSE_CONTINUE_TEXT}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              orderNo: '',
              licenseKeys: [''],
            }}
            validationSchema={validateOrderNoWithLicense}
            onSubmit={async () => {
              const requestData = {
                orderId: orderNoSelected,
                licenseKeys: Object.values(keyListToPass),
              };
              await axios
                .put(`/partner/orgs/${orgId}/licenses`, requestData)
                .then((response) => {
                  if (response?.data?.meta?.code === 200) {
                    props.selectedOrderNumber(orderNoSelected);
                    props.nextstepEvent();
                    props.licenseResponseSuccessFlag(true);
                    props.licenseResponseData(response?.data?.data);
                    scollToRef.current.scrollIntoView();
                    clearLicenseError();
                  } else {
                    if (response?.data?.meta?.userMsg) {
                      if (licenseNumberErrorRef.current) {
                        licenseNumberErrorRef.current.innerText = response.data.meta.userMsg;
                      }
                      setInvalidObjectError(response.data.meta.userMsg);
                    }
                    return;
                  }
                })
                .catch((error) => {
                  console.error(error);
                  return;
                });
            }}
          >
            {({ values, handleChange, handleSubmit, isValid, dirty }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <div className="titlename">
                  {constants.LICENSE_ORDER_NUMBER}
                </div>
                <>
                  <div className="LicenseTextFieldStyle">
                    <TextField
                      inputClasses="order-number"
                      placeholder={
                        <>
                          {constants.LICENSE_ORDER_NO_TEXT}{' '}
                          <span
                            style={{
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_64'),
                            }}
                          >
                            {' '}
                            *
                          </span>
                        </>
                      }
                      name="orderNo"
                      type="text"
                      removebottommargin="true"
                      removetopmargin="true"
                      value={values.orderNo}
                      onKeyDown={(event) => {
                        if (!event) return;

                        if (event.key === 'Backspace') {
                          // This disables the Continue button and Enter
                          // Licenses Manually link
                          setOrderNoSelected(false);
                        }

                        if (
                          (!['Enter', 'NumpadEnter'].includes(event.key) &&
                            event.keyCode !== 13) ||
                          orderAPIResponseFlag === true
                        ) {
                          return;
                        } else {
                          if (orderNumInputRef?.current?.value?.includes(';')) {
                            orderAPIResponseFlag = false;
                            setOrderIdError(
                              constants.LICENSE_INVALID_ORDER_NUMBER
                            );
                            return;
                          } else {
                            values.orderNo = orderNumInputRef?.current?.value;
                            setOrderNoSelected(
                              orderNumInputRef?.current?.value
                            );
                            setOrderAPIResponse(false);
                            orderAPIResponseFlag = false;
                            checkOrderNumber(event, handleChange, false);
                            setOrderIdError(null);
                          }
                        }
                      }}
                      onPaste={(event) => {
                        if (!event) return;

                        event.preventDefault();
                        setOrderAPIResponse(false);
                        orderAPIResponseFlag = false;
                        const pastedData =
                          event.clipboardData.getData('text/plain');
                        const orderNumber = pastedData.replace(/[\s;]/g, '');
                        values.orderNo = orderNumber;
                        setOrderNoSelected(orderNumber);
                        checkOrderNumber(orderNumber, handleChange, true);
                      }}
                    />
                    {values.orderNo && !orderAPIResponseFlag && (
                      <div className="error errorStyle">{orderIdError}</div>
                    )}
                  </div>
                </>
                <div className="titlename LicenseTextFieldStyle">
                  {constants.LICENSE_KEYS_TEXT}
                </div>
                <>
                  {keys?.map((key, index) => (
                    <div
                      key={`${key}-${index}`}
                      className="LicenseTextFieldStyle"
                    >
                      <div className="row">
                        {index < keys?.length - 1 ? (
                          <div className="col-md-11 removableLicenseTextFieldStyle">
                            <TextField
                              type="text"
                              id={key}
                              name={key}
                              ref={(el) =>
                                (licenseKeyInputRef.current[key] = el)
                              }
                              value={inputValues[key]}
                              maxLength="41"
                              beforeinputicon={
                                <img
                                  id={key + '-ErrorIcon'}
                                  src={markIcon}
                                  className="mark-icon-class"
                                />
                              }
                              onChange={(event) => {
                                handleInputChange(
                                  key,
                                  event.target.value,
                                  index,
                                  values.orderNo
                                );
                              }}
                              onPaste={(event) => {
                                if (!event) return;

                                event.preventDefault();
                                const pastedData =
                                  event.clipboardData.getData('text/plain');
                                const licenseKey = pastedData.replace(
                                  /[\s;]/g,
                                  ''
                                );
                                handleInputChange(
                                  key,
                                  licenseKey,
                                  index,
                                  values.orderNo
                                );
                              }}
                            />
                            {/* {key === undefined || testMethod(key) === true ? (
                              <></>
                            ) : ( */}
                            <div
                              id={key + '-Error'}
                              className="marginBottomKey"
                            >
                              <img
                                className="erroMessageIconStyle"
                                src={invalidKeyIcon}
                              />
                              <span className="erroMessage">
                                {constants.ADD_LICENSE_INVALID_MESSAGE}
                              </span>
                            </div>
                            {/* )} */}
                          </div>
                        ) : (
                          <div className="col-md-12">
                            <TextField
                              type="text"
                              // id={key}
                              name={key}
                              value={inputValues[key]}
                              ref={(el) =>
                                (licenseKeyInputRef.current[key] = el)
                              }
                              maxLength="41"
                              beforeinputicon={
                                inputValues?.length > 0 ? (
                                  <img
                                    id={key + '-ErrorIcon'}
                                    src={markIcon}
                                    className="mark-icon-class"
                                  />
                                ) : null
                              }
                              onChange={(event) => {
                                handleInputChange(
                                  key,
                                  event.target.value,
                                  index,
                                  values.orderNo
                                );
                              }}
                              onPaste={(event) => {
                                if (!event) return;

                                event.preventDefault();
                                const pastedData =
                                  event.clipboardData.getData('text/plain');
                                const licenseKey = pastedData.replace(
                                  /[\s;]/g,
                                  ''
                                );
                                handleInputChange(
                                  key,
                                  licenseKey,
                                  index,
                                  values.orderNo
                                );
                              }}
                            />
                            {inputValues?.length > 0 ? (
                              <div
                                id={key + '-Error'}
                                className="marginBottomKey"
                              >
                                <img
                                  className="erroMessageIconStyle"
                                  src={invalidKeyIcon}
                                />
                                <span className="erroMessage">
                                  {constants.ADD_LICENSE_INVALID_MESSAGE}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}

                        <div className="col-md-1">
                          {index < keys?.length - 1 && (
                            <img
                              src={licenseRemoveIcon}
                              alt="Remove Icon"
                              className="licenseRemoveIconStyle"
                              onClick={() => removeKeyAndField(index, key)}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                <Row className="justify-content-end">
                  {dirty && isValid && invalidObjectError?.length !== 0 && (
                    <div className="error errorStyle">
                      {constants.LICENSES_VALIDATION_FAIL_ERROR}
                    </div>
                  )}
                  <div className="error errorStyle" ref={licenseNumberErrorRef}>
                    {invalidObjectError}
                  </div>
                </Row>
                <Row className="justify-content-end">
                  {orderNoSelected ? (
                    <Col>
                      <p
                        className="enterTextManullyHighlightedStyle"
                        onClick={() => handleManualOrderClickWithKey()}
                      >
                        {constants.LICENSES_ENTER_BY_ORDER_TEXT}
                      </p>
                    </Col>
                  ) : (
                    <Col className="enterTextManullyStyle">
                      {constants.LICENSES_ENTER_BY_ORDER_TEXT}
                    </Col>
                  )}

                  <Col className="text-end">
                    <PrimaryButton
                      className="addlicense-btn btn2 btn-primary"
                      fontSize="14px"
                      height="44px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      width="auto"
                      type="submit"
                      disabled={!(orderNoSelected && validLicenseKey === true)}
                    >
                      {constants.LICENSE_CONTINUE_TEXT}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default One;
