import './NewLandingPage.scss'
import { marketingBrand, animation, brandLogo, MegatronLogo, MegatronAnimation, MegatronWhiteLogo } from '../../assets/images';
import { constants, AppDefaults } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../components/common';
import { useNavigate } from 'react-router-dom';

const NewLandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='wrapper-outer'>
      <div className="login-wrapper">
        <div className='fisrt-half'>
          <div>
            <img className="brand-logo" 
              src={
                    process.env.REACT_APP_PROJECT ===
                    AppDefaults.PROJECT_MEGATRON
                      ? MegatronWhiteLogo
                      : marketingBrand
                  } 
              alt="" 
            />
            <div className='text-outer'>
              <div className='left-text'>{constants.LANDING_PAGE_HEADER}</div>
            </div>
            <img className="landing-animation" 
              src={
                process.env.REACT_APP_PROJECT ===
                AppDefaults.PROJECT_MEGATRON
                  ? MegatronAnimation
                  : animation
              } 
              alt="" 
            />
          </div>
        </div>
        <div className='small-logo'>
          <img className="brand-logo" 
            src= {
              process.env.REACT_APP_PROJECT ===
              AppDefaults.PROJECT_MEGATRON
                ? MegatronLogo
                : brandLogo
            }
            alt="" 
          />
        </div>
        <div className='wrapper-outer'>
          <div className='right-container-landing-page'>
            <div className='upper-gap'>
              <div className='text-1'>{t('LANDING_PAGE_TITLE')}</div>
              <div className='text-2'>{constants.LANDING_PAGE_SUBTITLE}</div>
              <div className='text-3'>{constants.LANDING_PAGE_DESCRIPTION}</div>
            </div>
            {
              process.env.REACT_APP_PROJECT !== AppDefaults.PROJECT_MEGATRON ? 
              <PrimaryButton
                className="btn"
                type="button"
                width="100%"
                height= "56px"
                fontSize= "18px"
                lineHeight= "24px"
                fontWeight= "600"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                color={getComputedStyle(document.documentElement).getPropertyValue(
                  '--brand_white'
                )}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_24')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                onClick={() => {
                  window.open(`https://duclo.ai/book-demo`, '_blank');
                }}
              >
                {constants.LANDING_PAGE_BECOME_CUSTOMER}
              </PrimaryButton>
              : null 
            }
          </div>
        </div>
      </div>
    </div>
  );
  };
  
  export default NewLandingPage;
  