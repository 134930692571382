import { Row, Col } from 'react-bootstrap';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import classNames from 'classnames';
import { AppDefaults, Utils, constants } from '../../helpers';

const storageLabel = (usedSpaceMB, allocatedSpaceMB, themeType) => {
    if (allocatedSpaceMB === 0) {
      return null;
    }
  
    let usedSpaceText;
    if (usedSpaceMB < AppDefaults.ONEGB) {
      usedSpaceText = usedSpaceMB % 1 === 0 ? `${usedSpaceMB}MB` : `${usedSpaceMB.toFixed(1)}MB`;
    } else {
      const usedSpaceGB = usedSpaceMB / AppDefaults.ONEGB;
      usedSpaceText = Math.abs(usedSpaceGB % 1) < 0.1 ? Math.round(usedSpaceGB) + 'GB' : usedSpaceGB.toFixed(1) + 'GB';
    }
  
    const allocatedSpaceGB = allocatedSpaceMB === 0 ? '0.0' : ((allocatedSpaceMB / AppDefaults.ONEGB) % 1 === 0 ? (allocatedSpaceMB / AppDefaults.ONEGB).toFixed(0) : (allocatedSpaceMB / AppDefaults.ONEGB).toFixed(1));
    const usedPercentage = (usedSpaceMB / allocatedSpaceMB) * 100;
   let  themeClass;
    if (themeType === 1) {
      themeClass = 'storge-clip-title';
    } else if (themeType === 3) {
      themeClass = 'storge-clip-title-light40';
    } else {
      themeClass = 'storge-clip-title-light';
    }
  
    return (
      <Row>
        <Col className={`storage clips-total storage-text-dis ${themeClass}`}>
          {constants.USING} <span className={usedPercentage >= 90 ? 'storage-error' : 'storage-av'}>{usedSpaceText}</span> of {allocatedSpaceGB}{constants.AVAILABEL_STORAGE}
        </Col>
      </Row>
    );
  }
  
  const StorageDashboard = (usedSpaceMB, allocatedSpaceMB, from) => {
    if (allocatedSpaceMB === 0) {
      return null;
    }
    const usedSpaceGB = usedSpaceMB / AppDefaults.ONEGB;
    const allocatedSpaceGB = allocatedSpaceMB / AppDefaults.ONEGB;
    const rowClasses = classNames({
      'm-t-16': from === 1,
      'm-b-16': from === 1,
    });
  
    const exceededAllocation = usedSpaceMB > allocatedSpaceMB;
  
    if (exceededAllocation) {
      return (
        <Row className={rowClasses}>
          <Col>
            <div className='storage-dashboard'>
              <div>
                <AiOutlineExclamationCircle/>
              </div>
              <div>
                {Utils.replaceStringWithKeyValueArray(
                  constants.CLIP_STORAGE_INFO,
                  [
                    {
                      key: '$usedSpaceGB',
                      value: Math.abs(usedSpaceGB % 1) < 0.1 ? usedSpaceGB.toFixed(0) : usedSpaceGB.toFixed(1),
                    },
                    {
                      key: '$allocatedSpaceGB',
                      value: Math.abs(allocatedSpaceGB % 1) < 0.1 ? allocatedSpaceGB.toFixed(0) : allocatedSpaceGB.toFixed(1),
                    },
                  ]
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  };

  export {StorageDashboard, storageLabel}