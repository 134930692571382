import { HiOutlineMapPin } from 'react-icons/hi2';
import { HiChevronRight } from 'react-icons/hi2';

const AssignLocations = ({ label, clickHandler,classes, ...props }) => {
  return (
    <div className={`${classes} assign-location-wrap`}  onClick={clickHandler}>
      <div className="check-location-wrap check-location-btn">
        {label && (
          <div className="check-location-label d-felx flex-row">
            <div
              className={`icon ${
                props?.hideIcon?.toString() === 'true' ? 'd-none' : ''
              }`}
            >
              <HiOutlineMapPin />
            </div>
            <div>{label}</div>
          </div>
        )}
        <div className="check-location-btn">
          <HiChevronRight/>
        </div>
      </div>
    </div>
  );
};

export default AssignLocations;
