import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlinePencil } from 'react-icons/hi2';
import { HiPlusSm } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import './AnalyticsSettings.scss';
import { RangeInputField, ToggleInput } from '../../../../components/forms';
import { PrimaryButton, SiteModal } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import EnclusionZone from './ExclusionZone';
import InclusionZone from './InclusionZone';
import OccupancyQueue from './OccupancyQueue';
import LineCrossings from './LineCrossings';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const AnalyticsSettings = ({
  accountId,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  updateStore,
  hasManagePermission,
}) => {
  const [update, setUpdate] = useState(false);
  const [title, setTitle] = useState('');
  const [showDetectionmodal, setShowDetectionmodal] = useState(false);
  const [modelData, setModalData] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const [refResolution, setRefResolution] = useState();
  const resourceList = Utils.getDeviceSettingResource(7);
  const capAnalytics = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0]
  );
  const capMotion = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1]
  );
  const capShock = Utils.getCurrentTabProperty(capabilityList, resourceList[2]);
  const capTamper = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[3]
  );
  const capDefocus = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[4]
  );
  const capAudio = Utils.getCurrentTabProperty(capabilityList, resourceList[5]);
  const capVirtualLine = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[6]
  );
  const capInclusionArea = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[7]
  );
  const capExclusionArea = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[9]
  );
  const capQueueManagement = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[8]
  );
  const capSoundClassification = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[10]
  );
  const [vehicleDetection, setVehicleDetection] = useState(
    deviceInfos?.properties?.['vehicle-detection'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [licensePlateDetection, setLicensePlateDetection] = useState(
    deviceInfos?.properties?.['licenseplate-detection'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [personDetection, setPersonDetection] = useState(
    deviceInfos?.properties?.['person-detection'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [faceDetection, setFaceDetection] = useState(
    deviceInfos?.properties?.['face-detection'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  // const [loiteringDetection, setLoiteringDetection] = useState(
  //   deviceInfos?.properties?.['loitering-detection'] ===
  //     constants.PROPERTY_STATUS_TRUE
  // );
  const [soundClassificationEnabled, setSoundClassificationEnabled] = useState(
    deviceInfos?.properties?.['sc-enabled'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [gunShotEnabled, setGunShotEnabled] = useState(
    deviceInfos?.properties?.['sc-gunshot'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [glassBreakEnable, setGlassBreakEnable] = useState(
    deviceInfos?.properties?.['sc-glassbreak'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [scExplosionEnable, setScExplosionEnable] = useState(
    deviceInfos?.properties?.['sc-explosion'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [scScreamEnable, setScreamEnable] = useState(
    deviceInfos?.properties?.['sc-scream'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const [scLevel, setScLevel] = useState(
    deviceInfos?.properties?.['sc-level']
  );
  const [motionDetection, setMotionDetection] = useState(
    deviceInfos?.properties?.['md-enable'] === constants.PROPERTY_STATUS_TRUE
  );
  const [shockDetection, setShockDetection] = useState(
    deviceInfos?.properties?.['skd-enable'] === constants.PROPERTY_STATUS_TRUE
  );
  const [temperingDetection, setTemperingDetection] = useState(
    deviceInfos?.properties?.['td-enable'] === constants.PROPERTY_STATUS_TRUE
  );
  const [defocusDetection, setDefocusDetection] = useState(
    deviceInfos?.properties?.['dfd-enable'] === constants.PROPERTY_STATUS_TRUE
  );
  const [audioDetction, setAudioDetction] = useState(
    deviceInfos?.properties?.['audio-detection'] ===
      constants.PROPERTY_STATUS_TRUE
  );
  const localStoreProperties = JSON.parse(
    localStorage.getItem('localStoreProperty')
  );
  const [exclusionZonesData, setExclusionZonesData] = useState(
    deviceInfos?.properties?.['exclude-area']
      ? JSON.parse(deviceInfos?.properties?.['exclude-area'])
      : { 'exclude-areas': [] }
  );
  const [inclusionZonesData, setInclusionZonesData] = useState(
    deviceInfos?.properties?.['include-area']
      ? JSON.parse(deviceInfos?.properties?.['include-area'])
      : { 'include-areas': [] }
  );
  const [queuesData, setQueuesData] = useState(
    deviceInfos?.properties?.['queue']
      ? JSON.parse(deviceInfos?.properties?.['queue'])
      : { queues: [] }
  );
  const [lineCrossingsData, setLineCrossingsData] = useState(
    deviceInfos?.properties?.['virtual-line']
      ? JSON.parse(deviceInfos?.properties?.['virtual-line'])
      : { 'virtual-lines': [] }
  );
  const [showExclusionZone, setShowExclusionZone] = useState(false);
  const [showInclusionZone, setShowInclusionZone] = useState(false);
  const [showOccupancyQueue, setShowOccupancyQueue] = useState(false);
  const [showLineCrossing, setShowLineCrossing] = useState(false);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };

  useEffect(() => {
    Utils.vmsLogger().log('first', capabilityList);
    if (deviceId && deviceInfos) {
      if (deviceInfos) {
        const referenceResolution =
          deviceInfos?.properties?.['reference-resolution'];
        Utils.vmsLogger().log(
          'first',
          deviceInfos?.properties?.['reference-resolution']
        );
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, []);

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  useEffect(() => {
    if (modalIndex !== 0) {
      setShowDetectionmodal(true);
    }
  }, [update, modalIndex]);

  useEffect(() => {
    setVehicleDetection(
      deviceInfos?.properties?.['vehicle-detection'] ===
        constants.PROPERTY_STATUS_TRUE
    );
    setPersonDetection(
      deviceInfos?.properties?.['person-detection'] ===
        constants.PROPERTY_STATUS_TRUE
    );
    setFaceDetection(
      deviceInfos?.properties?.['face-detection'] ===
        constants.PROPERTY_STATUS_TRUE
    );
    setLicensePlateDetection(
      deviceInfos?.properties?.['licenseplate-detection'] ===
        constants.PROPERTY_STATUS_TRUE
    );
    // setLoiteringDetection(
    //   deviceInfos?.properties?.['loitering-detection'] ===
    //     constants.PROPERTY_STATUS_TRUE
    // );
    setSoundClassificationEnabled(
      deviceInfos?.properties?.['sc-enabled'] ===
        constants.PROPERTY_STATUS_TRUE
    );

    setGunShotEnabled(
      deviceInfos?.properties?.['sc-gunshot'] ===
        constants.PROPERTY_STATUS_TRUE
    );

    setGlassBreakEnable(
      deviceInfos?.properties?.['sc-glassbreak'] ===
        constants.PROPERTY_STATUS_TRUE
    );
   
    setScExplosionEnable(
      deviceInfos?.properties?.['sc-explosion'] ===
        constants.PROPERTY_STATUS_TRUE
    );

    setScreamEnable(
      deviceInfos?.properties?.['sc-scream'] ===
        constants.PROPERTY_STATUS_TRUE
    );

    setScLevel(
      deviceInfos?.properties?.['sc-level']
    );

    setMotionDetection(
      deviceInfos?.properties?.['md-enable'] === constants.PROPERTY_STATUS_TRUE
    );
    setShockDetection(
      deviceInfos?.properties?.['skd-enable'] === constants.PROPERTY_STATUS_TRUE
    );
    setTemperingDetection(
      deviceInfos?.properties?.['td-enable'] === constants.PROPERTY_STATUS_TRUE
    );
    setDefocusDetection(
      deviceInfos?.properties?.['dfd-enable'] === constants.PROPERTY_STATUS_TRUE
    );
    setAudioDetction(
      deviceInfos?.properties?.['audio-detection'] ===
        constants.PROPERTY_STATUS_TRUE
    );
    setExclusionZonesData(
      deviceInfos?.properties?.['exclude-area']
        ? JSON.parse(deviceInfos?.properties?.['exclude-area'])
        : { 'exclude-areas': [] }
    );
    setInclusionZonesData(
      deviceInfos?.properties?.['include-area']
        ? JSON.parse(deviceInfos?.properties?.['include-area'])
        : { 'include-areas': [] }
    );
    setLineCrossingsData(
      deviceInfos?.properties?.['virtual-line']
        ? JSON.parse(deviceInfos?.properties?.['virtual-line'])
        : { 'virtual-lines': [] }
    );
    setQueuesData(
      deviceInfos?.properties?.['queue']
        ? JSON.parse(deviceInfos?.properties?.['queue'])
        : { queues: [] }
    );

    if (modelData == 'undefined' &&  modalIndex !== 0) {
      setModalData(
        Utils.getDetectionValue(modalIndex, deviceInfos?.properties, modelData)
      );
    }
  }, [deviceInfos]);

  const selectDetection = (e, name) => {
    e.preventDefault();
    switch (name) {
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION:
        setModalIndex(1);
        setModalData(
          Utils.getModalData(
            1,
            deviceInfos?.properties,
            capMotion,
            resourceList[1]
          )
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION:
        setModalIndex(2);
        setModalData(
          Utils.getModalData(
            2,
            deviceInfos?.properties,
            capShock,
            resourceList[2]
          )
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION:
        setModalIndex(3);
        setModalData(
          Utils.getModalData(
            3,
            deviceInfos?.properties,
            capTamper,
            resourceList[3]
          )
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION:
        setModalIndex(4);
        setModalData(
          Utils.getModalData(
            4,
            deviceInfos?.properties,
            capDefocus,
            resourceList[4]
          )
        );
        break;
      case constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION:
        setModalIndex(5);
        setModalData(
          Utils.getModalData(
            5,
            deviceInfos?.properties,
            capAudio,
            resourceList[5]
          )
        );
        break;
        // case constants.DEVICES_TAB_ANALYTICS_SETTINGS_FACE_DETECTION:
        // setModalIndex(6);
        // setModalData(
        //   Utils.getModalData(
        //     2,
        //     deviceInfos?.properties,
        //     capShock,
        //     resourceList[2]
        //   )
        // );
        // break;
        // case constants.DEVICES_TAB_ANALYTICS_SETTINGS_LICENSE_PLATE_DETECTION:
        // setModalIndex(7);
        // setModalData(
        //   Utils.getModalData(
        //     2,
        //     deviceInfos?.properties,
        //     capShock,
        //     resourceList[2]
        //   )
        // );
        // break;
        case constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION:
        setModalIndex(8);
        setModalData(
          Utils.getModalData(
            6,
            deviceInfos?.properties,
            capSoundClassification,
            resourceList[10]
          )
        );
      default:
      // do nothing
    }
    setTitle(name);
  };

  const onChangeHandler = (e, property, resource) => {
    const status = getCheckPropertyStatus(
      !!!JSON.parse(e?.target?.value),
      property
    );
    updateStore(status);
    if(property === 'person-detection'){
      setFaceDetection(!!!JSON.parse(e?.target?.value))
      const personStatus = getCheckPropertyStatus(
        !!!JSON.parse(e?.target?.value),
        'face-detection'
      );
      setTimeout(() => {
        publishDeviceSetting(personStatus, resource, publishPayload);
      }, 1000);
    }
    if(property === 'vehicle-detection'){
      setFaceDetection(!!!JSON.parse(e?.target?.value))
      const vehicleStatus = getCheckPropertyStatus(
        !!!JSON.parse(e?.target?.value),
        'licenseplate-detection'
      );
      setTimeout(() => {
        publishDeviceSetting(vehicleStatus, resource, publishPayload);
      }, 1000);
    }
    publishDeviceSetting(status, resource, publishPayload);
    setUpdate(!!update);
  };

  const handleInclusionZonePlusClick = () => {
    setShowInclusionZone(true);
  };

  const handleExclusionZonePlusClick = () => {
    setShowExclusionZone(true);
  };

  const handleOccupancyQueuePlusClick = () => {
    setShowOccupancyQueue(true);
  };

  const handleLineCrossingPlusClick = () => {
    setShowLineCrossing(true);
  };

  return (
    <div
      className={`text-start device-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title">
        <label>{constants.ANALYTICS_SETTINGS}</label>
      </div>
      {Utils.getPropertyShowStatus(curDeviceStatus, 'md-enable', capMotion) && (
        <div className="settings-motion-detection-block">
          <div className='d-flex align-items-center'>
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION}
              name="motionDetection"
              changeHandler={(e) => {
                setMotionDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'md-enable', resourceList[1]);
              }}
              value={motionDetection}
              isAddEdit={true}
              disabled={
                deviceInfos?.properties?.recEventBased === 'true' ||
                !canChangeSettings
                  ? true
                  : false
              }
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) => {
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION
              );
            }}
            className={`add-edit-icon ${!motionDetection ? ' off-status' : ''}`}
          />
          </div>
          <div className='display-f m-t-15-m analytics-text-hint'>
            <div className='m-l-19 m-t-1'>
              <AiOutlineExclamationCircle size={15} stroke={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_56')}/>
            </div>
          <div className='text-margin'> {constants.ANALYTICS_MOTION_DETECTION_HINT} </div>
        </div>
        </div>
      )}

{Utils.getPropertyShowStatus(
        curDeviceStatus,
        'person-detection',
        capAnalytics
      ) && (
        <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_PERSON_DETECTION}
              name="personDetection"
              changeHandler={(e) => {
                setPersonDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'person-detection', resourceList[0]);
              }}
              value={personDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        </Row>
      )}

{Utils.getPropertyShowStatus(
        curDeviceStatus,
        'face-detection',
        capAnalytics
      ) && (
        <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_FACE_DETECTION}
              name="personDetection"
              changeHandler={(e) => {
                setFaceDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'face-detection', resourceList[0]);
              }}
              value={faceDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        </Row>
      )}

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'vehicle-detection',
        capAnalytics
      ) && (
        <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_VEHICLE_DETECTION}
              name="vehicleDetection"
              changeHandler={(e) => {
                setVehicleDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'vehicle-detection', resourceList[0]);
              }}
              value={vehicleDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        </Row>
      )}
      
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'licenseplate-detection',
        capAnalytics
      ) && (
        <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_LICENSE_PLATE_DETECTION}
              name="vehicleDetection"
              changeHandler={(e) => {
                setLicensePlateDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'licenseplate-detection', resourceList[0]);
              }}
              value={licensePlateDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        </Row>
      )}

{Utils.getPropertyShowStatus(curDeviceStatus, 'td-enable', capTamper) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION
              }
              name="temperingDetection"
              changeHandler={(e) => {
                setTemperingDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'td-enable', resourceList[3]);
              }}
              isAddEdit={true}
              value={temperingDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) =>
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION
              )
            }
            className={`add-edit-icon ${
              !temperingDetection ? ' off-status' : ''
            }`}
          />
        </div>
      )}

{/* {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'loitering-detection',
        capAnalytics
      ) && (
        <Row className="settings-block">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_LOITERING_DETECTION}
              name="loiteringDetection"
              changeHandler={(e) => {
                setLoiteringDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'loitering-detection', resourceList[0]);
              }}
              value={loiteringDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        </Row>
      )} */}

{Utils.getPropertyShowStatus(
        curDeviceStatus,
        'dfd-enable',
        capDefocus
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION}
              name="defocusDetection"
              changeHandler={(e) => {
                setDefocusDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'dfd-enable', resourceList[4]);
              }}
              isAddEdit={true}
              value={defocusDetection}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) =>
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION
              )
            }
            className={`add-edit-icon ${
              !defocusDetection ? ' off-status' : ''
            }`}
          />
        </div>
      )}

{Utils.getPropertyShowStatus(curDeviceStatus, 'skd-enable', capShock) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION}
              name="shockDetection"
              changeHandler={(e) => {
                setShockDetection(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'skd-enable', resourceList[2]);
              }}
              value={shockDetection}
              isAddEdit={true}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) =>
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION
              )
            }
            className={`add-edit-icon ${!shockDetection ? ' off-status' : ''}`}
          />
        </div>
      )}

{Utils.getPropertyShowStatus(
        curDeviceStatus,
        'audio-detection',
        capAudio
      ) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION}
              name="audioDetction"
              changeHandler={(e) => {
                setAudioDetction(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'audio-detection', resourceList[5]);
              }}
              isAddEdit={true}
              value={audioDetction}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) =>
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION
              )
            }
            className={`add-edit-icon ${!audioDetction ? ' off-status' : ''}`}
          />
        </div>
      )}

      {Utils.getPropertyShowStatus(curDeviceStatus, 'sc-enabled', capSoundClassification) && (
        <div className="settings-block-analytics">
          <Col>
            <ToggleInput
              label={
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION
              }
              name="soundClassification"
              changeHandler={(e) => {
                setSoundClassificationEnabled(!!!JSON.parse(e?.target?.value));
                onChangeHandler(e, 'sc-enabled', resourceList[10]);
              }}
              value={soundClassificationEnabled}
              isAddEdit={true}
              disabled={
                deviceInfos?.properties?.recEventBased === 'true' ? true : false
              }
            />
          </Col>
          <HiOutlinePencil
            onClick={(e) => {
              if(!soundClassificationEnabled) return;
              selectDetection(
                e,
                constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_CLASSIFICATION
              );
            }}
            className={`add-edit-icon ${!motionDetection ? ' off-status' : ''}`}
          />
        </div>
      )}

      <div className="tab-title">
        <label>{constants.ADVANCED_ANALYTICS}</label>
      </div>
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'include-area-enable',
        capInclusionArea
      ) && (
        <div className="advanced-activity-zone mt-4">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_ANALYTICS_SETTINGS_INCLUSION_ZONE}
          </div>
          {inclusionZonesData?.['include-areas']?.length > 0 ? (
            <HiOutlinePencil
              onClick={() => {
                if (!canChangeSettings) {
                  return;
                }
                handleInclusionZonePlusClick();
              }}
              className={`add-icon`}
              size={16}
            />
          ) : (
            <HiPlusSm
              onClick={() => {
                if (!canChangeSettings) {
                  return;
                }
                handleInclusionZonePlusClick();
              }}
              className={`add-icon`}
              size={20}
            />
          )}
        </div>
      )}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'exclude-area-enable',
        capExclusionArea
      ) && (
        <div className="advanced-activity-zone mt-4">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_ANALYTICS_SETTINGS_EXCLUSION_ZONE}
          </div>
          {exclusionZonesData?.['exclude-areas']?.length > 0 ? (
            <HiOutlinePencil
              onClick={() => {
                if (!canChangeSettings) {
                  return;
                }
                handleExclusionZonePlusClick();
              }}
              className={`add-icon`}
              size={16}
            />
          ) : (
            <HiPlusSm
              onClick={() => {
                if (!canChangeSettings) {
                  return;
                }
                handleExclusionZonePlusClick();
              }}
              className={`add-icon`}
              size={20}
            />
          )}
        </div>
      )}
      {/* {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'queue-enable',
        capQueueManagement
      ) && (
        <div className="advanced-activity-zone mt-4">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_ANALYTICS_SETTINGS_OCCUPANCY_QUEUE}
          </div>
          {queuesData?.['queues']?.length > 0 ? (
            <HiOutlinePencil
              onClick={handleOccupancyQueuePlusClick}
              className={`add-icon`}
              size={16}
            />
          ) : (
            <HiPlusSm
              onClick={handleOccupancyQueuePlusClick}
              className={`add-icon`}
              size={20}
            />
          )}
        </div>
      )}*/}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'virtual-line-enable',
        capVirtualLine
      ) && (
        <div className="advanced-activity-zone mt-4">
          <div className="activity-field-label">
            {constants.DEVICES_TAB_ANALYTICS_SETTINGS_LINE_CROSSING}
          </div>
          {lineCrossingsData?.['virtual-lines']?.length > 0 ? (
            <HiOutlinePencil
              onClick={handleLineCrossingPlusClick}
              className={`add-icon`}
              size={16}
            />
          ) : (
            <HiPlusSm
              onClick={handleLineCrossingPlusClick}
              className={`add-icon`}
              size={20}
            />
          )}
        </div>
      )}
      <SiteModal
        modalTitle={title}
        showModal={showDetectionmodal}
        hideModal={() => {
          setShowDetectionmodal(false);
          setModalIndex(0);
        }}
        classes="device-settings-restart-required"
      >
        <div className="sitemodal-inner">
          {modalIndex !== 6 && modalIndex !== 7 && modalIndex !== 8 && (
            <Row className="settings-block">
              <Col>
                <RangeInputField
                  label={constants.DEVICE_TAB_LEVEL_OF_DETECTION}
                  fieldName="levelDetection"
                  minValueLabel={constants.MIN_VALUE_LABEL}
                  maxValueLabel={constants.MAX_VALUE_LABEL}
                  maxValue={parseInt(modelData?.maxLevelDetection)}
                  minValue={parseInt(modelData?.minLevelDetection)}
                  currentValue={parseInt(modelData?.valueLevelDetection)}
                  thumbLabelHide={true}
                  isErrorMessage={false}
                  changeHandler={(value) => {
                    setModalData({
                      ...modelData,
                      valueLevelDetection: value.toString(),
                    });
                    const property = {
                      [modelData.levelKey]: value.toString(),
                    };
                    updateStore(property);
                    publishDeviceSetting(
                      property,
                      modelData.resource,
                      publishPayload
                    );
                  }}
                />
              </Col>
            </Row>
          )}

          {modalIndex !== 5 && modalIndex !== 8 && (
            <Row className="settings-block">
              <Col>
                <RangeInputField
                  label={constants.DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL}
                  fieldName="sensitivity"
                  minValueLabel={constants.MIN_VALUE_LABEL}
                  maxValueLabel={constants.MAX_VALUE_LABEL}
                  maxValue={parseInt(modelData?.maxSensitivity)}
                  minValue={parseInt(modelData?.minSensitivity)}
                  currentValue={parseInt(modelData?.valueSensitivity)}
                  thumbLabelHide={true}
                  isErrorMessage={false}
                  changeHandler={(value) => {
                    setModalData({
                      ...modelData,
                      valueSensitivity: value.toString(),
                    });
                    const property = {
                      [modelData.sensitivityKey]: value.toString(),
                    };
                    updateStore(property);
                    publishDeviceSetting(
                      property,
                      modelData.resource,
                      publishPayload
                    );
                  }}
                />
              </Col>
            </Row>
          )}
          {modalIndex !== 2 &&
            modalIndex !== 5 &&
            modalIndex !== 6 &&
            modalIndex !== 7 &&
            modalIndex !== 8 && (
              <Row className="settings-block">
                <Col md={12}>
                  <RangeInputField
                    label={constants.DEVICE_TAB_MINIMUM_DURATION}
                    fieldName="mDuration"
                    maxValue={parseInt(modelData?.maxDuration)}
                    minValue={parseInt(modelData?.minDuration)}
                    currentValue={parseInt(modelData?.valueDuration)}
                    isErrorMessage={false}
                    changeHandler={(value) => {
                      setModalData({
                        ...modelData,
                        valueDuration: value.toString(),
                      });
                      const property = {
                        [modelData.mindurationKey]: value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        modelData.resource,
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
          {modalIndex == 8 && (
            <Row>
              <Col>
                <Row className="settings-block">
                  <Col>
                    <RangeInputField
                      label={
                        constants.DEVICES_TAB_ANALYTICS_SETTINGS_SOUND_OF_CLASSIFICATION
                      }
                      fieldName="sensitivity"
                      minValueLabel={constants.MIN_VALUE_LABEL}
                      maxValueLabel={constants.MAX_VALUE_LABEL}
                      maxValue={parseInt(modelData?.maxLevel)}
                      minValue={parseInt(modelData?.minLevel)}
                      currentValue={parseInt(scLevel)}
                      thumbLabelHide={true}
                      isErrorMessage={false}
                      changeHandler={(value) => {
                        setModalData({
                          ...modelData,
                          scLevelValue: value?.toString(),
                        });
                        setScLevel(value?.toString());
                        const property = {
                          [modelData?.levelKey]: value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          modelData.resource,
                          publishPayload
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col className="sound-cls-types">Types</Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div>
                            <ToggleInput
                              label={
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_GUNSHOT
                              }
                              name="gunShotDetection"
                              changeHandler={(e) => {
                                setGunShotEnabled(!!!JSON.parse(e?.target?.value));
                                onChangeHandler(e, 'sc-gunshot', resourceList[10]);
                              }}
                              value={gunShotEnabled}                              
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div>
                            <ToggleInput
                              label={
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_SCREAM
                              }
                              name="screamDetection"
                              changeHandler={(e) => {
                                setScreamEnable(!!!JSON.parse(e?.target?.value));
                                onChangeHandler(e, 'sc-scream', resourceList[10]);
                              }}
                              value={scScreamEnable}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div>
                            <ToggleInput
                              label={
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_EXPLOSION
                              }
                              name="explosionDetection"
                              changeHandler={(e) => {
                                setScExplosionEnable(!!!JSON.parse(e?.target?.value));
                                onChangeHandler(e, 'sc-explosion', resourceList[10]);
                              }}
                              value={scExplosionEnable} 
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="range-field-wrap-types m-t-8 ">
                        <div className="m-l-r-20 w-90">
                          <div>
                            <ToggleInput
                              label={
                                constants.DEVICES_TAB_ANALYTICS_SETTINGS_GLASSBREAK
                              }
                              name="glassBreakDetection"
                              changeHandler={(e) => {
                                setGlassBreakEnable(!!!JSON.parse(e?.target?.value));
                                onChangeHandler(e, 'sc-glassbreak', resourceList[10]);
                              }}
                              value={glassBreakEnable}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>

        <PrimaryButton
          className="btn btn-primary mt-4"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setShowDetectionmodal(false);
            setModalIndex(0);
          }}
        >
          {constants.DEVICES_TAB_ANALYTICS_BACK}
        </PrimaryButton>
      </SiteModal>

      <SiteModal
        modalTitle={constants.INCLUSION_ZONE_POPUP_TITLE}
        showModal={showInclusionZone}
        hideModal={() => {
          setShowInclusionZone(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <InclusionZone
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capInclusionArea={capInclusionArea}
          inclusionZonesData={inclusionZonesData}
          hideModelPopup={() => {
            setShowInclusionZone(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.EXCLUSION_ZONE_POPUP_TITLE}
        showModal={showExclusionZone}
        hideModal={() => {
          setShowExclusionZone(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <EnclusionZone
          accountId={accountId}
          refResolution={refResolution}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          vehicleDetection={vehicleDetection}
          personDetection={personDetection}
          capExclusionArea={capExclusionArea}
          exclusionZonesData={exclusionZonesData}
          hideModelPopup={() => {
            setShowExclusionZone(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.OCCUPANCY_QUEUE_POPUP_TITLE}
        showModal={showOccupancyQueue}
        hideModal={() => {
          setShowOccupancyQueue(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <OccupancyQueue
          accountId={accountId}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuid}
          capQueueManagement={capQueueManagement}
          queuesData={queuesData}
          hideModelPopup={() => {
            setShowOccupancyQueue(false);
          }}
        />
      </SiteModal>

      <SiteModal
        modalTitle={constants.LINE_CROSSING_POPUP_TITLE}
        showModal={showLineCrossing}
        hideModal={() => {
          setShowLineCrossing(false);
        }}
        classes="analytics-settings-activity-zone"
      >
        <LineCrossings
          refResolution={refResolution}
          accountId={accountId}
          deviceId={deviceId}
          deviceInfos={deviceInfos}
          devicePublishId={devicePublishId}
          uuid={uuidv4()}
          vehicleDetection={vehicleDetection}
          personDetection={personDetection}
          capVirtualLine={capVirtualLine}
          lineCrossingsData={lineCrossingsData}
          hideModelPopup={() => {
            setShowLineCrossing(false);
          }}
        />
      </SiteModal>
    </div>
  );
};

export default AnalyticsSettings;
