import {Container, Col, Row} from 'react-bootstrap';

const PageWrapper = (props) => {
  return (
    <Container className={props.className}>
        <Row className='w-100'>
            <Col xs lg="11" className='w-100'>
                {props.children}
            </Col>
        </Row>
    </Container>
  );
}

export default PageWrapper;
