const ScrollbarWrapper = (props) => {
  return (
    <div
      className={`overflow-auto ${props.className}`}
      style={{ height: props.height }}
    >
      {props.children}
    </div>
  );
};

ScrollbarWrapper.defaultProps = {
  height: '100px',
};

export default ScrollbarWrapper;
