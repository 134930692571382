import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button as CancelButton, Col, Row } from 'react-bootstrap';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  TextField,
  checkboxClasses,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import {
  Avatar,
  ListTable,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  SiteToast,
  TextBlock,
} from '../../../components/common';
import { AppDefaults, constants, Utils } from '../../../helpers';

import { usePoliciesStore } from '../../../store/policiesStore';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../../store/CustomerOrgUsersStore';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { useSelector } from 'react-redux';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

import noteTypeMap from '../shared/noteTypeMap';
import EvidenceTabNoContent from './EvidenceTabNoContent';
import NoteDetails from './NoteDetails';

import {
  HiOutlineAdjustments,
  HiOutlineChevronRight,
  HiOutlineDocument,
  HiOutlineDocumentText,
  HiOutlineVideoCamera,
} from 'react-icons/hi';
import { PiImageBold } from 'react-icons/pi';
import { IoClose } from 'react-icons/io5';
import { styled } from '@mui/material/styles';

import './EvidenceTab.scss';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const gridFields = [
  {
    field: 'incidentNoteType',
    sortableField: 'sortable_incidentNoteType',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_FILE_TYPE,
  },
  {
    field: 'fileName',
    sortableField: 'sortable_fileName',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_FILE_NAME,
  },
  {
    field: 'createdBy',
    sortableField: 'sortable_createdBy',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_ADDED_BY,
  },
  {
    field: 'createdDate',
    sortableField: 'sortable_createdDate',
    fieldType: 'text',
    displayName:
      constants.INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_DATE_AND_TIME_ADDED,
  },
  {
    field: 'action',
    fieldType: 'icon',
    clickHandler: () => {},
    displayContent: (
      <HiOutlineAdjustments className="filter-action" size={1.5} />
    ),
  },
];

const getColDefs = () => {
  const maxWidthMap = {
    incidentNoteType: 240,
    fileName: 240,
    createdBy: 240,
    createdDate: 240,
    action: 48,
  };

  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    sortableField: gridField.sortableField,
    displayName:
      gridField.fieldType === 'text'
        ? gridField.displayName
        : gridField.displayContent,
    cellMaxWidth: maxWidthMap[gridField.field],
  }));
};

const evidenceTypeFilters = [
  {
    key: 'comment',
    show: true,
    displayText: constants.INCIDENTS_EVIDENCE_EVIDENCE_TYPE_COMMENT,
    icon: <HiOutlineDocumentText className="filter-icon" size={1.2} />,
  },
  {
    key: 'document',
    show: true,
    displayText: constants.INCIDENTS_EVIDENCE_EVIDENCE_TYPE_DOCUMENT,
    icon: <HiOutlineDocument className="filter-icon" size={1.2} />,
  },
  {
    key: 'video',
    show: true,
    displayText: constants.INCIDENTS_EVIDENCE_EVIDENCE_TYPE_VIDEO,
    icon: <HiOutlineVideoCamera className="filter-icon" size={1.2} />,
  },
  {
    key: 'image',
    show: true,
    displayText: constants.INCIDENTS_EVIDENCE_EVIDENCE_TYPE_IMAGE,
    icon: <PiImageBold className="filter-icon" size={1.2} />,
  },
];

const EvidenceTab = ({
  selectedIncident,
  setShowToast,
  setUserMsg,
  statusClosed,
  setIncludesIncidentVideo,
  setShowFilterIncidentsModal,
  fetchTimezoneLocation,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [showIncidentsLoader, setShowIncidentsLoader] = useState(false);
  const [showFileUploadLoader, setShowFileUploadLoader] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [hasEvidence, setHasEvidence] = useState(false);
  const [hasComment, setHasComment] = useState(false);
  const [evidenceFilters, setEvidenceFilters] = useState([
    ...evidenceTypeFilters,
  ]);
  const [hasViewEditAccess, setHasViewEditAccess] = useState(false);
  const [viewNoteDetails, setViewNoteDetails] = useState(false);
  const [showBackToEvidence, setShowBackToEvidence] = useState(false);
  const [incidentNotes, setIncidentNotes] = useState([]);
  const [columnDefs, setColumnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState(null);
  const [comment, setComment] = useState('');
  const [attachedFile, setAttachedFile] = useState('');
  const [attachedFileName, setAttachedFileName] = useState('');
  const [attachedFileContents, setAttachedFileContents] = useState(null);
  const [attachedFileContentType, setAttachedFileContentType] = useState('');
  const [filePath, setFilePath] = useState('');
  const [presignedURL, setPresignedURL] = useState('');
  const [selectedIncidentNoteId, setSelectedIncidentNoteId] = useState(null);

  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const customerOrgUsers = getCustomerOrgUsersData();

  const fileHolderRef = useRef();
  const commentRef = useRef();
  const currentUserPolicies = getLoggedInUserPolicies();
  const [orgDetails, setOrgDetails] = useState();

  const getRowData = (incidentNotes) => {
    let incidentNotesProps = {};
    const rowData = [];

    if (!Array.isArray(incidentNotes) || !Array.isArray(columnDefs)) {
      return;
    }

    // Set the correct Action column click handler
    columnDefs[columnDefs?.length - 1].clickHandler = () =>
      setShowFilterModal(true);

    incidentNotes.forEach((incidentNote) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'CREATEDDATE':
            incidentNotesProps[def?.field] = (
              <div className="date-field">
                {def?.field in incidentNote
                  ? moment(parseInt(incidentNote?.createdDate)).format(
                      AppDefaults.INCIDENT_EVIDENCE_NOTE_DATE_FORMAT
                    )
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            incidentNotesProps[def?.sortableField] =
              def?.field in incidentNote
                ? incidentNote?.createdDate
                : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT;
            break;

          case 'CREATEDBY':
            incidentNotesProps[def?.field] = (
              <div
                key={`avatar-field-${incidentNote?.incidentNoteId}`}
                className="avatar-field"
              >
                <Avatar
                  valueType={
                    incidentNote?.avatarURL &&
                    Utils.checkURLValidity(incidentNote?.avatarURL)
                      ? 'icon'
                      : 'text'
                  }
                  value={
                    incidentNote?.avatarURL &&
                    Utils.checkURLValidity(incidentNote?.avatarURL)
                      ? incidentNote?.avatarURL
                      : incidentNote?.initials
                  }
                  size="semiLarge"
                  avatarStyle="roundedCircle"
                />
                <span>{incidentNote?.createdBy}</span>
              </div>
            );
            incidentNotesProps[def?.sortableField] =
              incidentNote?.incidentNoteId;
            break;

          case 'INCIDENTNOTETYPE':
            incidentNotesProps[def?.field] =
              noteTypeMap[incidentNote[def?.field]?.toUpperCase()] ||
              noteTypeMap['DOCUMENT'];
            incidentNotesProps[def?.sortableField] =
              incidentNote[def?.field]?.toUpperCase();
            break;

          case 'ACTION':
            incidentNotesProps[def?.field] = (
              <div
                key={`action-field-${incidentNote?.incidentNoteId}`}
                className="action-field"
              >
                <HiOutlineChevronRight size={1.0} />
              </div>
            );
            incidentNotesProps[def?.sortableField] = null;
            break;

          default:
            if (def?.field in incidentNote) {
              incidentNotesProps[def?.field] = incidentNote[def?.field];
              incidentNotesProps[def?.sortableField] = incidentNote[def?.field];
            }
        }
      });

      incidentNotesProps.rowId = incidentNote?.incidentNoteId;
      rowData.push(incidentNotesProps);
      incidentNotesProps = {};
    });

    return rowData;
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    setHasViewEditAccess(
      currentUserPolicies?.update_incident_status ||
        currentUserPolicies?.view_created_incident ||
        currentUserPolicies?.view_shared_incident
    );
    fetchData(orgDetails?.orgId);
  }, [orgDetails?.orgId]);

  const generateTableData = (incidentNotes) => {
    const tableRowData = getRowData(incidentNotes);
    setIncidentNotes([...incidentNotes]);
    setRowData([...tableRowData]);
  };

  const uploadFile = async (remoteFileOrigin) => {
    let remoteFilePath = '';

    if (
      !remoteFileOrigin ||
      !remoteFileOrigin?.filePath ||
      !remoteFileOrigin?.preSignedURL ||
      !attachedFile
    ) {
      return remoteFilePath;
    }

    try {
      remoteFilePath = remoteFileOrigin?.filePath;

      const requestOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': attachedFileContentType },
        body: attachedFile,
      };

      const res = await fetch(remoteFileOrigin?.preSignedURL, requestOptions);

      if (res?.ok) {
        console?.info('File uploaded successfully');
      } else {
        throw new Error(`${res?.status}: ${res?.statusText}`);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      return remoteFilePath;
    }
  };

  const submitNewNote = async (remoteFilePath) => {
    let isNewNoteCreated = false;

    try {
      // File name, file path and comment/description are all
      // required fields.
      const reqBody = {
        fileName: attachedFileName,
        filePath: remoteFilePath,
        description: comment,
      };

      const res = await axios.post(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/notes`,
        reqBody,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData.meta?.code === 200) {
        isNewNoteCreated = true;
      } else {
        setUserMsg(res?.meta?.userMsg);
        setShowToast(true);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      return isNewNoteCreated;
    }
  };

  const handleCommentChange = (event) => {
    event?.preventDefault();
    const newComment = event?.target?.value;
    setComment(newComment);
  };

  const handleFileSelect = (event) => {
    event?.preventDefault();
    const file = event?.target?.files[0];

    if (file) {
      const { name, type, size } = file;

      // Show an error if specified file is over 10 MB in size
      if (size > 10000000) {
        setUserMsg(
          constants.INCIDENTS_EVIDENCE_INCIDENT_ATTACHMENT_MAX_FILESIZE_ERROR
        );
        setShowToast(true);
      } else {
        setAttachedFileName(name);
        setAttachedFile(file);
        setAttachedFileContentType(
          type.includes('zip') ? 'application/zip' : type
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      let remoteFilePath = '',
        fetchRemoteFileOriginResponse;

      setShowFileUploadLoader(true);

      // If a file was attached, get its file origin and upload
      // to S3
      if (attachedFileName) {
        // (1) Get the presigned URL and file path from S3
        fetchRemoteFileOriginResponse = await fetchRemoteFileOrigin();

        // (2) Upload the attached file to the remote URL received
        remoteFilePath = await uploadFile(fetchRemoteFileOriginResponse);
      }

      // Create the new Note
      const submitNoteResponse = await submitNewNote(remoteFilePath);

      if (submitNoteResponse) {
        await fetchData(orgDetails?.orgId);
        clearComment();
        if (remoteFilePath) {
          removeAttachedFile();
        }
        clearNoteStates();
      } else {
        throw 'ERROR: An error was encountered while creating the new note.';
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      setShowFileUploadLoader(false);
    }
  };

  const fetchData = useCallback(async (orgidArg) => {
    if (orgidArg) {
      try {
        setShowIncidentsLoader(true);
        const notesData = await fetchIncidentNotes(orgidArg);

        if (Array.isArray(notesData) && notesData.length > 0) {
          const notesWithAvatars = notesData?.map((note) => {
            // const getAccountAvatarURLResponse =
            //   await Utils?.getAccountAvatarURL(orgidArg, note.accountId);
            const accountAvatarAndInitials = customerOrgUsers.find(
              (orgUser) => orgUser.accountId === note.accountId
            );

            return {
              ...note,
              initials: accountAvatarAndInitials?.initials,
              avatarURL: accountAvatarAndInitials?.image?.url,
            };
          });

          // const notesWithAvatars = await Promise.all(notesPromises);

          if (Array.isArray(notesWithAvatars)) {
            if (notesWithAvatars?.length > 0) {
              // Sort the notes by their created date first
              notesWithAvatars.sort((a, b) => {
                if (a.createdDate > b.createdDate) {
                  return -1;
                }

                if (a.createdDate < b.createdDate) {
                  return 1;
                }

                return 0;
              });
            }

            generateTableData(notesWithAvatars);
            setHasEvidence(true);
          }
        }
      } catch (error) {
        Utils.vmsLogger().error(error);
        setUserMsg(error);
        setShowToast(true);
      } finally {
        setShowIncidentsLoader(false);
      }
    }
  }, []);

  const fetchIncidentNotes = useCallback(async (orgidArg) => {
    let notes = [];
    if (orgidArg) {
      try {
        const res = await axios.get(
          `/incident/orgs/${orgidArg}/incidents/${selectedIncident?.incidentId}/notes`,
          {
            ...Utils.requestHeader(),
            credentials: 'include',
            withCredentials: true,
          }
        );

        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          if (Array.isArray(responseData?.data?.notes)) {
            notes = [...responseData?.data?.notes];
            notes.every((note) => {
              if (note.incidentNoteType?.toLowerCase() === 'VIDEO') {
                setIncludesIncidentVideo && setIncludesIncidentVideo(true);
                return false;
              }
              return true;
            });
          } else {
            setHasEvidence(false);
          }
        } else {
          setUserMsg(res?.meta?.userMsg);
          setShowToast(true);
          setHasEvidence(false);
        }
      } catch (error) {
        Utils.vmsLogger().error(error);
        setUserMsg(error);
        setShowToast(true);
      } finally {
        return notes;
      }
    }
  }, []);

  const fetchRemoteFileOrigin = async () => {
    let remoteFileOrigin = {
      filePath: '',
      preSignedURL: '',
    };

    try {
      if (!attachedFile) {
        throw 'ERROR: there is no file selected for upload.';
      }

      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${selectedIncident?.incidentId}/notes/presignedURL`,
        {
          params: {
            fileName: attachedFile?.name,
          },
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setFilePath(responseData?.data?.filePath);
        setPresignedURL(responseData?.data?.presignedURL);
        remoteFileOrigin.filePath = responseData?.data?.filePath;
        remoteFileOrigin.preSignedURL = responseData?.data?.preSignedURL;
      } else {
        setUserMsg(res?.meta?.userMsg);
        setShowToast(true);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      return remoteFileOrigin;
    }
  };

  const clearComment = () => {
    commentRef.current.value = null;
  };

  const removeAttachedFile = () => {
    fileHolderRef.current.value = null;
    setAttachedFileName('');
    setAttachedFile('');
    setAttachedFileContentType('');
    setAttachedFileContents(null);
  };

  const clearNoteStates = async () => {
    commentRef.current.value = '';
    setHasComment(false);
    setFilePath(null);
    setAttachedFile('');
    setAttachedFileContents(null);
    setAttachedFileName('');
    setComment('');
  };

  const applyEvidenceTypeFilters = () => {
    let notes = [...incidentNotes];
    let filteredNotes;

    if (!Array.isArray(notes) || notes?.length < 1) {
      return;
    }

    filteredNotes = notes.filter((note) => {
      let filteredType = evidenceFilters.find(
        (evidenceType) =>
          evidenceType?.key?.toUpperCase() === note.incidentNoteType
      );

      if (filteredType && filteredType.show === true) {
        return note;
      }
    });

    setRowData([...getRowData(filteredNotes)]);
  };

  return (
    <>
      {viewNoteDetails ? (
        <NoteDetails
          incidentId={selectedIncident?.incidentId}
          incidentNoteId={selectedIncidentNoteId}
          setViewNoteDetails={setViewNoteDetails}
          showBackToEvidence={showBackToEvidence}
          timezone={selectedIncident?.timezone}
          fetchTimezoneLocation={fetchTimezoneLocation}
        />
      ) : (
        <>
          {/* Comments-Uploader Panel */}
          <Row className="mt-4 mb-3 evidence-comments-uploader">
            <Col>
              <Box className="evidence-comments-uploader-wrapper">
                <Paper className="evidence-comments-input" elevation={0}>
                  <TextField
                    id="outlined-multiline-static"
                    className="evidence-comment-field"
                    forw
                    inputRef={commentRef}
                    multiline
                    rows={3}
                    name="comment"
                    onChange={(e) => {
                      const commentValue = commentRef.current.value;
                      setHasComment(commentValue ? true : false);
                      handleCommentChange(e);
                    }}
                    disabled={statusClosed}
                    fullWidth
                    required
                  />
                  {!hasComment && (
                    <div className="custom-placeholder">
                      {Utils.replaceStringValues(
                        constants.INCIDENTS_EVIDENCE_INCIDENT_COMMENT_PLACEHOLDER,
                        '$maxFileSizInMB',
                        AppDefaults.INCIDENT_EVIDENCE_FILE_MAX_SIZE
                      )}
                      <span className="required">*</span>
                    </div>
                  )}
                </Paper>
                <Paper className="evidence-upload-input" elevation={0}>
                  <div className="evidence-upload-file-listing">
                    <span className="evidence-upload-file-label">
                      {constants.INCIDENTS_EVIDENCE_INCIDENT_ATTACHMENT_TEXT}
                    </span>
                    {attachedFile && (
                      <>
                        <span className="evidence-upload-file-name">
                          {attachedFile?.name}
                        </span>
                        <IoClose
                          className={`close-btn${
                            !attachedFile ? ' hidden' : ''
                          }`}
                          size={16}
                          onClick={(e) => {
                            e?.preventDefault();
                            removeAttachedFile();
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="evidence-upload-action-buttons">
                    <Button
                      className="evidence-upload-attach-btn"
                      component="label"
                      variant="outlined"
                      //   startIcon={<CloudUploadIcon />}
                      disabled={statusClosed || attachedFile?.name}
                      href="#file-upload"
                    >
                      {
                        constants.INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_ATTACH_FILE_BUTTON_LABEL
                      }
                      <VisuallyHiddenInput
                        ref={fileHolderRef}
                        accept={
                          AppDefaults.INCIDENT_EVIDENCE_SUPPORTED_FILE_EXTENSIONS
                        }
                        type="file"
                        name="fileHolder"
                        onChange={handleFileSelect}
                      />
                    </Button>
                    <PrimaryButton
                      className="evidence-upload-upload-btn"
                      loader={showFileUploadLoader}
                      type="submit"
                      disabled={statusClosed || !comment}
                      width={'140px'}
                      height={'32px'}
                      fontSize={'12px'}
                      onClick={(e) => {
                        e?.preventDefault();
                        handleSubmit();
                      }}
                    >
                      {attachedFile
                        ? constants.INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_UPLOAD_BUTTON_LABEL
                        : constants.INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_SUBMIT_BUTTON_LABEL}
                    </PrimaryButton>
                  </div>
                </Paper>
              </Box>
            </Col>
          </Row>
          {/* Evidence & Notes */}
          <Row>
            <Col className="evidence-section">
              <div className="mt-2 mb-2 evidence-section-title">
                {constants.INCIDENTS_EVIDENCE_INCIDENT_EVIDENCE_AND_NOTES_TITLE}
              </div>
              <div
                className={`${
                  hasEvidence ? 'mb-1 ' : 'mb-4 '
                } evidence-section-subtitle`}
              >
                {constants.INCIDENTS_EVIDENCE_INCIDENT_EXPORT_SERVICE_NOTICE}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {showIncidentsLoader ? (
                <Box className="mb-4 evidence-section-content-wrapper centered">
                  <Paper className="evidence-no-content" elevation={0}>
                    <SiteSpinner height="50px" width="50px" />
                  </Paper>
                </Box>
              ) : hasEvidence ? (
                <div className="incidents-evidence-notes-table-container">
                  <ListTable
                    colsDef={columnDefs}
                    colSorting={true}
                    rowData={rowData}
                    cssClasses="notes-table"
                    rowClassName={!hasViewEditAccess ? 'row-item-disabled' : ''}
                    rowClickDisabled={!hasViewEditAccess}
                    rowClickHandler={(id) => {
                      if (hasViewEditAccess) {
                        setSelectedIncidentNoteId(id);
                        setShowBackToEvidence(true);
                        setViewNoteDetails(true);
                      }
                    }}
                    specialDataField="INCIDENTNOTETYPE"
                    specialColumnType="INCIDENTNOTETYPE"
                    sortableField="sortableField"
                  />
                </div>
              ) : (
                <Box className="mb-4 evidence-section-content-wrapper">
                  <EvidenceTabNoContent />
                </Box>
              )}
            </Col>
          </Row>
          {/* Modals */}
          {/* Evidence Type Filter Modal */}
          <SiteModal
            key={'evidence-type-filter-modal'}
            classes="evidence-type-filter-modal"
            wrapperClass="evidence-type-filter-modal-wrapper"
            modalTitle={constants.INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_TITLE}
            showModal={showFilterModal}
            hideModal={() => setShowFilterModal(false)}
            modalFooter={
              <>
                <div className="mb-1 evidence-type-filter-button-wrapper">
                  <PrimaryButton
                    className="evidence-type-filter-save-btn"
                    // loader={showLoader}
                    type="submit"
                    onClick={(e) => {
                      e?.preventDefault();
                      applyEvidenceTypeFilters();
                      setShowFilterModal(false);
                    }}
                  >
                    {
                      constants.INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_APPLY_BUTTON_LABEL
                    }
                  </PrimaryButton>
                </div>
                <div className="evidence-type-filter-button-wrapper">
                  <CancelButton
                    className="evidence-type-filter-cancel-btn"
                    variant="outline-secondary"
                    onClick={(e) => {
                      e?.preventDefault();
                      setShowFilterModal(false);
                    }}
                  >
                    {
                      constants.INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_CANCEL_BUTTON_LABEL
                    }
                  </CancelButton>
                </div>
              </>
            }
          >
            {Array.isArray(evidenceFilters) &&
              evidenceFilters.map((evidenceFilter) => (
                <TextBlock
                  key={`textblock-${evidenceFilter?.key}`}
                  className="filters-content-item"
                >
                  <div className="filters-content-item-details">
                    {evidenceFilter?.icon}
                    {evidenceFilter.displayText}
                  </div>
                  <Checkbox
                    key={`checkbox-${evidenceFilter?.key}`}
                    className="filters-content-item-checkbox"
                    name={evidenceFilter.key}
                    checked={evidenceFilter.show}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 26 },
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_primary'),
                      },
                    }}
                    onChange={(e) => {
                      if (!e) return;

                      const filters = [...evidenceFilters];
                      const { name, checked } = e.target;
                      const statusIndex = filters.findIndex(
                        (s) => s.key === name
                      );

                      filters[statusIndex].show = checked;
                      setEvidenceFilters([...filters]);
                    }}
                  />
                </TextBlock>
              ))}
          </SiteModal>
        </>
      )}
    </>
  );
};

export default EvidenceTab;
