import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HiMiniChevronDown } from 'react-icons/hi2';

import './AccordionList.scss';

const AccordionList = ({
  containerClass,
  listItems,
  chevronColor,
  selectedListItemId,
  selectedSublistId,
  onChangeCallback,
  disabled,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [sublistItemId, setSublistItemId] = useState(selectedSublistId);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={containerClass}>
      {Array.isArray(listItems) && listItems.length > 0
        ? listItems.map((listItem, listItemIndex) => (
            <Accordion
              key={`accordion-${listItem?.listItemId}-${listItemIndex}`}
              className="base-accordion"
              defaultExpanded={
                listItem?.listItemId + '' === selectedListItemId + ''
              }
              disabled={disabled}
              onChange={(e) => handleChange(e, `listItem${listItemIndex}`)}
            >
              <AccordionSummary
                className="base-summary"
                expandIcon={
                  <HiMiniChevronDown size={'1.5em'} color={chevronColor} />
                }
                aria-controls={`listItem${listItemIndex}-content`}
                id={`listItem${listItemIndex}-header`}
              >
                <Typography
                  className="base-title"
                  sx={{ width: '33%', flexShrink: 0 }}
                >
                  {listItem.listItemName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="base-details">
                <ul className="base-details-list">
                  {Array.isArray(listItem.sublist) &&
                    listItem.sublist.map((sublistItem, sublistItemIndex) => (
                      <li
                        key={`list-item-${sublistItem.sublistItemId}-${sublistItemIndex}`}
                      >
                        <div className="base-details-list-wrapper">
                          <div className="base-details-list-item">
                            <div className="base-details-list-item-name">
                              {sublistItem.sublistItemName}
                            </div>
                            <div>
                              <RadioGroup
                                aria-labelledby="base-details-radio-buttons"
                                name="controlled-radio-buttons-group"
                              >
                                <Radio
                                  sx={{
                                    color: getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue('--greyscale_80'),
                                  }}
                                  checked={
                                    sublistItem?.sublistItemId ===
                                    selectedSublistId
                                  }
                                  onChange={(e) => {
                                    setSublistItemId(
                                      sublistItem?.sublistItemId
                                    );
                                    onChangeCallback &&
                                      onChangeCallback(
                                        listItem?.listItemId,
                                        sublistItem?.sublistItemId
                                      );
                                  }}
                                  value={sublistItem?.sublistItemId}
                                  name="radio-buttons"
                                  inputProps={{ 'aria-label': 'A' }}
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))
        : 'Nothing to list'}
    </div>
  );
};

export default AccordionList;
