import moment from 'moment';
import { HiOutlineCalendar } from 'react-icons/hi';
import './CustomizeLicenseTable.scss';
import DatePicker from 'react-multi-date-picker';
import { useEffect, useState } from 'react';
import { Utils, constants } from '../../helpers';

const DATE_FORMAT = 'll';
const CustomizedTable = ({ data, onSelect }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (value, index) => {
    const updatedData = [...data];
    updatedData[index]['activeDate'] = value.toDate().getTime();

    const selectedItemIndex = selectedItems.findIndex(
      (item) => item.licenseKey === updatedData[index].licenseKey
    );
    if (selectedItemIndex !== -1) {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems[selectedItemIndex].activeDate = updatedData[index].activeDate;
      setSelectedItems(updatedSelectedItems);
    }
    onSelect(updatedData);
  };

  const handleCheckbox= (index) => {
    const updatedData = [...data];
    updatedData[index].isSelected = !updatedData[index].isSelected;
    updatedData[index].activeDate = updatedData[index].activeDate ? updatedData[index].activeDate : new Date().getTime();
    onSelect(updatedData);
  };

  useEffect(() => {
    onSelect(selectedItems);
  }, [selectedItems, data]);


  return (
    <div>
      <table className="cus-table">
        <thead>
          <tr>
            <th className="text-center">{constants.SERVICE_PRODUCT_NAME}</th>
            <th>{constants.LICENSE_KEY}</th>
            <th>{constants.ACTIVATEION_DATE}</th>
            <th>{constants.END_DATE}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr className="tr-bg">
                <td>
                  {item.isSelected}
                  <div className="col-1">
                    <input
                      type="checkbox"
                      className="custom-form-check-input"
                      onChange={() => handleCheckbox(index)}
                      checked={item.isSelected ? true : false}
                    />
                    <span className="m-l-24">{item.productDisplayName}</span>
                  </div>
                </td>
                <td className="text-left p-l-0 f-left">{item.licenseKey}</td>
                <td className="text-left p-l-0">
                  <div
                    className="date-field"
                  >
                    <HiOutlineCalendar className="calendar-icon" />
                    {item?.activeDate ? moment(item?.activeDate).format(DATE_FORMAT): moment(new Date()).format(DATE_FORMAT)}
                    <div className="picker-act-lic">
                      <DatePicker
                        key={item.licenseKey}
                        className="new-incident-date customized-datepicker"
                        containerClassName="new-incident-date-container"
                        format={Utils.getDateFormatStringByLocale(
                          navigator.language
                        )}
                        minDate={new Date()}
                        onChange={(value) => handleChange(value, index)}
                        calendar={true}
                        value={item.activeDate ? new Date(item.activeDate) : new Date()}
                        highlightToday={false}
                      />
                    </div>
                  </div>
                </td>
                <td className="text-left p-l-0">
                {item?.expiryDate ? <div className="date-field">
                    <HiOutlineCalendar className="calendar-icon" />
                    {moment(item?.expiryDate).format(DATE_FORMAT)}
                  </div>: 'NA'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomizedTable;
