import { ErrorMessage, useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { constants } from '../../helpers';

const SelectField = ({
  label,
  options,
  classes,
  defaultOptionValue,
  defaultOptionLabel,
  isFromOrg = false,
  placeholderClass,
  placeholderOptionClass,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);

  /*=== Generate selection options*/
  const generateOptions = () => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((item, i) => {
        return (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        );
      });
    }
    return null;
  };

  return (
    <div className={`${!isFromOrg && 'select-field-wrap row'} ${classes} ${(props.beforeinputicon || props.afterinputicon) ? 'input-with-icons' : 'position-relative'}`}>
      {(props.beforeinputicon)
        &&
          <div className="icon-before-timezone">
            {props.beforeinputicon}
          </div>
      }
      {label && (
        <label
          className="col-sm-3 form-label col-form-label"
          htmlFor={field?.name}
        >
          {label}
        </label>
      )}
      <div className={label ? 'col-sm-7' : 'col-sm-12'}>
        <Form.Select
          className={`form-control shadow-none ${placeholderClass} ${
            meta?.touched && meta?.error && 'is-invalid'
          }`}
          {...field}
          {...props}
          disabled={disabled}
        >
          <option value="" className={`${placeholderOptionClass}`}>
            {defaultOptionLabel
              ? defaultOptionLabel
              : constants.SELECT_FIELD_DEFAULT_VALUE_LABEL}
          </option>
          {generateOptions()}
        </Form.Select>
        <ErrorMessage component="div" name={field?.name} className="error" />
      </div>
    </div>
  );
};

export default SelectField;
