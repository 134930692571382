import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { BreadcrumbList, Header } from '../../components/common';

import './notificationsetting.scss';
import { constants } from '../../helpers';
import MyNotification from './MyNotification';
import { BsPerson } from 'react-icons/bs';
import { HiOutlineBellAlert } from 'react-icons/hi2';
import SystemNotification from './SystemNotification';

const NotificationSetting = () => {
  const [isMyNotification, setIsMyNotification] = useState(true);

  const breadList = [
    { url: '/notificationdashboard.html', title: constants.NOTIFICATION_TITLE },
    {
      url: '/notificationsetting.html',
      title: constants.NOTIFICATION_SETTINGS,
    },
  ];

  return (
    <div className="App">
      <Header />

      <Container className='mw-100'>
        <Row className="justify-content-md-center mt-5">
          <Col lg="12">
            <div className="page-header">
              <Container className='mw-100'>
                <Row>
                  <Col className="text-start">
                    <BreadcrumbList list={breadList} />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <div className="notification-setting">
          <div className="page-title">{constants.NOTIFICATION_SETTINGS}</div>
        </div>
        <div className="page-body">
          <div className="left-container">
            <div
              onClick={() => setIsMyNotification(true)}
              className={`my-notification-item ${
                isMyNotification ? 'selected' : ''
              }`}
            >
              <BsPerson
                size={22}
                color={
                  isMyNotification
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_08')
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_40')
                }
              />
              {constants.NOTIFICATION_MY_NOTIFICATION}
            </div>
            <div
              onClick={() => setIsMyNotification(false)}
              className={`system-notification ${
                !isMyNotification ? 'selected' : ''
              } `}
            >
              <HiOutlineBellAlert
                size={22}
                color={
                  !isMyNotification
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_08')
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_40')
                }
              />
              {constants.NOTIFICATION_SYSTEM_NOTIFICATION}
            </div>
          </div>
          <div className="right-container">
            {isMyNotification ? <MyNotification /> : <SystemNotification />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotificationSetting;
