import { generatePlaybackPayload, getUnixDate } from '../../../../../helpers/commonUtils';
import * as webRTCHandler from '../webRTCHandler/webRTCwsHandler';
import { v4 as uuidv4 } from 'uuid';
import {
  getPlaybackBackActiveTime,
  getWSSConnections,
  removeWSSConnections,
  setWSSConnections,
} from '../../../../../store/reducers/StreamingReducer';
import { getNoPlaybackContentAvailable, setNoPlaybackContentAvailable } from '../../../../../store/reducers/ViewsReducer';
import store from '../../../../../store/Store';
import { Utils, constants } from '../../../../../helpers';
import moment from 'moment';

const eosDevices = [];

const handleIncomingError = (error) => {
  if (error && error?.cid) {
    Utils.vmsLogger().log('Playback: Multi live error cid: ', error?.cid);
    Utils.vmsLogger().log('Playback: Multi live error dev_id: ', error?.msg?.properties?.dev_id);
    Utils.vmsLogger().log('Playback: Multi live error org_id: ', error?.msg?.properties?.org_id);
    Utils.vmsLogger().log('Playback: Multi live error code: ', error?.msg?.properties?.code);
    Utils.vmsLogger().log('Playback: Multi live error description: ', error?.msg?.properties?.desc);
  } else {
    Utils.vmsLogger().log("Playback: Multi live error", error);
  }
  const errorCode = error?.msg?.properties?.code;
  if (errorCode === 1204) {
    eosDevices.push(error?.msg?.properties?.dev_id);
    sendPauseCVR(error?.msg?.properties?.dev_id);
  }
};

const multiLiveDevices = [];

export const connectWithWebSocket = (cdnDetails, deviceId, orgId, accountId, isFromReconnecting) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  let wsClient;
  if (wssConnections?.[deviceId]) {
    wsClient = wssConnections[deviceId];
  } else {
    let serverDetails = cdnDetails;
    const playbackServer = serverDetails?.timeline_server;
    const jwt_token = playbackServer?.token;
    wsClient = new WebSocket(
      `${playbackServer?.protocol}://${playbackServer?.host}:${playbackServer?.port}`
    );
    // TODO: Delete later - Added below static URL for testing purpose
    // wsClient = new WebSocket(`wss://playback-json.duclo.net`);
    wsClient.deviceId = deviceId;
    wsClient.binaryType = 'arraybuffer';
    wsClient.onerror = function (error) {
      Utils.vmsLogger().log('Playback: Multi live connection error', error);
    };
    store.dispatch(setWSSConnections({ id: deviceId, client: wsClient }));
    wsClient.onopen = function () {
      if (wsClient.readyState === 1) {
        const playback_uuid = uuidv4();
        const correlation_id = uuidv4();
        const register_data = {
          playback_uuid,
          correlation_id,
          device_id: deviceId,
          accountId: accountId,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_REGISTER,
          org_id: orgId,
          jwt_token
        };
        multiLiveDevices.push(register_data);
        const register_payload = generatePlaybackPayload(register_data);
        wsClient.send(JSON.stringify(register_payload));
      }
    };

    wsClient.onclose = function (e) {
      sendPauseCVR(wsClient.deviceId);
      disconnectWithWebSocket(wsClient.deviceId);
      store.dispatch(removeWSSConnections(wsClient.deviceId));
      Utils.vmsLogger().log("Playback: Multi live web socket connection close.", e)
      if(wsClient && wsClient.deviceId && e.code !== 1005 && window.location.href.includes("viewId")) {
        Utils.vmsLogger().log("Playback multi live Websocket Reconnecting for device id", wsClient.deviceId);
        setTimeout(() => {
          connectWithWebSocket(cdnDetails, wsClient.deviceId, orgId, accountId, true);
        }, 1500);
      }
    };

    wsClient.onmessage = function (event) {
      const data = JSON.parse(event.data);

      switch (data?.msg?.properties?.type) {
        case 'REGISTERED':
          if (isFromReconnecting) {
            const time = store.getState(getPlaybackBackActiveTime)?.streaming?.playbackActiveTime;
            if (time && time !== '') sendPlayCVR(moment(time).add({ seconds: 2 }).toDate(), data.from);
          }
          Utils.vmsLogger().log("Playback: Web socket registered for cid", data?.cid);
          Utils.vmsLogger().log("Playback: Web socket registered for device id", data?.from);
          Utils.vmsLogger().log("Playback: Web socket registered for organization Id", orgId);
          return;

        case 'READY':
          webRTCHandler.getLocalStream(serverDetails.p2p_server, deviceId, cdnDetails, orgId, accountId);
          return;

        case 'answer':
            webRTCHandler.handleAnswer(data.msg.properties, deviceId);
            return;
    
        case 'ice' :
            webRTCHandler.handleCandidate(data.msg.properties, deviceId);
            return;
            
        default:
          if (data.msg.properties.type === 'ERROR') {
            handleIncomingError(data);
            if (data?.msg?.properties?.code === 1004) {
              const noData = [];
              noData.push(data?.msg?.properties?.dev_id);
              Utils.vmsLogger().log("Playback: Multi live not started for device id => ", data?.msg?.properties?.dev_id)
              const mergedArr = [...store.getState(getNoPlaybackContentAvailable)?.views?.noPlaybackContentAvailable, ...noData]
              store.dispatch(
                setNoPlaybackContentAvailable(mergedArr)
              );
            }
            return;
          }
          let msg;
          try {
            msg = JSON.parse(event.data);
          } catch (e) {
            if (e instanceof SyntaxError) {
              handleIncomingError('Error parsing incoming JSON: ' + event.data);
            } else {
              handleIncomingError(
                'Unknown error parsing response: ' + event.data
              );
            }
            return;
          }
      }
    };
  }
};

export const sendPlayCVR = (time, deviceId) => {
  const findEosIndex = eosDevices?.findIndex((device) => device === deviceId);
  if (findEosIndex !== -1) eosDevices?.splice(findEosIndex, 1);
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const actualTime = getUnixDate(time) * 1000;
  const data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    actualTime,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_PLAY,
    orgId: selectedDevice?.org_id
  };
  const play_payload = generatePlaybackPayload(data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if (wsClient && wsClient.readyState === 1) {
    Utils.vmsLogger().log("Playback: Multi live Play command send for device Id: ", data?.device_id);
    Utils.vmsLogger().log("Playback: Multi live Play command send for cid: ", data?.correlation_id);
    Utils.vmsLogger().log("Playback: Multi live Play command send for org Id: ", data?.orgId);
    Utils.vmsLogger().log("Playback: Multi live Play command send for timestamp: ", data?.actualTime);
    wsClient.send(JSON.stringify(play_payload));
  }
};

export const checkEOSDevices = () => {
  if(eosDevices?.length > 0) {
    return eosDevices;
  } else {
    return [];
  }
}

export const sendPauseCVR = (deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_STOP,
    orgId: selectedDevice?.org_id
  };
  const pause_payload = generatePlaybackPayload(data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  webRTCHandler.handleLeaveCall(deviceId);
  if (wsClient && wsClient.readyState === 1) {
    wsClient.send(JSON.stringify(pause_payload));
  }
};

export const sendWebRTCOffer = (data, deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const offer_data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_OFFER,
    orgId: selectedDevice?.org_id,
    sdp: data.sdp
  };
  const offer_payload = generatePlaybackPayload(offer_data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if(wsClient) {
    wsClient.send(JSON.stringify(offer_payload));
  }
};

export const sendWebRTCCandidate = (event, deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const candidate_data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_ICE,
    orgId: selectedDevice?.org_id,
    candidate: event.candidate
  };
  const candidate_payload = generatePlaybackPayload(candidate_data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if(wsClient) {
    wsClient.send(JSON.stringify(candidate_payload));
  }
};

export const disconnectWithWebSocket = (deviceId) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if (wsClient && wsClient.close) {
    wsClient.close();
  }
  store.dispatch(removeWSSConnections(deviceId));
  webRTCHandler.handleLeaveCall(deviceId);
};

export const checkWSSConnection = (deviceId) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if (wsClient) return true;
};
