import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';

export const devicesMQTTStore = createStore(
  persist(
    (set, get) => ({
      accountId: null,
      sessionId: null,
      isPublisherSubscribed: false,
      isReceiverSubscribed: false,
      latestSnapshots: {},
      latestEvents: {},

      setOrder: (newOrder) => {
        if (!newOrder) return;

        set({
          order: newOrder,
        });
      },

      setIsPublisherSubscribed: (status) =>
        set({ isPublisherSubscribed: status }),

      getIsPublisherSubscribed: () => get().isPublisherSubscribed,

      setIsReceiverSubscribed: (status) =>
        set({ isReceiverSubscribed: status }),

      getIsReceiverSubscribed: () => get().isReceiverSubscribed,

      setLatestSnapshots: (acctId, sid, snapshots) => {
        if (!snapshots) return;

        set({
          latestSnapshots: snapshots,
        });
      },

      getLatestSnapshots: (acctId, sid) => {
        return get().latestSnapshots;
      },

      setSnapshotByDeviceId: (acctId, sid, deviceId, snapshot) => {
        if (!acctId || !sid || !deviceId || !snapshot) return;

        set((state) => {
          const newSnapshot = {
            [deviceId]: snapshot,
          };

          state.validateConfig(acctId, sid);

          return {
            ...state,
            latestSnapshots: { ...state.latestSnapshots, ...newSnapshot },
          };
        });
      },

      getSnapshotByDeviceId: (acctId, sid, deviceId) => {
        if (!acctId || !sid || !deviceId) return;

        return deviceId && get().latestEvents[deviceId];
      },

      resetSnapshots: () =>
        set((state) => {
          return {
            ...state,
            latestSnapshots: {},
          };
        }),

      setEventByDeviceId: (acctId, sid, deviceId, event) => {
        if (!acctId || !sid || !deviceId) return;

        set((state) => {
          const newEvent = {
            [deviceId]: event,
          };

          state.validateConfig(acctId, sid);

          return {
            ...state,
            latestEvents: { ...state.latestEvents, ...newEvent },
          };
        });
      },

      getEventByDeviceId: (acctId, sid, deviceId) => {
        if (!acctId || !sid || !deviceId) return;

        return deviceId && get().latestSnapshots[deviceId];
      },

      deleteEventByDeviceId: (acctId, sid, deviceId) => {
        if (!acctId || !sid || !deviceId) return;

        let currentLatestEvents = get().latestEvents;

        delete currentLatestEvents[deviceId];

        set((state) => {
          state.validateConfig(acctId, sid);

          return {
            ...state,
            latestEvents: { ...currentLatestEvents },
          };
        });
      },

      resetEvents: (acctId, sid) => {
        if (!acctId || !sid) {
          return;
        }

        set((state) => {
          state.validateConfig(acctId, sid);

          return {
            ...state,
            latestEvents: {},
          };
        });
      },

      setAccountId: (acctId) => {
        if (!acctId) return;

        set((state) => {
          return {
            ...state,
            accountId: acctId,
          };
        });
      },

      getAccountId: () => {
        return get().accountId;
      },

      deleteAccountId: () => {
        set({ accountId: null });
      },

      setSessionId: (sid) => {
        if (!sid) return;
        set((state) => {
          return {
            ...state,
            sessionId: sid,
          };
        });
      },

      getSessionId: () => {
        return get().sessionId;
      },

      deleteSessionId: () => {
        set({ sessionId: null });
      },

      validateConfig: (acctId, sid) => {
        if (get().sessionId !== sid) {
          this.resetSnapshots();
          this.setIsPublisherSubscribed(false);
          this.setIsReceiverSubscribed(false);
          this.setSessionId(sid);
        }

        if (get().accountId !== acctId) {
          this.setAccountId(acctId);
        }
      },
    }),
    {
      name: 'net.duclo.vms.mqtt.devices',
      version: '1.0',
    }
  )
);
