import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsRemoteStreamPlay } from '../../../store/reducers/StreamingReducer';
import { Utils } from '../../../helpers';

const ImageGridLoading = ({
  imageURL = null,
  cdnValue,
  deviceId,
  selectedEventTime,
}) => {
  const dispatch = useDispatch();
  const imageRef = useRef();
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(selectedEventTime / 1000));

    if (
      deviceId &&
      newTime &&
      !imageURL &&
      cdnValue?.protocol &&
      cdnValue?.host
    ) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      const date = Utils.fetchDateInUnix(newTime);

      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
        {
          credentials: 'include',
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setSelectedSnapSrc(URL.createObjectURL(blob));
        })
        .catch(() => {
          Utils.vmsLogger().log('Error got', deviceId);
        });
    }
  }, [selectedEventTime, deviceId]);

  return (
    <>
      {selectedSnapSrc ? (
        <>
          <div className="playback-grid-image">
            <img
              ref={imageRef}
              id="img-snapshot"
              className="playback-snapshot-img"
              src={selectedSnapSrc}
              alt=""
              onLoad={(event) => {
                // event.target.style.display = 'inline-block';
                dispatch(setIsRemoteStreamPlay(true));
              }}
              onError={(event) => {
                event.target.style.display = 'none';
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default ImageGridLoading;
