import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useNotificationStore = create(
  persist(
    (set, get) => ({
      stepsData: {},
      isEditFlow: false,

      getStepsData: () => {
        return get().stepsData;
      },

      setStepsData: (stepsData) => {
        if (!stepsData) return;

        set({
          stepsData: stepsData,
        });
      },

      getEditFlowStatus: () => {
        return get().isEditFlow;
      },

      setEditFlowStatus: (isEditFlow) => {
        if (!isEditFlow) return;

        set({
          isEditFlow: isEditFlow,
        });
      },

      resetStepData: () =>
        set((state) => {
          return {
            ...state,
            stepsData: {},
            isEditFlow: false,
          };
        }),
    }),
    {
      name: 'net.duclo.vms.tags',
      version: '1.0',
    }
  )
);
