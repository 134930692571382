import { Container, Navbar } from 'react-bootstrap';
import { AppDefaults } from '../../helpers';
import { ReactComponent as BrandLogoSvg } from '../../assets/images/BrandLogo.svg';

import { ReactComponent as MegatronLogoSvg } from '../../assets/images/MegatronLogo.svg';

function Header({
  backgroundColor,
  numOfICartItems,
  showCart = true,
  showNavigation = true,
  isAccountScreen = false,
  cartFetch = true,
  breadcrumbData = [],
  currentDeviceId = false,
  showHeader = true,
  onPushReceive,
  isFromVideoWall = false,
  isFromVerifyAccount = false,
  interRef = false,
}) {
  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      className={`site-top-bar`}
      style={{ backgroundColor: backgroundColor }}
    >
      <Container className="site-top-bar-container" fluid>
        <Navbar.Brand
          href={`${
            process.env.REACT_APP_PROJECT !== AppDefaults.PROJECT_MEGATRON
              ? '/customers/manage.html'
              : ''
          }`}
        >
          {process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? (
            <MegatronLogoSvg />
          ) : (
            <BrandLogoSvg />
          )}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

Header.defaultProps = {
  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
    '--brand_white'
  ),
};

export default Header;
