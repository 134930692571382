import React, { createContext, useState } from 'react';
import { DeviceTypeEnum } from '../../../helpers/enums';

export const DetailsContext = createContext(null);

const DetailsContextProvider = ({ children }) => {
  const [info, setInfo] = useState({
    serialnumber: '',
    macaddress: '',
    selectmanufacturer: '',
    selectmodel: '',
    channelCount: 0,
    step2info: {},
    deviceType: DeviceTypeEnum.IPCam,
  });

  const [stepdata, setStepdata] = useState(true);

  return (
    <DetailsContext.Provider value={{ info, setInfo, stepdata, setStepdata }}>
      {children}
    </DetailsContext.Provider>
  );
};

export default DetailsContextProvider;
