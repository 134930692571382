// import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { constants } from '../../../helpers';

import './AddTags.scss';

const AddTags = ({
  availableTags = [],
  incidentTagsChangeHandler,
  setIsTagFormModified,
}) => {
  const assignTag = (tagId) => {
    let currentTagList = Array.isArray(availableTags) ? availableTags : [];

    if (!tagId || !Array.isArray(currentTagList) || currentTagList.length < 1) {
      return;
    }

    const tagIndex = currentTagList.findIndex((tag) => tag.tagId === tagId);

    if (tagIndex !== -1) {
      currentTagList[tagIndex].assigned = !!!currentTagList[tagIndex].assigned;

      setIsTagFormModified && setIsTagFormModified(true);
      incidentTagsChangeHandler &&
        incidentTagsChangeHandler([...currentTagList]);
    }
  };

  return (
    <div className="add-tags-form">
      <div className="mb-3 add-tags-modal-description">
        {constants.INCIDENTS_ADD_TAGS_MODAL_DESCRIPTION}
      </div>
      <Container>
        <Row>
          <Col>
            <div className="mb-3 manage-tags-pill-wrapper">
              {Array.isArray(availableTags) &&
                availableTags.map((tag) => (
                  <div
                    key={tag?.tagId}
                    className={`manage-tags-pill${
                      tag?.assigned === true ? ' assigned' : ' unassigned'
                    }`}
                    onClick={() => {
                      assignTag(tag?.tagId);
                    }}
                  >
                    {tag?.name}
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddTags;
