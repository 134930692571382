import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Avatar from '../../components/common/Avatar';

const AvatarList = (props) => {
  const {
    avatarColor = getComputedStyle(document.documentElement).getPropertyValue(
      '--primary_40'
    ),
    avatarBackgroundColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--primary_96'),
  } = props;
  return (
    <div className="avatars-list">
      <Container>
        <Row>
          <div className="d-flex flex-row">
            <Avatar
              valueType={props.avatarValueType}
              value={props.avatarValue}
              size={props.avatarSize}
              avatarStyle={props.avatarStyle}
              backgroundColor={avatarBackgroundColor}
              color={avatarColor}
            />
            <div className="avatars-list-title" key={props.key}>
              {props.title}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

AvatarList.defaultProps = {
  avatarValueType: 'text',
  avatarValue: 'AS',
  avatarSize: 'medium',
  avatarStyle: 'roundedCircle',
};

export default AvatarList;
