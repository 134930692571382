import { create } from 'zustand';
import axios from 'axios';
import { Utils } from '../helpers';

const useIncidentsStore = create((set, get) => ({
  incidents: [],
  getIncidents: () => {
    return get().incidents;
  },
  setIncidents: async (route) => {
    if (!route) return;

    const res = await axios.get(
      route,
      Utils.requestHeader()
    );
    const responseData = res?.data;
    let result = { status: 'failure', msg: 'Unknown internal API error' };

    // Set incidents if request is successful
    if (responseData?.meta?.code === 200) {
      set({
        incidents: [...responseData?.data?.incidents],
      });
      result = { status: 'success', msg: 'Incidents retrieved`' };
    } else {
      if (res?.code) {
        result = { status: 'failure', msg: `${res.code}: ${res.message}` };
      } else if (responseData?.data) {
        result = { status: 'failure', msg: responseData?.data?.userMsg };
      }
    }
    return result;
  },
  removeIncidents: () => set({ incidents: [] }),
}));

export default useIncidentsStore;
