import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import 'moment-timezone';
import { getLiveStreams, getStreamBitmapData } from '../../../../store/reducers/StreamingReducer';
import '../../../../assets/css/timeline.scss';
import ImageFetch from '../../../cameras/components/ImageFetch';
import { constants } from '../../../../helpers';
import { ArrowRight } from 'react-bootstrap-icons';
import Store from '../../../../store/Store';

const PlaybackControlsView = ({
  deviceId,
  layout,
  muteAudio,
  time,
  cdnValue,
  deviceName,
  onLiveClick,
}) => {
  const remoteStream = useSelector(getLiveStreams)?.[deviceId];
  const remoteVideoRef = useRef();

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const bitmapObject = Store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;

  useEffect(() => {
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      remoteVideo.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <span onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <span className={hovered ? 'hovered device-name' : 'device-name'}>
        {deviceName}
      </span>
      <span
        onClick={() => onLiveClick()}
        className={hovered ? 'hovered detail-camera' : 'detail-camera'}
      >
        {constants.CAMERA_DETAIL}
        <ArrowRight width={18} />
      </span>
      {remoteStream?.active ? (
        <div className={`${hovered} remote-view-wrapper`}>
          <video
            id={`video${layout}`}
            // width="764"
            // height="450"
            ref={remoteVideoRef}
            autoPlay={true}
            playsInline={true}
            muted={true}
          />
          <canvas 
            id={`canvas${layout}`} 
            width={bitmapObjectWidth}
            height={bitmapObjectHeight} 
          />
        </div>
      ) : (
        <ImageFetch time={time} cdnValue={cdnValue} deviceId={deviceId} />
      )}
    </span>
  );
};

export default PlaybackControlsView;
