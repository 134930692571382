import { Outlet } from 'react-router-dom';
import ManageLicenses from './ManageLicenses';
import ListTable from './ListTable';
import AddLicense from './addLicense/AddLicense';

const Licenses = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Licenses, ListTable, ManageLicenses, AddLicense };
