import { Paper } from '@mui/material';
import { constants } from '../../../helpers';

import './EvidenceTab.scss';

const EvidenceTabNoContent = () => {
  return (
    <Paper className="evidence-no-content" elevation={0}>
      <div className="evidence-no-content-title">
        {constants.INCIDENTS_EVIDENCE_INCIDENT_NO_CONTENT_TITLE}
      </div>
      <div className="evidence-no-content-subtitle">
        {constants.INCIDENTS_EVIDENCE_INCIDENT_NO_CONTENT_SUBTITLE}
      </div>
    </Paper>
  );
};

export default EvidenceTabNoContent;
