import * as amplitude from '@amplitude/analytics-browser';
//All amplitude track event is defined below
const AmplitudeEventEnums = {
    SIGN_IN: 'Sign In'
    
  };

  let AmplitudeEventPropertyEnums = {
   EMAIL_ID: 'email_id'
    
  };
  export {
   AmplitudeEventEnums,
   AmplitudeEventPropertyEnums,
   amplitude,
  };