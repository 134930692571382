import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const useOrganizations = create(
  persist(
    (set, get) => ({
      // partnerOrgData: [],
      customerOrgData: [],
      manageCustomerOrg: {},
      // loggedInUserRole: '',
      userPushData: {},
      // selfStatus: '',
      // selfAccount: {},
      selectedCategories: [],

      // getOrganizations: async (route) => {
      //   if (!route) return;

      //   const res = await axios.get(route, Utils.requestHeader());
      //   const response = res?.data;
      //   let result;

      //   // Set organizations if request is successful
      //   if (response?.meta?.code === 200) {
      //     let organizationsListData = response?.data?.organizations;
      //     let loggedInUserData = response?.data?.account;
      //     if (loggedInUserData) {
      //       set({
      //         selfAccount: loggedInUserData,
      //       });
      //       set({
      //         loggedInUserRole: loggedInUserData?.role,
      //       });
      //     }
      //     if (organizationsListData) {
      //       set({
      //         selfStatus: '1',
      //       });
      //       let primaryOrg = organizationsListData?.filter((e) => {
      //         return e.orgType === 'INSTALLER_ORG';
      //       });
      //       if (primaryOrg.length === 1) {
      //         let primaryOrgData = primaryOrg[0];
      //         set({
      //           partnerOrgData: primaryOrgData,
      //         });
      //       }

      //       let custOrgs = organizationsListData?.filter((e) => {
      //         return e.orgType !== 'INSTALLER_ORG';
      //       });
      //       if (custOrgs.length >= 1) {
      //         set({
      //           customerOrgData: custOrgs,
      //         });
      //       }
      //     }

      //     result = {
      //       status: 'success',
      //       msg: 'organizations listing data retrieved`',
      //     };
      //   } else {
      //     if (res?.code) {
      //       result = { status: 'failure', msg: `${res.code}: ${res.message}` };
      //     } else if (response?.data) {
      //       result = { status: 'failure', msg: response?.data?.userMsg };
      //     } else {
      //       result = { status: 'failure', msg: 'Unknown internal API error' };
      //     }
      //   }
      //   return result;
      // },

      getOrganizationContact: async (route) => {
        if (!route) return;

        const res = await axios.get(route, Utils.requestHeader());
        const response = res?.data;
        const orgContactList = [];
        if (response?.meta?.code === 200) {
          if (response?.data?.length > 0) {
            response.data.forEach((contact) => {
              const contactObj = {
                label: contact.firstName + ' ' + contact.lastName,
                value: contact.accountId,
              };
              orgContactList.push(contactObj);
            });

            orgContactList.sort((a, b) => a.label.localeCompare(b.label));
          }
          return orgContactList;
        } else {
          let result;
          if (res?.code) {
            result = { status: 'failure', msg: `${res.code}: ${res.message}` };
          } else if (response?.data) {
            result = { status: 'failure', msg: response?.data?.userMsg };
          } else {
            result = { status: 'failure', msg: 'Unknown internal API error' };
          }
          return result;
        }
      },

      // getCustomerOrgData: () => {
      //   return get().customerOrgData;
      // },
      // getSelfAccountData: () => {
      //   return get().selfAccount;
      // },
      // setSelfAccounData: (selfAccount) => {
      //   if (!selfAccount) return;

      //   set({
      //     selfAccount: selfAccount,
      //   });
      // },

      // setSelfAccounData: (selfAccount) => {
      //   if (!selfAccount) return;

      //   set({
      //     selfAccount: selfAccount,
      //   });
      // },

      // setPartnerOrgData: (partnerOrgs) => {
      //   if (!partnerOrgs) return;

      //   set({
      //     partnerOrgData: partnerOrgs,
      //   });
      // },

      // getPartnerOrgData: () => {
      //   return get().partnerOrgData;
      // },

      // resetPartnerOrgData: () =>
      //   set((state) => {
      //     return {
      //       ...state,
      //       partnerOrgData: [],
      //     };
      //   }),

      // setCustomerOrgData: (customerOrgs) => {
      //   if (!customerOrgs) return;

      //   set({
      //     customerOrgData: customerOrgs,
      //   });
      // },

      // resetCustomerOrgData: () =>
      //   set((state) => {
      //     return {
      //       ...state,
      //       customerOrgData: [],
      //     };
      //   }),

      setManageCustomerOrgData: (customerOrg) => {
        if (!customerOrg) return;

        set({
          manageCustomerOrg: customerOrg,
        });
      },

      resetManageCustomerOrgData: () =>
        set((state) => {
          return {
            ...state,
            manageCustomerOrg: [],
          };
        }),

      // setLoggedInUserRole: (userRole) => {
      //   if (!userRole) return;

      //   set({
      //     loggedInUserRole: userRole,
      //   });
      // },

      // resetLoggedInUserRole: () =>
      //   set((state) => {
      //     return {
      //       ...state,
      //       loggedInUserRole: '',
      //     };
      //   }),

      // getAllFilteredCustomerOrgIds: () => {
      //   try {
      //     let resultData = get().customerOrgData;

      //     if (resultData?.length >= 1) {
      //       const customerOrgsData = [];
      //       resultData.forEach((customer) => {
      //         customerOrgsData.push(customer);
      //       });
      //       return customerOrgsData;
      //     }
      //     return null;
      //   } catch (error) {
      //     return error;
      //   }
      // },

      setUserPushData: (userPushData) => {
        if (!userPushData) return;

        set({
          userPushData: userPushData,
        });
      },

      getUserPushData: () => {
        return get().userPushData;
      },

      // setSelfStatus: (selfStatus) => {
      //   if (!selfStatus) return;

      //   set({
      //     selfStatus: selfStatus,
      //   });
      // },

      // getSelfStatus: () => {
      //   return get().selfStatus;
      // },

      setSelectedCategories: (selectedCategories) => {
        set({
          selectedCategories: selectedCategories,
        });
      },

      getSelectedCategories: () => {
        return get().selectedCategories;
      },

      // resetSelfAccount: () =>
      //   set((state) => {
      //     return {
      //       ...state,
      //       selfAccount: {},
      //     };
      //   }),

      resetOrganizationsData: () =>
        set((state) => {
          return {
            ...state,
            userPushData: {},
            partnerOrgData: [],
            customerOrgData: [],
            manageCustomerOrg: {},
            loggedInUserRole: '',
          };
        }),
    }),
    {
      name: 'net.duclo.vms.organizations',
      version: '1.0',
    }
  )
);
