import { Utils } from './';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

/**
 *
 * @param {*} CVR Metadata
 * @returns timeline massaged data
 */
const getTimelineData = (data, time) => {
  let finalResult = [];
  data.forEach((range) => {
    const rangeData = {};
    rangeData.start = new Date(
      Utils.getDate(moment(range?.start).subtract(time).unix())
    );
    rangeData.end = new Date(
      Utils.getDate(moment(range?.end).subtract(time).unix())
    );
    rangeData.type = range.type || '';
    rangeData.id = uuidv4();
    // TODO: delete later
    Object.preventExtensions(range);
    finalResult.push(rangeData);
  });
  return finalResult;
};

/**
 *
 * @param {*} Events scrubber Metadata
 * @returns events massaged data
 */
const getEventsScubberData = (data, time) => {
  let finalResult = [];
  data.forEach((range) => {
    const rangeData = {};
    rangeData.start = new Date(
      moment(Utils.getDate(range?.start / 1000)).subtract(time)
    );
    rangeData.data = range?.data;
    rangeData.type = range.type || '';
    rangeData.id = uuidv4();
    // TODO: delete later
    Object.preventExtensions(range);
    finalResult.push(rangeData);
  });
  return finalResult;
};

/**
 *
 * @param {*} CVR Metadata
 * @returns timeline massaged data
 */
const updatedTimelineData = (data, time) => {
  let finalResult = [];
  data.forEach((range) => {
    const rangeData = {};
    rangeData.start = new Date(
      Utils.getDate(moment(range?.start).add(time).unix())
    );
    rangeData.end = new Date(
      Utils.getDate(moment(range?.end).add(time).unix())
    );
    rangeData.type = range.type || '';
    rangeData.id = uuidv4();
    rangeData.deviceId = range.deviceId;
    // TODO: delete later
    Object.preventExtensions(range);
    finalResult.push(rangeData);
  });
  return finalResult;
};

export { getTimelineData, getEventsScubberData, updatedTimelineData };
