import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';



export const useCustOrgLocationArea = create(
  persist(
    (set, get) => ({
      deviceWallSelectedLocation: {},
      deviceWallSelectedArea: {},
      
      getDeviceWallSelectedLocation: () => {
        const location = get().deviceWallSelectedLocation;

        if (!location) return null;

        return location
      },

      setDeviceWallSelectedLocation: (location) => {
        if (!_.isObject(location)) return;
        
        set({
          deviceWallSelectedLocation: location,
        });
      },
      getDeviceWallSelectedArea: () => {
        const area = get().deviceWallSelectedArea;

        if (!area) return null;

        return area;
      },

      setDeviceWallSelectedArea: (area) => {
        if (!_.isObject(area)) return;

        set({
          deviceWallSelectedArea: area,
        });
      },
      resetDeviceWallSelectedLocation: () => {        
        set({
          deviceWallSelectedLocation: {},
        });
      },
      resetDeviceWallSelectedArea: () => {
        set({
          deviceWallSelectedArea: {},
        });
      },
     
    }),
    {
      name: 'net.duclo.vms.useCustOrgLocationArea',
      version: '1.2',
    }
  )
);
