import { Outlet } from 'react-router-dom';
import Cameras from './Cameras';
import CameraList from './CameraList';
import CameraDashboard from './CameraDashboard';
import VideoWall from './VideoWall';
import CameraWallDevices from './CameraWallDevices';

const CamerasPage = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { CameraList, CameraDashboard, Cameras, CamerasPage, VideoWall, CameraWallDevices };
