import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AppDefaults } from './helpers';
import { constants } from './helpers';
import constantsMegatron from './helpers/configuration/translations/megatron/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
      en: {
        translations:
          process.env.REACT_APP_PROJECT == AppDefaults?.PROJECT_DUCLO
            ? constants
            : process.env.REACT_APP_PROJECT == AppDefaults?.PROJECT_MEGATRON &&
            constantsMegatron,
      },
    },
    debug: true,
    useSuspense: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
