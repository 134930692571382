import { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
} from 'react-icons/hi2';

const SiteToast = ({
  customCss,
  position,
  show,
  delay,
  title,
  body,
  onClickHandle = false,
  onCloseCallback,
  variant = 'error',
  setUserMsg
}) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    show && setShowModal(true);
  }, [show]);

  const callbackMethod = (e) => {
    e?.stopPropagation();
    onClickHandle && onClickHandle(1);
  };

  const onCloseModal = (e) => {
    e?.stopPropagation();
    setShowModal(false);
    onCloseCallback && onCloseCallback(false);
    setUserMsg && setUserMsg('')
  };

  const getToastIcon = () => {
    let iconComponent = (
      <HiOutlineCheckCircle
        size={15}
        fill={getComputedStyle(document.documentElement).getPropertyValue(
          '--warning_48'
        )}
      />
    );

    switch (variant?.toUpperCase()) {
      case 'ERROR':
        iconComponent = (
          <HiOutlineExclamationCircle
            size={15}
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_64'
            )}
          />
        );
        break;

      case 'SUCCESS':
        iconComponent = (
          <HiOutlineCheckCircle
            size={15}
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              '--success_48'
            )}
          />
        );
        break;

      default:
      // Use default icon
    }

    return iconComponent;
  };

  const getClassNames = () => {
    let classes = 'm-1 w-100';

    switch (variant?.toUpperCase()) {
      case 'ERROR':
        classes += ' error-toast';
        break;

      case 'SUCCESS':
        classes += ' success-toast';
        break;

      default:
        classes += ' info-toast';
      // No additional classes needed
    }

    return classes;
  };

  return (
    <ToastContainer className={`m-1 ${customCss}`} position={position}>
      <Toast
        onClick={(e) => callbackMethod(e)}
        className={getClassNames()}
        onClose={(e) => onCloseModal(e)}
        autohide
        show={showModal}
        delay={delay}
      >
        <Toast.Header>
          {getToastIcon()}
          <span className="me-auto">{title}</span>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default SiteToast;
