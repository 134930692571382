import { useState, useEffect, useContext } from 'react';
import One from './StepOne';
import Three from './StepThree';
import Two from './StepTwo';
import { DetailsContext } from './DetailsContextProvider';
import { getPassedDeviceInfoFromDeviceList } from '../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';

const FormSteps = (props) => {
  const [step, setstep] = useState(1);
  const reloadData = () => props.reloadData();
  const hideModal = () => props.hideModal();
  const { setInfo } = useContext(DetailsContext);
  const passedDeviceInfoFromDeviceList = useSelector(
    getPassedDeviceInfoFromDeviceList
  );
  const handleDiscoverDevices = () => props.onDiscoverDevices();
  localStorage.setItem('modalOpen', 1);

  useEffect(() => {
    if (passedDeviceInfoFromDeviceList) {
      setstep(3);
      setInfo({
        serialNo: passedDeviceInfoFromDeviceList.serialNo,
        macAddress: passedDeviceInfoFromDeviceList.macAddress,
        manufacturer: passedDeviceInfoFromDeviceList.manufacturer,
        model: passedDeviceInfoFromDeviceList.modelNumber,
        deviceType: passedDeviceInfoFromDeviceList.deviceType.toLowerCase(),
        step2info: {
          deviceName: passedDeviceInfoFromDeviceList.deviceName,
          locationId: passedDeviceInfoFromDeviceList.locationId,
          areaId: passedDeviceInfoFromDeviceList.areaId,
          timezone: passedDeviceInfoFromDeviceList.properties.timezone,
        },
      });
    }
  }, [passedDeviceInfoFromDeviceList]);

  const nextStep = () => {
    setstep(step + 1);
  };

  const prevStep = () => {
    setstep(step - 1);
  };
  const firstStep = () => {
    setstep(step - 2);
  };

  switch (step) {
    case 1:
      return (
        <>
          <One
            reloadData={() => reloadData()}
            hideModal={() => hideModal()}
            nextstepEvent={nextStep}
          />
        </>
      );
    case 2:
      return (
        <>
          <Two
            reloadData={() => reloadData()}
            hideModal={() => hideModal()}
            nextstep={nextStep}
            prevstep={prevStep}
          />
        </>
      );

    case 3:
      return (
        <>
          <Three
            reloadData={() => reloadData()}
            hideModal={() => hideModal()}
            firstStep={firstStep}
            onDiscoverDevices={handleDiscoverDevices}
          />
        </>
      );

    default:
      return null;
  }
};

export default FormSteps;
