import { ErrorMessage, useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { constants } from '../../helpers';

const LocationSelectField = ({
  label,
  options,
  classes,
  defaultOptionValue,
  defaultOptionLabel,
  isFromOrg = false,
  placeholderClass,
  placeholderOptionClass,
  required = false,
  labelclasses,
  visibleLocHelperText = false,
  locHelperText = '',
  ...props
}) => {
  const [field, meta] = useField(props);

  /*=== Generate selection options*/
  const generateOptions = () => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((item, i) => {
        return (
          <option key={i} value={item.location}>
            {item.label}
          </option>
        );
      });
    }
    return null;
  };

  return (
    <div className={`${!isFromOrg && 'select-field-wrap row'} ${classes} ${(props.beforeinputicon || props.afterinputicon) ? 'input-with-icons' : 'position-relative'}`}>
      {(props.beforeinputicon)
        &&
          <div className="icon-before-timezone">
            {props.beforeinputicon}
          </div>
      }
      {label && (
        <label
          className="col-sm-3 form-label col-form-label"
          htmlFor={field?.name}
        >
          {label}
        </label>
      )}
      <div className={label ? 'col-sm-7' : 'col-sm-12'}>
        {required && !props?.value && defaultOptionLabel &&
          <label className={`${labelclasses} placeholder-wrapper required form-select-label`} htmlFor={field?.name}>
            {defaultOptionLabel}
          </label>
        }
        <Form.Select
          className={`form-control shadow-none ${placeholderClass} ${
            ((meta?.touched && !meta?.value?.toString()?.length) || (visibleLocHelperText && locHelperText === constants.AUTO_TIMEZONE_FAIL_MESSAGE)) && "is-invalid"
          } ${labelclasses}`}
          {...field}
          {...props}
        >
          {
            required ?
            <option hidden></option> :
            <option value="">
              {defaultOptionLabel
                ? defaultOptionLabel
                : constants.SELECT_FIELD_DEFAULT_VALUE_LABEL}
            </option>
          }
          {generateOptions()}
        </Form.Select>
        {((meta?.touched && !meta?.value?.toString()?.length) || (visibleLocHelperText && locHelperText === constants.AUTO_TIMEZONE_FAIL_MESSAGE)) && <div className="error error-select-timezone">{constants.TIMEZONE_REQUIRED_ERROR_MESSAGE}</div>}      </div>
    </div>
  );
};

export default LocationSelectField;
