import { Outlet } from 'react-router-dom';
import ListTable from './ListTable';
import OrderDetails from './Details';
import OrderHistory from './History';

const Orders = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { ListTable, OrderDetails, OrderHistory, Orders };
