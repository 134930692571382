import React from 'react';

const Stepper = (props) => {
  return (
    <div className="d-flex gap-1 flex-row">
      {props.steps.map((step) => {
        return (
          <div
            style={{
              height: props.height,
              borderRadius: props.borderRadius,
              background: step.active ? 'var(--primary_40)' : 'var(--primary_88)',
              width: props.width,
              marginTop: props.marginTop
            }}
          ></div>
        );
      })}
    </div>
  );
};

Stepper.defaultProps = {
  borderRadius: '16px',
  background: '#0052CC',
  height: '4px',
  marginTop: '1rem'
};

export default Stepper;
