import { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';

import { SiteSpinner, Tags } from '../../../components/common';
import { constants, Utils } from '../../../helpers';

import { useOrganizations } from '../../../store/OrganizationsStore';

import {
  HiOutlineExclamationCircle,
  HiOutlineMinusCircle,
} from 'react-icons/hi';
import './ManageTags.scss';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const MAX_CHARACTERS_ALLOWED = 25;

const ManageTags = ({
  openModal,
  clipCategory = 'IM',
  setAreTagsModified,
  shouldSaveTags = false,
  setShowButtonLoader,
  callback,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [suppressButtonLoader, setSuppressButtonLoader] = useState(false);
  const [tags, setTags] = useState([]);
  const [systemTags, setSystemTags] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [newTag, setNewTag] = useState(null);
  const [newTagCharCount, setNewTagCharCount] = useState(0);
  const [tagNameExists, setTagNameExists] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isTagRemoved, setIsTagRemoved] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const newTagInputRef = new useRef();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );

  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchCurrentTags = async () => {
      try {
        setShowLoader(true);

        const res = await axios.get(
          `/partner/orgs/${orgDetails?.orgId}/tags?category=${clipCategory}`,
          Utils.requestHeader()
        );
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          if (
            Array.isArray(responseData?.data?.tags) &&
            responseData?.data?.tags?.length > 0
          ) {
            const currentTags = responseData?.data?.tags;
            setSystemTags(
              currentTags.filter((tag) => tag?.isReadOnly === true)
            );
            setCustomTags(
              currentTags.filter((tag) => tag?.isReadOnly === false)
            );
            setTags([...currentTags]);
          } else {
            setTags([]);
          }
        } else {
        }
      } catch (err) {
      } finally {
        setShowLoader(false);
      }
    };

    fetchCurrentTags();
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (shouldSaveTags === true) {
      saveTagsHandler();
    }
  }, [shouldSaveTags]);

  const updateTag = (newName, tagId) => {
    let currentTags = tags;

    if (
      !newName ||
      !tagId ||
      !Array.isArray(currentTags) ||
      currentTags.length < 1
    ) {
      return;
    }

    const tagIndex = currentTags.findIndex((tag) => tag.tagId === tagId);

    if (tagIndex !== -1) {
      currentTags[tagIndex].name = newName;
      setTags([...currentTags]);
      setAreTagsModified && setAreTagsModified(true);
    }
  };

  const deleteTag = (tagName) => {
    let currentTags = tags;

    if (!tagName || !Array.isArray(currentTags) || currentTags.length < 1) {
      return;
    }

    const tagIndex = currentTags.findIndex((tag) => tag.name === tagName);

    if (tagIndex !== -1) {
      currentTags.splice(tagIndex, 1);

      setTags(currentTags.length > 0 ? [...currentTags] : []);
      setCustomTags(
        currentTags.length > 0
          ? [...currentTags.filter((tag) => tag.isReadOnly === false)]
          : []
      );
      setIsModified(true);
      setIsTagRemoved(true);
      setAreTagsModified && setAreTagsModified(true);
    }
  };

  const saveTagsHandler = async () => {
    try {
      if (!suppressButtonLoader) {
        setShowButtonLoader && setShowButtonLoader(true);
      }

      const finalTags = tags.length > 0 ? [...tags] : [];
      const tagIndex = finalTags.findIndex(
        (tag) => tag.name?.toLowerCase() === newTag?.toLowerCase()
      );

      if ((newTag && tagIndex === -1) || finalTags.length < 1) {
        if (newTag) {
          finalTags.push({
            name: newTag,
          });
        }
      } else {
        if (newTag) {
          setTagNameExists(true);
          setIsModified(false);
          setAreTagsModified && setAreTagsModified(false);
          throw new Error(`Tag ${newTag} already exists`);
        }
        if (isTagRemoved) {
          setIsModified(true);
        }
      }

      const reqBody = {
        tags: finalTags,
      };

      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/tags/${
          clipCategory === 'IM' ? 'incidents' : 'clips'
        }`,
        reqBody,
        Utils.requestHeader()
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        setCustomTags(
          resData?.data?.tags?.filter((tag) => tag?.isReadOnly === false)
        );
        setTags([...resData.data.tags]);
        setNewTag(null);
        setNewTagCharCount(0);
        setIsModified(false);
        newTagInputRef.current.value = null;
        setAreTagsModified && setAreTagsModified(false);
      } else {
        Utils.vmsLogger().error(
          `ERROR: ${resData?.meta?.code} - ${resData?.meta?.desc}`
        );
      }
    } catch (err) {
      // TODO: generate error message
      Utils.vmsLogger().error(err);
    } finally {
      setSuppressButtonLoader(false);
      setShowButtonLoader && setShowButtonLoader(false);
      callback && callback(false);
    }
  };

  const getCustomTagDescription = () => {
    return clipCategory === 'IM'
      ? constants.INCIDENTS_MANAGE_TAGS_MODAL_INCIDENTS_CUSTOM_TAGS_DESCRIPTION
      : constants.INCIDENTS_MANAGE_TAGS_MODAL_CLIPS_CUSTOM_TAGS_DESCRIPTION;
  };

  return (
    <>
      {!formSubmitted && (
        <form
          onSubmit={handleSubmit(saveTagsHandler)}
          className="manage-tags-form"
        >
          <div className="mb-3 manage-tags-modal-description">
            {constants.INCIDENTS_MANAGE_TAGS_MODAL_DESCRIPTION}
          </div>
          <Container>
            {showLoader ? (
              <div className="d-flex justify-content-around">
                <SiteSpinner height="50px" width="50px" />
              </div>
            ) : (
              <>
                {/* System Tags */}
                {clipCategory === 'IM' && (
                  <Row className="mb-3">
                    <div className="px-0 manage-tags-modal-system-tags-title">
                      {constants.INCIDENTS_MANAGE_TAGS_MODAL_SYSTEM_TAGS_TITLE}
                      <div className="manage-tags-modal-system-tags-description">
                        {
                          constants.INCIDENTS_MANAGE_TAGS_MODAL_SYSTEM_TAGS_DESCRIPTION
                        }
                      </div>
                    </div>
                    <Tags
                      tagCategory={clipCategory}
                      systemTagsOnly={true}
                      selectedTagsOnly={true}
                      preSelectedTags={systemTags}
                    />
                  </Row>
                )}
                {/* Custom Tags */}
                {clipCategory === 'IM' && (
                  <Row>
                    <div className="mb-3 px-0 manage-tags-modal-custom-tags-title">
                      {constants.INCIDENTS_MANAGE_TAGS_MODAL_CUSTOM_TAGS_TITLE}
                      <div className="manage-tags-modal-custom-tags-description">
                        {
                          constants.INCIDENTS_MANAGE_TAGS_MODAL_CUSTOM_TAGS_DESCRIPTION
                        }
                      </div>
                    </div>
                  </Row>
                )}
                {Array.isArray(customTags) &&
                  customTags.map((tag) => (
                    <Row key={tag?.tagId}>
                      <div className="d-flex px-0 justify-content-start align-items-center">
                        <div className="mb-3 px-0 tag-input-wrapper">
                          <input
                            onChange={(e) => {
                              let value = e?.target?.value;
                              e.preventDefault();
                              if (tag.count < 1) {
                                updateTag(value, tag.tagId);
                              }
                            }}
                            placeholder={
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_ENTER_TAG_NAME_PLACEHOLDER
                            }
                            className={`manage-tags-input tag-input ${
                              tag.count > 0 ? 'read-only' : ''
                            }`}
                            value={tag?.name}
                            readOnly={tag.count > 0}
                          />
                        </div>
                        <div
                          className="mb-3 px-0 text-center delete-btn-wrapper"
                          onClick={(e) => {
                            e?.preventDefault();
                            if (tag.count < 1) {
                              deleteTag(tag.name);
                            }
                          }}
                        >
                          <HiOutlineMinusCircle
                            className={`delete-btn-icon ${
                              tag.count > 0 ? 'read-only' : ''
                            }`}
                            color={
                              tag.count > 0
                                ? getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--grayscale_56')
                                : getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--error_64')
                            }
                            size={20}
                          />
                        </div>
                      </div>
                    </Row>
                  ))}
                {/* New Tag */}
                <Row>
                  <div className="mb-3 px-0 tag-input-full-wrapper">
                    <input
                      {...register('tag', { required: false })}
                      ref={newTagInputRef}
                      onChange={(e) => {
                        e.preventDefault();
                        let value = e.target.value;

                        setTagNameExists(false);

                        if (!value) {
                          setIsModified(false);
                          setAreTagsModified && setAreTagsModified(false);
                          return;
                        }

                        if (value?.length <= MAX_CHARACTERS_ALLOWED) {
                          setNewTag(value);
                          setNewTagCharCount(value?.length);
                          setIsModified(true);
                          setAreTagsModified && setAreTagsModified(true);
                        }
                      }}
                      maxLength={MAX_CHARACTERS_ALLOWED}
                      placeholder={
                        constants.INCIDENTS_MANAGE_TAGS_MODAL_ENTER_TAG_NAME_PLACEHOLDER
                      }
                      className={`manage-tags-input tag-input-full${
                        tagNameExists ? ' error-input' : ''
                      }`}
                    />
                    <div className="inline-message-container">
                      <div className="d-flex justify-content-start align-items-center">
                        <HiOutlineExclamationCircle
                          className={`error-icon${
                            !tagNameExists && !errors?.tag ? ' hidden' : ''
                          }`}
                        />
                        {errors.tag && (
                          <div className="error-inline-message">
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_VALID_TAG_NAME_ERROR
                            }
                          </div>
                        )}
                        {tagNameExists && (
                          <div className="error-inline-message">
                            {
                              constants.INCIDENTS_MANAGE_TAGS_MODAL_TAG_ALREADY_EXISTS
                            }
                          </div>
                        )}
                        &nbsp;
                      </div>
                      <div className="px-0 manage-tags-input-char-limit text-right">
                        {newTagCharCount + `/${MAX_CHARACTERS_ALLOWED}`}
                      </div>
                    </div>
                  </div>
                </Row>
              </>
            )}
          </Container>
        </form>
      )}
    </>
  );
};

export default ManageTags;
