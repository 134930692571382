import { constants } from '../../../helpers';
import {
  HiOutlineDocument,
  HiOutlineDocumentText,
  HiOutlineVideoCamera,
} from 'react-icons/hi';
import { ReactComponent as ImageType } from '../../../assets/images/icons/ImageType.svg';

import './noteTypeMap.scss';

const noteTypeMap = {
  VIDEO: (
    <div className="node-type-field">
      <HiOutlineVideoCamera className="node-type-icon node-type-video" />
      {constants.INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_VIDEO}
    </div>
  ),
  IMAGE: (
    <div className="node-type-field">
      <ImageType className="node-type-icon node-type-image" />
      {constants.INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_IMAGE}
    </div>
  ),
  DOCUMENT: (
    <div className="node-type-field">
      <HiOutlineDocument className="node-type-icon node-type-document" />
      {constants.INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_DOCUMENT}
    </div>
  ),
  COMMENT: (
    <div className="node-type-field">
      <HiOutlineDocumentText className="node-type-icon node-type-comment" />
      {constants.INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_COMMENT}
    </div>
  ),
};

export default noteTypeMap;
