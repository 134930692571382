import React from "react";

import DetailsContextProvider from "../addDevice/DetailsContextProvider";
import DataContextProvider from "../addDevice/DataContextProvider";
import DeviceList from "./DeviceList";
import '../device.scss';

const Device = () => {
  return (
    <DetailsContextProvider>
      <DataContextProvider>
        <DeviceList />
      </DataContextProvider>
    </DetailsContextProvider>
  );
};

export default Device;
