import { createSlice } from '@reduxjs/toolkit';
import DefaultPolicies from './DefaultPolicies';

const initialState = {
  value: DefaultPolicies,
};
export const policieSlice = createSlice({
  name: 'loggedInUserPolicies',
  initialState,
  reducers: {
    updatePolicies: (state, action) => {
      state.value = action.payload;
    },
    resetPolicies: () => initialState,
  },
});

export const { updatePolicies, resetPolicies } = policieSlice.actions;
export const getPolicies = (state) => state.loggedInUserPolicies.value;
export default policieSlice.reducer;
