import { useEffect, useState } from 'react';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import { TextBlock } from '../../../components/common';
import { constants } from '../../../helpers';

const StatusFilters = ({ statusFilters, initialLoad, updateFilters }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters([
      {
        key: 'open',
        show: true,
        displayText: constants.INCIDENTS_MODAL_STATUS_FILTER_OPEN_LABEL,
      },
      {
        key: 'closed',
        show: true,
        displayText: constants.INCIDENTS_MODAL_STATUS_FILTER_CLOSED_LABEL,
      },
    ]);
  }, []);

  return (
    <>
      {initialLoad === true
        ? Array.isArray(filters) &&
          filters.map((status) => (
            <TextBlock
              key={`textblock-${status?.key}`}
              className="filters-content-item"
            >
              <div className="filters-content-item-details">
                {status.displayText}
              </div>
              <Checkbox
                key={`checkbox-${status?.key}`}
                className="filters-content-item-checkbox"
                name={status.key}
                defaultChecked={true}
                checked={status.show}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 26 },
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_primary'),
                  },
                }}
                onChange={(e) => {
                  if (!e) return;

                  const updatedFilters = [...filters];
                  const { name, checked } = e.target;
                  const statusIndex = updatedFilters.findIndex(
                    (s) => s.key === name
                  );

                  updatedFilters[statusIndex].show = checked;
                  updateFilters && updateFilters([...updatedFilters]);
                }}
              />
            </TextBlock>
          ))
        : Array.isArray(statusFilters) &&
          statusFilters.map((status) => (
            <TextBlock
              key={`textblock-${status?.key}`}
              className="filters-content-item"
            >
              <div className="filters-content-item-details">
                {status.displayText}
              </div>
              <Checkbox
                key={`checkbox-${status?.key}`}
                className="filters-content-item-checkbox"
                name={status.key}
                defaultChecked={true}
                checked={status.show}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 26 },
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_primary'),
                  },
                }}
                onChange={(e) => {
                  if (!e) return;

                  const filters = [...statusFilters];
                  const { name, checked } = e.target;
                  const statusIndex = filters.findIndex((s) => s.key === name);

                  filters[statusIndex].show = checked;
                  updateFilters && updateFilters([...filters]);
                }}
              />
            </TextBlock>
          ))}
    </>
  );
};

export default StatusFilters;
